import { ICollege } from '../models/ICollege';
import { IUniversity } from '../models/IUniversity';

export function extractUniversities(collegeData: ICollege[]) {
  return collegeData.map((college: ICollege) => {
    const university: IUniversity = {
      universityName: college.name,
      universityId: college.id,
    };
    return university;
  }) as IUniversity[];
}

export function extractUniversity(collegeData: ICollege) {
  return collegeData.name;
}
