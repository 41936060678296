import React from 'react';
import {
  Routes,
  Route,
} from 'react-router-dom';
import {
  CampusDrive, CreateCampusDrive, Dashboard, Evaluator, Exams, Home, ListCampusDrive, ListReview, Login, Roles,
} from '../views';
import RoutesConstants from '../constants/routesConstants';
import ListExams from '../views/homeView/examsView/listExamsView/ListExams';
import CreateExams from '../views/homeView/examsView/createExamsView/CreateExams';
import EditExams from '../views/homeView/examsView/editExamsView/EditExams';
import ViewExams from '../views/homeView/examsView/viewExamsView/ViewExams';
import ListRoles from '../views/homeView/rolesView/listRoles/ListRoles';
import Settings from '../views/homeView/settingsView/Settings';
import CandidateLogin from '../views/candidateView/candidateLoginView/CandidateLogin';
import EvaluateExam from '../views/homeView/examsView/evaluateExamView/EvaluateExam';
// import EvalOeqTypeQuestionView from '../components/QuestionViews/EvalOeqTypeQuestionView/EvalOeqTypeQuestionView';
import ExamReviewPage from '../components/examReviewPage/examReviewPage';
import CandidateStart from '../views/candidateView/candidateStartView/CandidateStart';
import CandidateStartError from '../views/candidateView/candidateStartErrorView/CandidateStartError';
import EditCampusDrive from '../views/homeView/campusDriveView/EditCampusDriveView/EditCampusDrive'
// import CandidateQuestion from '../components/candidateExamView/CandidateQuestion';
import CandidateReviewPage from '../components/candidateReviewPage/candidateReviewPage';
import CandidateEnd from '../views/candidateView/candidateEndView/CandidateEnd';
import Candidate from '../views/candidateView/Candidate';
import CandidateExamView from '../views/candidateView/candidateExamView/CandidateExamView';
import CandidateTermination from '../views/candidateView/candidateTerminationView/CandidateTermination';
import CandidateMarkSheet from '../views/adminView/candidateMarkSheet/CandidateMarkSheet';
import CandidateTakingExam from '../components/candidateExamView/CandidateTakingExam';
import FilterCandidates from '../components/filterCandidates/filterCandidates';
import Evaluation from '../components/evaluation/evaluation';
import ReEvaluateExamPage from '../components/reevaluateExamPage/ReEvaluateExamPage';
// import CandidateEnd from '../views/candidateView/candidateEndView/CandidateEnd';

export default function AppRoute() {
  const routesConstant = new RoutesConstants();

  return (
    <Routes>
      <Route path={routesConstant.HOME} element={<Home />}>
        <Route index element={<Dashboard />} />
        <Route path={routesConstant.ROLES} element={<Roles />}>
          <Route index element={<ListRoles />} />
        </Route>
        <Route path={routesConstant.CAMPUS_DRIVE} element={<CampusDrive />}>
          <Route index element={<ListCampusDrive />} />
          <Route
            path={routesConstant.CAMPUS_DRIVE + routesConstant.CAMPUS_DRIVE_CREATE}
            element={<CreateCampusDrive />}
          />
          <Route path={`${routesConstant.CAMPUS_DRIVE}/:campusDriveId${routesConstant.EXAM_EDIT}`} element={<EditCampusDrive />} />
        </Route>
        <Route path={routesConstant.EXAMS} element={<Exams />}>
          <Route index element={<ListExams />} />
          <Route
            path={`${routesConstant.EXAMS + routesConstant.CAMPUS_DRIVE}/:campusDriveId`}
            element={<ListExams />}
          />
          <Route path={`${routesConstant.EXAMS}/:examId`} element={<ViewExams />} />
          <Route path={`${routesConstant.EXAMS}${routesConstant.EVALUATE}/:examId`} element={<EvaluateExam />} />
          <Route path={`${routesConstant.EXAMS}${routesConstant.REEVALUATE}/:examId`} element={<ReEvaluateExamPage />} />
          <Route path={`${routesConstant.EXAMS}${routesConstant.SCOREBOARD}/:examId`} element={<FilterCandidates />} />
          <Route path={`${routesConstant.EXAMS}/:examId${routesConstant.EXAM_EDIT}`} element={<EditExams />} />
          {/* <Route path={`${routesConstant.EXAMS}/:examId${routesConstant.QUESTION}`} element={<EvalOeqTypeQuestionView />} /> */}
          <Route path={`${routesConstant.EXAMS}/:examId${routesConstant.QUESTION}`} element={<Evaluation />} />
          <Route path={`${routesConstant.EXAMS}${routesConstant.EXAMREVIEW}`} element={<ExamReviewPage />} />
          <Route path={`${routesConstant.EXAMS}${routesConstant.CANDIDATE_MARK_SHEET}/:examId/:candidateId`} element={<CandidateMarkSheet />} />
          <Route
            path={routesConstant.EXAMS + routesConstant.EXAM_CREATE}
            element={<CreateExams />}
          />
          {/* <Route path={`${routesConstant.EXAMS}${routesConstant.QUESTION}`} element={<CodeEditorTextAreas />} /> */}
        </Route>
        <Route path={routesConstant.SETTINGS} element={<Settings />} />
      </Route>
      <Route path={routesConstant.CANDIDATE} element={<Candidate />}>
        <Route path={`${routesConstant.CANDIDATE}${routesConstant.CANDIDATE_LOGIN}`} element={<CandidateLogin />} />
        <Route path={`${routesConstant.CANDIDATE}${routesConstant.CANDIDATE_START}`} element={<CandidateStart />} />
        <Route path={`${routesConstant.CANDIDATE}${routesConstant.CANDIDATE_START_ERROR}`} element={<CandidateStartError />} />
        <Route path={`${routesConstant.CANDIDATE}${routesConstant.CANDIDATE_TERMINATION}`} element={<CandidateTermination />} />
        <Route path={`${routesConstant.CANDIDATE}${routesConstant.CANDIDATE_END}`} element={<CandidateEnd />} />
        <Route element={<CandidateExamView />}>
          <Route path={`${routesConstant.CANDIDATE}${routesConstant.CANDIDATE_QUESTION}`} element={<CandidateTakingExam />} />
          <Route path={`${routesConstant.CANDIDATE}${routesConstant.CANDIDATE_REVIEW}`} element={<CandidateReviewPage />} />
        </Route>
      </Route>
      <Route path={routesConstant.LOGIN} element={<Login />} />
      <Route path={routesConstant.EVALUATE} element={<Evaluator />}>
        <Route index element={<EvaluateExam />} />
        <Route path={`${routesConstant.EVALUATE}${routesConstant.EXAMREVIEW}/:examId`} element={<ListReview />} />
      </Route>
    </Routes>
  );
}
