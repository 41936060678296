import React, { useEffect, useRef, useState } from 'react';
import './EditCampusDrive.css';
// import { getMonth, getYear } from 'date-fns';
import { useNavigate, useParams } from 'react-router';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import {
  useForm, SubmitHandler, useWatch, Controller,
} from 'react-hook-form';
import { AxiosResponse } from 'axios';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import {
  getAllUniversity,
  getCampusDrive,
  updateCampusDrive,
} from '../../../../services/campusDriveService';
import { IUniversity } from '../../../../models/IUniversity';
import { ICampusDrive } from '../../../../models/ICampusDrive';
import { extractUniversities } from '../../../../hooks/collegeHook';
import { ICollege } from '../../../../models/ICollege';
import RoutesConstants from '../../../../constants/routesConstants';
import { AddCollege, FieldSkeleton, SpinCheckButton } from '../../../../components';
import { setCollegeDialogTitle, setCollegeDialogVisible, setUpdateCollegeData } from '../../../../store/settingsStore';
import { useAppDispatch, useAppSelector } from '../../../../store/storeHooks';

export default function EditCampusDrive() {
  const navigate = useNavigate();
  const { campusDriveId }: any = useParams();
  const dispatch = useAppDispatch();
  const routesConstant = new RoutesConstants();
  const [universities, setUniversities] = useState<IUniversity[]>([]);
  const [loadingStatus, setLoadingStatus] = useState<'LOADED' | 'NOT_LOADING' | 'LOADING'>('NOT_LOADING');
  // const [startDate] = useState<any>(null);
  // const [endDate] = useState<any>(null);
  // eslint-disable-next-line no-unused-vars
  const [calenderStartDate, setCalenderStartDate] = useState<string>('');
  const collegeDialogVisible = useAppSelector((state) => state.settings.collegeDialogVisible);
  const collegeDialogTitle = useAppSelector((state) => state.settings.collegeDialogTitle);
  const [counter, setCounter] = useState<number>(1);
  // eslint-disable-next-line no-unused-vars
  const [calenderEndDate, setCalenderEndDate] = useState<string>('');
  const whiteSpaceRegex = /.\S./
  const toastRef = useRef<any>(null);
  const defaultValues = {
    driveName: '',
    university: '',
    driveDuration: [],
  };
  const {
    register, handleSubmit, control, setValue, formState: { errors }, setError, clearErrors, reset,
  } = useForm<ICampusDrive>({ defaultValues });

  const name = useWatch({
    control,
    name: 'driveName',
  });

  const university = useWatch({
    control,
    name: 'university',
  });
  const Range:any = useWatch({
    control,
    name: 'driveDuration',
  });

  useEffect(() => {
    getCampusDrive(campusDriveId)
      .then((response: AxiosResponse) => {
        setValue('driveName', response.data.name);
        setValue('university', response.data.College);
        const dateArray:Date[] = [];
        dateArray[0] = new Date(response.data.startDate);
        dateArray[1] = new Date(response.data.endDate);
        setCalenderStartDate(response.data.startDate);
        setCalenderEndDate(response.data.endDate);
        setValue('driveDuration', dateArray);
      })
      .catch((e) => {
        toastRef.current.show({
          severity: 'error',
          summary: 'Something went wrong',
          detail: e.response.data.message,
        });
      });

    getAllUniversity()
      .then((response: AxiosResponse) => {
        setUniversities(extractUniversities(response.data.colleges as ICollege[]));
      })
      .catch((e) => {
        toastRef.current.show({
          severity: 'error',
          summary: 'Something went wrong',
          detail: e.response.data.message,
        });
      });
  }, []);

  const goBackClick = () => {
    navigate(-1);
  };

  const goBackEnter = () => {
    // @ts-ignore
    if (event.key === 'Enter') {
      goBackClick();
    }
  };

  const onDriveDurationUpdate = (onChange: any, ev: any) => {
    onChange(ev);
    const { value } = ev;
    if (counter % 2 === 1) {
      setCalenderStartDate(value[0]);
      setCounter(counter + 1)
    } else {
      setCalenderEndDate(value[0]);
      setCounter(counter + 1);
    }
    if (value == null) {
      setError('driveDuration', { type: 'required', message: 'Drive duration is required.' });
    } else if (value[0] == null || value[1] == null) {
      setError('driveDuration', { type: 'range-required', message: 'Drive duration expects a date range.' });
    } else {
      clearErrors('driveDuration');
    }
  };

  const onSubmit: SubmitHandler<ICampusDrive> = (data: any) => {
    setLoadingStatus('LOADING');
    const { driveName, driveDuration } = data;
    const uni = data.university;
    uni.universityId = data.university.universityId;
    data.university = uni;
    data.id = campusDriveId;
    const nameRegex = /^[a-zA-Z][a-zA-Z\s0-9_-]+$/i
    let error = false;
    if (driveDuration && (driveDuration[0] == null || driveDuration[1] == null)) {
      setError('driveDuration', { type: 'range-required', message: 'Drive duration expects a date range.' });
      error = true;
    }
    if (!nameRegex.test(driveName)) {
      setError('driveName', { type: 'required', message: 'Drive Name should be alphanumeric starting with an alphabet.' })
      error = true;
    } else {
      clearErrors('driveDuration');
    }

    if (!error) {
      updateCampusDrive(data)
        .then(() => {
          setLoadingStatus('LOADED');
          setTimeout(() => {
            navigate(routesConstant.CAMPUS_DRIVE);
          }, 500);
        })
        .catch(() => {});
      reset();
    } else {
      setLoadingStatus('NOT_LOADING');
    }
  };

  const collegeOptionTemplate = (option: any) => (
    <div className="country-item">
      <div>{option.universityName}</div>
    </div>
  )

  const selectedCollegeTemplate = (option: any, props: { placeholder: string }) => {
    if (option) {
      return (
        <div className="country-item country-item-value">
          <div>{option.universityName}</div>
        </div>
      );
    }

    return (
      <span>
        {/* eslint-disable-next-line react/prop-types */}
        {props.placeholder}
      </span>
    );
  }

  const handleHideAddCollege = () => {
    dispatch(setCollegeDialogVisible(false));
  };

  const handleAddCollegeClick = () => {
    dispatch(setUpdateCollegeData(null));
    dispatch(setCollegeDialogTitle('Add'));
    dispatch(setCollegeDialogVisible(true));
  }

  const toast = (severity: string, summary: string, detail: string) => {
    toastRef.current.show({
      severity,
      summary,
      detail,
      life: 3000,
    })
  }

  return (
    <div className="create-campus-drive-view h-full flex flex-column align-items-center justify-content-start">
      <div className="header w-full flex flex-column align-items-start justify-content-center">
        <div role="button" tabIndex={0} onClick={goBackClick} onKeyDown={goBackEnter} className="back-navigation flex align-items-center justify-content-start cursor-pointer">
          <i className="pi pi-angle-left font-semibold" />
          <div className="label font-medium">
            Back
          </div>
        </div>

        <div className="title">
          Edit new campus drive
        </div>
      </div>

      <div className="create-drive-form w-full">
        <form onSubmit={handleSubmit(onSubmit)}>
          {
            (name.length === 0)
            && <FieldSkeleton label="Name" />
          }
          {
            (name.length > 0)
            && (
              <div className="field drive-name">
                <div className="label">
                  Name
                </div>
                <InputText {...register('driveName', { required: 'Drive name is required.', pattern: { value: whiteSpaceRegex, message: 'Invalid Drive Name' } })} className="input" type="text" placeholder="Enter a drive name" autoComplete="off" value={name} />
                {
                  errors.driveName?.type === 'required' ? (<small className="error-label p-error">{errors.driveName.message}</small>) : ''
                }
                {
                  errors.driveName?.type === 'pattern' ? (<small className="error-label p-error">{errors.driveName.message}</small>) : ''
                }
              </div>
            )
          }
          {
            (universities.length === 0)
            && <FieldSkeleton label="College" />
          }
          {
            (universities.length > 0)
            && (
              <div className="field university">
                <div className="w-full flex justify-content-between">
                  <div className="label">
                    College
                  </div>
                  <div
                    onClick={handleAddCollegeClick}
                    onKeyPress={() => handleAddCollegeClick}
                    role="button"
                    tabIndex={universities.length}
                    style={{
                      border: 'grey', borderRadius: '100%',
                    }}
                  >
                    <i className="pi pi-plus plus-button" />
                  </div>
                </div>
                <Dropdown {...register('university', { required: 'College is required.' })} value={university} options={universities} className="input" optionLabel="universityName" filter filterBy="universityName" placeholder={university.name} valueTemplate={selectedCollegeTemplate} itemTemplate={collegeOptionTemplate} />
                {/* <Dropdown {...register('university', { required: 'University is required.' })} value={university} className="input" optionLabel="universityName" options={universities} placeholder={university.name} /> */}
                {
                  errors.university?.type === 'required' ? (<small className="error-label p-error">{errors.university.message}</small>) : ''
                }
              </div>
            )
          }
          {
            (Range.length === 0)
            && <FieldSkeleton label="Duration of drive" />
          }
          {
            (Range.length > 0)
            && (
              <div className="field drive-duration">
                <div className="label">
                  Duration of drive
                </div>

                <Controller
                  control={control}
                  name="driveDuration"
                  rules={{
                    required: 'Drive duration is required.',
                  }}
                  render={({ field: { onChange, ref } }) => (
                    <Calendar ref={ref} onChange={(ev) => onDriveDurationUpdate(onChange, ev)} value={Range} className="input" minDate={new Date()} dateFormat="dd-mm-yy" onMonthChange={() => {}} placeholder="Select duration" selectionMode="range" />
                  )}
                />
                {
                  // @ts-ignore
                  errors.driveDuration ? (<small className="error-label p-error">{errors.driveDuration?.message}</small>) : ''
                }
              </div>
            )
          }
          <div className="submit-button-container w-full flex align-items-center justify-content-end">
            <SpinCheckButton styleClass={`submit-button ${loadingStatus === 'LOADING' || loadingStatus === 'LOADED' ? 'icon-present' : ''}`} label="Edit" loadingStatus={loadingStatus} onClick={null} />
          </div>

        </form>
      </div>
      <Toast ref={toastRef} />
      <Dialog header={`${collegeDialogTitle} College`} visible={collegeDialogVisible} style={{ width: '590px', height: '540px', borderRadius: '8px' }} onHide={handleHideAddCollege}>
        <AddCollege toast={toast} />
      </Dialog>
    </div>
  );
}
