import React, { useEffect, useRef, useState } from 'react';
import { Toast } from 'primereact/toast';
import { AxiosResponse } from 'axios';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { TiWarningOutline } from 'react-icons/ti';
import { ICollege } from '../../models/ICollege';
import DeleteIcon from '../../assets/icons/DeleteIcon.svg';
import EditIcon from '../../assets/icons/EditIcon.svg';
import { useAppDispatch, useAppSelector } from '../../store/storeHooks';
import {
  setCollegeData,
  setCollegeDialogTitle,
  setCollegeDialogVisible, setCollegeTotal,
  setUpdateCollegeData,
} from '../../store/settingsStore';
import { deleteUniversity, getPaginationUniversity } from '../../services/campusDriveService';
import { ActionButton } from '../index';
import SpinCheckButton from '../spinCheckButton/SpinCheckButton';

type IListCollegeRowProps = {
  index: number;
  college: ICollege;
  overlayClick: any;
  overlayIndex: number | null;
}

export default function ListCollegesRow({
  college, index, overlayClick, overlayIndex,
}: IListCollegeRowProps) {
  const toastRef = useRef<any>(null);
  const dispatch = useAppDispatch();
  // const currentIndex = useAppSelector((state) => state.settings.currentIndex);
  const collegeStartingNo = useAppSelector((state) => state.settings.collegeStartingPageNo);
  const toggleOverlay = () => {
    overlayClick(index)
  }
  const [deleteLoading, setDeleteLoading] = useState<'LOADING' | 'NOT_LOADING'>('NOT_LOADING');
  const [ongoingExamList, setOngoingExamList] = useState<string[]>();
  const [dialogVisible, setDialogVisible] = useState<boolean>(false);
  const [deleteCollegeVisible, setDeleteCollegeVisible] = useState<boolean>(false);

  const refreshCollegeData = () => {
    // dispatch(setCollegeData(null));
    getPaginationUniversity(collegeStartingNo)
      .then((response: AxiosResponse) => {
        dispatch(setCollegeData(response.data.colleges as ICollege[]));
        dispatch(setCollegeTotal(response.data.len));
      })
      .catch(() => {});
  }

  const editCollege = () => {
    overlayClick(index)
    dispatch(setUpdateCollegeData(college));
    dispatch(setCollegeDialogTitle('Update'));
    dispatch(setCollegeDialogVisible(true));
  }
  const onDeleteClick = () => {
    setDeleteLoading('LOADING')
    setDeleteCollegeVisible(true);
    setDeleteLoading('NOT_LOADING');
  }

  const hideDeleteVisible = () => {
    setDeleteCollegeVisible(false);
  }

  const deleteCollege = () => {
    setDeleteLoading('LOADING')
    deleteUniversity(college.id)
      .then((response: AxiosResponse) => {
        if (response.status === 200) {
          if (response.data.message === 'couldn\'t delete') {
            const listOngoingExam = response.data.examListOngoing.map((e: any) => e.name);
            console.log(listOngoingExam)
            setOngoingExamList(listOngoingExam);
            setDeleteCollegeVisible(false);
            setDialogVisible(true);
            setDeleteLoading('NOT_LOADING')
            // deleteUniversity(college.id,'1')
            //     .then((res: AxiosResponse) =>{
            //       if(res.status === 200) {
            //
            //       }
            //     })
          } else {
            overlayClick(index)
            toastRef.current.show({
              severity: 'success',
              summary: 'Deleted',
              detail: 'College has been deleted successfully!',
            });
            refreshCollegeData();
          }
        } else {
          overlayClick(index)
          toastRef.current.show({
            severity: 'error',
            summary: 'Something went wrong',
            detail: 'Please try again later!',
          });
        }
      })
      .catch(() => {
        overlayClick(index)
        toastRef.current.show({
          severity: 'error',
          summary: 'Something went wrong',
          detail: 'Please try again later!',
        });
      })
  }
  const handleClickOutside = (e:any) => {
    const targetClass = e.target.id;
    if (targetClass !== 'college-dropdown-button' && targetClass !== 'college-dropdown-content-edit' && targetClass !== 'college-dropdown-content-delete') {
      overlayClick(null)
    }
  };

  const dialogClose = () => {
    setDialogVisible(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const checkOverlay = (i: number) => {
    if (overlayIndex) {
      return !(i === overlayIndex)
    }
    return true
  }

  return (
  // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div>
      <div className={`row w-full ${(index + 1) % 5 === 0 ? 'last-row' : ''}`}>
        <div className="name cell">
          {college.name}
        </div>
        <div className="contact cell">
          {college.contact}
        </div>
        <div className="location cell">
          {college.location}
        </div>
        <div className="poc cell">
          {college.poc}
        </div>
        <div className="elipsis cell">
          <div className={`overlay shadow-1 ${checkOverlay(index) ? 'hidden' : ''}`}>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <div className="overlay-item" onClick={editCollege} id="college-dropdown-content-edit">
              <img src={EditIcon} alt="Edit College" id="college-dropdown-content-edit" />
              <div className="text" id="college-dropdown-content-edit">
                Edit
              </div>
            </div>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <div className="overlay-item" onClick={onDeleteClick} id="college-dropdown-content-delete">
              {
                deleteLoading === 'NOT_LOADING' && <img src={DeleteIcon} alt="Delete College" id="college-dropdown-content-delete" />
              }
              {
                deleteLoading === 'LOADING' && <i className="pi pi-spin pi-spinner" id="college-dropdown-content-delete" />
              }
              <div className="text" id="college-dropdown-content-delete">
                Delete
              </div>
            </div>
          </div>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
          <i className="pi pi-ellipsis-h cursor-pointer" id="college-dropdown-button" onClick={toggleOverlay} />
        </div>
      </div>
      <Toast ref={toastRef} />
      <Dialog
        header="Deletion Failed!"
        className="dialog-box"
        visible={dialogVisible}
        style={{ width: '50%', height: 'auto', borderRadius: '8px' }}
        onHide={dialogClose}
      >
        <div className="field align-items-center justify-content-between">
          <div className="split email">
            <div
              className="label info-label"
              style={{
                padding: '15px 0px 15px 0px',
                fontSize: '17px',
                fontWeight: '600',
                color: 'grey',
              }}
            >
              The Following Exams are in Ongoing status, Please
              {' '}
              <b style={{ color: '#717171' }}>End</b>
              {' '}
              those exams and try deleting it again!
            </div>
            <div>
              <ul style={{ display: 'block', margin: '0px 20px', color: 'black' }}>
                {
                    ongoingExamList?.map((e) => (
                      <div>
                        <li>
                          {e}
                        </li>
                      </div>
                    ))
                  }

              </ul>
            </div>
          </div>
          <div className="add-button-container invite-footer-button w-full flex align-items-center justify-content-end">
            <div className="sendParticular--button">
              <Button
                label="Cancel"
                className="p-button-outlined mr-3"
                style={{ color: '#0369A1' }}
                onClick={dialogClose}
              />
              <Button
                label="Ok"
                style={{ background: '#0369A1', width: '100px' }}
                onClick={dialogClose}
              />
            </div>
          </div>
        </div>
      </Dialog>
      <div className="delete-college-dialog">
        <Dialog
          header="Are you sure, you want to DELETE this college?"
          visible={deleteCollegeVisible}
          className="dialog-header end-exam-dialog-header"
          style={{ width: '50%', height: 'auto', borderRadius: '5px' }}
          onHide={hideDeleteVisible}
        >
          <div className="flex align-items-center justify-content-center w-full">
            <div className="flex align-items-center justify-content-between">
              <div className="pt-2 pr-1">
                <TiWarningOutline
                  className=" bg-cyan-50 text-cyan-800 p-2 w-4rem h-4rem border-circle"
                />
              </div>
            </div>
            <text className="end-body-text">
              <p style={{ color: '#544e4e', wordSpacing: '3px' }}>
                This will delete all the
                <b> CampusDrives, Exams, Candidates, Questions, EvaluationDetails </b>
                {' '}
                related to this college and cannot be reverted
              </p>
            </text>
          </div>
          <div className="rowdiv flex align-items-center justify-content-end">
            <ActionButton
              type="outline"
              label="Cancel"
              clickHandler={hideDeleteVisible}
              styleClass="w-7rem"
            />
            <div className="w-1rem" />
            <SpinCheckButton
              label="Delete College"
              onClick={deleteCollege}
              loadingStatus={deleteLoading}
              styleClass="font-medium end-exam w-10rem"
            />
            {/* TODO : add onClick event */}
          </div>
          <div />
        </Dialog>
      </div>
    </div>

  )
}
