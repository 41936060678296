export default class RoutesConstants {
  HOME: string = '/';

  LOGIN: string = '/login';

  DASHBOARD: string = '/';

  ROLES: string = '/roles';

  ROLES_LIST: string = '/';

  CAMPUS_DRIVE: string = '/campus-drive';

  CAMPUS_DRIVE_LIST: string = '/';

  CAMPUS_DRIVE_CREATE: string = '/create';

  EXAMS: string = '/exams';

  EXAM_CREATE: string = '/create';

  EXAM_END: string = '/end';

  EXAM_LIST: string = '/';

  EXAM_EDIT: string = '/edit';

  SETTINGS: string = '/settings';

  EVALUATE: string = '/evaluate';

  REEVALUATE: string = '/reevalaute';

  CANDIDATE: string = '/candidate';

  CANDIDATE_LOGIN:string = '/candidate-login';

  CANDIDATE_START:string = '/candidate-start';

  CANDIDATE_START_ERROR:string = '/candidate-start-error';

  CANDIDATE_END:string = '/candidate-end';

  QUESTION: string = '/question';

  EXAMREVIEW: string = '/review';

  CANDIDATE_QUESTION: string = '/candidate-question';

  CANDIDATE_REVIEW : string = '/candidate-review';

  CANDIDATE_TERMINATION : string = '/candidate-termination';

  CANDIDATE_MARK_SHEET: string = '/mark-sheet';

  SCOREBOARD: string = '/scoreboard';
}
