import React, { useRef, useState, useEffect } from 'react';
import './CandidateStart.css';
import { AxiosResponse } from 'axios';
import { useNavigate } from 'react-router';
import { Toast } from 'primereact/toast';
import { SpinCheckButton } from '../../../components';
import CandidateNavbar from '../../../components/candidateNavbar/CandidateNavbar';
import {
  updateStartExam, getInitData,
} from '../../../services/candidateService';
import { useAppDispatch } from '../../../store/storeHooks';
import RoutesConstants from '../../../constants/routesConstants';
import {
  setExamSession,
  setQuesId,
  setResponseStartTime,
  setTotalCount,
} from '../../../store/questionCounter';

import { setLoader, setMessage } from '../../../store/loaderStore';
import { setIsRunning, setTimeRemaining, setTimeTracker } from '../../../store/examSessionStore';
// import { getNextQuestion } from '../../../services/examService';

// const parseOptions = (question: any) => {
//   const temp: [] = [];
//   Object.keys(question).forEach((key) => {
//     if (key.startsWith('option')) {
//       // @ts-ignore
//       temp.push(question[key]);
//     }
//   });
//   return temp;
// }

export default function CandidateStart() {
  const [uploadButtonStatus, setUploadButtonStatus] = useState<'LOADING' | 'LOADED' | 'NOT_LOADING'>('NOT_LOADING');
  const dispatch = useAppDispatch();
  const routesConstant = new RoutesConstants();
  const toastRef = useRef<any>(null);
  const navigate = useNavigate();
  const [duration, setDuration] = useState<number>(0);
  const [quesCount, setQuesCount] = useState<number>(0);
  const [statusId, setStatusId] = useState<number>(1);

  useEffect(() => {
    getInitData()
      .then((response: AxiosResponse) => {
        dispatch(setIsRunning(false));
        dispatch(setTotalCount(response.data.questionCount));
        dispatch(setExamSession(response.data.examSession));
        setDuration(response.data.examSession.duration);
        setQuesCount(response.data.questionCount);
        setStatusId(response.data.examSession.statusId);
      })
  }, [])

  const toast = (severity: string, summary: string, detail: string) => {
    toastRef.current.show({
      severity,
      summary,
      detail,
      life: 3000,
    })
  }

  const start = () => {
    dispatch(setLoader(true));
    dispatch(setMessage('Validating Session...'));
    updateStartExam()
      .then((response: AxiosResponse) => {
        if (response.data.status === true) {
          const dat = response.data;
          // const questionIds = (dat.qnIds);
          // questionIds = shuffleQuestion(questionIds, candidateData.regNo)
          const examSessioncat = dat.examSession;
          dispatch(setExamSession(examSessioncat));
          // updated fetch
          dispatch(setQuesId(dat.qnIds));
          setUploadButtonStatus('LOADED');
          dispatch(setLoader(false));
          dispatch(setMessage(''));
          dispatch(setTimeRemaining(examSessioncat.duration));
          dispatch(setTimeTracker(examSessioncat.duration));
          dispatch(setResponseStartTime(examSessioncat.duration));
          dispatch(setIsRunning(true));
          navigate(`${routesConstant.CANDIDATE}${routesConstant.CANDIDATE_QUESTION}`);
        } else {
          setTimeout(() => {
            toast(
              'error',
              'Error',
              response.data.message,
            )
          });
          dispatch(setLoader(false));
          navigate(`${routesConstant.CANDIDATE}${routesConstant.CANDIDATE_START}`);
          navigate(`${routesConstant.CANDIDATE}${routesConstant.CANDIDATE_START}`);
        }
      }).catch((error) => {
        dispatch(setLoader(false));
        toast(
          'error',
          'Error',
          error.response.data.message,
        )
        dispatch(setLoader(true));
        setTimeout(() => {
          dispatch(setLoader(false));
          navigate(`${routesConstant.CANDIDATE}${routesConstant.CANDIDATE_LOGIN}`);
        }, 2000);
      })
  };
  return (
    <div className="main-container">
      <CandidateNavbar time="true" />
      <Toast ref={toastRef} />
      <div className="candidate-start-page-container">

        <div className="candidate">
          Welcome to your Presidio Exam.
          <br />
          Please read the instructions carefully before proceeding.
        </div>
        <div className="">

          <div className="base-candidate">
            <ul>
              <li>
                There are
                {' '}
                {quesCount}
                {' '}
                questions in this exam, you can answer as much as you want, mark some questions for review to answer later.
              </li>
              <li>
                Once you start the exam, you will have
                {' '}
                { Math.ceil(duration / 60)}
                {' '}
                mins to complete it, if your time elasped your test will be automatically submitted.
              </li>
              <li>Once the exam is finished there is no way to go back and change the answers.</li>
              <li>Relax and think about all your answers before submitting. We know you will ace this exam. 🍀</li>
            </ul>
          </div>

        </div>

        <div className="instructions-start-button">
          <SpinCheckButton styleClass="start-exam-button-item" disabled={statusId === 4 || statusId === 5} onClick={start} loadingStatus={uploadButtonStatus} label={statusId === 1 ? 'Start Exam' : 'Resume Exam'} />
        </div>

      </div>

    </div>

  );
}
