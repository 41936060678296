import React, { useEffect, useState } from 'react';
import { Checkbox } from 'primereact/checkbox';
import { candidateQuestion } from '../MarkSheetQuestion';
import './MarkSheetMSQ.css';

export default function MarkSheetMSQ({ markSheetDisplay, isCandidate, setResponseString }: candidateQuestion) {
  const [options, setOptions] = useState<{name: string, key: string, checked: boolean}[]>();
  const [preFetchResponseString, setPreFetchResponseString] = useState<string>('');

  useEffect(() => {
    const temp: {name: string, key: string, checked: boolean}[] = [];
    const { response } = markSheetDisplay;
    const selectedAnswer = response ? (response.answer ? response.answer.split('#%#$') : []) : [];
    const setPrefetch = selectedAnswer.join('#%#$');
    setPreFetchResponseString(setPrefetch.length === 0 ? '' : setPrefetch);
    Object.keys(markSheetDisplay.question).forEach((key) => {
      if (key.startsWith('option')) {
        // @ts-ignore
        const option: string = markSheetDisplay.question[key];
        if (option !== null && option.length !== 0) temp.push({ key, name: option, checked: selectedAnswer.findIndex((s: string) => s === option) !== -1 });
      }
    });
    setOptions(temp);
  }, [markSheetDisplay]);

  const selectOption = (e: any) => {
    if (options) {
      const selected: string[] = [];
      setOptions(options.map(
        (option) => {
          if (option.name === e.value.name) {
            option.checked = !option.checked;
          }
          if (option.checked) selected.push(option.name);
          return option;
        },
      ));
      const selectedParse = selected.join('#%#$');
      if (selectedParse === preFetchResponseString) setResponseString(undefined);
      else if (selectedParse.length !== 0) setResponseString(selectedParse);
      else setResponseString(null);
    }
  }

  return (
    <div className="markSheet-msq-panel">
      { options
            && options.map((option) => (
              <div key={option.key} className="field-checkbox">
                <Checkbox
                  inputId={option.key}
                  name="category"
                  value={option}
                  checked={option.checked}
                  disabled={!isCandidate}
                  onChange={selectOption}
                />
                <label htmlFor={option.key}>{option.name}</label>
              </div>
            ))}
    </div>
  )
}
