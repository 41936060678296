import AwsConstants from '../constants/awsConstants';

const awsConstants = new AwsConstants();

const awsMobile = {
  aws_project_region: awsConstants.REGION,
  aws_cognito_region: awsConstants.REGION,
  aws_user_pools_id: awsConstants.USER_POOL_ID,
  aws_user_pools_web_client_id: awsConstants.CLIENT_ID,
  oauth: {
    domain: awsConstants.DOMAIN,
    redirectSignIn: `${window.location.origin}`,
    redirectSignOut: `${window.location.origin}`,
    scope: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
    responseType: 'code',
  },
};

export default awsMobile;
