import React, { useState } from 'react';
import './MarkSheetFooter.css';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { candidateQuestion } from '../MarkSheetQuestion';
import { bookmarkQuestion } from '../../../services/examService';
import { SpinCheckButton } from '../../index';

export default function MarkSheetFooter({
  markSheetDisplay, navigator, setSelectedQuestion, isCandidate, setNavigator, selectedQuestion, setMarkSheetDisplay, setOnReviewClick,
}: candidateQuestion) {
  const [bookmarkButtonStatus, setBookmarkButtonStatus] = useState<'LOADING' | 'LOADED' | 'NOT_LOADING'>('NOT_LOADING')
  const nextHandler = () => {
    if (navigator) setSelectedQuestion(navigator.find((n) => n.order === markSheetDisplay.order + 1));
  }

  const backHandler = () => {
    if (navigator) setSelectedQuestion(navigator.find((n) => n.order === markSheetDisplay.order - 1));
  }

  const bookmarkStatusChange = (bookmark: boolean) => {
    if (navigator && selectedQuestion) {
      setNavigator(navigator.map((navigatorItem) => {
        if (navigatorItem.id === selectedQuestion.id) {
          navigatorItem.marked = bookmark;
          setSelectedQuestion(navigatorItem);
        }
        return navigatorItem;
      }));
    }
  }

  const bookmarkHandler = () => {
    if (navigator && selectedQuestion) {
      setBookmarkButtonStatus('LOADING');
      bookmarkQuestion({
        response: {
          id: markSheetDisplay.response ? markSheetDisplay.response.id : '',
          questionId: markSheetDisplay.question.id,
          bookmark: !selectedQuestion.marked,
          categoryId: markSheetDisplay.question.categoryId,
        },
      }).then(({ data }) => {
        if (setMarkSheetDisplay) {
          setMarkSheetDisplay(() => {
            markSheetDisplay.response = data.res;
            return markSheetDisplay;
          });
          bookmarkStatusChange(data.res.bookmark);
        }
        setBookmarkButtonStatus('NOT_LOADING')
      }).catch(() => {
        setBookmarkButtonStatus('NOT_LOADING');
      });
    }
  }

  return (
    <div className="mark-sheet-footer-flex">
      { markSheetDisplay.order !== 1
      && (
      <div className="back-button w-full">
        <Button label="Back" className="p-button-raised p-button-info p-button-sm markSheet-button" onClick={backHandler} />
      </div>
      )}
      { (!isCandidate && markSheetDisplay.evaluation) && (
      <div className="points-container w-full">
        <span className="mark-sheet-point-display">Points: </span>
        <InputText
          style={{ width: '120px', textAlign: 'right' }}
          className="p-inputtext-sm evaluated-points-container"
          value={
          markSheetDisplay.evaluation.points !== null ? markSheetDisplay.evaluation.points : 'Not Evaluated'
        }
          disabled
        />
        {' '}
        <span>
          /
          {markSheetDisplay.question.point}
        </span>
      </div>
      )}
      <div className="mark-next-container w-full ">
        {/* { markSheetDisplay.evaluation && ( */}
        {/* <div className="timespent-container"> */}
        {/*   /!* <span className="mark-sheet-point-display">Time spent: </span> *!/ */}
        {/*   {' '} */}
        {/*   /!* <span> *!/ */}
        {/*   /!*  {markSheetDisplay.response.timeSpent} *!/ */}
        {/*   /!* seconds *!/ */}
        {/*   /!* </span> *!/ */}
        {/* </div> */}
        {/* ) } */}
        { isCandidate && selectedQuestion
        && (
        <div className="bookmark-button">
          <SpinCheckButton
            label={selectedQuestion.marked ? 'Un-Mark' : 'Mark'}
            styleClass="p-button-raised p-button-info p-button-sm markSheet-button"
            onClick={bookmarkHandler}
            loadingStatus={bookmarkButtonStatus}
          />
        </div>
        )}
        { (navigator && markSheetDisplay.order !== navigator.length)
        && (
        <div className="next-button">
          <Button label="Next" className="p-button-raised p-button-info p-button-sm markSheet-button" onClick={nextHandler} />
        </div>
        )}
        { (navigator && isCandidate && markSheetDisplay.order === navigator.length)
            && (
            <div className="next-button">
              <Button label="Review" className="p-button-raised p-button-info p-button-sm markSheet-button" onClick={() => setOnReviewClick(true)} />
            </div>
            )}
      </div>
    </div>
  )
}
