import React, { useEffect, useState } from 'react';
import './ListCategories.css';
import { InputText } from 'primereact/inputtext';
import { ActionButton, ListCategoriesRow } from '../index';
import { ICategory } from '../../models/ICategory';

type IListCategoriesProps = {
  categories: ICategory[],
  handleAddCategoryClick: any
}

export default function ListCategories({ categories, handleAddCategoryClick }: IListCategoriesProps) {
  const [listCategories, setListCategories] = useState<any>(null);
  const [startingCategory, setStartingCategory] = useState(0);
  const [overlayIndex, setOverlayIndex] = useState<number | null>(null);
  const [categorySearch, setCategorySearch] = useState<any>('');

  useEffect(() => {
    setListCategories(categories);
  }, []);

  const updateIndex = (index: number) => {
    if (overlayIndex === index) {
      setOverlayIndex(null)
    } else {
      setOverlayIndex(index)
    }
  }
  const onSearchQueryChange = (value: any) => {
    setCategorySearch(value);
    let result = categories;
    if (value !== '') {
      result = categories.filter((category: ICategory) => category.name.toLowerCase().includes(value.toLowerCase()));
    } else if (value === '' || value === undefined) {
      result = categories
      setCategorySearch('');
    }

    setListCategories(result);
  }

  const handleNextPageClick = () => {
    if (startingCategory + 5 < listCategories!.length) setStartingCategory(startingCategory + 5)
  }

  const handlePreviousPageClick = () => {
    if (startingCategory - 5 >= 0) setStartingCategory(startingCategory - 5)
  }

  return (
    <div className="list-categories-container w-full">
      <div className="header flex align-items-center justify-content-between">
        <div className="title font-semibold">
          Question Categories
        </div>

        <div className="filter-container flex">
          <div className="filter-fields">
            <div className="p-inputgroup">
              <span className="search p-input-icon-left">
                <i className="pi pi-search" />
                <InputText value={categorySearch} onChange={(e) => onSearchQueryChange(e.target.value)} placeholder="Search by Category" />
              </span>
            </div>
          </div>

          <div className="new-category">
            <ActionButton type="primary" label="Add Category" clickHandler={handleAddCategoryClick} />
          </div>
        </div>
      </div>

      <div className="list-categories-container w-full">
        <div className="list-categories-table w-full">
          <div className="row-header row w-full">
            <div className="name cell">
              NAME
            </div>
            <div className="elipsis cell" />
          </div>

          {
            listCategories?.map((category: ICategory, index: number) => {
              if (index >= startingCategory && index < startingCategory + 5) {
                return <ListCategoriesRow key={category.id} index={index + 1} category={category} overlayClick={updateIndex} overlayIndex={overlayIndex} />
              }
              return <div key={category.id} />
            })
          }
        </div>

        <div className="footer w-full flex align-items-center justify-content-between">
          <div className="result-details">
            {
              listCategories != null
              && `Showing ${(listCategories!.length === 0) ? 0 : (startingCategory + 1)} to ${(startingCategory + 5 > listCategories!.length) ? listCategories!.length : (startingCategory + 5)} of ${listCategories?.length} results`
            }
          </div>
          <div className="pagination-buttons flex">
            <div className="mr-3">
              {
                (startingCategory >= 5) && (
                <ActionButton type="outline" label="Previous" clickHandler={handlePreviousPageClick} />
                )
              }
            </div>
            <div className="">
              {
                (listCategories !== null) && (startingCategory + 5 < listCategories!.length) && (
                <ActionButton type="outline" label="Next" clickHandler={handleNextPageClick} />
                )
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
