import React from 'react';
import IListQuestion from '../../models/IListQuestion';
import { ICategory } from '../../models/ICategory';
import EditIcon from '../../assets/icons/EditIcon.svg';

type IListQuestionsRowProps = {
  categories: ICategory[],
  question: IListQuestion,
  moreInfoHandler: any,
    updateQuestionVisible: Function,
}

export default function ListQuestionsRow({
  question, moreInfoHandler, categories, updateQuestionVisible,
}: IListQuestionsRowProps) {
  let result = 'N/A'
  const getCategoryName = (categoryId: string) => {
    for (let i = 0; i < categories.length; i += 1) {
      if (categories[i].id === categoryId) {
        result = categories[i].name
      }
    }
    return result;
  }
  const handleMoreInfoClick = () => {
    moreInfoHandler(question);
  }
  return (
    <div className="row w-full">
      <div
        style={{ cursor: 'pointer' }}
        role="button"
        tabIndex={0}
        onKeyPress={() => updateQuestionVisible(question.id)}
        onClick={() => updateQuestionVisible(question.id)}
        className="edit cell"
      >
        <img src={EditIcon} alt="Edit Question" id="college-dropdown-content-edit" />
      </div>
      <div className="question cell">
        <div className="text-ellipsis">
          {question.question}
        </div>
      </div>
      <div className="exam cell">
        {question.type}
      </div>
      <div className="category cell">
        {getCategoryName(question.categoryId)}
      </div>
      <div className="more-info cell">
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
        <p onClick={handleMoreInfoClick} className="cursor-pointer">More Information</p>
      </div>
    </div>
  )
}
