import React, { useEffect, useState } from 'react';
import './evaluationFooter.css';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
// import { RadioButton } from 'primereact/radiobutton';
// import { Dialog } from 'primereact/dialog';
import { Dialog } from 'primereact/dialog';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { evaluationNavigatorType } from '../../../../evaluation';
import { questionType } from '../../../../../navigator/Navigator';
import { evaluationType, responseType } from '../../../../../markSheetQuestion/MarkSheetQuestion';
import { addPoints, endEvaluationApi } from '../../../../../../services/examService';
import { SpinCheckButton } from '../../../../../index';
import RoutesConstants from '../../../../../../constants/routesConstants';

type EvaluationFooterType = {
  responseNavigator: evaluationNavigatorType[],
  setResponseNavigator: Function,
  markSheetDisplay: {
    order: number,
    question: questionType,
    response: responseType
    evaluation?: evaluationType,
  },
  setMarkSheetDisplay: Function,
  selectedResponse: evaluationNavigatorType | null | undefined,
  setSelectedResponse: Function,
}

export default function EvaluationFooter({
  responseNavigator, setResponseNavigator,
  markSheetDisplay, setMarkSheetDisplay, selectedResponse, setSelectedResponse,
}: EvaluationFooterType) {
  const location = useLocation();
  const navigate = useNavigate();
  const routesConstants = new RoutesConstants();
  const [preFetchEvaluation, setPreFetchEvaluation] = useState<string>('');
  const [updatedEvaluation, setUpdatedEvaluation] = useState<string | null | undefined>('');
  const [endButtonStatus, setEndButtonStatus] = useState<'LOADING' | 'LOADED' | 'NOT_LOADING'>('NOT_LOADING')
  const [error, setError] = useState<string|null>(null);
  const [endEvaluationVisible, setEndEvaluationVisible] = useState<boolean>(false);
  const [isEnded, setIsEnded] = useState<boolean>(false);
  // const [skipResponses, setSkipResponses] = useState<String[] | null>(null);
  const examId = location.pathname.split('/', 3).pop();

  useEffect(() => {
    const { evaluation } = markSheetDisplay;
    const evaluated = evaluation ? `${evaluation.points}` : null;
    setPreFetchEvaluation(evaluated || '');
    setUpdatedEvaluation(evaluated || '');
  }, [markSheetDisplay]);

  const updateEvaluationNavigator = (evaluated: boolean, isSkipped: boolean) => {
    if (responseNavigator) {
      setResponseNavigator(responseNavigator.map((navigatorObject) => {
        if (navigatorObject.responseId === markSheetDisplay.response.id) {
          navigatorObject.evaluated = evaluated;
          navigatorObject.skipResponse = isSkipped;
        }
        return navigatorObject;
      }))
    }
  }

  const createEvaluation = (isSkipResponse: boolean, ended?: boolean) => {
    if (preFetchEvaluation !== updatedEvaluation) {
      const payload = {
        responseId: markSheetDisplay.response.id,
        points: updatedEvaluation ? (updatedEvaluation.length === 0 ? null : updatedEvaluation) : null,
        evaluationId: markSheetDisplay.evaluation ? markSheetDisplay.evaluation.id : null,
      }
      addPoints(payload).then(({ data }) => {
        if (data.status) {
          markSheetDisplay.evaluation = data.evaluation;
          if (markSheetDisplay.evaluation) {
            updateEvaluationNavigator(true, isSkipResponse);
            if (ended) setIsEnded(true);
            else setIsEnded(false);
          } else {
            updateEvaluationNavigator(false, isSkipResponse);
            if (ended) setIsEnded(true);
            else setIsEnded(false);
          }
          setMarkSheetDisplay(markSheetDisplay);
        }
      }).catch(() => {});
    } else if (updatedEvaluation.length !== 0) {
      updateEvaluationNavigator(true, false);
      if (ended) setIsEnded(true);
      else setIsEnded(false);
    } else {
      updateEvaluationNavigator(false, true);
      if (ended) setIsEnded(true);
      else setIsEnded(false);
    }
  }

  useEffect(() => {
    if (isEnded) {
      const skipRes: string[] = [];
      const evaluatedRes: string[] = [];
      if (responseNavigator) {
        responseNavigator.map((navigatorObject) => {
          if (navigatorObject.skipResponse) {
            skipRes.push(navigatorObject.responseId);
          }
          if (navigatorObject.evaluated) {
            evaluatedRes.push(navigatorObject.responseId);
          }
          return navigatorObject;
        })
      }
      const data = {
        examId,
        skipResponse: skipRes,
        evaluatedResponse: evaluatedRes,
      }
      endEvaluationApi(data).then(() => navigate(`${routesConstants.EXAMS}`)).catch(() => { setEndButtonStatus('NOT_LOADING') })
    }
  }, [responseNavigator]);

  useEffect(() => {
    if (updatedEvaluation && updatedEvaluation.length !== 0) {
      if (Number(updatedEvaluation) > markSheetDisplay.question.point || Number(updatedEvaluation) < 0) {
        setError('Points Not Valid');
      } else {
        setError(null);
      }
    } else {
      setError(null);
    }
  }, [updatedEvaluation]);

  const handleHideEndEvaluation = () => {
    setEndEvaluationVisible(false);
  };
  const nextHandler = () => {
    if (updatedEvaluation === '') createEvaluation(true);
    else createEvaluation(false);
    if (responseNavigator) setSelectedResponse(responseNavigator.find((n) => n.order === markSheetDisplay.order + 1));
  }

  const backHandler = () => {
    if (updatedEvaluation === '') createEvaluation(true);
    else createEvaluation(false);
    if (responseNavigator) setSelectedResponse(responseNavigator.find((n) => n.order === markSheetDisplay.order - 1));
  }
  const handleEndEvaluation = () => {
    setEndEvaluationVisible(true);
  }

  const endEvaluation = () => {
    if (updatedEvaluation === '') {
      setEndButtonStatus('LOADING');
      createEvaluation(true, true);
    } else {
      setEndButtonStatus('LOADING');
      createEvaluation(false, true);
    }
  }

  useEffect(() => {
    if (updatedEvaluation === '') createEvaluation(true);
    else createEvaluation(false);
  }, [selectedResponse]);

  return (
    <div>
      <div className="evaluation-footer-flex">
        { markSheetDisplay.order !== 1
        && (
          <div className="evaluation-back-button w-full">
            <Button label="Back" className="p-button-raised p-button-info p-button-sm markSheet-button" onClick={backHandler} />
          </div>
        )}

        <div className="evaluation-points-container w-full">
          <span className="evaluation-point-display">Points: </span>
          <div className="flex flex-column">
            <div className="flex flex flex-row">
              <InputText
                style={{ width: '120px', textAlign: 'right' }}
                placeholder="Evaluate"
                className="p-inputtext-sm evaluated-points-container"
                value={updatedEvaluation || ''}
                onInput={(e: any) => setUpdatedEvaluation(e.target.value)}
              />
              {' '}
              <span className="mt-2">
                /
                {markSheetDisplay.question.point}
              </span>
            </div>
            <p className="error-display">{error}</p>
          </div>

        </div>

        <div className="evaluation-next-container w-full ">

          { (responseNavigator && markSheetDisplay.order !== responseNavigator.length)
          && (
            <div className="evaluation-next-button">
              <Button label="Next" className="p-button-raised p-button-info p-button-sm markSheet-button" onClick={nextHandler} />
            </div>
          )}
          { (responseNavigator && markSheetDisplay.order === responseNavigator.length)
          && (
            <div className="evaluation-next-button">
              <Button label="End Evaluation" className="p-button-raised p-button-info p-button-sm markSheet-button" onClick={handleEndEvaluation} />
            </div>
          )}
        </div>
      </div>
      <Dialog header="End Evaluation" className="dialog-box" visible={endEvaluationVisible} style={{ width: '587px', height: '230px', borderRadius: '8px' }} onHide={handleHideEndEvaluation}>
        <div className="eval-dialog-align">
          <h6>Would you like to end the evaluation? You will not be able to review the previous responses once you exit.</h6>
          <div className="file-upload-container w-full h-auto">
            <div className="upload-button-container w-full flex align-items-center justify-content-end">
              <div className="exit-button">
                <SpinCheckButton styleClass="eval-exit-button-item" label="End" loadingStatus={endButtonStatus} onClick={endEvaluation} />
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  )
}
