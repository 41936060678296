import { Link } from 'react-router-dom';
import React from 'react';
import { IUpcomingExams } from '../../models/IUpcomingExams';
import './DashboardUpcomingExamItem.css'
import RoutesConstants from '../../constants/routesConstants';

type IDashboardExamItem={
    upcomingExams: IUpcomingExams;
    listexpectedParticipants:any[];
    onCardClick: Function,
}
export default function DashboardExamItem({ upcomingExams, listexpectedParticipants, onCardClick }:IDashboardExamItem) {
  const routesConstant = new RoutesConstants();
  const cardClickTrigger = () => {
    onCardClick(upcomingExams)
  }
  return (
    <div className="exam-card-1" onClick={cardClickTrigger} onKeyPress={cardClickTrigger} role="button" tabIndex={0}>
      <div className="dashboard-exam-list-1 w-full flex">
        <div className="dashboard-exam-list-content-1 w-full">
          <div className="line-1">
            <div className="university-name-col title">
              {upcomingExams.Exam.CampusDrive ? upcomingExams.Exam.CampusDrive.College.name : ''}
            </div>
          </div>
          <div className="line-3 flex w-full">
            <div className="cell exam-name-col">
              {upcomingExams.Exam.examName}
            </div>
            <div className="cell participants-col">
              Expected participants:
              {'  '}
              {listexpectedParticipants}
              {' '}
            </div>
            <div className="cell date-col">
              {upcomingExams.Exam.examDate}
            </div>
          </div>
        </div>
        <div className="arrow-icon">
          <Link to={`${routesConstant.EXAMS}/${upcomingExams.Exam.id}`} className="no-underline"><i className="pi pi-angle-right" /></Link>
        </div>
      </div>
    </div>

  )
}
