import React, { useEffect, useRef, useState } from 'react';
import './ListReview.css';
import { useParams } from 'react-router';
import { AxiosResponse } from 'axios';
import { Toast } from 'primereact/toast';
import IListReview from '../../../../models/IListReview';
import ListReviewRow from '../../../../components/listReviewRow/ListReviewRow';
import ReviewPageSkeleton from '../../../../components/skeleton/ReviewPageSkeleton/ReviewPageSkeleton';
import { getReviewDetails } from '../../../../services/evaluationService';

export default function ListReview() {
  const column = ['CATEGORYNAME', 'ASSIGNED', 'INPROGRESS', 'NOTASSIGNED', 'TOTAL'];
  const { examId }: any = useParams();
  const [ReviewList, setReviewList] = useState<IListReview[] | null>(null);
  const toastRef = useRef<any>(null);
  useEffect(() => {
    getReviewDetails(examId)
      .then((response: AxiosResponse) => {
        setReviewList(response.data.data);
      })
      .catch((e) => {
        toastRef.current.show({
          severity: 'error',
          summary: 'Something went wrong',
          detail: e.response.data.message,
        });
      })
  }, [])
  return (
    <div className="review-page-container">
      <div className="header flex">
        <div className="title font-semibold">
          Review Questions
        </div>
      </div>
      {(ReviewList == null)
        && (
          <div className="skeleton">
            <ReviewPageSkeleton column={column} />
          </div>
        )}
      {(ReviewList != null && ReviewList.length > 0)
        && (
          <div className="exam-list-container">
            <div className="list-header flex  ">
              {[...column].map((el) => (
                <div className="cell" style={{ width: `${100 / column.length}%` }}>
                  {el.toUpperCase()}
                </div>
              ))}
            </div>
            <div className="content">
              {ReviewList!.map((_review, index) => (
                <ListReviewRow
                  shade={index}
                  lastItem={(index + 1) === ReviewList!.length}
                  reviewData={_review}
                  column={column}
                />
              ))}
            </div>
          </div>
        )}
      {
            (ReviewList != null && ReviewList!.length === 0)
        && (
          <div className="no-data w-full flex align-items-center justify-content-center">
            <div className="no-exam-content">The exam has not yet started.</div>
          </div>
        )
      }
      <Toast ref={toastRef} />
    </div>

  );
}
