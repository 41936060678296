import React, { useState } from 'react';
import './examReviewPage.css';
import { SpinCheckButton } from '../index';

export default function ExamReviewPage() {
  const [uploadButtonStatus] = useState<'LOADING' | 'LOADED' | 'NOT_LOADING'>('NOT_LOADING')
  const questionValues = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35,
    36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76];
  return (
    <div className="examReviewPage-container h-full w- full">
      <div className="examReviewPage-container-form">
        <h3>Evaluated 76 questions!</h3>
        <p>Do you want to continue evaluating with the next set of questions or end this session?</p>
        <div className="grid-container ">
          {questionValues.map((value) => (
            <div className="grid-item">
              <div className="dot-circle-green ml-1 mb-2" />
              <div className="question-number justify-content-center">{value}</div>
            </div>
          ))}
        </div>
        <div className="dot-explanation">
          <div className="dot-circle-green-display" />
          <div className="answer pl-3">Correct Answer</div>
        </div>
        <div className="dot-explanation">
          <div className="dot-circle-red-display" />
          <div className="answer pl-3">Wrong Answer</div>
        </div>
        <div className="footer-content">
          <div className="next-question-button">
            <SpinCheckButton styleClass="upload-button-item next-question-button" loadingStatus={uploadButtonStatus} label="Next Set of Questions" />
          </div>
          <div className="submit-evaluation-button">
            <SpinCheckButton styleClass="submit-evaluation-button" loadingStatus={uploadButtonStatus} label="Submit and End Evaluation" />
          </div>
        </div>
      </div>
    </div>
  );
}
