import React, { useEffect, useRef, useState } from 'react';
import { TiWarningOutline } from 'react-icons/ti'
import './ViewExams.css';
import { useNavigate, useParams } from 'react-router';
import { AxiosResponse } from 'axios';
import { Skeleton } from 'primereact/skeleton';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { IListExam } from '../../../../models/IListExam';
import {
  getAllCategory,
  getExam,
  startExam, endExam, getQuestionPoints,
} from '../../../../services/examService';
import { IListCampusDrive } from '../../../../models/IListCampusDrive';
import {
  ActionButton,
  AddCandidates,
  AddQuestions, ExamErrorModalRow, ExamQuestionsSkeleton,
  ViewExamHeaderCard,
  ViewExamHeaderSkeleton,
  ListQuestions, ListCandidates,
  AddEvaluatorFilter,
} from '../../../../components';
import RoutesConstants from '../../../../constants/routesConstants';
import ExamCandidatesSkeleton from '../../../../components/skeleton/examCandidatesSkeleton/ExamCandidatesSkeleton';
import ExamEvaluatorsSkeleton from '../../../../components/skeleton/examEvaluatorsSkeleton/ExamEvaluatorsSkeleton';
import IListCandidate from '../../../../models/IListCandidate';
import IListQuestion from '../../../../models/IListQuestion';
import { useAppDispatch, useAppSelector } from '../../../../store/storeHooks';
import {
  setCandidateData, setQuestionData, setEvaluatorData, setSessionData, setCandidateTotal,
} from '../../../../store/examStore';
import ListEvaluators from '../../../../components/listEvaluators/ListEvaluators';
import AddEvaluators from '../../../../components/addEvaluators/AddEvaluators';

export default function ViewExams() {
  const dispatch = useAppDispatch();
  const routesConstants = new RoutesConstants();
  const { examId }: any = useParams();
  const navigate = useNavigate();
  const toastRef = useRef<any>(null);
  const [examData, setExamData] = useState<IListExam | null>(null);
  const [campusDriveData, setCampusDriveData] = useState<IListCampusDrive | null>(null);

  const candidatesData = useAppSelector((state) => state.exams.candidateData);
  const questionsData = useAppSelector((state) => state.exams.questionsData);
  const evaluatorData = useAppSelector((state) => state.exams.evaluatorData);
  const totalCandidates = useAppSelector((state) => state.exams.candidateTotal);

  const [candidateSectionVisible, setCandidateSectionVisible] = useState<boolean>(false)
  const [evaluatorSectionVisible, setEvaluatorSectionVisible] = useState<boolean>(false)
  const [questionSectionVisible, setQuestionSectionVisible] = useState<boolean>(false)

  const [examStatus, setExamStatus] = useState<any>(null);
  const [categories, setCategories] = useState(null);
  const [questionPoints, setQuestionPoints] = useState(null);

  const [addCandidatesVisible, setAddCandidatesVisible] = useState<boolean>(false);
  const [addQuestionsVisible, setAddQuestionsVisible] = useState<boolean>(false);
  const [addEvaluatorsVisible, setAddEvaluatorsVisible] = useState<boolean>(false);
  const [addCandidateFilterVisible, setAddCandidateFilterVisible] = useState<boolean>(false);

  const [updateCandidate, setUpdateCandidate] = useState<string>();
  const [updateQuestion, setUpdateQuestion] = useState<string>();
  const [average, setAverage] = useState<number>(0)
  const [endExamVisible, setConfirmExamEnd] = useState<boolean>(false);
  const [evaluationFilterApplied, setEvaluationFilterApplied] = useState<boolean>(false);
  const [startRange, setStartRange] = useState<number>(0);
  const [endRange, setEndRange] = useState<number>(0);

  const [modalErrors, setModalErrors] = useState<string[] | null>(null);
  const [errorModalHeader, setErrorModalHeader] = useState<string>('');
  const [errorModalVisible, setErrorModalVisible] = useState<boolean>(false);
  // const [addButtonStatus, setAddButtonStatus] = useState<'LOADING' | 'LOADED' | 'NOT_LOADING'>('NOT_LOADING');

  const getFormattedTime = (startTime: string, endTime: string) => {
    const startTimeArr = startTime.split(':');
    const endTimeArr = endTime.split(':');
    const start = startTimeArr[0].split('T');
    const end = endTimeArr[0].split('T');
    let startHour = parseInt(start[1], 10);
    let endHour = parseInt(end[1], 10);
    const startMeridium = (startHour >= 12) ? 'PM' : 'AM';
    const endMeridium = (endHour >= 12) ? 'PM' : 'AM';
    startHour = (startHour === 0) ? 12 : ((startHour > 12) ? (startHour - 12) : startHour);
    endHour = (endHour === 0) ? 12 : ((endHour > 12) ? (endHour - 12) : endHour);
    const startMin = startTimeArr[1];
    const endMin = endTimeArr[1];
    return {
      startTime: `${startHour}${(startMin === '00') ? ' ' : `.${startMin}`}${startMeridium}`,
      endTime: `${endHour}${(endMin === '00') ? ' ' : `.${endMin}`}${endMeridium}`,
    };
  }
  useEffect(() => {
    /*
     * Getting the exam data from the exam if exam data is not available
     */

    if (examData == null) {
      setCandidateData(null)
      setQuestionData(null)
      setEvaluatorData(null)
      getAllCategory()
        .then((response: AxiosResponse) => {
          setCategories(response.data);
        })
        .catch((e) => {
          toastRef.current.show({
            severity: 'error',
            summary: 'Something went wrong',
            detail: e.response.data.message,
          });
        })
      getQuestionPoints()
        .then((response: AxiosResponse) => {
          setQuestionPoints(response.data);
        })
        .catch((e) => {
          toastRef.current.show({
            severity: 'error',
            summary: 'Something went wrong',
            detail: e.response.data.message,
          });
        })
      getExam(examId)
        .then((response: AxiosResponse) => {
          const responseData = response.data
          // const start = new Date(responseData.exams.startTime).toString();
          // const end = new Date(responseData.exams.endTime).toString();
          // // eslint-disable-next-line prefer-destructuring
          // responseData.exams.startTime = start.split(' ')[4];
          // // eslint-disable-next-line prefer-destructuring
          // responseData.exams.endTime = end.split(' ')[4];
          setExamData(responseData.exams);
          responseData.exams.CampusDrive.campusDriveName = responseData.exams.CampusDrive.name;
          responseData.exams.CampusDrive.collegeName = responseData.exams.CampusDrive.College.name;
          setCampusDriveData(responseData.exams.CampusDrive);
          dispatch(setCandidateData(responseData.candidates.rows as IListCandidate[]));
          dispatch(setCandidateTotal(responseData.candidates.count));
          setCandidateSectionVisible(true)
          setAverage(responseData.avg[0].avg ? responseData.avg[0].avg : 0)
          dispatch(setQuestionData(responseData.questions.rows as IListQuestion[]));
          dispatch(setSessionData(responseData.examSessions))
          setQuestionSectionVisible(true)
          dispatch(setEvaluatorData(responseData.evaluators.rows));
          setEvaluatorSectionVisible(true)
          if (responseData.startRange !== null) setEvaluationFilterApplied(responseData.startRange)
        })
        .catch((e) => {
          toastRef.current.show({
            severity: 'error',
            summary: 'Something went wrong',
            detail: e.response.data.message,
          });
        });
    }
    if (examData != null) {
      if (examData.statusId === 1) {
        setExamStatus('upcoming');
      } else if (examData.statusId === 2) {
        setExamStatus('ongoing');
      } else if (examData.statusId === 3) {
        setExamStatus('completed');
      } else if (examData.statusId === 4) {
        setExamStatus('evaluating')
      } else {
        setExamStatus('upcoming');
      }
      if (examData.startRange != null) {
        setEvaluationFilterApplied(true);
        setStartRange(examData.startRange);
        setEndRange(examData.endRange);
      }
    }
  }, [examData]);

  const refreshEvalHandler = (value:boolean) => {
    setEvaluatorSectionVisible(value)
  }

  const refreshCandidateHandler = (value:boolean) => {
    setCandidateSectionVisible(value)
  }

  const refreshQuestionHandler = (value:boolean) => {
    setCandidateSectionVisible(value)
  }

  const [updateCandidateHeader, setUpdateCandidateHeader] = useState<string>();

  const updateCandidateVisible = (candidateId: string) => {
    setUpdateCandidate(candidateId);
    setAddCandidatesVisible(true);
  }

  useEffect(() => {
    if (updateCandidate) setUpdateCandidateHeader('Update Candidate');
    else setUpdateCandidateHeader('Add Candidate');
  }, [updateCandidate]);

  const [updateQuestionHeader, setUpdateQuestionHeader] = useState<string>();

  const updateQuestionVisible = (questionId: string) => {
    setUpdateQuestion(questionId);
    setAddQuestionsVisible(true);
  }

  useEffect(() => {
    if (updateQuestion) setUpdateQuestionHeader('Update Question');
    else setUpdateQuestionHeader('Add Question');
  })

  const refreshEvaluatioFilterHandler = (value:boolean, stRange: number, eRange: number) => {
    setEvaluationFilterApplied(value);
    setExamStatus('evaluating');
    setStartRange(stRange);
    setEndRange(eRange);
  }

  // const getTime = (startTime: string, endTime: string) => {
  //   console.log(startTime, endTime)
  //   const startTimeArr = startTime.split(':');
  //   const endTimeArr = endTime.split(':');
  //
  //   let startHour = parseInt(startTimeArr[0], 10);
  //   let endHour = parseInt(endTimeArr[0], 10);
  //   const startMin = startTimeArr[1];
  //   const endMin = endTimeArr[1];
  //
  //   const startMeridium = (startHour >= 12) ? 'PM' : 'AM';
  //   const endMeridium = (endHour >= 12) ? 'PM' : 'AM';
  //   startHour = (startHour === 0) ? 12 : ((startHour > 12) ? (startHour - 12) : startHour);
  //   endHour = (endHour === 0) ? 12 : ((endHour > 12) ? (endHour - 12) : endHour);
  //
  //   return {
  //     startTime: `${startHour}${(startMin === '00') ? '' : `.${startMin}`}${startMeridium}`,
  //     endTime: `${endHour}${(endMin === '00') ? '' : `.${endMin}`}${endMeridium}`,
  //   };
  // }

  const getDate = (date: string) => {
    const months = ['', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const dateArr = date.split('-');

    return `${dateArr[2]} ${months[parseInt(dateArr[1], 10)]} ${dateArr[0]}`;
  }

  const getCandidatesCount = () => {
    const csvData = candidatesData!.filter((candidate: IListCandidate) => candidate.source === 'IMP');
    return `${csvData.length} from CSV, ${totalCandidates - csvData.length} added here`;
  }

  const getQuestionsCount = () => {
    const csvData = questionsData!.filter((question: IListQuestion) => question.source === 'IMP');
    return `${csvData.length} from CSV, ${questionsData!.length - csvData.length} added here`;
  }

  const toast = (severity: string, summary: string, detail: string) => {
    toastRef.current.show({
      severity,
      summary,
      detail,
      life: 3000,
    })
  }

  const goBackClick = () => {
    navigate(-1);
  };

  const goBackEnter = () => {
    // @ts-ignore
    if (event.key === 'Enter') {
      goBackClick();
    }
  };

  const handleEditExamClick = () => {
    navigate(`${routesConstants.EXAMS}/${examId}${routesConstants.EXAM_EDIT}`);
  };

  const handleAddCandidateFilterClick = () => {
    setAddCandidateFilterVisible(true)
  }

  const handleAddEvaluatorClick = () => {
    setAddEvaluatorsVisible(true);
  };

  const handleHideAddEvaluator = () => {
    setAddEvaluatorsVisible(false);
  };

  const handleShowExamConfirmationClick = () => {
    setConfirmExamEnd(true);
  };

  const handleHideEndExamConfirmationClick = () => {
    setConfirmExamEnd(false);
  };

  const handleAddCandidateClick = () => {
    setAddCandidatesVisible(true);
  };

  const handleHideAddCandidate = () => {
    setUpdateCandidate(undefined);
    setAddCandidatesVisible(false);
  };

  const handleHideAddEvaluationFilter = () => {
    setAddCandidateFilterVisible(false)
  }

  const handleAddQuestionClick = () => {
    setAddQuestionsVisible(true)
  };

  const handleHideAddQuestions = () => {
    setUpdateQuestion(undefined);
    setAddQuestionsVisible(false);
  }

  const handleHideErrorModal = () => {
    setErrorModalVisible(false);
  }

  const handleQuestionsError = (errors: string[]) => {
    setModalErrors(errors);
    setErrorModalHeader(`${errors.length} Errors in Questions`);
    setErrorModalVisible(true);
  }

  const handleCandidatesError = (errors: string[]) => {
    setModalErrors(errors);
    setErrorModalHeader(`${errors.length} Errors in Candidates`);
    setErrorModalVisible(true);
  }

  // const handleCandidateTemplateDownload = () => {
  //   setAddButtonStatus('LOADING');
  //   getCandidateTemplate()
  //     .then((response: AxiosResponse) => {
  //       if (response.status === 200) {
  //         window.open(response.data.url, '_blank', 'noopener,noreferrer');
  //         setAddButtonStatus('LOADED');
  //       }
  //     })
  //     .catch((e) => {
  //       toastRef.current.show({
  //         severity: 'error',
  //         summary: 'Something went wrong',
  //         detail: e.response.data.message,
  //       });
  //     })
  // }

  const handleStartExam = () => {
    startExam(examId)
      .then((res:AxiosResponse) => {
        if (res.data.status) {
          toast(
            'success',
            'Success',
            res.data.message,
          )
          setExamStatus('ongoing');
        } else {
          toast(
            'error',
            'Error',
            res.data.message,
          )
        }
      })
      .catch((e) => {
        toastRef.current.show({
          severity: 'error',
          summary: 'Something went wrong',
          detail: e.response.data.message,
        });
      })
  }
  const handleEndExam = async () => {
    await endExam(examId)
      .then((res: AxiosResponse) => {
        if (res.status === 200) {
          setExamStatus('completed');
        } else {
          toast(
            'error',
            'Something went wrong',
            'Please try again later',
          )
        }
        setConfirmExamEnd(false);
      })
      .catch((e) => {
        toastRef.current.show({
          severity: 'error',
          summary: 'Something went wrong',
          detail: e.response.data.message,
        });
      })
  }
  const handleEvaluateExam = () => {
    // `${routesConstants.EXAMS}/${examId}${routesConstants.EXAM_EDIT}
    if (evaluationFilterApplied) {
      navigate(`${routesConstants.EXAMS}${routesConstants.EVALUATE}/${examId}`);
    }
    // } else {
    //   navigate(`${routesConstants.EVALUATE}${routesConstants.EVALUATION_NOT_STARTED}`);
    // }
  }
  const handleReEvaluateExam = () => {
    // `${routesConstants.EXAMS}/${examId}${routesConstants.EXAM_EDIT}
    if (evaluationFilterApplied) {
      navigate(`${routesConstants.EXAMS}${routesConstants.REEVALUATE}/${examId}`);
    }
    // } else {
    //   navigate(`${routesConstants.EVALUATE}${routesConstants.EVALUATION_NOT_STARTED}`);
    // }
  }
  const handleScoreBoard = () => {
    navigate(`${routesConstants.EXAMS}${routesConstants.SCOREBOARD}/${examId}`);
  }

  // @ts-ignore
  return (
    <div className="view-exams-view h-full w-full">
      {
            (examData == null || campusDriveData == null)
            && (
            <ViewExamHeaderSkeleton goBackClick={goBackClick} goBackEnter={goBackEnter} />
            )
        }
      {
            (examData != null && campusDriveData != null)
            && (
            <div className="header flex align-items-center justify-content-between">
              <div className="title-container flex flex-column align-items-start justify-content-center">
                <div role="button" tabIndex={0} onClick={goBackClick} onKeyDown={goBackEnter} className="back-navigation flex align-items-center justify-content-start cursor-pointer">
                  <i className="pi pi-angle-left font-semibold" />
                  <div className="label font-medium">
                    Back
                  </div>
                </div>

                <div className="title">
                  {examData!.name}
                </div>
                <div className="sub-title">
                  {`${campusDriveData!.name.toUpperCase()}, ${campusDriveData!.College.name.toUpperCase()}`}
                </div>
                {
                    (examStatus != null && examStatus === 'completed')
                    && (
                    <div className="sub-title warning-text">NO EVALUATION FILTERS APPLIED</div>
                    )
                }
                {
                    (examStatus != null && examStatus === 'evaluating' && evaluationFilterApplied)
                    && (
                    <div>
                      <div className="sub-title success-text">
                        EVALUATION FILTER RANGE APPLIED FROM
                        {' '}
                        {startRange}
                        {' '}
                        to
                        {' '}
                        {endRange}
                      </div>
                    </div>
                    )
                }
              </div>
              <div className="exam-status-container flex">
                <div className="exam-status flex align-items-center h-full w-auto">
                  {
                          (examStatus != null && examStatus === 'upcoming')
                          && (
                          // when in upcoming status render edit exam action button
                          <div className="h-auto w-full flex align-items-center justify-content-between">
                            <div className="upcoming">
                              <div className="status-dot" />
                              <div className="status-label">UPCOMING</div>
                            </div>
                            <div>
                              <div className="edit-exam">
                                <ActionButton
                                  type="primary"
                                  label="Edit Exam"
                                  clickHandler={handleEditExamClick}
                                  styleClass="font-medium"
                                />
                              </div>
                            </div>
                            <div className="end-exam">
                              <ActionButton
                                type="primary"
                                label="Start Exam"
                                clickHandler={handleStartExam}
                                styleClass="font-medium start-exam"
                              />
                            </div>
                            <div className="evaluation-filter">
                              <ActionButton
                                type="primary"
                                label="Score Board"
                                clickHandler={handleScoreBoard}
                                styleClass="font-medium start-exam"
                              />
                              {/* <Button className={'action-button "font-medium start-exam" "primary"'} onClick={handleEvaluateExam} label="Score Board" icon="fa-solid fa-trophy" /> */}
                            </div>
                          </div>
                          )
                      }
                  {
                          (examStatus !== null && examStatus === 'evaluating' && evaluationFilterApplied)
                          && (
                          // when in completed status and after evaluation filter applied render evaluate exam action button
                          // TODO : create evaluate exam button and remove the existing edit exam button
                          <div className="h-auto w-full flex align-items-center justify-content-between">
                            <div className="completed">
                              <div className="status-dot" />
                              <div className="status-label">EVALUATING</div>
                            </div>
                            <div className="evaluation-filter">
                              <ActionButton
                                type="primary"
                                label="Evaluation Filter"
                                clickHandler={handleAddCandidateFilterClick}
                                styleClass="font-medium"
                              />
                            </div>
                            <div className="evaluate-exam-button">
                              <ActionButton
                                type="primary"
                                label="Evaluate Exam"
                                clickHandler={handleEvaluateExam}
                                styleClass="font-medium start-exam"
                              />
                            </div>
                            <div className="evaluate-exam-button">
                              <ActionButton
                                type="primary"
                                label="Re-Evaluate Exam"
                                clickHandler={handleReEvaluateExam}
                                styleClass="font-medium start-exam"
                              />
                            </div>
                            <div className="evaluation-filter">
                              <ActionButton
                                type="primary"
                                label="Score Board"
                                clickHandler={handleScoreBoard}
                                styleClass="font-medium start-exam"
                              />
                              {/* <Button className={'action-button "font-medium start-exam" "primary"'} onClick={handleEvaluateExam} label="Score Board" icon="fa-solid fa-trophy" /> */}
                            </div>
                          </div>
                          )
                      }
                  {
                      (examStatus != null && examStatus === 'completed')
                      && (
                      // when in completed status render and evaluation filter not applied render candidate-filter action button
                      // TODO : create evaluate exam button and remove the existing edit exam button
                      <div className="h-auto w-full flex align-items-center justify-content-between">
                        <div className="completed">
                          <div className="status-dot" />
                          <div className="status-label">COMPLETED</div>
                        </div>
                        <div className="evaluation-filter">
                          <ActionButton
                            type="primary"
                            label="Evaluation Filter"
                            clickHandler={handleAddCandidateFilterClick}
                            styleClass="font-medium"
                          />
                        </div>
                        {/* <div className="evaluate-exam-button"> */}
                        {/*  <ActionButton */}
                        {/*    type="primary" */}
                        {/*    label="Evaluate Exam" */}
                        {/*    clickHandler={handleEvaluateExam} */}
                        {/*    styleClass="font-medium start-exam diabled" */}
                        {/*    disabled */}
                        {/*  /> */}
                        {/*  /!*<span className="tooltiptext">Apply evaluation filter</span>*!/ */}
                        {/* </div> */}
                        <div className="evaluation-filter">
                          <ActionButton
                            type="primary"
                            label="Score Board"
                            clickHandler={handleScoreBoard}
                            styleClass="font-medium start-exam"
                          />
                          {/* <Button className={'action-button "font-medium start-exam" "primary"'} onClick={handleEvaluateExam} label="Score Board" icon="fa-solid fa-trophy" /> */}
                        </div>
                      </div>
                      )
                  }
                  {
                          (examStatus != null && examStatus === 'ongoing')
                          && (
                          // when in ongoing status render edit exam and end exam action buttons
                          <div className="h-auto w-full flex align-items-center justify-content-between">
                            <div className="ongoing">
                              <div className="status-dot" />
                              <div className="status-label">ONGOING</div>
                            </div>

                            <div className="edit-exam">
                              <ActionButton
                                type="primary"
                                label="Edit Exam"
                                clickHandler={handleEditExamClick}
                                styleClass="font-medium "
                              />
                            </div>
                            <div className="end-exam .end-exam-button">
                              <ActionButton
                                type="Danger"
                                label="End Exam"
                                clickHandler={handleShowExamConfirmationClick}
                                styleClass="font-medium p-button-danger .end-exam-button"
                              />
                            </div>
                            <div className="evaluation-filter">
                              <ActionButton
                                type="primary"
                                label="Score Board"
                                clickHandler={handleScoreBoard}
                                styleClass="font-medium start-exam"
                              />
                              {/* <Button className={'action-button "font-medium start-exam" "primary"'} onClick={handleEvaluateExam} label="Score Board" icon="fa-solid fa-trophy" /> */}
                            </div>
                          </div>
                          )
                      }

                </div>
              </div>

            </div>
            )
        }
      <div className="header-card-container h-auto w-full flex align-items-center justify-content-between">
        {
            (examData == null)
              ? <Skeleton height="90px" width="240px" borderRadius="8px" />
              : <ViewExamHeaderCard title="TIME" content={getFormattedTime(examData!.startTime, examData!.endTime)} />
          }
        {
            (examData == null)
              ? <Skeleton height="90px" width="240px" borderRadius="8px" />
              : <ViewExamHeaderCard title="DATE" content={getDate(examData.examDate)} />
          }
        {
            (questionsData == null)
              ? <Skeleton height="90px" width="240px" borderRadius="8px" />
              : <ViewExamHeaderCard title="NUMBER OF QUESTIONS" content={questionsData!.length.toString()} subContent={getQuestionsCount()} />
          }
        {
            (candidatesData == null)
              ? <Skeleton height="90px" width="240px" borderRadius="8px" />
              : <ViewExamHeaderCard title="NUMBER OF CANDIDATES" content={totalCandidates.toString()} subContent={getCandidatesCount()} />
          }
        <ViewExamHeaderCard title="AVERAGE PERCENTAGE" content={average.toFixed(2)} />
      </div>
      {/* added evaluator */}
      {
            ((evaluatorData != null && evaluatorData!.length === 0) || evaluatorData == null || !evaluatorSectionVisible)
            && (
            <div className="evaluator-list-container w-full">
              <div className="header w-full flex align-items-center justify-content-between">
                <div className="title">
                  Evaluators
                </div>
                <div className="filter-container flex">
                  <div className="add-evaluator">
                    <ActionButton type="primary" label="Add Evaluator" clickHandler={handleAddEvaluatorClick} />
                  </div>
                </div>
              </div>
              {
                      (evaluatorData == null || !evaluatorSectionVisible) && <ExamEvaluatorsSkeleton />
                  }

              {
                      (evaluatorData != null && evaluatorData!.length === 0 && evaluatorSectionVisible)
                      && (
                      <div className="empty-evaluator flex align-items-center justify-content-center">
                        No evaluators added yet.
                      </div>
                      )
                  }
            </div>
            )
        }

      {
            (evaluatorData != null && evaluatorData!.length > 0 && evaluatorSectionVisible)
            && <ListEvaluators evaluators={evaluatorData} handleAddEvaluatorClick={handleAddEvaluatorClick} />
        }

      {
            ((candidatesData !== null && candidatesData!.length === 0) || candidatesData === null || !candidateSectionVisible)
            && (
            <div className="candidates-list-container w-full">
              <div className="header w-full flex align-items-center justify-content-between">
                <div className="title">
                  Candidates
                </div>
                {
                  (examData?.statusId !== 3)
                  && (

                  <div className="filter-container-view-exam flex align-items-center justify-content-between">
                    <div className="add-candidates">
                      <ActionButton type="primary" label="Add Candidates" clickHandler={handleAddCandidateClick} />
                    </div>
                    {/* <div>
                      <SpinCheckButton styleClass="upload-button-item" label="Download Template" loadingStatus={addButtonStatus} onClick={handleCandidateTemplateDownload} />
                    </div> */}
                  </div>
                  )
                }
              </div>
              {
                      (candidatesData == null || !candidateSectionVisible) && <ExamCandidatesSkeleton />
                  }

              {
                      (candidatesData != null && candidatesData!.length === 0 && candidateSectionVisible)
                      && (
                      <div className="empty-candidates flex align-items-center justify-content-center">
                        No candidates added yet.
                      </div>
                      )
                  }
            </div>
            )
        }

      {
            (candidatesData != null && candidatesData!.length > 0 && candidateSectionVisible)
            && <ListCandidates candidates={candidatesData} updateCandidateVisible={updateCandidateVisible} handleAddCandidateClick={handleAddCandidateClick} examData={examData as IListExam} examId={examId} toast={toast} handleSectionRefresh={refreshCandidateHandler} />
        }

      {
            ((questionsData != null && questionsData!.length === 0) || questionsData == null || categories == null || !questionSectionVisible)
            && (
            <div className="questions-list-container w-full">
              <div className="header w-full flex align-items-center justify-content-between">
                <div className="title">
                  Questions
                </div>
                {
                  (examData?.statusId !== 3)
                  && (
                    <div className="filter-container-view-exam flex align-items-center justify-content-between">
                      <div className="add-questions">
                        <ActionButton type="primary" label="Add Questions" clickHandler={handleAddQuestionClick} />
                      </div>
                    </div>
                  )
                }
              </div>
              {
                      (questionsData == null || !questionSectionVisible) && <ExamQuestionsSkeleton />
                  }
              {
                      (questionsData != null && questionsData!.length === 0 && questionSectionVisible)
                      && (
                      <div className="empty-questions flex align-items-center justify-content-center">
                        No questions added yet.
                      </div>
                      )
                  }
            </div>
            )
        }

      {
            (examStatus != null && examStatus === 'ongoing')
            && (
            <div className="end-exam-dialog">
              <Dialog
                header="Are you sure, you want to END this exam?"
                visible={endExamVisible}
                className="dialog-header end-exam-dialog-header"
                style={{ width: '580px', height: '200px', borderRadius: '5px' }}
                onHide={handleHideEndExamConfirmationClick}
              >
                <div className="flex align-items-center justify-content-center w-full">
                  <div className="flex align-items-center justify-content-between">
                    <div className="pt-2 pr-1">
                      <TiWarningOutline
                        className=" bg-cyan-50 text-cyan-800 p-2 w-4rem h-4rem border-circle"
                      />
                    </div>
                  </div>
                  <text className="end-body-text">
                    <p>This will end the exam for all the candidates and cannot be reverted</p>
                  </text>
                </div>
                <div className="rowdiv flex align-items-center justify-content-end">
                  <ActionButton
                    type="outline"
                    label="Cancel"
                    clickHandler={handleHideEndExamConfirmationClick}
                    styleClass="w-7rem"
                  />
                  <div className="w-1rem" />
                  <ActionButton
                    type="primary"
                    label="Yes, End Exam"
                    clickHandler={handleEndExam}
                    styleClass="font-medium end-exam w-10rem"
                  />
                  {/* TODO : add onClick event */}
                </div>
                <div />
              </Dialog>
            </div>

            )
        }

      {
            (questionsData != null && questionsData!.length > 0 && examData !== null && categories !== null && questionSectionVisible)
            && <ListQuestions questions={questionsData} updateQuestionVisible={updateQuestionVisible} handleAddQuestionClick={handleAddQuestionClick} examData={examData as IListExam} categories={categories} />
        }

      <Dialog header="Add Evaluator" visible={addEvaluatorsVisible} style={{ width: '550px', height: '330px', borderRadius: '8px' }} onHide={handleHideAddEvaluator}>
        <AddEvaluators setCompVisible={setAddEvaluatorsVisible} toast={toast} examId={examId} addEvalHandler={refreshEvalHandler} />
      </Dialog>

      <Dialog header={updateCandidateHeader} visible={addCandidatesVisible} style={{ width: '590px', height: '860px', borderRadius: '8px' }} onHide={handleHideAddCandidate}>
        <AddCandidates updateCandidate={updateCandidate} addCandidateHandler={refreshCandidateHandler} setCompVisible={setAddCandidatesVisible} handleCandidatesError={handleCandidatesError} toast={toast} campusDriveData={campusDriveData as IListCampusDrive} />
      </Dialog>

      <Dialog header="Add Evaluation Filter" visible={addCandidateFilterVisible} style={{ width: '590px', height: '560px', borderRadius: '8px' }} onHide={handleHideAddEvaluationFilter}>
        <AddEvaluatorFilter addEvaluationFilterHandler={refreshEvaluatioFilterHandler} setCompVisible={setAddCandidateFilterVisible} toast={toast} examData={examData as IListExam} candidateLength={candidatesData ? totalCandidates : 0} />
        {/* <AddEvaluatorFilter setCompVisible={setAddCandidateFilterVisible} toast={toast} examData={examData as IListExam} /> */}
      </Dialog>

      {
            categories !== null && (
            <Dialog header={updateQuestionHeader} visible={addQuestionsVisible} style={{ width: '590px', height: '860px', borderRadius: '8px' }} onHide={handleHideAddQuestions}>
              <AddQuestions updateQuestion={updateQuestion} addQuestionHandler={refreshQuestionHandler} setCompVisible={setAddQuestionsVisible} handleQuestionError={handleQuestionsError} categories={categories} questionPoint={questionPoints} toast={toast} />
            </Dialog>
            )
        }

      <Dialog header={errorModalHeader} visible={errorModalVisible} style={{ width: '590px', height: 'auto', borderRadius: '8px' }} onHide={handleHideErrorModal}>
        {
              (modalErrors != null)
              && (
                modalErrors.map((error: string, index: number) => <ExamErrorModalRow index={index} value={error} />)
              )
          }
      </Dialog>
      <Toast ref={toastRef} />
    </div>
  );
}
