import React, { useState } from 'react';
import './UploadField.css';
import AddDocument from '../../assets/icons/AddDocument.svg';
import { IFileData } from '../../models/IFileData';

type UploadFileProps = {
  title: string;
  file: IFileData,
  setFile: any,
  validator: any,
  postValidation: any,
}

export default function UploadField({
  title, file, setFile, validator, postValidation,
}: UploadFileProps) {
  const [validationStatus, setValidationStatus] = useState<'loading' | 'success' | 'failed'>('loading');
  const [error, setErrors] = useState({
    isError: false,
    message: '',
  });
  const allowedFileTypes = ['xls', 'csv', 'xlsx'];

  const handleRemoveFile = () => {
    setFile({
      name: '',
      file: null,
      isPresent: false,
    })
  }

  const handleRemoveFileEnter = (ev: any) => {
    if (ev.keyCode === 13) {
      handleRemoveFile();
    }
  }

  const handleFileUpload = async (ev: any) => {
    setErrors({
      isError: false,
      message: '',
    })
    ev.preventDefault();
    ev.stopPropagation();
    if (ev.target.files.length === 1) {
      if (allowedFileTypes.includes((ev.target.files[0].name as string).split('.')[1].toLowerCase())) {
        const fileData = {
          name: ev.target.files[0].name,
          file: ev.target.files[0],
          isPresent: true,
        }
        await setFile(fileData)
        setValidationStatus('loading');
        const result = await validator(fileData);
        if (result.valid) {
          setValidationStatus('success');
        }
        if (!result.valid) {
          setValidationStatus('failed');
        }
        postValidation(result);
      } else {
        setErrors({
          isError: true,
          message: 'File type not allowed',
        })
      }
    } else {
      setErrors({
        isError: true,
        message: 'Do not upload multiple files',
      })
    }
  }

  return (
    <>
      {
        (file.file == null && !file.isPresent)
        && (
          <div className="upload-field">
            <div className="header">
              {`Upload file (Will replace all ${title})`}
            </div>
            <div className="upload-container flex flex-column align-items-center justify-content-center cursor-pointer">
              <div className="icon-container">
                <img src={AddDocument} alt="Add Document" />
              </div>
              <div>
                <span className="text-1">{`Upload ${title} file `}</span>
                <span className="text-2">or drag and drop</span>
              </div>
              <div className="text-3">
                CSV, xls, xlsx, upto 10mb
              </div>
            </div>
            {
              (error.isError)
              && <small id="username2-help" className="p-error block">{error.message}</small>
            }
            <input type="file" name="file-upload" id="file-upload" className="file-input-field" onChange={handleFileUpload} />
          </div>
        )
      }
      {
        (file.file != null && file.isPresent)
        && (
          <div className="file-present w-full flex align-items-center justify-content-between">
            <div className="text-container">
              <div className="text">
                {file.name}
              </div>

              <div className="validation-status-container flex">
                {
                  (validationStatus === 'loading')
                    && (
                    <div>
                      <i className="pi pi-spin pi-spinner spinner-status" />
                      <span className="status-text">
                        Validating
                      </span>
                    </div>
                    )
                }

                {
                  (validationStatus === 'failed')
                  && (
                    <div>
                      <i className="pi pi-times close-status" />
                      <span className="status-text close-status">
                        Validation Failed
                      </span>
                    </div>
                  )
                }

                {
                  (validationStatus === 'success')
                  && (
                    <div>
                      <i className="pi pi-check done-status" />
                      <span className="status-text done-status">
                        Ready to upload
                      </span>
                    </div>
                  )
                }
              </div>
            </div>
            <div className="buttons cursor-pointer" role="button" tabIndex={0} onKeyDown={handleRemoveFileEnter} onClick={handleRemoveFile}>
              <i className="pi pi-times close-icon" />
            </div>
          </div>
        )
      }
    </>
  )
}
