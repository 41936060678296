import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICollege } from '../models/ICollege';
import { ICategory } from '../models/ICategory';
import { IUniversity } from '../models/IUniversity';

export interface SettingsState {
  collegeData: ICollege[] | null;
  collegeDialogVisible: boolean;
  collegeDialogTitle: 'Add' | 'Update';
  updateCollegeData: ICollege | null;
  categoryData: ICategory[] | null;
  categoryDialogVisible: boolean;
  updateCategoryData:ICategory | null;
  categoryDialogTitle: 'Add' | 'Update';
  collegeStartingPageNo: number;
  collegeTotal: number;
  currentIndex: number;
  isCollegeSearch: boolean;
  collegeSearchValue: string;
  allCollegeData: IUniversity[] | null;
}

const initialState: SettingsState = {
  collegeData: null,
  collegeDialogVisible: false,
  collegeDialogTitle: 'Add',
  updateCollegeData: null,
  categoryData: null,
  categoryDialogVisible: false,
  updateCategoryData: null,
  categoryDialogTitle: 'Add',
  collegeStartingPageNo: 0,
  collegeTotal: 0,
  currentIndex: 0,
  isCollegeSearch: false,
  collegeSearchValue: '',
  allCollegeData: null,
};

export const settingsStore = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setCollegeData: (state, action: PayloadAction<ICollege[] | null>) => {
      state.collegeData = action.payload
    },
    setCollegeDialogVisible: (state, action: PayloadAction<boolean>) => {
      state.collegeDialogVisible = action.payload;
    },
    setCollegeDialogTitle: (state, action: PayloadAction<'Add' | 'Update'>) => {
      state.collegeDialogTitle = action.payload;
    },
    setUpdateCollegeData: (state, action: PayloadAction<ICollege | null>) => {
      state.updateCollegeData = action.payload;
    },
    setCategoryData: (state, action: PayloadAction<ICategory[] | null>) => {
      state.categoryData = action.payload
    },
    setCategoryDialogVisible: (state, action: PayloadAction<boolean>) => {
      state.categoryDialogVisible = action.payload;
    },
    setUpdateCategoryData: (state, action: PayloadAction<ICategory | null>) => {
      state.updateCategoryData = action.payload;
    },
    setCategoryDialogTitle: (state, action: PayloadAction<'Add' | 'Update'>) => {
      state.categoryDialogTitle = action.payload;
    },
    setCollegeStartingPageNo: (state, action: PayloadAction<number>) => {
      state.collegeStartingPageNo = action.payload;
    },
    setCollegeTotal: (state, action: PayloadAction<number>) => {
      state.collegeTotal = action.payload;
    },
    setCurrentIndex: (state, action: PayloadAction<number>) => {
      state.currentIndex = action.payload;
    },
    setIsCollegeSearch: (state, action: PayloadAction<boolean>) => {
      state.isCollegeSearch = action.payload;
    },
    setCollegeSearchValue: (state, action: PayloadAction<string>) => {
      state.collegeSearchValue = action.payload;
    },
    setAllCollegeData: (state, action: PayloadAction<IUniversity[] | null>) => {
      state.allCollegeData = action.payload;
    },
  },
});

export const {
  setCollegeData,
  setCollegeDialogTitle,
  setCollegeDialogVisible,
  setUpdateCollegeData,
  setCategoryData,
  setCategoryDialogTitle,
  setCategoryDialogVisible,
  setUpdateCategoryData,
  setCollegeStartingPageNo,
  setCollegeTotal,
  setCurrentIndex,
  setIsCollegeSearch,
  setCollegeSearchValue,
  setAllCollegeData,
} = settingsStore.actions;

export default settingsStore.reducer;
