import React, { useRef } from 'react';
import './filterListCandidates.css';
import { Link } from 'react-router-dom';
import { GrPowerReset } from 'react-icons/gr';
import { AxiosResponse } from 'axios';
import { Toast } from 'primereact/toast';
import RoutesConstants from '../../constants/routesConstants';
// import IListCandidate from '../../models/IListCandidate';
import ISessionData from '../../models/ISessionData';
import { candidateSessionReset, getExamSessionCandidates } from '../../services/examService';
import { setSessionData, setTotalSessionData } from '../../store/scoreboardStore';
import { useAppDispatch } from '../../store/storeHooks';

type IListCampusDriveRowProp = {
  candidate: ISessionData,
  examId: string,
}

function FilterCListCandidates({ candidate, examId }: IListCampusDriveRowProp) {
  const routesConstant = new RoutesConstants();
  const dispatch = useAppDispatch();
  const toastRef = useRef<any>(null);
  const resetHandler = () => {
    toastRef.current.show({
      severity: 'info',
      summary: 'Session Reset',
      detail: 'Resetting candidate session status.',
    });
    candidateSessionReset(candidate)
      .then(() => {
        getExamSessionCandidates(examId, 0, '8', 0)
          .then((response: AxiosResponse) => {
            toastRef.current.show({
              severity: 'success',
              summary: 'Session Reset',
              detail: 'Candidate Session reset successfull.',
            });
            dispatch(setSessionData(response.data.candidates));
            dispatch(setTotalSessionData(response.data.len));
          })
      })
      .catch((e) => {
        toastRef.current.show({
          severity: 'error',
          summary: 'Session Reset',
          detail: e.response.data.message,
        });
      })
  }

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {
        (candidate != null)
        && (
          <div className="filter-candidates-list-row flex w-full">
            <div className="cell campus-drive-col align-content-center">{candidate.Candidate.name}</div>
            <div className="cell university-col">{candidate.Candidate.regNo}</div>
            <div className="cell status-col">
              {
                (candidate.statusId === 2)
                && 'Started'
              }
              {
                (candidate.statusId === 4)
                && 'Ended'
              }
              {
                (candidate.statusId === 5)
                && (
                <div className="Terminated-container">
                  Terminated
                  <GrPowerReset className="reset-button" onClick={resetHandler} />
                </div>
                )
              }
            </div>
            <div className="cell exams-col">{candidate.marks}</div>
            <div className="cell students-col">{`${candidate.percentage ? candidate.percentage.toFixed(2) : '-'}`}</div>
            <div className="view-campus-drive h-full flex align-items-center">
              <Link
                style={{ color: '#0369A1' }}
                to={`${routesConstant.EXAMS}${routesConstant.CANDIDATE_MARK_SHEET}/${examId}/${candidate.candidateId}`}
                className="no-underline"
              >
                Review
              </Link>
            </div>
            <Toast ref={toastRef} />
          </div>
        )
      }
    </>
  );
}

export default FilterCListCandidates;
