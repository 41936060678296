import React, { useEffect, useRef, useState } from 'react';
import './filterCandidates.css';
import { Button } from 'primereact/button';
// import { useNavigate } from 'react-router';
import { AxiosResponse } from 'axios';
// import { useForm } from 'react-hook-form';
import { InputText } from 'primereact/inputtext';
import { useLocation } from 'react-router-dom';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../store/storeHooks';
import IListCandidate from '../../models/IListCandidate';
import SpinCheckButton from '../spinCheckButton/SpinCheckButton';
import {
  downloadFilteredCandidates,
  getCandidatesGreaterFilter,
  getCandidatesLesserFilter, getEvaluatedCandidateFilter,
  getExamSessionCandidates,
  searchCandidateApi,
} from '../../services/examService';
import {
  setCandidateCurrentIndex,
  setCandidateData, setCandidateIsSearch,
  setCandidatePageNo,
  setCandidateTotal,
} from '../../store/examStore';
import FilterCListCandidates from '../filterListCandidates/filterCListCandidates';
// import { CampusDriveListSkeleton } from '../skeleton';
import { setCollegeSearchValue } from '../../store/settingsStore';
import {
  setCurrentIndex,
  setIsFilterGreater,
  setIsFilterLesser, setIsFilterPassed,
  setPercentage,
  setSessionData,
  setStartingPageNo,
  setTotalSessionData,
  setIsEvaluatedFilter,
} from '../../store/scoreboardStore';
import ISessionData from '../../models/ISessionData';
import FilterIcon from '../../assets/icons/FilterIcon.svg';

export default function FilterCandidates() {
  const [previousButtonStatus, setPreviousButtonStatus] = useState<'LOADING' | 'LOADED' | 'NOT_LOADING'>('NOT_LOADING');
  const [nextButtonStatus, setNextButtonStatus] = useState<'LOADING' | 'LOADED' | 'NOT_LOADING'>('NOT_LOADING');
  const [searchButtonStatus, setSearchButtonStatus] = useState<'LOADING' | 'LOADED' | 'NOT_LOADING'>('NOT_LOADING');
  // const candidatesData = useAppSelector((state) => state.exams.candidateData);
  // const totalCandidates = useAppSelector((state) => state.exams.candidateTotal);
  const candidateCurrentIndex = useAppSelector((state) => state.scoreboard.currentIndex);
  const candidatePageNo = useAppSelector((state) => state.scoreboard.startingPageNo);
  const isSearch = useAppSelector((state) => state.exams.candidateIsSearch);
  const candidatesData = useAppSelector((state) => state.scoreboard.sessionData);
  const totalCandidates = useAppSelector((state) => state.scoreboard.totalSessionData);
  const isGreaterFilter = useAppSelector((state) => state.scoreboard.isFilterGreater);
  const isLesserFilter = useAppSelector((state) => state.scoreboard.isFilterLesser);
  const isPassedFilter = useAppSelector((state) => state.scoreboard.isFilterPassed);
  const navigate = useNavigate();
  // const isEvaluatedFilter = useAppSelector((state) => state.scoreboard.isEvaluatedFilter);
  const percent = useAppSelector((state) => state.scoreboard.percentage);
  const [dialogVisible, setDialogVisible] = useState<boolean>(false);
  const [buttonLoader, setButtonLoader] = useState<'LOADING' | 'LOADED' | 'NOT_LOADING'>('NOT_LOADING');
  const [candidateFilter, setCandidateFilter] = useState<any>('');
  const [placeholder, setPlaceholder] = useState<string>('Search candidate');
  // const [filterResult, setFilterResult] = useState<any>('');
  const [filterQuery, setFilterQuery] = useState<string>();
  const [startingCandidate] = useState(0);
  const [checkValuePC, setCheckValuePC] = useState<boolean>(false);
  const location = useLocation();
  const dispatch = useAppDispatch();
  const examId = String(location.pathname.split('/').pop());
  const [downloadButtonStatus, setDownloadButtonStatus] = useState<'LOADING' | 'LOADED' | 'NOT_LOADING'>('NOT_LOADING');
  const toastRef = useRef<any>(null);
  const toast = (severity: string, summary: string, detail: string) => {
    toastRef.current.show({
      severity,
      summary,
      detail,
      life: 3000,
    })
  }
  let candidateLen: number = 0;
  if (candidatesData != null) {
    candidateLen = candidatesData.length;
  }
  const candidateFilterOptions = [
    { name: 'Percentage greater than..', code: 'PCT' },
    { name: 'Percentage lesser than..', code: 'PLT' },
    { name: 'Passed Candidates', code: 'PC' },
    { name: 'Evaluated Candidates', code: 'EC' },
  ];

  const defaultValues = {
    percentage: '',
  };

  const {
    register, handleSubmit, formState: { errors },
  } = useForm({ defaultValues });
  useEffect(() => {
    getExamSessionCandidates(examId, 0, '8', 0)
      .then((response: AxiosResponse) => {
        dispatch(setSessionData(response.data.candidates));
        dispatch(setTotalSessionData(response.data.len));
      })
  }, [])
  const handleNextPageClick = () => {
    setNextButtonStatus('LOADING');
    if (isSearch === false && isGreaterFilter === false && isLesserFilter === false && isPassedFilter === false) {
      getExamSessionCandidates(examId, candidatePageNo + 1, '8', 0)
        .then((response: AxiosResponse) => {
          dispatch(setStartingPageNo(candidatePageNo + 1));
          dispatch(setCurrentIndex(candidateCurrentIndex + 8));
          dispatch(setSessionData(response.data.candidates));
          dispatch(setTotalSessionData(response.data.len));
          setNextButtonStatus('NOT_LOADING');
        })
        .catch(() => {
          toast(
            'error',
            'Error',
            'Something went Wrong',
          )
        })
    } else if (isGreaterFilter === true) {
      getCandidatesGreaterFilter(examId, candidatePageNo + 1, '8', 0, percent)
        .then((response: AxiosResponse) => {
          dispatch(setStartingPageNo(candidatePageNo + 1));
          dispatch(setCurrentIndex(candidateCurrentIndex + 8));
          dispatch(setSessionData(response.data.candidates));
          dispatch(setTotalSessionData(response.data.len));
          setNextButtonStatus('NOT_LOADING');
          toast(
            'info',
            'Applied Filter ',
            `Percentage Greater Than ${percent}`,
          )
        })
        .catch(() => {
          toast(
            'error',
            'Error',
            'Something went Wrong',
          )
        })
    } else if (isLesserFilter === true) {
      getCandidatesLesserFilter(examId, candidatePageNo + 1, '8', 0, percent)
        .then((response: AxiosResponse) => {
          dispatch(setStartingPageNo(candidatePageNo + 1));
          dispatch(setCurrentIndex(candidateCurrentIndex + 8));
          dispatch(setSessionData(response.data.candidates));
          dispatch(setTotalSessionData(response.data.len));
          setNextButtonStatus('NOT_LOADING');
        })
        .catch(() => {
          toast(
            'error',
            'Error',
            'Something went Wrong',
          )
        })
    } else if (isPassedFilter === true) {
      getExamSessionCandidates(examId, candidatePageNo + 1, '8', percent)
        .then((response: AxiosResponse) => {
          dispatch(setStartingPageNo(candidatePageNo + 1));
          dispatch(setCurrentIndex(candidateCurrentIndex + 8));
          dispatch(setSessionData(response.data.candidates));
          dispatch(setTotalSessionData(response.data.len));
          setNextButtonStatus('NOT_LOADING');
        })
        .catch(() => {
          toast(
            'error',
            'Error',
            'Something went Wrong',
          )
        })
    } else {
      searchCandidateApi(examId, candidatePageNo + 1, filterQuery || '')
        .then((response: AxiosResponse) => {
          dispatch(setCandidateData(response.data.candidates as IListCandidate[]));
          dispatch(setCandidateTotal(response.data.len));
          dispatch(setCandidateCurrentIndex(candidateCurrentIndex + 5));
          dispatch(setCandidatePageNo(candidatePageNo + 1));
          setNextButtonStatus('NOT_LOADING');
        })
        .catch(() => {
          toast(
            'error',
            'Error',
            'Something went Wrong',
          )
        })
    }
  }

  const handlePreviousPageClick = () => {
    setPreviousButtonStatus('LOADING');
    if (isSearch === false && isGreaterFilter === false && isLesserFilter === false && isPassedFilter === false) {
      getExamSessionCandidates(examId, candidatePageNo - 1, '8', 0)
        .then((response: AxiosResponse) => {
          dispatch(setStartingPageNo(candidatePageNo - 1));
          dispatch(setCurrentIndex(candidateCurrentIndex - 8));
          dispatch(setSessionData(response.data.candidates));
          dispatch(setTotalSessionData(response.data.len));
          setPreviousButtonStatus('NOT_LOADING');
        })
        .catch(() => {
          toast(
            'error',
            'Error',
            'Something went Wrong',
          )
        })
    } else if (isGreaterFilter === true) {
      getCandidatesGreaterFilter(examId, candidatePageNo - 1, '8', 0, percent)
        .then((response: AxiosResponse) => {
          dispatch(setStartingPageNo(candidatePageNo - 1));
          dispatch(setCurrentIndex(candidateCurrentIndex - 8));
          dispatch(setSessionData(response.data.candidates));
          dispatch(setTotalSessionData(response.data.len));
          setPreviousButtonStatus('NOT_LOADING');
          toast(
            'info',
            'Applied Filter',
            `Percentage Greater Than ${percent}`,
          )
        })
        .catch(() => {
          toast(
            'error',
            'Error',
            'Something went Wrong',
          )
        })
    } else if (isLesserFilter === true) {
      getCandidatesLesserFilter(examId, candidatePageNo - 1, '8', 0, percent)
        .then((response: AxiosResponse) => {
          dispatch(setStartingPageNo(candidatePageNo - 1));
          dispatch(setCurrentIndex(candidateCurrentIndex - 8));
          dispatch(setSessionData(response.data.candidates));
          dispatch(setTotalSessionData(response.data.len));
          setPreviousButtonStatus('NOT_LOADING');
        })
        .catch(() => {
          toast(
            'error',
            'Error',
            'Something went Wrong',
          )
        })
    } else if (isPassedFilter === true) {
      getExamSessionCandidates(examId, candidatePageNo - 1, '8', percent)
        .then((response: AxiosResponse) => {
          dispatch(setStartingPageNo(candidatePageNo - 1));
          dispatch(setCurrentIndex(candidateCurrentIndex - 8));
          dispatch(setSessionData(response.data.candidates));
          dispatch(setTotalSessionData(response.data.len));
          setPreviousButtonStatus('NOT_LOADING');
        })
        .catch(() => {
          toast(
            'error',
            'Error',
            'Something went Wrong',
          )
        })
    } else {
      searchCandidateApi(examId, candidatePageNo - 1, filterQuery || '')
        .then((response: AxiosResponse) => {
          dispatch(setCandidateData(response.data.candidates as IListCandidate[]));
          dispatch(setCandidateTotal(response.data.len));
          dispatch(setCandidateCurrentIndex(candidateCurrentIndex - 5));
          dispatch(setCandidatePageNo(candidatePageNo - 1));
          setPreviousButtonStatus('NOT_LOADING');
        })
        .catch(() => {
          toast(
            'error',
            'Error',
            'Something went Wrong',
          )
        })
    }
  }

  const downloadCandidateFilter = () => {
    setDownloadButtonStatus('LOADING');
    const filterdata:any = {};
    filterdata.examId = examId
    filterdata.greater = null;
    filterdata.lesser = null;
    filterdata.passed = 0;
    filterdata.evaluated = null;
    console.log(candidateFilter)
    if (isGreaterFilter) {
      filterdata.greater = percent;
    } else if (isLesserFilter) {
      filterdata.lesser = percent;
    } else if (isPassedFilter) {
      filterdata.passed = percent;
    } else if (candidateFilter.code === 'EC') {
      filterdata.evaluated = '0';
    }
    downloadFilteredCandidates(filterdata)
      .then((response:AxiosResponse) => {
        if (response.status === 200) {
          window.location.assign(response.data.url);
          setDownloadButtonStatus('LOADED');
        } else {
          setDownloadButtonStatus('NOT_LOADING');
        }
      })
  }

  const onFilterApply = () => {
    setSearchButtonStatus('LOADING');
    dispatch(setCandidateIsSearch(true));
    dispatch(setCandidatePageNo(0));
    dispatch(setCollegeSearchValue(filterQuery || ''));
    searchCandidateApi(examId, 0, filterQuery || '')
      .then((response: AxiosResponse) => {
        dispatch(setCandidateCurrentIndex(0));
        dispatch(setCandidateData(response.data.candidates as IListCandidate[]));
        dispatch(setCandidateTotal(response.data.len));
        dispatch(setCandidatePageNo(0));
        setSearchButtonStatus('NOT_LOADING');
      })
      .catch(() => {
        toast(
          'error',
          'Error',
          'Something went Wrong',
        )
      });
  };
  const dialogClose = () => {
    setCheckValuePC(false);
    // setPercentage(0);
    setDialogVisible(false);
  }
  const onFilterQueryChange = (e: { value: any}) => {
    const filter = e.value;
    setPlaceholder(e.value.name);
    setCandidateFilter(filter);
    console.log(candidateFilter)
    if (filter.code === 'EC') {
      getEvaluatedCandidateFilter(examId, 0, '8', '0')
        .then((response: AxiosResponse) => {
          dispatch(setSessionData(response.data.candidates));
          dispatch(setTotalSessionData(response.data.len));
          dispatch(setStartingPageNo(0));
          dispatch(setCurrentIndex(0));
          toast(
            'info',
            'Applied Filter',
            'Evaluated Candidates',
          )
        })
        .catch(() => {
          toast(
            'error',
            'Error',
            'Something went Wrong',
          )
        })
    } else {
      setDialogVisible(true);
    }
  }
  const onSubmit: SubmitHandler<any> = (data: any) => {
    if (data.percentage >= 0) {
      setCheckValuePC(false);
      setButtonLoader('LOADING');
      dispatch(setPercentage(data.percentage));
      if (candidateFilter.code === 'PCT') {
        dispatch(setIsFilterPassed(false));
        dispatch(setIsFilterLesser(false));
        dispatch(setIsFilterGreater(true));
        dispatch(setIsEvaluatedFilter(false));
        getCandidatesGreaterFilter(examId, 0, '8', 0, data.percentage)
          .then((response: AxiosResponse) => {
            dispatch(setSessionData(response.data.candidates));
            dispatch(setTotalSessionData(response.data.len));
            dispatch(setStartingPageNo(0));
            dispatch(setCurrentIndex(0));
            setButtonLoader('NOT_LOADING');
            setDialogVisible(false);
            toast(
              'info',
              'Applied Filter',
              `Percentage Greater Than ${percent}`,
            )
          })
          .catch(() => {
            toast(
              'error',
              'Error',
              'Something went Wrong',
            )
          })
      } else if (candidateFilter.code === 'PLT') {
        dispatch(setIsFilterGreater(false));
        dispatch(setIsFilterPassed(false));
        dispatch(setIsFilterLesser(true));
        dispatch(setIsEvaluatedFilter(false));
        getCandidatesLesserFilter(examId, 0, '8', 0, data.percentage)
          .then((response: AxiosResponse) => {
            dispatch(setSessionData(response.data.candidates));
            dispatch(setTotalSessionData(response.data.len));
            dispatch(setStartingPageNo(0));
            dispatch(setCurrentIndex(0));
            setButtonLoader('NOT_LOADING');
            setDialogVisible(false);
            toast(
              'info',
              'Applied Filter',
              `Percentage Lesser Than ${percent}`,
            )
          })
          .catch(() => {
            toast(
              'error',
              'Error',
              'Something went Wrong',
            )
          })
      } else if (candidateFilter.code === 'PC') {
        dispatch(setIsFilterGreater(false));
        dispatch(setIsFilterLesser(false));
        dispatch(setIsFilterPassed(true));
        dispatch(setIsEvaluatedFilter(false));
        getExamSessionCandidates(examId, 0, '8', data.percentage)
          .then((response: AxiosResponse) => {
            dispatch(setSessionData(response.data.candidates));
            dispatch(setTotalSessionData(response.data.len));
            dispatch(setStartingPageNo(0));
            dispatch(setCurrentIndex(0));
            setButtonLoader('NOT_LOADING');
            setDialogVisible(false);
            toast(
              'info',
              'Applied Filter',
              'Passed Candidates',
            )
          })
          .catch(() => {
            toast(
              'error',
              'Error',
              'Something went Wrong',
            )
          })
      }
      // dispatch(setPercentage(0));
    } else {
      setCheckValuePC(true);
    }
  }
  const checkValue = async (object: any) => {
    if (object.target.value < 0) {
      setCheckValuePC(true);
    } else {
      setCheckValuePC(false);
    }
  }
  const goBackClick = () => {
    navigate(-1);
  };

  const goBackEnter = () => {
    // @ts-ignore
    if (event.key === 'Enter') {
      goBackClick();
    }
  };
  return (
    <div className="campus-drive-list-view h-full w-full">
      <div className="header flex align-items-center justify-content-between">
        <div className="title-container flex flex-column align-items-start justify-content-center h-full">
          <div role="button" tabIndex={0} onClick={goBackClick} onKeyDown={goBackEnter} className="back-navigation flex align-items-center justify-content-start cursor-pointer">
            <i className="pi pi-angle-left font-semibold" />
            <div className="label font-medium">
              Back
            </div>
          </div>
          <div className="title font-semibold">
            Score Board
          </div>
        </div>
        <div className="filter-container flex">
          <div className="filter-fields">
            <div className="p-inputgroup">
              <span className="search p-input-icon-left">
                <i className="pi pi-search" />
                <InputText onInput={(e: any) => setFilterQuery(e.target.value)} type="string" placeholder={placeholder} />
              </span>
            </div>
          </div>
          <div className="search-drive mr-2">
            <SpinCheckButton styleClass="search-drive-button font-medium" loadingStatus={searchButtonStatus} onClick={() => onFilterApply()} label="Search" />
          </div>
          <span className="p-input-icon-left flex align-items-center mr-2 filter-border">
            <img src={FilterIcon} className="filter-icon" alt="Filter" />
            <Dropdown options={candidateFilterOptions} onChange={onFilterQueryChange} optionLabel="name" placeholder="Filter" showClear={candidateFilter !== '' && candidateFilter != null} />
          </span>
          <div className="new-drive">
            <SpinCheckButton styleClass="download-button font-medium" loadingStatus={downloadButtonStatus} onClick={downloadCandidateFilter} label="Download" />
          </div>
        </div>
      </div>
      {/* { */}
      {/*   (candidatesData == null) */}
      {/*   && <CampusDriveListSkeleton /> */}
      {/* } */}
      {
        (candidatesData != null && candidatesData!.length > 0)
        && (
          <>
            <div className="drive-list-container w-full h-auto flex flex-column align-items-center justify-content-start">
              <div className="list-header flex w-full">
                <div className="cell campus-drive-col">
                  NAME
                </div>
                <div className="cell university-col">
                  REG. NUMBER
                </div>
                <div className="cell status-col">
                  STATUS
                </div>
                <div className="cell exams-col">
                  SCORE
                </div>
                <div className="cell students-col">
                  PERCENTAGE
                </div>
                <div className="view-campus-drive h-full" />
              </div>

              {
                candidatesData?.map((candidate: ISessionData, index: number) => {
                  if (index >= startingCandidate && index < startingCandidate + 8) {
                    return <FilterCListCandidates key={candidate.id} examId={examId} candidate={candidate} />
                  }
                  return <div key={candidate.id} />
                })
              }
            </div>
            <div className="drive-footer w-full flex align-items-center justify-content-between">
              <div className="drive-result-details">
                {
                  candidatesData != null && `Showing ${(candidatesData!.length === 0) ? 0 : (candidateCurrentIndex + 1)} to ${(totalCandidates < candidateCurrentIndex + 8) ? (totalCandidates) : (candidateCurrentIndex + 8)} of ${totalCandidates} results`
                }
              </div>
              <div className="pagination-buttons flex">
                {
                  (candidatePageNo !== 0)
                  && (
                    <div className="previous-button">
                      <SpinCheckButton styleClass="previous-button" label="Previous" loadingStatus={previousButtonStatus} onClick={handlePreviousPageClick} />
                    </div>
                  )
                }
                {
                  ((totalCandidates - candidateLen) !== candidateCurrentIndex)
                  && (
                    <div className="next-button">
                      <SpinCheckButton styleClass="next-button" label="Next" loadingStatus={nextButtonStatus} onClick={handleNextPageClick} />
                    </div>
                  )
                }

              </div>
            </div>
          </>
        )
      }
      {
        (candidatesData != null && candidatesData!.length === 0)
        && (
          <div className="no-data w-full flex align-items-center justify-content-center">
            <div className="no-campusDrive-content">No candidate data available. Add candidate to list here.</div>
          </div>
        )
      }
      {
        (candidateFilter?.code === 'PLT' || candidateFilter?.code === 'PCT')
        && (
        <Dialog header="Enter Percentage" className="dialog-box" visible={dialogVisible} style={{ width: '30%', height: '22%', borderRadius: '8px' }} onHide={dialogClose}>
          <form className="dialog-align" onSubmit={handleSubmit(onSubmit)}>
            <div className="field align-items-center justify-content-between">
              <div className="field split email">
                <div className="label email-label" />
                <div className="email-field-input">
                  <InputText {...register('percentage', { required: 'Percentage is required.' })} style={{ width: '100%' }} type="number" className="input-text" onInput={checkValue} placeholder="Enter Percentage here" />
                  {
                        errors.percentage?.type === 'required' ? (<small className="error-label p-error">{errors.percentage.message}</small>) : ''
                    }
                  {
                        (checkValuePC) && (
                        <small className="error-label p-error">
                          {' '}
                          Value cannot be negative
                        </small>
                        )
                    }
                </div>
              </div>
              <div className="add-button-container w-full flex align-items-center justify-content-end">
                <div className="add-button">
                  <Button label="Cancel" className="p-button-outlined p-button-sm" style={{ color: '#0369A1', width: 150, marginRight: '15px' }} onClick={dialogClose} />
                  <SpinCheckButton label="Filter" onClick={handleSubmit(onSubmit)} loadingStatus={buttonLoader} styleClass="dialog-buttons" />
                </div>
              </div>
            </div>
          </form>
        </Dialog>
        )
      }
      {
        (candidateFilter?.code === 'PC')
        && (
        <Dialog header="Enter Score" className="dialog-box" visible={dialogVisible} style={{ width: '30%', height: '22%', borderRadius: '8px' }} onHide={dialogClose}>
          <form className="dialog-align" onSubmit={handleSubmit(onSubmit)}>
            <div className="field align-items-center justify-content-between">
              <div className="field split email">
                <div className="label email-label" />
                <div className="email-field-input">
                  <InputText {...register('percentage', { required: 'Percentage is required.' })} style={{ width: '100%' }} type="number" min={0} className="input-text" onInput={checkValue} placeholder="Enter Score here" />
                  {
                        errors.percentage?.type === 'required' ? (<small className="error-label p-error">{errors.percentage.message}</small>) : ''
                    }
                  {
                        (checkValuePC) && (
                        <small className="error-label p-error">
                          {' '}
                          Value cannot be negative
                        </small>
                        )
                    }
                </div>
              </div>
              <div className="add-button-container w-full flex align-items-center justify-content-end">
                <div className="add-button">
                  <Button label="Cancel" className="p-button-outlined p-button-sm" style={{ color: '#0369A1', width: 150, marginRight: '15px' }} onClick={dialogClose} />
                  <SpinCheckButton label="Filter" loadingStatus={buttonLoader} styleClass="dialog-buttons" onClick={handleSubmit(onSubmit)} />
                </div>
              </div>
            </div>
          </form>
        </Dialog>
        )
      }
      <Toast ref={toastRef} />
    </div>
  );
}
