import React, { useEffect, useState } from 'react';
import { ProgressBar } from 'primereact/progressbar';
import { questionNavigatorType, questionType } from '../navigator/Navigator';
import './MarkSheetQuestion.css';
import MarkSheetMCQ from './markSheetMCQ/MarkSheetMCQ';
import MarkSheetFooter from './markSheetFooter/MarkSheetFooter';
import MarkSheetOEQT from './markSheetOEQT/MarkSheetOEQT';
import MarkSheetMSQ from './markSheetMSQ/MarkSheetMSQ';
import { createResponse } from '../../services/examService';
import { evaluationNavigatorType } from '../evaluation/evaluation';
import EvaluationFooter
  from '../evaluation/evaluationNavigation/responseNavigator/responseNavigatorDisplay/evaluationFooter/evaluationFooter';
import MarkSheetOEQC from './markSheetOEQC/MarkSheetOEQC';
import { useAppSelector } from '../../store/storeHooks';

export type evaluationType = {
    id: string | null,
    responseId: string | null,
    points: number | null,
    type: string | null,
    createdAt: string | null,
    updatedAt: string | null,
    deletedAt: string | null,
}

export type responseType = {
    id: string,
    questionId: string,
    examId: string,
    categoryId: number,
    sessionId: string,
    answer: string,
    timeSpent: number,
    bookmark: boolean,
    evaluate: string,
    language: string,
    createdAt: string,
    updatedAt: string | null,
    deletedAt: string | null,
}

export type candidateQuestion = {
    markSheetDisplay: {
        order: number,
        question: questionType,
        response: responseType
        evaluation?: evaluationType,
    },
    navigator?: questionNavigatorType[],
    setSelectedQuestion?: any,
    isCandidate?: boolean,
    setResponseString?: any,
    selectedQuestion?: questionNavigatorType,
    setNavigator?: any,
    reviewHandler?: any,
    setMarkSheetDisplay?: any,
    setOnReviewClick?: any,
    onReviewClick?: any,
  responseNavigator?: evaluationNavigatorType[] | null,
  setResponseNavigator?: any,
  selectedResponse?: evaluationNavigatorType | null | undefined,
  setSelectedResponse?: any,
}

export default function MarkSheetQuestion({
  markSheetDisplay, navigator, setSelectedQuestion, selectedQuestion, isCandidate, setNavigator, reviewHandler, setMarkSheetDisplay,
  setOnReviewClick, onReviewClick, responseNavigator, setResponseNavigator, selectedResponse, setSelectedResponse,
}: candidateQuestion) {
  const [responseString, setResponseString] = useState<string | null | undefined>(undefined);
  const navigatorLoader: boolean = useAppSelector((state) => state.loader.navigatorLoader);
  const oeqcCodingLanguage = useAppSelector((state) => state.counter.oeqcCodinglanguage)
  console.log('the state of language is', oeqcCodingLanguage)

  let classname;
  if (responseNavigator) {
    classname = 'evaluation-sheet-display-canvas';
  } else {
    classname = 'sheet-display-canvas';
  }

  const updateAnswered = (answered: boolean) => {
    if (navigator) {
      setNavigator(navigator.map((navigatorObject) => {
        if (navigatorObject.id === markSheetDisplay.question.id) {
          navigatorObject.answered = answered;
        }
        return navigatorObject;
      }))
    }
  }

  const updateResponse = (goToReview?: boolean) => {
    const response = {
      id: markSheetDisplay.response ? markSheetDisplay.response.id : '',
      questionId: markSheetDisplay.question.id,
      answer: responseString,
      timeSpent: 0,
      bookmark: markSheetDisplay.response ? markSheetDisplay.response.bookmark : false,
      categoryId: markSheetDisplay.question.categoryId,
      language: oeqcCodingLanguage,
    };
    createResponse({ response }).then(() => {
      console.log('the response fro the qstn is', response)
      if (response.answer && response.answer.trim().length !== 0) {
        updateAnswered(true);
      } else {
        updateAnswered(false);
      }
      if (goToReview) reviewHandler();
    }).catch(() => {
    });
  }

  useEffect(() => {
    if (responseString !== undefined) {
      updateResponse();
      setResponseString(undefined);
    }
  }, [selectedQuestion]);

  useEffect(() => {
    if (onReviewClick) {
      if (responseString !== undefined) {
        updateResponse(true);
      } else {
        reviewHandler();
      }
    }
    if (setOnReviewClick) setOnReviewClick(false);
  }, [onReviewClick]);

  return (
    <div className={classname}>
      <div className="sheet-display-question-head">
        { markSheetDisplay.question.type === 'MCQ' && <p style={{ margin: '0' }}>(Multiple Choice Question) Choose only one answer</p>}
        { markSheetDisplay.question.type === 'OEQT' && <p style={{ margin: '0' }}>(OEQT) Text Based Question</p>}
        { markSheetDisplay.question.type === 'MSQ' && <p style={{ margin: '0' }}>(Multiple Select Question) Choose one or more answers</p>}
        { markSheetDisplay.question.type === 'OEQC' && (
        <p style={{ margin: '0' }}>
          (OEQC) Code Based Question
          {' '}
          {/* <br /> */}
          {' '}
          {isCandidate && (
          <p style={{
            float: 'right', fontSize: '14px', margin: '0% 4% 0% 0%', color: 'darkgray',
          }}
          >
            Language :
            <b>{markSheetDisplay.question.language}</b>

          </p>
          )}
          { !isCandidate && (
          <p style={{
            float: 'right', fontSize: '14px', margin: '0% 4% 0% 0%', color: 'darkgray',
          }}
          >
            Language chosen :
            <b>{markSheetDisplay.question.language}</b>

          </p>
          )}
        </p>
        )}
      </div>
      <div className="sheet-display-question">
        <p>
          <b>{markSheetDisplay.order}</b>
          .
          &nbsp;
          {markSheetDisplay.question.question}
        </p>
      </div>
      <div className="sheet-display-solution">
        { markSheetDisplay.question.type === 'MCQ' && (
        <MarkSheetMCQ
          setResponseString={setResponseString}
          isCandidate={isCandidate}
          markSheetDisplay={markSheetDisplay}
        />
        )}
        { markSheetDisplay.question.type === 'OEQC' && (
        <MarkSheetOEQT
          isCandidate={isCandidate}
          markSheetDisplay={markSheetDisplay}
          setResponseString={setResponseString}
        />
        )}
        { markSheetDisplay.question.type === 'OEQT' && (
        <MarkSheetOEQC
          isCandidate={isCandidate}
          markSheetDisplay={markSheetDisplay}
          setResponseString={setResponseString}
        />
        )}
        { markSheetDisplay.question.type === 'MSQ'
            && (
            <MarkSheetMSQ
              isCandidate={isCandidate}
              markSheetDisplay={markSheetDisplay}
              setResponseString={setResponseString}
            />
            )}
      </div>
      { !navigatorLoader && <div className="question-horizontal-line" />}
      { navigatorLoader && (
      <div className="question-horizontal-line">
        <ProgressBar color="#0369A1" style={{ height: '2px' }} mode="indeterminate" />
      </div>
      )}
      { !responseNavigator && (
      <div className="sheet-display-footer">
        <MarkSheetFooter
          navigator={navigator}
          setNavigator={setNavigator}
          markSheetDisplay={markSheetDisplay}
          setMarkSheetDisplay={setMarkSheetDisplay}
          selectedQuestion={selectedQuestion}
          setSelectedQuestion={setSelectedQuestion}
          isCandidate={isCandidate}
          setOnReviewClick={setOnReviewClick}
        />
      </div>
      ) }
      {
        responseNavigator && (
          <div className="sheet-display-footer">
            <EvaluationFooter
              responseNavigator={responseNavigator}
              setResponseNavigator={setResponseNavigator}
              markSheetDisplay={markSheetDisplay}
              setMarkSheetDisplay={setMarkSheetDisplay}
              selectedResponse={selectedResponse}
              setSelectedResponse={setSelectedResponse}
            />
          </div>
        )
      }
    </div>
  );
}
