import React from 'react';
import { Outlet } from 'react-router';
import { Navbar } from '../../components';

function SuperEvaluator() {
  return (
    <div>
      <div className="admin-view w-full">
        <div className="section-1">
          <Navbar />
        </div>
        <div className="section-2">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default SuperEvaluator;
