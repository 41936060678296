import React, { useState, useEffect, useRef } from 'react';
import './AddCandidates.css';
import { useParams } from 'react-router';
import {
  Controller, useForm, useWatch, SubmitHandler,
} from 'react-hook-form';
import {
  AxiosResponse,
// AxiosError
} from 'axios';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { getMonth, getYear } from 'date-fns';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { FieldSkeleton, SpinCheckButton, UploadField } from '../index';
import { validateCandidates } from '../../hooks/examHook';
import {
  addCandidate, bulkupload, getCandidates, getPresignedURL, uploadQuestionCandidateToS3,
} from '../../services/examService';
import { IPreSignedURL } from '../../models/IPreSignedURL';
import ApiContants from '../../constants/apiContants';
import { IListCampusDrive } from '../../models/IListCampusDrive';
import { useAppDispatch } from '../../store/storeHooks';
import { setCandidateData, setCandidateTotal } from '../../store/examStore';
import IListCandidate from '../../models/IListCandidate';
import { getCandidate, getCandidateTemplate, updateCandidateData } from '../../services/candidateService';

type IProps = {
  setCompVisible: any,
  toast: any,
  campusDriveData: IListCampusDrive,
  handleCandidatesError: any,
  addCandidateHandler: Function,
  updateCandidate: string | undefined,
}

type ICandidate = {
  'Name': string,
  'Gender': string,
  'DOB': string,
  'RegisterNumber': string,
  'Batch': string,
  'Email': string,
  'Phone': string,
  'GitHub': string,
  'LinkedIn': string,
}

type IPostCandidate = {
  'candidate': ICandidate,
  'examId': string,
  'collegeId': string,
}

export default function AddCandidates({
  setCompVisible, handleCandidatesError, toast, campusDriveData, addCandidateHandler, updateCandidate,
}: IProps) {
  const dispatch = useAppDispatch();
  const apiConstants = new ApiContants();
  const { examId }: any = useParams();
  const [file, setFile] = useState({
    name: '',
    file: null,
    isPresent: false,
  });
  const [uploadButtonStatus, setUploadButtonStatus] = useState<'LOADING' | 'LOADED' | 'NOT_LOADING'>('NOT_LOADING')
  const [uploadButtonDisabled, setUploadButtonDisabled] = useState<boolean>(true);
  const [addButtonStatus, setAddButtonStatus] = useState<'LOADING' | 'LOADED' | 'NOT_LOADING'>('NOT_LOADING');
  const nameRegex = /^[A-Za-z][A-Za-z\s]*$/
  const regNoRegex = /^[a-zA-Z0-9]+$/
  // const dateRegex = /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/
  const phoneRegex = /^[6-9]\d{9}$/
  const emailRegex = /^[a-zA-Z]+[a-zA-Z0-9+_.-]+@[a-zA-Z.]+$/
  const urlRegex = /[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+,.~#?&//=]*)/
  const whiteSpaceRegex = /.*\S.*/
  const [startDate] = useState<any>(null);
  const [endDate] = useState<any>(null);
  const [updateLoader, setUpdateLoader] = useState<Boolean>(false)
  const [errorMsg, setErrorMsg] = useState<string[] | null>(null);
  const [errorDialog, setErrorDialog] = useState<boolean>(false);
  const [downloadButtonStatus, setDownloadButtonStatus] = useState<'LOADING' | 'LOADED' | 'NOT_LOADING'>('NOT_LOADING');
  const defaultValues = {
    name: '',
    registerNumber: '',
    dob: '',
    gender: '',
    batch: '',
    email: '',
    phoneNumber: '',
    github: '',
    linkedin: '',
  };

  const genderOptions = [
    { label: 'Male', value: 'Male' },
    { label: 'Female', value: 'Female' },
  ]
  const toastRef = useRef<any>(null);

  const {
    register, handleSubmit, control, setValue, formState: { errors },
  } = useForm({ defaultValues });

  const registerNumber = useWatch({
    control,
    name: 'registerNumber',
  });

  const dob = useWatch({
    control,
    name: 'dob',
  });

  const gender = useWatch({
    control,
    name: 'gender',
  });

  const batch = useWatch({
    control,
    name: 'batch',
  });

  const email = useWatch({
    control,
    name: 'email',
  });

  const phoneNumber = useWatch({
    control,
    name: 'phoneNumber',
  });

  const github = useWatch({
    control,
    name: 'github',
  });

  const linkedin = useWatch({
    control,
    name: 'linkedin',
  });
  const name = useWatch({
    control,
    name: 'name',
  });

  useEffect(() => {
    if (updateCandidate) {
      setUpdateLoader(true)
      getCandidate(updateCandidate).then(({ data }) => {
        let otherData;
        if (data.additionalDetails) {
          otherData = data.additionalDetails.substring(1, data.additionalDetails.length - 1).split('],[');
        }
        const dobFormatted = (data.dob).substr(0, 10)
        setValue('name', data.name);
        setValue('registerNumber', data.regNo);
        setValue('dob', dobFormatted);
        setValue('gender', data.gender);
        setValue('batch', data.batch);
        setValue('email', data.email);
        setValue('phoneNumber', data.phone);
        if (otherData) {
          if (otherData[0].length !== 0) setValue('github', otherData[0]);
          if (otherData[1].length !== 0) setValue('linkedin', otherData[1]);
        }
        setUpdateLoader(false)
      });
    }
  }, []);

  const handleCandidateTemplateDownload = () => {
    setDownloadButtonStatus('LOADING');
    getCandidateTemplate()
      .then((response: AxiosResponse) => {
        if (response.status === 200) {
          window.open(response.data.url, '_blank', 'noopener,noreferrer');
          setDownloadButtonStatus('LOADED');
        }
      })
      .catch((e) => {
        toastRef.current.show({
          severity: 'error',
          summary: 'Something went wrong',
          detail: e.response.data.message,
        });
      })
  }

  const refreshCandidatesData = () => {
    dispatch(setCandidateData(null));
    getCandidates(examId, 0)
      .then((response:AxiosResponse) => {
        addCandidateHandler(true)
        dispatch(setCandidateData(response.data.candidates as IListCandidate[]));
        dispatch(setCandidateTotal(response.data.len));
      })
  }

  const validateCandidatesHandler = (fileData: any) => {
    const data = {
      name: fileData.file.name,
      file: fileData.file,
      isPresent: true,
    };
    setFile(data);
    if (!fileData.valid) {
      setUploadButtonDisabled(true);
      setTimeout(() => {
        handleCandidatesError(fileData.errors);
        setCompVisible(false);
      }, 1000);
    } else {
      setUploadButtonDisabled(false);
    }
  }

  const errorResponse = () => {
    setTimeout(() => {
      setCompVisible(false);
      toast(
        'error',
        'Something went wrong',
        'Please try again later',
      )
    });
  }

  const candidateError = () => {
    setTimeout(() => {
      setCompVisible(false);
      toast(
        'error',
        'Candidate already Exist',
        'Cannot add Candidate who already exist in the exam ',
      )
    });
  }

  const handleHidevalidationForm = () => {
    setErrorMsg(null)
    setErrorDialog(false);
  }

  const onSubmit: SubmitHandler<any> = (data: any) => {
    setAddButtonStatus('LOADING');
    const addCandidateData: IPostCandidate = {
      candidate: {
        Name: data.name,
        Gender: data.gender,
        DOB: data.dob.toString(),
        RegisterNumber: data.registerNumber,
        Batch: data.batch,
        Email: data.email,
        Phone: data.phoneNumber,
        GitHub: data.github,
        LinkedIn: data.linkedin,
      },
      examId,
      collegeId: campusDriveData.College.id,
    }
    if (!updateCandidate) {
      addCandidate(addCandidateData)
        .then((response: AxiosResponse) => {
          if (response.status === 200) {
            setAddButtonStatus('LOADED');
            if (response === null) {
              candidateError();
            } else {
              addCandidateHandler(false)
              setTimeout(() => {
                setCompVisible(false);
                toast(
                  'success',
                  'Success',
                  'Candidate has been added successfully',
                )
              });
              refreshCandidatesData();
            }
          } else {
            errorResponse();
          }
        })
        .catch(() => {
          errorResponse();
        })
    } else {
      updateCandidateData(updateCandidate, addCandidateData)
        .then((response: AxiosResponse) => {
          if (response.status === 200) {
            if (response.data.update) {
              setAddButtonStatus('LOADED');
              if (response === null) {
                candidateError();
              } else {
                addCandidateHandler(false)
                setTimeout(() => {
                  setCompVisible(false);
                  toast(
                    'success',
                    'Success',
                    'Candidate has been updated successfully',
                  )
                });
                refreshCandidatesData();
              }
            } else {
              toast('error', 'Error', response.data.message);
              setAddButtonStatus('NOT_LOADING');
            }
          } else {
            errorResponse();
          }
        })
        .catch(() => {
          errorResponse();
        })
    }
    // }
  }

  const uploadCandidates = () => {
    setUploadButtonStatus('LOADING');
    getPresignedURL(examId, apiConstants.CANDIDATE, file.name.split('.')[1])
      .then((response: AxiosResponse) => {
        const data = response.data as IPreSignedURL;
        uploadQuestionCandidateToS3(data.url, file.file)
          .then(() => {
            bulkupload(examId, apiConstants.CANDIDATE, file.name.split('.')[1])
              .then((bulkUploadResponse:AxiosResponse) => {
                if (bulkUploadResponse.status === 200) {
                  if (bulkUploadResponse.data.error) {
                    setUploadButtonStatus('NOT_LOADING');
                    setErrorMsg(bulkUploadResponse.data.errorMessages);
                    setErrorDialog(true);
                  } else {
                    setUploadButtonStatus('LOADING');
                    setTimeout(() => {
                      setCompVisible(false);
                      toast(
                        'success',
                        'Success',
                        'Candidates file has been uploaded successfully',
                      )
                    });
                    refreshCandidatesData();
                  }
                } else {
                  errorResponse();
                }
              })
              .catch(() => {
                errorResponse();
              });
          })
          .catch(() => {
            errorResponse();
          });
      })
      .catch(() => {
        errorResponse();
      })
  }

  return (

    <div className="add-candidates-container h-full w- full">
      <div className="add-candidates-form">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="field name ">

            {(!updateLoader) && (
              <>
                <div className="label">
                  Name
                </div>
                <InputText
                  {...register('name', { required: 'Name is required.', pattern: { value: nameRegex, message: 'Enter a valid name' } })}
                  className="input"
                  type="text"
                  placeholder="Enter candidate's name"
                  autoComplete="off"
                  value={name}
                />

              </>
            )}
            {(updateLoader) && (
            <FieldSkeleton label="Name" />
            )}

            {
              errors.name?.type === 'required' ? (<small className="error-label p-error">{errors.name.message}</small>) : ''
            }
            {
              errors.name?.type === 'pattern' ? (<small className="error-label p-error">{errors.name.message}</small>) : ''
            }
          </div>

          <div className="field flex align-items-center justify-content-between">
            <div className="field split register-number">
              {(!updateLoader) && (
              <>
                <div className="label">
                  Registration Number
                </div>
                <InputText
                  {...register('registerNumber', {
                    required: 'Registration Number is required.',
                    pattern: { value: regNoRegex, message: 'Enter a valid Register Number' },
                  })}
                  className="input"
                  type="text"
                  placeholder="Enter registration number"
                  autoComplete="off"
                  value={registerNumber}
                />

              </>
              )}
              {(updateLoader) && (
              <FieldSkeleton label="Registration Number" />
              )}

              {
                errors.registerNumber?.type === 'required' ? (<small className="error-label p-error">{errors.registerNumber.message}</small>) : ''
              }
              {
                errors.registerNumber?.type === 'pattern' ? (<small className="error-label p-error">{errors.registerNumber.message}</small>) : ''
              }
            </div>
            <div className="field split dob">
              {(!updateLoader) && (
              <>
                <div className="label">
                  Date Of Birth
                </div>
                <div id="cal">
                  <Controller
                    control={control}
                    name="dob"
                    rules={{
                      required: 'DOB is required.',
                    }}
                    render={({ field: { onChange, ref } }) => (
                      <Calendar
                        ref={ref}
                        onChange={(ev) => onChange(ev)}
                        className="input box"
                        minDate={(startDate != null) ? startDate : new Date('01/01/1990')}
                        maxDate={(endDate != null) ? endDate : new Date('01/01/2005')}
                        viewDate={(startDate != null) ? (new Date(getYear(startDate), getMonth(startDate))) : undefined}
                        dateFormat="dd-mm-yy"
                        onMonthChange={() => { }}
                        placeholder="Choose a Date Of Birth"
                        value={new Date(dob)}
                      />
                    )}
                  />
                  {errors.dob ? (<small className="error-label p-error">{errors.dob?.message}</small>) : ''}
                </div>

              </>
              )}
              {(updateLoader) && (
              <FieldSkeleton label="Date of Birth" />
              )}
            </div>
          </div>

          <div className="field flex align-items-center justify-content-between">
            <div className="field split gender">
              {(!updateLoader) && (
              <>
                <div className="label">
                  Gender
                </div>
                <Dropdown
                  {...register('gender', { required: 'Gender is required.' })}
                  value={gender}
                  className="input"
                  optionLabel="label"
                  options={genderOptions}
                  placeholder="Select gender"
                  appendTo="self"
                />

              </>
              )}
              {(updateLoader) && (
              <FieldSkeleton label="Date of Birth" />
              )}

              {
                errors.gender?.type === 'required' ? (<small className="error-label p-error">{errors.gender.message}</small>) : ''
              }
            </div>

            <div className="field split batch">
              {(!updateLoader) && (
              <>
                <div className="label">
                  Batch
                </div>
                <InputText
                  {...register('batch', {
                    required: 'Batch is required.',
                    pattern: { value: whiteSpaceRegex, message: 'Invalid Batch' },
                  })}
                  className="input"
                  type="text"
                  placeholder="Enter candidate's batch"
                  autoComplete="off"
                  value={batch}
                />

              </>
              )}
              {(updateLoader) && (
              <FieldSkeleton label="Batch" />
              )}
              {
                errors.batch?.type === 'required' ? (<small className="error-label p-error">{errors.batch.message}</small>) : ''
              }
              {
                errors.batch?.type === 'pattern' ? (<small className="error-label p-error">{errors.batch.message}</small>) : ''
              }
            </div>
          </div>

          <div className="field flex align-items-center justify-content-between">
            <div className="field split email">
              {(!updateLoader) && (
              <>
                <div className="label">
                  Email
                </div>
                <InputText
                  {...register('email', {
                    required: 'Email is required.',
                    pattern: { value: emailRegex, message: 'Email is invalid' },
                  })}
                  className="input"
                  type="text"
                  placeholder="Enter email here"
                  autoComplete="off"
                  value={email}
                />

              </>
              )}
              {(updateLoader) && (
              <FieldSkeleton label="Email" />
              )}

              {
                errors.email?.type === 'required' ? (<small className="error-label p-error">{errors.email.message}</small>) : ''
              }
              {
                errors.email?.type === 'pattern' ? (<small className="error-label p-error">{errors.email.message}</small>) : ''
              }
            </div>

            <div className="field split phone-number">
              {(!updateLoader) && (
              <>
                <div className="label">
                  Phone Number
                </div>
                <InputText
                  {...register('phoneNumber', {
                    required: 'Phone Number is required.',
                    pattern: { value: phoneRegex, message: 'Phone Number is invalid' },
                  })}
                  className="input"
                  type="text"
                  placeholder="Enter phone number here"
                  autoComplete="off"
                  value={phoneNumber}
                />

              </>
              )}
              {(updateLoader) && (
              <FieldSkeleton label="Phone Number" />
              )}

              {
                errors.phoneNumber?.type === 'required' ? (<small className="error-label p-error">{errors.phoneNumber.message}</small>) : ''
              }
              {
                errors.phoneNumber?.type === 'pattern' ? (<small className="error-label p-error">{errors.phoneNumber.message}</small>) : ''
              }
            </div>
          </div>

          <div className="field github">
            {(!updateLoader) && (
            <>
              <div className="label">
                Github
              </div>
              <InputText
                {...register('github', { pattern: { value: urlRegex, message: 'Github link is not a valid url' } })}
                className="input"
                type="text"
                placeholder="Enter github link here"
                autoComplete="off"
                value={github}
              />

            </>
            )}
            {(updateLoader) && (
            <FieldSkeleton label="Github" />
            )}

            {
              errors.github?.type === 'pattern' ? (<small className="error-label p-error">{errors.github.message}</small>) : ''
            }
          </div>

          <div className="field linkedin">
            {(!updateLoader) && (
            <>
              <div className="label">
                Linkedin
              </div>
              <InputText
                {...register('linkedin', { pattern: { value: urlRegex, message: 'Linkedin link is not a valid url' } })}
                className="input"
                type="text"
                placeholder="Enter linkedin link here"
                autoComplete="off"
                value={linkedin}
              />

            </>
            )}
            {(updateLoader) && (
            <FieldSkeleton label="Github" />
            )}

            {
              errors.linkedin?.type === 'pattern' ? (<small className="error-label p-error">{errors.linkedin.message}</small>) : ''
            }
          </div>

          <div className="add-button-container w-full flex align-items-center justify-content-end">
            <div className="add-button">
              <SpinCheckButton styleClass="add-button-item" label={updateCandidate ? 'Update Candidate' : 'Add Candidate'} loadingStatus={addButtonStatus} onClick={handleSubmit(onSubmit)} />
            </div>
          </div>
        </form>
      </div>

      <div className="file-upload-container w-full h-auto">
        <UploadField title="candidates" file={file} setFile={setFile} postValidation={validateCandidatesHandler} validator={validateCandidates} />
        <div className="upload-button-container w-full flex align-items-center justify-content-end">
          <div>
            <SpinCheckButton styleClass="upload-button-item" label="Download Template" loadingStatus={downloadButtonStatus} onClick={handleCandidateTemplateDownload} />
          </div>
          <div className="upload-button">
            <SpinCheckButton styleClass="upload-button-item" disabled={uploadButtonDisabled} label="Upload Candidates" loadingStatus={uploadButtonStatus} onClick={uploadCandidates} />
          </div>
        </div>
      </div>
      <Dialog className="validation-dialog" header="Validation error" visible={errorDialog} style={{ width: '550px', height: '330px', borderRadius: '8px' }} onHide={handleHidevalidationForm}>
        <div className="error-validation-dialog">
          {
            errorMsg?.map((error: string) => (
              <div className="error-validation-row">
                {error}
              </div>
            ))
          }
        </div>
      </Dialog>
      <Toast ref={toastRef} />
    </div>
  )
}
