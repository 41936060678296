import { Link } from 'react-router-dom';
import React from 'react';
import './ListExamRow.css';
import { ListExamRowSkeletion } from '../skeleton';
import { findTimeDuration } from '../../hooks/examHook';
import RoutesConstants from '../../constants/routesConstants';
import { IListExam } from '../../models/IListExam';

type IListExamRowProp = {
  shade: number;
  lastItem: boolean;
  examData: IListExam;
  userRole: string;
}

export default function IListExamRow(props: IListExamRowProp) {
  const {
    shade, lastItem, examData, userRole,
  } = props;

  const routesConstant = new RoutesConstants();

  return (
    <>
      {
        (examData == null)
        && <ListExamRowSkeletion shade={shade} lastItem={lastItem} />
      }
      { (examData != null)
      && (
      <div className={`list-exam-row flex w-full ${shade % 2 === 0 ? 'shade' : ''} ${lastItem ? 'last-item' : ''}`}>
        <div className="cell campus-drive-col">{examData.campusDriveName}</div>
        <div className="cell exam-name-col">{examData.name}</div>
        <div className="cell col">{findTimeDuration(examData.startTime, examData.endTime)}</div>
        {userRole === 'Admin' && (
          examData.candidateCount === 1
            ? <div className="cell student-col ">{`${examData.candidateCount} Student`}</div>
            : <div className="cell student-col ">{`${examData.candidateCount} Students`}</div>
        )}
        {/* eslint-disable-next-line no-mixed-operators */}
        {(userRole === 'Evaluator' || userRole === 'Super Evaluator') && (
        <div className="cell col">
          <div>
            {examData.pending}
            /
            {examData.total}
          </div>
        </div>
        )}

        {/* eslint-disable-next-line no-mixed-operators */}
        <div className="cell col buttons flex align-items-center justify-content-start view-eval-rev-container">
          {(userRole === 'Super Evaluator' || userRole === 'Admin') && (
          <div className="view-exam h-full flex align-items-center ">
            <Link to={`${routesConstant.EXAMS}/${examData.id ? examData.id : examData.examId}`} className="no-underline">View</Link>
          </div>
          )}
          {
            (examData.statusId === 4)
              && (
              <div className="view-exam h-full flex align-items-center">
                <Link to={`${routesConstant.EXAMS}${routesConstant.EVALUATE}/${examData.id}`} className="no-underline">Evaluate</Link>
              </div>
              )
          }
          {/* { */}
          {/*    (examData.statusId !== 4) */}
          {/*    && ( */}
          {/*    <div className="view-exam h-full flex align-items-center"> */}
          {/*      <div className="description">Filters not added</div> */}
          {/*    </div> */}
          {/*    ) */}
          {/* } */}

          {(examData.statusId === 4 && (userRole === 'Super Evaluator' || userRole === 'Admin')) && (
          <div className="view-exam h-full flex align-items-center ">
            <Link to={`${routesConstant.EVALUATE}${routesConstant.EXAMREVIEW}/${examData.id}`} className="no-underline">
              Review
            </Link>
          </div>
          )}
        </div>
      </div>
      )}
    </>
  );
}
