class EvaluateConstants {
  ASSIGNED : string = 'ASSIGNED';

  NOTASSIGNED: string = 'NOTASSIGNED';

  TOTAL: string = 'TOTAL';

  INPROGESS: string = 'INPROGRESS';

  CATEGORYNAME: string = 'CATEGORYNAME';
}

export default EvaluateConstants;
