import React, { useEffect, useState } from 'react';
import RolesConstants from '../../../constants/rolesContants';
import { useAppSelector } from '../../../store/storeHooks';
import { ListExamRowSkeletion } from '../index';

export default function ExamListSkeleton() {
  const [userRole, setUserRole] = useState('');
  const authRole = useAppSelector((state) => state.auth.role);
  const rolesConstants = new RolesConstants();
  useEffect(() => {
    setUserRole((authRole === rolesConstants.ADMIN) ? 'Admin' : (authRole === rolesConstants.SUPER_EVALUATOR) ? 'Super Evaluator' : (authRole === rolesConstants.EVALUATOR) ? 'Evaluator' : '');
  })
  return (
    <div className="exam-list-container w-full h-auto flex flex-column align-items-center justify-content-start">
      <div className="list-header flex w-full">
        <div className="cell col">
          CAMPUS DRIVE
        </div>
        <div className="cell col">
          EXAM NAME
        </div>
        <div className="cell col">
          TIME
        </div>
        {userRole === 'Admin' && (
        <div className="cell  student-col">
          STUDENTS
        </div>
        )}
        {/* eslint-disable-next-line no-mixed-operators */}
        {(userRole === 'Evaluator' || userRole === 'Super Evaluator') && (
        <div className="cell col">
          PENDING VS TOTAL QUESTIONS
        </div>
        )}
        <div className="cell col " />
      </div>

      <ListExamRowSkeletion shade={0} lastItem={false} />
      <ListExamRowSkeletion shade={1} lastItem={false} />
      <ListExamRowSkeletion shade={0} lastItem={false} />
      <ListExamRowSkeletion shade={1} lastItem={false} />
      <ListExamRowSkeletion shade={0} lastItem />
    </div>
  );
}
