import React, { useEffect } from 'react';
import PrimeReact from 'primereact/api';
import { useNavigate } from 'react-router';
import AppRoute from './routes/AppRoute';
import 'primereact/resources/themes/tailwind-light/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import axiosInit from './services/axiosInit';
import { Loader } from './components';
import { useAppDispatch } from './store/storeHooks';
import { attachHub } from './services/authService';

function App() {
  PrimeReact.ripple = true;
  axiosInit();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    attachHub(navigate, dispatch);
  }, []);

  return (
    <>
      <AppRoute />
      <Loader />
    </>
  );
}

export default App;
