import React from 'react';
import './QuestionNavigator.css';
import { Dropdown } from 'primereact/dropdown';
import QuestionNavigatorDisplay from '../questionNavigatorDisplay/QuestionNavigatorDisplay';
import { questionCategoryType, questionNavigatorType } from '../navigator/Navigator';

type questionNavigatorProps = {
    questionCategoryList: questionCategoryType[],
    questionCategory: number | null,
    setQuestionCategory: any,
    sortedQuestions: questionNavigatorType[],
    setSortedQuestions: any,
    selectedQuestion: questionNavigatorType | null | undefined,
    setSelectedQuestion: any,
    dropdown: boolean,
}

export default function QuestionNavigator({
  questionCategoryList,
  questionCategory,
  setQuestionCategory,
  sortedQuestions,
  setSortedQuestions,
  selectedQuestion,
  setSelectedQuestion,
  dropdown,
}: questionNavigatorProps) {
  const onCategoryChange = (e: any) => {
    setQuestionCategory(e.value);
  }

  const selectedCategoryTemplate = (option: { label: string, value: string }) => {
    if (option) {
      return (
        <div className="country-item country-item-value">
          <div>{option.label}</div>
        </div>
      );
    }

    return (
      <span>
        Category
      </span>
    );
  }

  const categoryOptionTemplate = (option: any) => (
    <div>
      <div>{option.label}</div>
    </div>
  )

  return (
    <div className="question-navigator-wrap">
      { dropdown && (
      <div style={{ marginLeft: '10px', marginRight: '10px' }}>
        <Dropdown
          value={questionCategory}
          options={questionCategoryList}
          onChange={onCategoryChange}
          optionLabel="label"
          filter
          showClear
          filterBy="label"
          placeholder="Category"
          valueTemplate={selectedCategoryTemplate}
          itemTemplate={categoryOptionTemplate}
          style={{ width: '100%' }}
        />
      </div>
      ) }
      <div className="question-number-display-set">
        <QuestionNavigatorDisplay
          sortedQuestions={sortedQuestions}
          setSortedQuestions={setSortedQuestions}
          selectedQuestion={selectedQuestion}
          setSelectedQuestion={setSelectedQuestion}
        />
      </div>
      <div style={{
        display: 'flex', flexFlow: 'row nowrap', justifyContent: 'space-between', marginLeft: '1%', padding: '10px',
      }}
      >
        <span style={{
          color: 'green', fontSize: '10px', border: '1px solid green', borderRadius: '20%', padding: '2px', margin: '2px',
        }}
        >
          ANSWERED
        </span>
        <span style={{
          color: 'white', fontSize: '10px', backgroundColor: 'darkorange', borderRadius: '20%', padding: '3px', margin: '2px',
        }}
        >
          MARKED
        </span>
        <span style={{
          color: 'white', fontSize: '10px', backgroundColor: '#0369A1', borderRadius: '20%', padding: '3px', margin: '2px',
        }}
        >
          CURRENT
        </span>
      </div>
    </div>
  );
}
