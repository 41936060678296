import React from 'react';
import { Outlet } from 'react-router';
import './Exams.css';

export default function Exams() {
  return (
    <div className="exams-view w-full flex flex-auto align-items-center justify-content-center">
      <div className="exams-layout h-full flex align-items-center justify-content-center">
        <Outlet />
      </div>
    </div>
  );
}
