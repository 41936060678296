/* eslint-disable react/no-array-index-key */
import React from 'react';
import { ListReviewRowSkeleton } from '..';
import './ReviewPageSkeleton.css';

type IListReviewHeaderSkeletonProp = {

    column : string[];
  }

export default function ReviewPageSkeleton(props: IListReviewHeaderSkeletonProp) {
  const {
    column,
  } = props;
  return (
    <div className="review-list-container">
      <div className="list-header flex w-full">
        {[...column].map((el, index) => (
          <div key={`Skeleton Header ${index}`} className="cell" style={{ width: `${100 / column.length}%` }}>
            {el}
          </div>
        ))}
      </div>

      <ListReviewRowSkeleton shade={0} lastItem={false} column={column} />
      <ListReviewRowSkeleton shade={1} lastItem={false} column={column} />
      <ListReviewRowSkeleton shade={0} lastItem={false} column={column} />
    </div>
  );
}
