export default class {
  SUCCESS_TITLE: string = 'success';

  SUCCESS_SUMMARY: string = 'Success';

  ERROR_TITLE: string = 'error';

  ERROR_SUMMARY: string = 'Error';

  // Login specific toast contents
  LOGIN_SUCCESS_DETAIL: string = 'Logged in successfully';

  PERSIST_SUCCESS_DETAIL: string = 'Welcome back!';

  COPY_MALPRACTICE: string = 'Warning! You are not allowed to COPY content';

  PASTE_MALPRACTICE: string = 'Warning! You are not allowed to PASTE content';

  CUT_MALPRACTICE: string = 'Warning! You are not allowed to CUT content';

  TAB_MALPRACTICE: string = 'Warning! You are not allowed to Switch Tabs/Windows';
}
