import { Link } from 'react-router-dom';
import React from 'react';
import { ICompletedExams } from '../../models/ICompletedExams';
import './DashboardExamItem.css'
import RoutesConstants from '../../constants/routesConstants';

type IDashboardExamItem={
  completedExams: ICompletedExams,
  listactiveParticipants:any
  listofAvg:any
  onCardClick: Function,
}
export default function DashboardExamItem({
  completedExams, listactiveParticipants, listofAvg, onCardClick,
}:IDashboardExamItem) {
  const routesConstant = new RoutesConstants();
  const cardClickTrigger = () => {
    onCardClick(completedExams)
  }
  return (
    <div className="exam-card" onClick={cardClickTrigger} onKeyPress={cardClickTrigger} role="button" tabIndex={0}>
      <div className="dashboard-exam-list w-full flex">
        <div className="dashboard-exam-list-content w-full">
          <div className="line-1">
            <div className="university-name-col title">
              {completedExams.Exam.CampusDrive ? completedExams.Exam.CampusDrive.College.name : ''}
            </div>
          </div>
          <div className="line-2 flex w-full">
            <div className="cell exam-name-col">
              {completedExams.Exam.examName}
            </div>
            <div className="cell participants-col">
              Participants Attended:
              {'  '}
              {listactiveParticipants}
              {' '}
            </div>
            <div className="cell percentage-passed-col">
              Average:
              {parseFloat(listofAvg).toFixed(2) || 0}
              %
            </div>
            <div className="cell date-col">
              {completedExams.Exam.examDate}
            </div>
          </div>
        </div>
        <div className="arrow-icon">
          <Link to={`${routesConstant.EXAMS}/${completedExams.Exam.id}`} className="no-underline"><i className="pi pi-angle-right" /></Link>
        </div>
      </div>
    </div>

  )
}
