import * as React from 'react';
import { Link } from 'react-router-dom';
import { IUpcomingExams } from '../../models/IUpcomingExams';
import './OngoingExamCard.css'
import RoutesConstants from '../../constants/routesConstants';

type IDashboardExamItem={
  onGoingExams: IUpcomingExams;
  listactiveParticipants:any;
  onCardClick: any;
}
function ongoingExamCard({ onGoingExams, listactiveParticipants, onCardClick }: IDashboardExamItem) {
  const routesConstant = new RoutesConstants();

  const cardClickTrigger = () => {
    onCardClick(onGoingExams)
  }
  if (!listactiveParticipants) {
    listactiveParticipants = 0
  }
  return (

    <div role="button" tabIndex={0} className="ongoing-exam-card" onClick={cardClickTrigger} onKeyPress={cardClickTrigger}>
      <div className="text-xl text-white font-bold pt-2 ongoing-campus-drive-name">
        {onGoingExams.Exam.CampusDrive ? onGoingExams.Exam.CampusDrive.College.name : ''}
      </div>
      <div className="text-sm text-white font-light">
        {onGoingExams.Exam.examName}
      </div>
      <div className="flex flex-row card-footer">
        <span className="text-white text-sm">
          {listactiveParticipants}
          {' '}
          Participants
        </span>
      </div>
      <div className="arrow-icons">
        <Link to={`${routesConstant.EXAMS}/${onGoingExams.Exam.id}`} className="no-underline"><i className="pi pi-angle-right text-white" /></Link>
      </div>
    </div>

  )
}

export default ongoingExamCard;
