const candidateErrors = {
  NAME_MISSING: 'Name is missing in row ',
  GENDER_MISSING: 'Gender is missing in row ',
  GENDER_INVALID: 'Gender value is invalid in row ',
  DOB_MISSING: 'DOB is missing in row ',
  DOB_INVALID: 'DOB value is invalid in row ',
  REGISTRATION_NUMBER_MISSING: 'Registration Number is missing in row ',
  BATCH_MISSING: 'Batch is missing in row ',
  EMAIL_MISSING: 'Email is missing in row ',
  EMAIL_INVALID: 'Email values is invalid in row ',
  PHONE_MISSING: 'Phone is missing in row ',
  PHONE_INVALID: 'Phone value is invalid in row ',
}

export default candidateErrors
