import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ExamSessionStore {
    isRunning: boolean,
    timeTracker: number,
    timeRemaining: number
}

const initialState: ExamSessionStore = {
  isRunning: false as boolean,
  timeTracker: 0 as number,
  timeRemaining: 0 as number,
}

export const examSessionStore = createSlice({
  name: 'examSession',
  initialState,
  reducers: {
    setIsRunning: (state, action: PayloadAction<boolean>) => {
      state.isRunning = action.payload;
    },
    setTimeTracker: (state, action: PayloadAction<number>) => {
      state.timeTracker = action.payload;
    },
    setTimeRemaining: (state, action: PayloadAction<number>) => {
      state.timeRemaining = action.payload;
    },
  },
});

export const { setIsRunning, setTimeTracker, setTimeRemaining } = examSessionStore.actions;

export default examSessionStore.reducer;
