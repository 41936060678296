import React, { createRef, useState } from 'react';
import './TimeSelect.css';
import { Dropdown } from 'primereact/dropdown';

type TimeSelectProps = {
  label: string;
  callback: any;
  timeValue: string;
};

export default function TimeSelect(props: TimeSelectProps) {
  const { label, callback, timeValue } = props;
  const regEx = /[0-1][0-9]/
  const ampm = [
    { label: 'AM', value: 'AM' },
    { label: 'PM', value: 'PM' },
  ];
  const [hour, setHour] = useState('');
  const [min, setMin] = useState('');
  const [meridium, setMeridium] = useState([]);
  const hourRef:any = createRef();
  const minRef: any = createRef();
  const date = new Date();

  const formatHour = async (value: string, meridiumVal = meridium) => {
    const merid = meridiumVal.length === 0 ? ((date.getHours() / 12 > 1) ? 'PM' : 'AM') : meridiumVal;
    if (value !== '') {
      let hours = parseInt(value, 10);
      hours = (hours > 12) ? hours - 12 : hours;
      return (merid === 'AM' ? (hours % 12) : (hours === 12 ? 12 : (hours + 12))).toString();
    }
    return value;
  }

  const padZero = (input: string) => input.padStart(2, '0');

  const changeMeridium = async (e: { value: any}) => {
    const merid = e.value
    setMeridium(merid);
    const error = hour === '' || min === '';
    const hours = await formatHour(hour, merid);
    setHour(hours)
    await callback(`${padZero(hours)}:${padZero(min)}`, error);
  }

  function getHours(time: string) {
    if (time != null) {
      const finalHour = Number(time.substring(0, 2)) % 12;
      if (finalHour >= 10) {
        return `${finalHour}`;
      }
      return `0${finalHour}`;
    }
    return null;
  }

  const onHourChange = async (e: any) => {
    let error = false;
    let message = '';
    if (e.target.value === '') {
      message = `${label} is required.`
      error = true
    }
    if (parseInt(e.target.value, 10) > 12 || parseInt(e.target.value, 10) === 0) {
      message = `${label} is invalid.`
      error = true
    }
    const hours = await formatHour(e.target.value);
    await setHour(await formatHour(hours));
    await callback(`${padZero(hours)}:${padZero(min)}`, error, message);
    if (regEx.test(hours)) {
      document.getElementById(`minutes-${label}`)!.focus();
    }
  }

  const onMinChange = async (e: any) => {
    const mins = e.target.value;
    let message = '';
    let error = false
    if (mins > 60 || mins < 0) {
      message = `${label} is invalid.`
      error = true
    } else if (mins === '') {
      message = `${label} Required.`
      error = true
    } else {
      await setMin(mins);
    }
    if (regEx.test(mins)) {
      document.getElementById(`minutes-${label}`)!.focus();
    }
    await callback(`${padZero(hour)}:${padZero(mins)}`, error, message);
  }

  const maxLengthCheck = (object: any) => {
    if (object.target.value.length > object.target.maxLength) {
      object.target.value = object.target.value.slice(0, object.target.maxLength)
    }
  }

  const handleBlur = (ref: any) => {
    ref.current.value = padZero(ref.current.value);
    // getMinutes(timeValue) || padZero((date.getMinutes() % 60).toString())
  }

  return (
    <div className="time-select-field flex flex-column">
      <div className="label">
        {label}
      </div>

      <div className="time-field-container flex align-items-center">
        <div className="time-container h-full flex align-items-center justify-content-start overflow-hidden">
          <input ref={hourRef} className="hours text" type="number" maxLength={2} placeholder={getHours(timeValue) || padZero((date.getHours() % 12).toString())} onBlur={() => handleBlur(hourRef)} onInput={maxLengthCheck} onChange={onHourChange} />
          <div className="text flex h-full align-items-center">:</div>
          <input ref={minRef} id={`minutes-${label}`} className="mins text flex-1" type="number" maxLength={2} placeholder="00" onBlur={() => handleBlur(minRef)} onInput={maxLengthCheck} onChange={onMinChange} />
        </div>

        <div className="ampm-container h-full">
          <Dropdown className="input" value={meridium} options={ampm} onChange={changeMeridium} optionLabel="label" placeholder="AM" appendTo="self" />
        </div>
      </div>
    </div>
  );
}
