import React, { useEffect, useState } from 'react';
import './ListEvaluators.css';
import IUser from '../../models/IUser';
import { ActionButton } from '../index';
import ListEvaluatorsRow from '../listEvaluatorsRow/ListEvaluatorsRow';

type IListEvaluatorsProps = {
    evaluators: IUser[],
    // examData: IListExam,
  handleAddEvaluatorClick: any,
 // evaluatorsCount : number,
}

export default function ListEvaluators({ evaluators, handleAddEvaluatorClick }: IListEvaluatorsProps) {
  const [listEvaluators, setListEvaluators] = useState<any>(null);
  const [startingEvaluator, setStartingEvaluator] = useState(0);

  useEffect(() => {
    setListEvaluators(evaluators);
  }, []);

  const handleNextPageClick = () => {
    if (startingEvaluator + 5 < listEvaluators!.length) setStartingEvaluator(startingEvaluator + 5)
  }

  const handlePreviousPageClick = () => {
    if (startingEvaluator - 5 >= 0) setStartingEvaluator(startingEvaluator - 5)
  }
  // @ts-ignore
  // @ts-ignore
  // @ts-ignore
  return (
    <>
      <div className="list-evaluator-container w-full">
        <div className="header w-full flex align-items-center justify-content-between">
          <div className="title flex align-items-center h-full">
            Evaluator
          </div>
          <div className="filter-container flex">
            <div className="filter-container flex">
              <div className="add-evaluator">
                <ActionButton type="primary" label="Add Evaluator" clickHandler={handleAddEvaluatorClick} />
              </div>
            </div>
          </div>
        </div>

        <div className="list-evaluator-container w-full">
          <div className="list-evaluator-table w-full">
            <div className="row-header row w-full">
              <div className="name cell">
                NAME
              </div>
              <div className="reg-no cell">
                EMAIL
              </div>
              <div className="dob cell">
                ROLE
              </div>
              <div className="gender cell">
                EVALUATED QUESTIONS
              </div>
            </div>

            {
              listEvaluators?.map((evaluator: IUser, index: number) => {
                if (index >= startingEvaluator && index < startingEvaluator + 5) {
                  return <ListEvaluatorsRow key={evaluator.id} evaluator={evaluator} />
                }
                return <div key={evaluator.id} />
              })
            }
          </div>

          <div className="footer w-full flex align-items-center justify-content-between">
            <div className="result-details">
              {
                  listEvaluators != null
                  && `Showing ${(listEvaluators!.length === 0) ? 0 : (startingEvaluator + 1)} to ${(startingEvaluator + 5 > listEvaluators!.length) ? listEvaluators!.length : (startingEvaluator + 5)} of ${listEvaluators!.length} results`
              }
            </div>
            <div className="pagination-buttons flex">
              <div className="previous-button">
                {(startingEvaluator >= 5) && (
                <ActionButton type="primary" label="Previous" clickHandler={handlePreviousPageClick} />
                )}

              </div>
              <div className="next-button">
                {
                  (listEvaluators !== null) && (startingEvaluator + 5 < listEvaluators!.length) && (
                  <ActionButton type="primary" label="Next" clickHandler={handleNextPageClick} />
                  )
                }

              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="list-evaluator-line" />
    </>

  )
}
