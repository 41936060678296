import React from 'react';
import { useAppSelector } from '../../store/storeHooks';
import './Loader.css';
import PresdidoHireLogo from '../../assets/icons/PresidioHireLogo.svg';
import {
  LoaderVector1,
  LoaderVector2,
  LoaderVector3,
  LoaderVector4,
  LoaderVector5,
  LoaderVector6,
  LoaderVector7,
  LoaderVector8,
} from '../../assets/icons/loader';

function Loader() {
  const loading = useAppSelector((state) => state.loader.loading);
  const message = useAppSelector(((state) => state.loader.message));
  return (
    <div className={(loading) ? 'loading' : 'not-loading'}>
      <div className="flex flex-column align-items-center justify-content-center">
        <img className="presidio-hire-logo" src={PresdidoHireLogo} alt="Presdio Hire" />
        <div className="loader-container flex align-items-start justify-content-start">
          <img className="loader-vector-1 relative" src={LoaderVector1} alt="Loading Vector Group" />
          <img className="loader-vector-2 relative" src={LoaderVector2} alt="Loading Vector Group" />
          <img className="loader-vector-3 relative" src={LoaderVector3} alt="Loading Vector Group" />
          <img className="loader-vector-4 relative" src={LoaderVector4} alt="Loading Vector Group" />
          <img className="loader-vector-5 relative" src={LoaderVector5} alt="Loading Vector Group" />
          <img className="loader-vector-6 relative" src={LoaderVector6} alt="Loading Vector Group" />
          <img className="loader-vector-7 relative" src={LoaderVector7} alt="Loading Vector Group" />
          <img className="loader-vector-8 relative" src={LoaderVector8} alt="Loading Vector Group" />
        </div>
        {/* <img className="spinner" src={SpinnerLoader} alt="Spinner Loader" /> */}
        <p>{message}</p>
      </div>
    </div>
  );
}

export default Loader;
