import React from 'react';
import './CampusDriveListSkeleton.css';
import { Skeleton } from 'primereact/skeleton';

export default function CampusDriveListSkeleton() {
  return (
    <div className="campus-drive-list-skeleton  w-full h-auto flex flex-column align-items-center justify-content-start">
      <div className="list-header flex w-full">
        <div className="cell campus-drive-col">
          CAMPUS DRIVE
        </div>
        <div className="cell university-col">
          UNIVERSITY
        </div>
        <div className="cell exams-col">
          NUMBER OF EXAMS
        </div>
        <div className="cell students-col">
          STUDENTS
        </div>
        <div className="view-campus-drive h-full" />
      </div>

      <div className="list-row flex w-full shade">
        <div className="cell campus-drive-col">
          <Skeleton width="80%" className="mb-2" />
        </div>
        <div className="cell university-col">
          <Skeleton width="80%" className="mb-2" />
        </div>
        <div className="cell exams-col">
          <Skeleton width="80%" className="mb-2" />
        </div>
        <div className="cell students-col">
          <Skeleton width="80%" className="mb-2" />
        </div>
        <div className="view-campus-drive h-full flex align-items-center justify-content-end">
          <Skeleton width="100%" className="mb-2" />
        </div>
      </div>

      <div className="list-row flex w-full">
        <div className="cell campus-drive-col">
          <Skeleton width="80%" className="mb-2" />
        </div>
        <div className="cell university-col">
          <Skeleton width="80%" className="mb-2" />
        </div>
        <div className="cell exams-col">
          <Skeleton width="80%" className="mb-2" />
        </div>
        <div className="cell students-col">
          <Skeleton width="80%" className="mb-2" />
        </div>
        <div className="view-campus-drive h-full flex align-items-center justify-content-end">
          <Skeleton width="100%" className="mb-2" />
        </div>
      </div>

      <div className="list-row flex w-full shade">
        <div className="cell campus-drive-col">
          <Skeleton width="80%" className="mb-2" />
        </div>
        <div className="cell university-col">
          <Skeleton width="80%" className="mb-2" />
        </div>
        <div className="cell exams-col">
          <Skeleton width="80%" className="mb-2" />
        </div>
        <div className="cell students-col">
          <Skeleton width="80%" className="mb-2" />
        </div>
        <div className="view-campus-drive h-full flex align-items-center justify-content-end">
          <Skeleton width="100%" className="mb-2" />
        </div>
      </div>

      <div className="list-row flex w-full">
        <div className="cell campus-drive-col">
          <Skeleton width="80%" className="mb-2" />
        </div>
        <div className="cell university-col">
          <Skeleton width="80%" className="mb-2" />
        </div>
        <div className="cell exams-col">
          <Skeleton width="80%" className="mb-2" />
        </div>
        <div className="cell students-col">
          <Skeleton width="80%" className="mb-2" />
        </div>
        <div className="view-campus-drive h-full flex align-items-center justify-content-end">
          <Skeleton width="100%" className="mb-2" />
        </div>
      </div>

      <div className="list-row flex w-full shade last-item">
        <div className="cell campus-drive-col">
          <Skeleton width="80%" className="mb-2" />
        </div>
        <div className="cell university-col">
          <Skeleton width="80%" className="mb-2" />
        </div>
        <div className="cell exams-col">
          <Skeleton width="80%" className="mb-2" />
        </div>
        <div className="cell students-col">
          <Skeleton width="80%" className="mb-2" />
        </div>
        <div className="view-campus-drive h-full flex align-items-center justify-content-end">
          <Skeleton width="100%" className="mb-2" />
        </div>
      </div>
    </div>
  );
}
