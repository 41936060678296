import React, { useRef, useState } from 'react';
import './CandidateLogin.css';
import { InputText } from 'primereact/inputtext';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Toast } from 'primereact/toast';
import { useNavigate } from 'react-router';
import { SpinCheckButton } from '../../../components';
import PresidioHireLogo from '../../../assets/icons/PresidioHireLogo.svg';
import { candidateVerify } from '../../../services/candidateService';
import RoutesConstants from '../../../constants/routesConstants';
import {
  setCandidateData, setExamSession, setExamIdData, setDuration, setTotalCount, setExamRemainTimings,
} from '../../../store/questionCounter';

import { useAppDispatch } from '../../../store/storeHooks';
import {
  setEmail, setId, setName, setRole, setAuthenticated,
} from '../../../store/authStore';
import { storeJWT } from '../../../services/authService';
import { setMalpracticeCount } from '../../../store/examStore';

export default function CandidateLogin() {
  const [addButtonStatus, setAddButtonStatus] = useState<'LOADING' | 'LOADED' | 'NOT_LOADING'>('NOT_LOADING');
  const toastRef = useRef<any>(null);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const defaultValues = {
    examcode: '',
    regNo: '',
    password: '',
  };

  const toast = (severity: string, summary: string, detail: string) => {
    toastRef.current.show({
      severity,
      summary,
      detail,
      life: 3000,
    })
  }

  const {
    register, handleSubmit,
    // formState: { errors },
  } = useForm({ defaultValues });
  const routesConstant = new RoutesConstants();
  const onSubmit: SubmitHandler<any> = (data: any) => {
    setAddButtonStatus('LOADING');
    setTimeout(() => { setAddButtonStatus('NOT_LOADING'); }, 10000);
    if (data.examcode === '' || data.regNo === '' || data.password === '') {
      setTimeout(() => {
        navigate(0);
        toast(
          'error',
          'Invalid Input',
          'try again with valid input',
        )
      });
      setAddButtonStatus('NOT_LOADING');
    }

    candidateVerify(data.examcode, data)
      .then((response:any) => {
        if (response) {
          if (response.examStatus) {
            if (response.examStatus === 'ENDED') {
              setAddButtonStatus('NOT_LOADING');
              toast(
                'error',
                'Sorry',
                'Exam has ended',
              )
            }
          }
          if (response.data.success === true) {
            const {
              candidate, exam, auth, jwt, examSession, totalCount, malpracticeCount,
            } = response.data;
            if (exam.statusId === 2) {
              storeJWT(jwt)
              dispatch(setAuthenticated(true));
              dispatch(setId(auth.id))
              dispatch(setRole(auth.role));
              dispatch(setName(auth.name));
              dispatch(setEmail(auth.email))
              dispatch(setCandidateData(candidate))
              dispatch(setExamIdData(data.examcode))
              dispatch(setDuration(exam.duration));
              dispatch(setExamSession(examSession));
              dispatch(setTotalCount(totalCount));
              setAddButtonStatus('LOADED');
              dispatch(setMalpracticeCount(malpracticeCount))
              console.log(exam.timeAlerts);
              const examRemainTiming:number[] = exam.timeAlerts.split(',').map((e:string) => (
                (Number(e) * 60)
              ));
              dispatch(setExamRemainTimings(examRemainTiming));

              navigate(`${routesConstant.CANDIDATE}${routesConstant.CANDIDATE_START}`);
            } else {
              setTimeout(() => {
                setAddButtonStatus('LOADED');
                navigate(`${routesConstant.CANDIDATE}${routesConstant.CANDIDATE_START_ERROR}`);
              })
            }
          } else {
            setTimeout(() => {
              toast(
                'error',
                'Invalid Credentials',
                response.data.message,
              )
              // navigate(`${routesConstant.CANDIDATE}${routesConstant.CANDIDATE_START_ERROR}`);
            })
          }
          setAddButtonStatus('LOADED');

          if (response.data.error) {
            setTimeout(() => {
              navigate(0);
              setAddButtonStatus('NOT_LOADING');
              toast(
                'error',
                response.data.message,
                'Please try again',
              )
            });
            setAddButtonStatus('NOT_LOADING');
            setTimeout(() => {
              navigate(0);
            });
          }
        } else {
          setAddButtonStatus('NOT_LOADING');
          toast(
            'error',
            'Network Error',
            'Please try again',
          )
        }
      })
      .catch((e) => {
        console.log(e);
        toastRef.current.show({
          severity: 'error',
          summary: 'Something went wrong',
          detail: e.response.data.message,
        });
      })
  }
  return (
    <div className="candidate-exam-container">
      <div className="candidate-exam-form">
        <div className="logo-container w-full">
          <img src={PresidioHireLogo} className="presidio-hire-logo" alt="Presidio Hire Logo" />
        </div>
        <div className="text truncate">
          Hello! Welcome to Presidio Hire👋
        </div>
        <div className="candidate-content">
          <div className="text-base">
            We are so glad you are here. Enter your exam code, username and password to continue to your test.
          </div>
          <div className="text-base">
            If you are not sure about your login information contact your nearest presidio admin.
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-field split category no-margin-top">
            <div className="cont">
              <div className="label">
                Exam Code
              </div>
              <InputText className="formInputText" {...register('examcode')} id="examcode" placeholder="Enter the ExamCode" />
            </div>
            <div className="cont">
              <div className="label">
                Username
              </div>
              <InputText className="formInputText" {...register('regNo')} id="username" placeholder="Enter the Username" />
            </div>
            <div className="cont">
              <div className="label">
                Password
              </div>
              <InputText className="formInputText" {...register('password')} id="password" type="password" placeholder="Enter the Password" />
            </div>
            <div className="login-button-container w-full">
              <div className="login-button">
                <SpinCheckButton styleClass="upload-button-item" loadingStatus={addButtonStatus} label="Login" onClick={handleSubmit(onSubmit)} />
              </div>
            </div>
          </div>
        </form>

      </div>
      <div className="footer font-normal">
        <div className="text-center">
          © 2022 Presidio. All rights reserved.
        </div>
      </div>

      <Toast ref={toastRef} />
    </div>
  );
}
