import { Skeleton } from 'primereact/skeleton';
import './ListExamRowSkeleton.css';
import React from 'react';

type IListExamRowSkeletonProp = {
  shade: number;
  lastItem: boolean;
}

export default function ListExamRowSkeletion(props: IListExamRowSkeletonProp) {
  const {
    shade, lastItem,
  } = props;

  return (
    <div className={`list-exam-row-skeleton flex w-full ${shade % 2 === 0 ? 'shade' : ''} ${lastItem ? 'last-item' : ''}`}>
      <div className="cell campus-drive-col">
        <Skeleton width="80%" className="mb-2" />
      </div>
      <div className="cell university-col">
        <Skeleton width="80%" className="mb-2" />
      </div>
      <div className="cell exams-col">
        <Skeleton width="80%" className="mb-2" />
      </div>
      <div className="cell students-col">
        <Skeleton width="80%" className="mb-2" />
      </div>
      <div className="view-campus-drive h-full flex align-items-center justify-content-end">
        <Skeleton width="80%" className="mb-2" />
      </div>
    </div>
  );
}
