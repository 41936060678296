import React, { useState } from 'react';
import './CandidateStartError.css';
import CandidateNavbar from '../../../components/candidateNavbar/CandidateNavbar';

export default function CandidateStartError() {
  // eslint-disable-next-line no-unused-vars
  const [uploadButtonStatus] = useState<'LOADING' | 'LOADED' | 'NOT_LOADING'>('NOT_LOADING')

  return (
    <div className="candidate-start-error-page">
      <CandidateNavbar time="true" />
      <div className="candidate-start-error-page-container">
        <div className="candidate-error-header">
          Sorry, but you are not supposed to be here. ✋

        </div>

        <div className="candidate-error-content align-content-center">

          You either
          {' '}
          <b>have already taken this exam</b>
          {' '}
          or you haven’t
          {' '}
          <b>been assigned any exam </b>
          {' '}
          yet.
          <br />
          Contact your nearest Presidio admin for more information.

        </div>

      </div>
    </div>

  );
}
