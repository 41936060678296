import React from 'react';
import './ViewQuestionModal.css';
import IListQuestion from '../../models/IListQuestion';
import questionConstants from '../../constants/questionConstants';

type IViewQuestionModalProps = {
  question: IListQuestion
}

export default function ViewQuestionModal({ question }: IViewQuestionModalProps) {
  const optionHeaders = ['optionA', 'optionB', 'optionC', 'optionD', 'optionE', 'optionF', 'optionG', 'optionH', 'optionI', 'optionJ']
  const getMSQAnswers = () => question.correct.split(',');
  return (
    <div className="view-question-modal">
      <div className="options">
        <div className="options-title">OPTIONS</div>
        {
          // @ts-ignore
          optionHeaders.map((option: any) => <div className="options-value">{question[option]}</div>)
        }
      </div>
      <div className="answer">
        {
          (question.type === questionConstants.MCQ)
          && (
            <>
              <div className="answer-title">ANSWER</div>
              <div className="answer-value">
                {
                  // @ts-ignore
                  question[`option${question.correct}`]
                }
              </div>
            </>
          )
        }
        {
          (question.type === questionConstants.MSQ)
          && (
            <>
              <div className="answer-title">ANSWERS</div>

                {
                  getMSQAnswers().map((option: string) => (
                    <div className="answer-value">
                      {
                        // @ts-ignore
                        question[`option${option}`]
                      }
                    </div>
                  ))
                }

            </>
          )
        }
      </div>
    </div>
  )
}
