import React, { useState } from 'react';
import './AddEvaluators.css';
import { useParams } from 'react-router';
import { AxiosResponse } from 'axios';
import { SubmitHandler, useForm, useWatch } from 'react-hook-form';
// import { MultiSelect } from 'primereact/multiselect';
import Select from 'react-select';
import { FieldSkeleton, SpinCheckButton } from '../index';
import {
  getEvaluators, addEvaluator, getEvaluatorsByRole,
} from '../../services/examService';
import { useAppDispatch } from '../../store/storeHooks';
import { setEvaluatorData } from '../../store/examStore';
import IUser from '../../models/IUser';

type IProps = {
  setCompVisible: any,
  toast: any,
  examId: any,
  addEvalHandler: any,
}
let isDisabled = false;
export default function AddEvaluators({
  setCompVisible, toast, addEvalHandler,
}: IProps) {
  const dispatch = useAppDispatch();
  const { examId }: any = useParams();

  const [addButtonStatus, setAddButtonStatus] = useState<'LOADING' | 'LOADED' | 'NOT_LOADING'>('NOT_LOADING');

  const [evaluators, setEvaluators] = useState<IUser[] | undefined>(undefined);
  // const [superEvaluators, setSuperEvaluators] = useState<IUser[] | undefined>(undefined);

  const defaultValues = {
    role: '',
    evaluator: undefined,
  };
  const [evaluatorsData, setEvaluatorsData] = useState<IUser[] | undefined>(undefined);
  const [superEvaluatorsData, setSuperEvaluatorsData] = useState<IUser[] | undefined>(undefined);

  const {
    register, control, formState: { errors }, setValue,
  } = useForm({ defaultValues });

  const refreshEvaluatorsData = () => {
    dispatch(setEvaluatorData(null));
    getEvaluators(examId)
      .then((response:AxiosResponse) => {
        let data: IUser[] = response.data.EVL;
        data = data.concat(response.data.SEVL);
        dispatch(setEvaluatorData(data));
      }).then(() => {
        addEvalHandler(true)
      })
      .catch(() => {});
  }

  const errorResponse = () => {
    setTimeout(() => {
      setCompVisible(false);
      toast(
        'error',
        'Something went wrong',
        'Please try again later',
      )
    });
  }
  const role = useWatch({
    control,
    name: 'role',
  })
  const evaluator = useWatch({
    control,
    name: 'evaluator',
  });
  function setDisabled(value: number) {
    if (value === 1) { isDisabled = true; } else isDisabled = false;
  }

  const changeEvaluator = async (event:any) => {
    setValue('role', event.role)
    setValue('evaluator', undefined)
    setEvaluators(undefined);
    let data: any;
    if (evaluatorsData !== undefined && superEvaluatorsData !== undefined) {
      if (event.value === '1') {
        // @ts-ignore
        setEvaluators(evaluatorsData);
      }
      if (event.value === '2') {
        // @ts-ignore
        setEvaluators(superEvaluatorsData);
      }
    } else {
      await getEvaluatorsByRole(examId)
        .then((response:AxiosResponse) => {
          data = response.data;
          data.EVL = data.EVL.map((el:any) => {
            el.label = el.name;
            el.value = el.id;
            return el
          })
          setEvaluatorsData(data.EVL);
          data.SEVL = data.SEVL.map((el:any) => {
            el.label = el.name;
            el.value = el.id;
            return el
          })
          setSuperEvaluatorsData(data.SEVL);
          if (event.value === '1') {
            setEvaluators(data.EVL);
          }
          if (event.value === '2') {
            setEvaluators(data.SEVL);
          }
        })
        .catch(() => {});
    }
  }

  const onSubmit: SubmitHandler<any> = () => {
    setAddButtonStatus('LOADING');
    if (evaluator) {
      addEvaluator(examId, evaluator)
        .then((response: AxiosResponse) => {
          if (response.status === 200) {
            setAddButtonStatus('LOADED');
            addEvalHandler(false)
            setTimeout(() => {
              setCompVisible(false);
              toast(
                'success',
                'Success',
                'Evaluator has been added successfully',
              )
            });
            refreshEvaluatorsData()
          } else {
            errorResponse();
          }
        })
        .catch(() => {
          errorResponse();
        })
    }
  }
  const roleOptions:any[] = [
    { label: 'Super Evaluator', value: '2' },
    { label: 'Evaluator', value: '1' },
  ]

  const selectEvaluators = (e:any) => {
    setValue('evaluator', e)
  }

  return (
    <div className="add-evaluators-container w- full">
      <div className="add-evaluators-form">
        <form>

          <div className="field">
            <div className="label">
              Role
            </div>
            <Select {...register('role', { required: 'Role is required.' })} onChange={changeEvaluator} value={role} className="input" options={roleOptions} placeholder="Select Role" />
            {
            errors.role ? <small className="error-label p-error">{errors.role.message}</small> : ''
          }
          </div>
          {
              (!evaluators)
              && (
              <>
                <FieldSkeleton label="Evaluators" />
                {setDisabled(1)}
              </>
              )
          }
          {
              (evaluators)
              && (
              <div className="field">
                <div className="label">
                  Evaluators
                </div>
                {setDisabled(0)}
                <Select isMulti {...register('evaluator', { required: 'Super Evaluators are required.' })} onChange={selectEvaluators} value={evaluator} className="input" options={evaluators} placeholder="Select Evaluators" />
                {
                      // @ts-ignore
                      errors.evaluator ? (<small className="error-label p-error">{errors.evaluator.message}</small>) : ''
                    }
              </div>
              )
          }

          <div className="add-button-container w-full flex align-items-center justify-content-end">
            <div className="add-button">
              <SpinCheckButton styleClass="add-button-item" label="Add Evaluator" loadingStatus={addButtonStatus} onClick={onSubmit} disabled={isDisabled} />
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}
