export default class ApiContants {
  COLLEGE: string = '/college';

  CAMPUS_DRIVE: string = '/campusDrive';

  CAMPUS_DRIVE_COUNT: string = '/count';

  USER: string = '/user';

  EXAM: string = '/exams';

  BULKUPLOAD: string = '/bulkupload';

  EXAM_END:string = '/end';

  EXAM_COUNT: string = '/count';

  QUESTION: string = '/question';

  QUESTIONTEMPLATE: string = '/template';

  GETQUESTION: string = '/getQuestion';

  CANDIDATE: string = '/candidate';

  TEMPLATE: string = '/template';

  CATEGORY: string = '/category';

  EVALUATOR: string = '/evaluators';

  EVALUATE: string = '/evaluate';

  REEVALUATE: string = '/re-evaluate';

  STARTEXAM: string = '/start';

  EXAMUSERS: string = '/userForExam';

  ADDEVALUATOR: string = '/';

  RESTEVALUATOR: string = '/rest';

  SENDINVITE: string = '/emailinvite';

  INVITEUSER: string = '/inviteuser';

  CANDIDATEINVITE: string = '/emailinvite';

  QUEUE: string = '/queue';

  EDIT_CATEGORY: string = '/edit';

  CANDIDATE_TAKE_EXAM: string = '/verifystart';

  EXAM_VERIFY: string = '/verifyexamstatus';

  NEXT_QUESTION: string = '/nextQuestion';

  CANDIDATE_RESPONSE: string = '/createresponse';

  EVENT: string = '/event';

  BOOKMARK: string = '/bookmark';

  SKIP: string = '/skip';

  END: string = '/end';

  UPDATE: string = '/update'

  UPDATE_CANDIDATE_SESSION: string = '/updateexamsession';

  REVIEW: string = '/review';

  CANDIDATE_REVIEW: string = '/responsereview';

  HEARTBEAT: string = '/event/heartbeat';

  FINISH: string = '/event/finish';

  MALPRACTICE: string = '/malpractice';

  QUESTION_POINTS: string = '/questionPoints';

  SEARCH: string = '/search';

  FILTER: string = '/filter';

  RANGE: string = '/range';

  DASHBOARD:string = '/dashboard';

  MARKSHEET: string = '/candidateReview';

  AUTH: string = '/authData';

  INITDATA: string = '/initData';

  DOWNLOADFILTEREDQUESTIONS: string = '/filterquestiondownload';

  DOWNLOADFILTEREDCANDIDATES: string = '/filtercandidatedownload';

  RESET : string = '/reset';

  SETTINGS: string = '/settings';

  GET_ADDITIONAL_SETTINGS: string = '/additional';

  UPDATE_ADDITIONAL_SETTINGS: string = '/additionalUpdate';

  START_PAGE: string = '/startpage';
}
