import React, { useEffect, useState, useRef } from 'react';
import './ListColleges.css';
import { InputText } from 'primereact/inputtext';
import { AxiosResponse } from 'axios';
import { Toast } from 'primereact/toast';
import {
  ActionButton, ExamCandidatesSkeleton, ListCollegesRow, SpinCheckButton,
} from '../index';
import { ICollege } from '../../models/ICollege';
import { useAppDispatch, useAppSelector } from '../../store/storeHooks';
import {
  setCollegeData, setCollegeSearchValue,
  setCollegeStartingPageNo,
  setCollegeTotal,
  setCurrentIndex, setIsCollegeSearch,
} from '../../store/settingsStore';
import { getPaginationUniversity, searchCollegeApi } from '../../services/campusDriveService';

type IListCollegesProps = {
  colleges: ICollege[] | null,
  handleAddCollegeClick: any
}

export default function ListColleges({ handleAddCollegeClick }: IListCollegesProps) {
  // const [listColleges, setListColleges] = useState<any>(null);
  const [previousButtonStatus, setPreviousButtonStatus] = useState<'LOADING' | 'LOADED' | 'NOT_LOADING'>('NOT_LOADING');
  const [nextButtonStatus, setNextButtonStatus] = useState<'LOADING' | 'LOADED' | 'NOT_LOADING'>('NOT_LOADING');
  const [searchButtonStatus, setSearchButtonStatus] = useState<'LOADING' | 'LOADED' | 'NOT_LOADING'>('NOT_LOADING');
  const dispatch = useAppDispatch();
  // const [startingCollege, setStartingCollege] = useState(0);
  const [overlayIndex, setOverlayIndex] = useState<number | null>(null);
  // const [collegeSearch, setCollegeSearch] = useState<any>('');
  const listColleges = useAppSelector((state) => state.settings.collegeData);
  const collegeStartingNo = useAppSelector((state) => state.settings.collegeStartingPageNo);
  const totalCollege = useAppSelector((state) => state.settings.collegeTotal);
  const currentIndex = useAppSelector((state) => state.settings.currentIndex);
  const collegeData = useAppSelector((state) => state.settings.collegeData);
  const isSearch = useAppSelector((state) => state.settings.isCollegeSearch);
  const searchCollegeValue = useAppSelector((state) => state.settings.collegeSearchValue);
  const [searchValue, setSearchValue] = useState<string>('');
  const toastRef = useRef<any>(null);

  let collegeLen: number = 0;
  if (collegeData != null) {
    collegeLen = collegeData.length;
  }
  useEffect(() => {
    getPaginationUniversity(collegeStartingNo)
      .then((response: AxiosResponse) => {
        dispatch(setCollegeData(response.data.colleges as ICollege[]));
        dispatch(setCollegeTotal(response.data.len));
      })
      .catch(() => {})
  }, []);

  const updateIndex = (index: number) => {
    if (overlayIndex === index) {
      setOverlayIndex(null)
    } else {
      setOverlayIndex(index)
    }
  }

  const handleNextPageClick = () => {
    setNextButtonStatus('LOADING');
    if (isSearch === false) {
      getPaginationUniversity(collegeStartingNo + 1)
        .then((response: AxiosResponse) => {
          dispatch(setCollegeStartingPageNo(collegeStartingNo + 1));
          dispatch(setCurrentIndex(currentIndex + 5));
          dispatch(setCollegeData(response.data.colleges as ICollege[]));
          dispatch(setCollegeTotal(response.data.len));
          setNextButtonStatus('NOT_LOADING');
        })
        .catch(() => {
        })
    } else {
      const data: any = {};
      data.query = {
        sortBy: 'updatedAt',
        pageNo: collegeStartingNo + 1,
        collegeName: searchCollegeValue,
      };
      searchCollegeApi(data)
        .then((response: AxiosResponse) => {
          dispatch(setCollegeData(response.data.colleges as ICollege[]));
          dispatch(setCollegeTotal(response.data.len));
          dispatch(setCurrentIndex(currentIndex + 5));
          dispatch(setCollegeStartingPageNo(collegeStartingNo + 1));
          setNextButtonStatus('NOT_LOADING');
        })
        .catch(() => {})
    }
  }

  const handlePreviousPageClick = () => {
    setPreviousButtonStatus('LOADING');
    if (isSearch === false) {
      getPaginationUniversity(collegeStartingNo - 1)
        .then((response: AxiosResponse) => {
          dispatch(setCollegeStartingPageNo(collegeStartingNo - 1));
          dispatch(setCurrentIndex(currentIndex - 5));
          dispatch(setCollegeData(response.data.colleges as ICollege[]));
          dispatch(setCollegeTotal(response.data.len));
          setPreviousButtonStatus('NOT_LOADING');
        })
        .catch(() => {
        })
    } else {
      const data: any = {};
      data.query = {
        sortBy: 'updatedAt',
        pageNo: collegeStartingNo - 1,
        collegeName: searchCollegeValue,
      };
      searchCollegeApi(data)
        .then((response: AxiosResponse) => {
          dispatch(setCollegeData(response.data.colleges as ICollege[]));
          dispatch(setCollegeTotal(response.data.len));
          dispatch(setCurrentIndex(currentIndex - 5));
          dispatch(setCollegeStartingPageNo(collegeStartingNo - 1));
          setPreviousButtonStatus('NOT_LOADING');
        })
        .catch(() => {})
    }
  }
  const searchCollege = () => {
    setSearchButtonStatus('LOADING');
    dispatch(setIsCollegeSearch(true));
    dispatch(setCollegeStartingPageNo(0));
    dispatch(setCollegeSearchValue(searchValue));
    const data: {query :{ sortBy: string, pageNo: number, collegeName: string}} = {
      query: {
        sortBy: 'updatedAt',
        pageNo: 0,
        collegeName: searchValue,
      },
    }

    searchCollegeApi(data)
      .then((response: AxiosResponse) => {
        if (response.data.colleges.length !== 0) {
          dispatch(setCurrentIndex(0));
          dispatch(setCollegeData(response.data.colleges as ICollege[]));
          dispatch(setCollegeTotal(response.data.len));
          dispatch(setCollegeStartingPageNo(0));
          dispatch(setCurrentIndex(0));
        } else {
          setSearchValue('');
          toastRef.current.show({
            severity: 'info',
            summary: 'Not Found',
            detail: 'There is no data found',
          });
        }
        setSearchButtonStatus('NOT_LOADING');
      })
      .catch(() => {})
  }

  return (
    <>
      <div className="list-colleges-container w-full">
        <div className="header flex align-items-center justify-content-between">

          {
            ((collegeData != null && collegeData.length === 0) || collegeData == null)
            && (
              <div className="colleges-list-container w-full">
                <div className="header w-full flex align-items-center justify-content-between">
                  <div className="title">
                    Colleges
                  </div>
                  <div className="filter-container flex">
                    <div className="add-college">
                      <ActionButton type="primary" label="Add College" clickHandler={handleAddCollegeClick} />
                    </div>
                  </div>
                </div>
                {(collegeData == null) && <ExamCandidatesSkeleton />}

                {(collegeData != null && collegeData!.length === 0)
                  && (
                    <div className="empty-colleges flex align-items-center justify-content-center">
                      No colleges added yet.
                    </div>
                  )}
              </div>
            )
          }
          {
            (collegeData != null && collegeData.length > 0)
            && (
              <>
                <div className="title font-semibold">
                  Colleges
                </div>
                <div className="filter-container flex">
                  <div className="flex flex-row">
                    <div className="filter-fields">
                      <div className="p-inputgroup">
                        <span className="search p-input-icon-left">
                          <i className="pi pi-search" />
                          <InputText
                            onInput={(e: any) => setSearchValue(e.target.value)}
                            type="string"
                            placeholder="Search by Name"
                            value={searchValue}
                          />
                        </span>
                      </div>
                    </div>
                    <div className="search-drive mr-2">
                      <SpinCheckButton styleClass="search-drive-button font-medium" loadingStatus={searchButtonStatus} onClick={searchCollege} label="Search" icons="pi pi-search" />
                    </div>
                  </div>
                  <div className="new-college">
                    <ActionButton type="primary" styleClass="add-college-button" label="Add College" clickHandler={handleAddCollegeClick} />
                  </div>
                </div>

              </>
            )
          }

        </div>
        {
          (collegeData != null && collegeData.length > 0)
          && (
          <div className="list-colleges-container w-full">
            <div className="list-colleges-table w-full">
              <div className="row-header row w-full">
                <div className="name cell">
                  NAME
                </div>
                <div className="contact cell">
                  CONTACT
                </div>
                <div className="location cell">
                  LOCATION
                </div>
                <div className="poc cell">
                  POINT OF CONTACT
                </div>
                <div className="elipsis cell" />
              </div>

              {
                listColleges?.map((college: ICollege, index: number) => (
                  <ListCollegesRow
                    key={college.id}
                    index={index + 1}
                    college={college}
                    overlayClick={updateIndex}
                    overlayIndex={overlayIndex}
                  />
                ))
              }
            </div>

            <div className="footer w-full flex align-items-center justify-content-between">
              <div className="result-details">
                {collegeData != null
                  && `Showing ${(listColleges!.length === 0) ? 0 : (currentIndex + 1)} to ${(totalCollege < currentIndex + 5) ? (totalCollege) : (currentIndex + 5)} of ${totalCollege} results`}
              </div>
              <div className="pagination-buttons flex">
                {(collegeStartingNo !== 0)
                  && (
                    <div className="mr-3">
                      <SpinCheckButton styleClass="" label="Previous" loadingStatus={previousButtonStatus} onClick={handlePreviousPageClick} />
                    </div>
                  )}
                {((totalCollege - collegeLen) !== currentIndex)
                  && (
                    <div className="">
                      <SpinCheckButton styleClass="" label="Next" loadingStatus={nextButtonStatus} onClick={handleNextPageClick} />
                    </div>
                  )}
              </div>
            </div>
          </div>
          )
        }
      </div>
      <div className="list-colleges-line" />
      <Toast ref={toastRef} />
    </>
  );
}
