import React, { useRef, useState } from 'react';
import './AddCategory.css';
import { SubmitHandler, useForm } from 'react-hook-form';
import { InputText } from 'primereact/inputtext';
import { AxiosResponse } from 'axios';
import _ from 'lodash';
import { Toast } from 'primereact/toast';
import { SpinCheckButton } from '../index';
import { ICreateCategory } from '../../models/ICreateCategory';
import {
  addCategory, editQuestionCategory, getAllCategory,
} from '../../services/examService';
import { setCategoryData, setCategoryDialogVisible, setUpdateCategoryData } from '../../store/settingsStore';
import { useAppDispatch, useAppSelector } from '../../store/storeHooks';
import { ICategory } from '../../models/ICategory';

type IProps = {
  toast: any
}

export default function AddCategory({ toast }: IProps) {
  // @ts-ignore
  const updateCategoryData: ICategory | null = useAppSelector((state) => state.settings.updateCategoryData);
  // eslint-disable-next-line no-unused-vars
  const categoryDialogTitle = useAppSelector((state) => state.settings.categoryDialogTitle);
  // eslint-disable-next-line no-unused-vars
  const [noChangeError, setNoChangeError] = useState('');
  const dispatch = useAppDispatch();
  const toastRef = useRef<any>(null);

  const [addButtonStatus, setAddButtonStatus] = useState<'LOADING' | 'LOADED' | 'NOT_LOADING'>('NOT_LOADING');
  const whiteSpaceRegex = /.*\S.*/
  const defaultValues = {
    name: '',
  }
  if (updateCategoryData != null) {
    defaultValues.name = updateCategoryData.name;
  }
  const defaultValueCopy = defaultValues;

  const {
    register, handleSubmit, formState: { errors },
  } = useForm({ defaultValues });

  const refreshCategoryData = () => {
    dispatch(setCategoryData(null));
    getAllCategory()
      .then((response: AxiosResponse) => {
        dispatch(setCategoryData(response.data as ICategory[]));
      })
      .catch(() => {});
  }

  const updateCategory = (categoryData: ICreateCategory) => {
    editQuestionCategory(updateCategoryData!.id, categoryData)
      .then((response: AxiosResponse) => {
        if (response.data.content != null) {
          setAddButtonStatus('LOADED');
          setTimeout(() => {
            dispatch(setCategoryDialogVisible(false));
            toast(
              'success',
              'Success',
              'Category has been updated successfully',
            )
          });
          dispatch(setUpdateCategoryData(null));
          refreshCategoryData();
        } else {
          setTimeout(() => {
            dispatch(setCategoryDialogVisible(false));
            toast(
              'error',
              'Something went wrong',
              'Please try again later',
            )
          });
        }
      })
      .catch((e) => {
        toastRef.current.show({
          severity: 'error',
          summary: 'Something went wrong',
          detail: e.response.data.message,
        });
      });
  }

  const addCategoryData = (categoryData: ICreateCategory) => {
    addCategory(categoryData)
      .then((response: AxiosResponse) => {
        if (response.status === 200 && response.data.message === 'success') {
          setAddButtonStatus('LOADED');
          setTimeout(() => {
            dispatch(setCategoryDialogVisible(false));
            toast(
              'success',
              'Success',
              'Category has been added successfully',
            )
          });
          refreshCategoryData();
        } else {
          setTimeout(() => {
            dispatch(setCategoryDialogVisible(false));
            toast(
              'error',
              'Duplicate entry',
              'The category already exists',
            )
          });
        }
      })
      .catch((e) => {
        toastRef.current.show({
          severity: 'error',
          summary: 'Something went wrong',
          detail: e.response.data.message,
        });
      });
  }

  const onSubmit: SubmitHandler<any> = (data: any) => {
    setAddButtonStatus('LOADING');
    const categoryData: ICreateCategory = {
      name: data.name,
    };

    if (updateCategoryData == null) {
      addCategoryData(categoryData);
    } else if (_.isEqual(categoryData, defaultValueCopy)) {
      setNoChangeError('No changes were made!');
      setAddButtonStatus('NOT_LOADING');
    } else {
      setNoChangeError('');
      updateCategory(categoryData);
    }
  };

  return (
    <div className="add-category-container h-full w-full">
      <div className="add-category-form h-full w-full">
        <form onSubmit={handleSubmit(onSubmit)} className="h-full w-full flex flex-column align-items-center justify-content-between">
          <div className="field name no-margin-top">
            <br />
            <div className="label">
              &nbsp;Name
            </div>
            <InputText {...register('name', { required: 'Category Name is required.', pattern: { value: whiteSpaceRegex, message: 'Invalid Category' } })} className="input" type="text" placeholder="Enter category name" autoComplete="off" />
            {
              errors.name?.type === 'required' ? (<small className="error-label p-error">{errors.name.message}</small>) : ''
            }
            {
              errors.name?.type === 'pattern' ? (<small className="error-label p-error">{errors.name.message}</small>) : ''
            }
          </div>
          {
            noChangeError !== '' ? (<small className="error-label p-error">{noChangeError}</small>) : ''
          }
          <div className="add-button-container w-full flex align-items-center justify-content-end">
            <div className="add-button">
              <SpinCheckButton styleClass="add-button-item" label={`${categoryDialogTitle} Category`} loadingStatus={addButtonStatus} onClick={handleSubmit(onSubmit)} />
            </div>
          </div>
        </form>
      </div>
      <Toast ref={toastRef} />
    </div>
  )
}
