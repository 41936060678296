import React from 'react';
import { evaluationNavigatorType } from '../../evaluation';
import ResponseNavigatorDisplay from './responseNavigatorDisplay/ResponseNavigatorDisplay';

type ResponseNavigatorProps = {
  sortedResponse: evaluationNavigatorType[],
  setSortedResponse: Function,
  selectedResponse: evaluationNavigatorType | null | undefined,
  setSelectedResponse: Function,
}

export default function ResponseNavigator({
  sortedResponse, setSortedResponse, selectedResponse, setSelectedResponse,
}: ResponseNavigatorProps) {
  return (
    <div className="question-navigator-wrap">
      <div className="question-number-display-set">
        <ResponseNavigatorDisplay
          sortedResponse={sortedResponse}
          setSortedResponse={setSortedResponse}
          selectedResponse={selectedResponse}
          setSelectedResponse={setSelectedResponse}
        />
      </div>
    </div>
  );
}
