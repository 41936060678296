import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import IResponse from '../models/IResponse';

export interface EvaluationState {
  categoryId: string | null;
  categoryValue: string | '';
  responseData: IResponse | null;
  validRespondeId: {id: string, questionId: string}[] | null;
  invalidResponseId: [] | null;
  totalValidResponses: number | null;
  currentIndex: number | null;
  count: number | null;
}

const initialState: EvaluationState = {
  categoryId: null,
  categoryValue: '',
  responseData: null,
  validRespondeId: null,
  invalidResponseId: null,
  totalValidResponses: null,
  currentIndex: null,
  count: null,
};

export const evaluationStore = createSlice({
  name: 'evaluation',
  initialState,
  reducers: {
    setCategoryIdData: (state, action: PayloadAction<string | null>) => {
      state.categoryId = action.payload
    },
    setResponseData: (state, action: PayloadAction<IResponse | null>) => {
      state.responseData = action.payload
    },
    setTotalValidResponsesData: (state, action: PayloadAction<number | null>) => {
      state.totalValidResponses = action.payload
    },
    setValidResponseIdsData: (state, action: PayloadAction<[] | null>) => {
      state.validRespondeId = action.payload
    },
    setinValidResponseIdsData: (state, action: PayloadAction<[] | null>) => {
      state.invalidResponseId = action.payload
    },
    setCountData: (state, action: PayloadAction<number | null>) => {
      state.count = action.payload
    },
    setCategoryValueData: (state, action: PayloadAction<string | ''>) => {
      state.categoryValue = action.payload
    },
    setCurrentIndexData: (state, action: PayloadAction<number | null>) => {
      state.currentIndex = action.payload
    },
  },
});

export const {
  setCategoryIdData,
  setResponseData,
  setTotalValidResponsesData,
  setValidResponseIdsData,
  setCategoryValueData,
  setCountData,
  setCurrentIndexData,
  setinValidResponseIdsData,
} = evaluationStore.actions;

export default evaluationStore.reducer;
