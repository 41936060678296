import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import ISessionData from '../models/ISessionData';

export interface ScoreBoardStore {
  sessionData: ISessionData[] | null,
  startingPageNo: number,
  currentIndex: number,
  totalSessionData: number,
  isFilterGreater: boolean,
  isFilterLesser: boolean,
  isFilterPassed: boolean,
  isEvaluatedFilter: boolean,
  percentage: number,
}

const initialState: ScoreBoardStore = {
  sessionData: null,
  startingPageNo: 0,
  currentIndex: 0,
  totalSessionData: 0,
  isFilterGreater: false,
  isFilterLesser: false,
  isFilterPassed: false,
  isEvaluatedFilter: false,
  percentage: 0,
}

export const scoreboardStore = createSlice({
  name: 'scoreboard',
  initialState,
  reducers: {
    setSessionData: (state, action: PayloadAction<ISessionData[] | null>) => {
      state.sessionData = action.payload
    },
    setStartingPageNo: (state, action: PayloadAction<number>) => {
      state.startingPageNo = action.payload
    },
    setCurrentIndex: (state, action: PayloadAction<number>) => {
      state.currentIndex = action.payload
    },
    setTotalSessionData: (state, action: PayloadAction<number>) => {
      state.totalSessionData = action.payload
    },
    setIsFilterGreater: (state, action: PayloadAction<boolean>) => {
      state.isFilterGreater = action.payload
    },
    setIsFilterLesser: (state, action: PayloadAction<boolean>) => {
      state.isFilterLesser = action.payload
    },
    setIsFilterPassed: (state, action: PayloadAction<boolean>) => {
      state.isFilterPassed = action.payload
    },
    setIsEvaluatedFilter: (state, action: PayloadAction<boolean>) => {
      state.isEvaluatedFilter = action.payload
    },
    setPercentage: (state, action: PayloadAction<number>) => {
      state.percentage = action.payload
    },
  },
});

export const {
  setSessionData, setStartingPageNo, setCurrentIndex, setTotalSessionData, setIsFilterGreater, setIsFilterLesser, setIsFilterPassed, setIsEvaluatedFilter, setPercentage,
} = scoreboardStore.actions;

export default scoreboardStore.reducer;
