import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IUser } from '../models/IUser';

export interface UserState {
  userData: IUser[] | null;
  currentIndex: number;
  totalCount: number;
  isSearch: boolean,
  searchValue: string,
  userStartingPageNo: number,
  searchType: string,
  searchCode: string,
}

const initialState: UserState = {
  userData: null,
  currentIndex: 0,
  totalCount: 0,
  isSearch: false,
  searchValue: 'A',
  userStartingPageNo: 0,
  searchType: '',
  searchCode: '',
};

export const userStore = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setUserData: (state, action: PayloadAction<IUser[] | null>) => {
      state.userData = action.payload
    },
    setCurrentIndex: (state, action: PayloadAction<number>) => {
      state.currentIndex = action.payload
    },
    setTotalCount: (state, action: PayloadAction<number>) => {
      state.totalCount = action.payload
    },
    setIsSearch: (state, action: PayloadAction<boolean>) => {
      state.isSearch = action.payload
    },
    setSearchValue: (state, action: PayloadAction<string>) => {
      state.searchValue = action.payload
    },
    setUserStartingPageNo: (state, action: PayloadAction<number>) => {
      state.userStartingPageNo = action.payload
    },
    setSearchType: (state, action: PayloadAction<string>) => {
      state.searchType = action.payload
    },
    setSearchCode: (state, action: PayloadAction<string>) => {
      state.searchCode = action.payload
    },

  },
});

export const {
  setUserData,
  setCurrentIndex,
  setTotalCount,
  setIsSearch,
  setSearchValue,
  setUserStartingPageNo,
  setSearchType,
  setSearchCode,
} = userStore.actions;

export default userStore.reducer;
