import { Skeleton } from 'primereact/skeleton';
import './ListReviewRowSkeleton.css';
import React from 'react';

type IListReviewRowSkeletonProp = {
  shade: number;
  lastItem: boolean;
  column : string[];
}

export default function ListReviewRowSkeletion(props: IListReviewRowSkeletonProp) {
  const {
    shade, lastItem, column,
  } = props;

  return (

    <div className={`list-review-row-skeleton flex w-full ${shade % 2 === 0 ? 'shade' : ''} ${lastItem ? 'last-item' : ''}`}>
      {[...column].map((index) => (
        <div key={`Skeleton Row ${index}`} className="cell" style={{ width: `${100 / column.length}%` }}>
          <Skeleton width="80%" className="mb-2" />
        </div>
      ))}
    </div>
  );
}
