export default class MalpracticeConstants {
  COPY: number = 8;

  PASTE: number = 10;

  CUT: number = 9;

  TAB_SWITCH: number = 11;

  MAX_MALPRACTICE: number = 3;
}
// export default class MalpracticeConstants {
//   COPY: number = 800000;
//
//   PASTE: number = 1000000;
//
//   CUT: number = 9000000;
//
//   TAB_SWITCH: number = 1100000;
//
//   MAX_MALPRACTICE: number = 5000000;
// }
