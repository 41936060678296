import React, { useEffect, useState } from 'react';
import './CandidateTermination.css';
import { useNavigate } from 'react-router';
import CandidateNavbar from '../../../components/candidateNavbar/CandidateNavbar';
import { setIsRunning } from '../../../store/examSessionStore';
import { useAppDispatch } from '../../../store/storeHooks';

export default function CandidateTermination() {
  // eslint-disable-next-line no-unused-vars
  const [uploadButtonStatus] = useState<'LOADING' | 'LOADED' | 'NOT_LOADING'>('NOT_LOADING');
  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  useEffect(() => {
    dispatch(setIsRunning(false));
    addEventListener(('popstate'), () => {
      localStorage.clear();
      navigate('/candidate/candidate-login')
    })
  }, [])

  return (
    <div className="candidate-termination-page-container">
      <CandidateNavbar time="false" />
      <div className="candidate-termination-content">

        <div className="candidate">
          Sorry, you have been terminated from this exam 🚫
        </div>

        <div className="base-candidate">
          <div className="err-msg">
            You
            {' '}
            <b>have breached the ethics of this exam.</b>
            <br />
            Contact your nearest Presidio admin for more information.
          </div>
        </div>
      </div>
    </div>
  );
}
