import React, { useEffect, useRef, useState } from 'react';
import './ListRoles.css';
import { AxiosResponse } from 'axios';
import { Toast } from 'primereact/toast';
import {
  getPaginationUsers,
} from '../../../../services/userService';
import ExamCandidatesSkeleton from '../../../../components/skeleton/examCandidatesSkeleton/ExamCandidatesSkeleton';
import { ListUsers } from '../../../../components';
import { IUser } from '../../../../models/IUser';
import { useAppDispatch, useAppSelector } from '../../../../store/storeHooks';
import { setTotalCount, setUserData } from '../../../../store/userStore';

export default function ListRoles() {
  const dispatch = useAppDispatch();
  const userData = useAppSelector((state) => state.users.userData);
  const [resultLoaded, isResultLoaded] = useState(false);

  const toastRef = useRef<any>(null);

  useEffect(() => {
    // dispatch(setUserData(null));
    const data: any = {};
    getPaginationUsers(0, data)
      .then((response: AxiosResponse) => {
        if (response.status === 200) {
          dispatch(setUserData(response.data.user as IUser[]));
          dispatch(setTotalCount(response.data.len));
          isResultLoaded(true);
        }
      })
      .catch((e) => {
        toastRef.current.show({
          severity: 'error',
          summary: 'Something went wrong',
          detail: e.response.data.message,
        });
      })
  }, []);
  return (
    <div className="roles-list-view h-full w-full">
      {
        ((userData != null && userData!.length === 0) || userData == null)
        && (
          <div className="roles-list-container w-full">
            {
       (resultLoaded)
           && <ListUsers users={[]} />
            }
            {
              (userData == null) && <ExamCandidatesSkeleton />
            }
          </div>
        )
      }
      <Toast ref={toastRef} />
      {
        (userData != null && userData.length > 0)
        && <ListUsers users={userData} />
      }
    </div>
  )
}
