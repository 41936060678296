import axios from 'axios';
import ApiContants from '../constants/apiContants';

const apiConstants = new ApiContants();

// eslint-disable-next-line import/prefer-default-export
export function getAdditionalSetting() {
  return axios.get(`${apiConstants.SETTINGS}${apiConstants.GET_ADDITIONAL_SETTINGS}`);
}

export function updateAdditionalSetting(data: any) {
  return axios.post(`${apiConstants.SETTINGS}${apiConstants.UPDATE_ADDITIONAL_SETTINGS}`, data);
}
