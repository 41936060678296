import React from 'react';
import './ActionButton.css';
import { Button } from 'primereact/button';

type ActionButtonProps = {
  type: 'primary' | 'outline' | 'text' | 'Danger' | 'Warning';
  label: string,
  styleClass?: string,
  clickHandler: any,
  disabled?: boolean
}

export default function ActionButton({
  type, label, styleClass, clickHandler,
}: ActionButtonProps) {
  return <Button className={`action-button ${styleClass} ${type}`} onClick={clickHandler} label={label} />
}
