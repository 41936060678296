import React, { useEffect, useState } from 'react';
import './Navbar.css';
import { Link, useLocation } from 'react-router-dom';
// import { Auth } from 'aws-amplify';
// import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import PresidioHireLogo from '../../assets/icons/PresidioHireLogo.svg';
import RoutesConstants from '../../constants/routesConstants';
import { useAppDispatch, useAppSelector } from '../../store/storeHooks';
import RolesConstants from '../../constants/rolesContants';
import { authLogout } from '../../services/authService';
import { setLoader } from '../../store/loaderStore';
import { setExamStatusId, setExamStartingNo, setCurrentDataIndex } from '../../store/examStore';
import { setSearchPageNoData } from '../../store/campusDriveStore';

function Navbar() {
  const routesConstants = new RoutesConstants();
  const rolesConstants = new RolesConstants();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const authRole = useAppSelector((state) => state.auth.role);
  const authName = useAppSelector((state) => state.auth.name);
  const [activeState, setActiveState] = useState(routesConstants.DASHBOARD);
  const [userRole, setUserRole] = useState('');
  const [userName, setUserName] = useState('');
  const [userAvatar, setUserAvatar] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);

  useEffect(() => {
    setActiveState((location.pathname === routesConstants.DASHBOARD) ? routesConstants.DASHBOARD : `/${location.pathname.split('/')[1]}`);
    setUserRole((authRole === rolesConstants.ADMIN) ? 'Admin' : (authRole === rolesConstants.SUPER_EVALUATOR) ? 'Super Evaluator' : (authRole === rolesConstants.EVALUATOR) ? 'Evaluator' : '');
    // setUserRole('Admin');
    // setUserAvatar('DS');
    // setUserName('Devamuthan S');
    // Auth.currentAuthenticatedUser()
    //   .then((user) => {
    //     const authName = user.attributes.name.split(',');
    const name = authName.split(' ');
    if (name[0] && name[1] && name[0].length > 0 && name[1].length > 0) {
      setUserAvatar(`${name[1].trim()[0]}${name[0].trim()[0]}`);
      setUserName(`${name[1].trim()} ${name[0]}`);
    }
    // })
    // .catch(() => {});
  }, [location.pathname, authName, authRole]);
  /*
   * Clearing User Store and Auth Store
   * on logout
   */

  const logoutEvent = () => {
    dispatch(setLoader(true));
    authLogout();
  };

  const dropdownChange = (event: any) => {
    if (event.target.id === 'Logout') {
      dispatch(setLoader(true));
      logoutEvent();
    }
  }
  const handleExam = () => {
    dispatch(setExamStatusId(0));
    dispatch(setExamStartingNo(0));
    dispatch(setCurrentDataIndex(0));
  }
  const handleCampusDrive = () => {
    dispatch(setSearchPageNoData(0));
    dispatch(setCurrentDataIndex(0));
  }

  // const navSelectItems = [
  //   { label: 'Logout', value: 'logout' },
  // ];
  const DropDownList = ['Logout']

  return (
    <div className="navbar-comp w-full shadow-1 flex align-items-center justify-content-between">
      <div className="presidio-hire-logo-container">
        <img src={PresidioHireLogo} className="presidio-hire-logo" alt="Presidio Hire Logo" />
      </div>

      <div className="navigation flex align-items-center h-full">
        {userRole === 'Admin' && (
          <div className="navigation-links flex align-items-center justify-content-evenly h-full">
            <Link to={`${routesConstants.DASHBOARD}`} className={activeState === routesConstants.DASHBOARD ? 'active' : ''}>
              <div className="link">Dashboard</div>
            </Link>
            <Link to={`${routesConstants.ROLES}`} className={activeState === routesConstants.ROLES ? 'active' : ''}>
              <div className="link">Roles</div>
            </Link>
            <Link onClick={handleCampusDrive} to={`${routesConstants.CAMPUS_DRIVE}`} className={activeState === routesConstants.CAMPUS_DRIVE ? 'active' : ''}>
              <div className="link">Campus Drive</div>
            </Link>
            <Link onClick={handleExam} to={`${routesConstants.EXAMS}`} className={activeState === routesConstants.EXAMS ? 'active' : ''}>
              <div className="link">Exams</div>
            </Link>
            <Link to={`${routesConstants.SETTINGS}`} className={activeState === routesConstants.SETTINGS ? 'active' : ''}>
              <div className="link">Settings</div>
            </Link>
          </div>
        )}

        {(userRole === 'Super Evaluator' || userRole === 'Evaluator') && (
        <div className="navigation-links-seval navigation-links flex align-items-center justify-content-evenly h-full">
          <Link to={`${routesConstants.DASHBOARD}`} className={activeState === routesConstants.DASHBOARD ? 'active' : ''}>
            <div className="link">Dashboard</div>
          </Link>
          <Link onClick={handleExam} to={`${routesConstants.EXAMS}`} className={activeState === routesConstants.EXAMS ? 'active' : ''}>
            <div className="link">Exams</div>
          </Link>
        </div>
        )}

        <div className="navigation-profile flex align-items-center h-full w-auto">
          <div className="profile-avatar border-circle bg-blue-400 flex align-items-center justify-content-center text-white font-semibold">
            {userAvatar}
          </div>
          <div className="profile h-full w-auto flex flex-column align-items-start justify-content-center">
            <div className="profile-name font-medium">
              {userName}
            </div>
            <div className="profile-role">{userRole}</div>
          </div>
        </div>
        <div className="profile-drop  cursor-pointer flex align-items-center justify-content-center outline-0">
          <Button className="profile-dropdown-button" icon="pi pi-angle-down font-normal" onClick={() => setShowDropdown(!showDropdown)} />
          {/* <Dropdown options={navSelectItems} onChange={dropdownChange} /> */}
        </div>
        <div className="outerDropDown">
          {showDropdown && (
            <div onChange={dropdownChange} className="profile-dropdown">
              {DropDownList.map((eachValue) => <button className="profile-dropdown-list" onClick={dropdownChange} id={eachValue} type="button">{eachValue}</button>)}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Navbar;
