import React, { useEffect, useState } from 'react';
import Editor from '@monaco-editor/react';
// import { MonacoDiffEditor } from 'react-monaco-editor';
import { candidateQuestion } from '../MarkSheetQuestion';
import './MarkSheetOEQT.css';
import LanguagesDropdown from '../../languageDropdown/LanguageDropdown';
import { useAppDispatch, useAppSelector } from '../../../store/storeHooks';
import { setOeqcCodingLanguage } from '../../../store/questionCounter';
import { setCodingLanguage } from '../../../store/examStore';

export default function MarkSheetOEQT({ markSheetDisplay, setResponseString } : candidateQuestion) {
  const [preFetchResponseString, setPreFetchResponseString] = useState<string | null>(null);
  const [response, setResponse] = useState<string>('');
  const codingLanguage = useAppSelector((state) => state.exams.codingLanguage)
  const oeqcCodingLanguage = useAppSelector((state) => state.counter.oeqcCodinglanguage)
  const dispatch = useAppDispatch();
  // console.log('the selected lang is', codingLanguage)
  useEffect(() => {
    // let codingLanguage = '';
    if (markSheetDisplay.response) {
      console.log('inside if')
      dispatch(setCodingLanguage(markSheetDisplay.response.language))
      dispatch(setOeqcCodingLanguage(markSheetDisplay.response.language))
    } else {
      console.log('inside else')
      dispatch(setCodingLanguage(markSheetDisplay.question.language))
      dispatch(setOeqcCodingLanguage(markSheetDisplay.question.language))
    }
    console.log('in use effect 1')
    if (markSheetDisplay.response) {
      setPreFetchResponseString(markSheetDisplay.response.answer);
      setResponse(markSheetDisplay.response.answer === null ? '' : markSheetDisplay.response.answer);
    } else setResponse('');
  }, [markSheetDisplay]);

  const onInputHandler = (newvalue:any) => {
    setResponse(newvalue)
  }
  const onSelectChange = async (sl:any) => {
    await dispatch(setCodingLanguage(sl.value))
    await dispatch(setOeqcCodingLanguage(sl.value))
    console.log('onselect change', codingLanguage)
  };

  useEffect(() => {
    console.log('in use effect 2')
    if (response === preFetchResponseString) setResponseString(undefined);
    else if (response.trim().length !== 0) setResponseString(response);
    else if (response.trim().length === 0) setResponseString(null);
  }, [response])
  // console.log('the response language is', markSheetDisplay.response.language)
  console.log('the lang used in the editor is ', codingLanguage)
  // const editor = MonacoDiffEditor.original
  // .editor.getContainerDomNode().addEventListener('paste', (event:any) => {
  // // console.log("paste handle in monaco editor contianer");
  //   event.preventDefault();
  // });
  return (
    <div>
      <div className="px-4 py-2">
        { markSheetDisplay.question.language === 'any' && (
        <LanguagesDropdown
          onSelectChange={onSelectChange}
          selectedLanguage={oeqcCodingLanguage}
        />
        )}
      </div>
      <div
        className="markSheet-oeqt-container"
        onPaste={(e:any) => {
          e.preventDefault()
        }}
      >
        <Editor
          className="container"
          height="300px"
          theme="vs-dark"
          language={codingLanguage}
          value={response}
          onChange={onInputHandler}
        />
      </div>
    </div>
  )
}
