import React, { useRef } from 'react';
import { Navigate } from 'react-router-dom';
import './Login.css';
import { Toast } from 'primereact/toast';
import { authLogin } from '../../services/authService';
import MicrosoftIcon from '../../assets/icons/MicrosoftLogo.svg';
import LoginImage from '../../assets/images/LoginImage.svg';
import PresidioHireLogo from '../../assets/icons/PresidioHireLogo.svg';
import { useAppDispatch, useAppSelector } from '../../store/storeHooks';
import ToastConstants from '../../constants/toastConstants';
import { setWelcomeToast } from '../../store/authStore';
import RoutesConstants from '../../constants/routesConstants';
import { setLoader } from '../../store/loaderStore';
import RolesConstants from '../../constants/rolesContants';

function Login() {
  const authenticated = useAppSelector((state) => state.auth.authenticated);
  const userRole = useAppSelector((state) => state.auth.role);
  const toast = useRef(null);
  const toastConstants = new ToastConstants();
  const routesConstants = new RoutesConstants();
  const roleConst = new RolesConstants();
  const dispatch = useAppDispatch();

  if ((authenticated) && (userRole !== roleConst.CANDIDATE)) {
    return (
      <Navigate
        to={routesConstants.HOME}
        state={{
          from: routesConstants.LOGIN,
          message: {
            title: toastConstants.SUCCESS_TITLE,
            detail: toastConstants.PERSIST_SUCCESS_DETAIL,
          },
        }}
      />
    );
  }

  dispatch(setWelcomeToast(true));

  const onLoginClick = () => {
    dispatch(setLoader(true));
    authLogin();
  };

  return (
    <div className="login-view w-full h-full flex align-items-center justify-content-center">
      <div className="left-container h-full flex align-items-center justify-content-center">
        <div className="content-holder h-full flex flex-column align-items-center justify-content-between">
          <div className="login-container shadow-2 flex flex-column align-items-center justify-content-between">
            <div className="logo-container w-full">
              <img src={PresidioHireLogo} className="presidio-hire-logo" alt="Presidio Hire Logo" />
            </div>
            <div className="title w-full font-semibold">
              Login
            </div>
            <div className="description w-full font-normal">
              Login using your Presidio work email to access your account.
              Contact your admin if you don’t have access.
            </div>
            <div className="login-button-container w-full flex items-center justify-center">
              <button
                type="submit"
                className="login-button w-full h-full flex align-items-center justify-content-center cursor-pointer"
                onClick={onLoginClick}
              >
                <img src={MicrosoftIcon} className="login-icon" alt="Microsoft Logo" />
                <span className="login-label text-white font-medium">Sign in with Presidio </span>
              </button>
            </div>
          </div>
          <div className="footer font-normal text-center">
            © 2022 Presidio. All rights reserved.
          </div>
        </div>
      </div>

      <div className="right-container h-full flex align-items-center justify-content-end">
        <img src={LoginImage} alt="Login Page" className="bg-right h-full" />
      </div>

      <Toast ref={toast} />
    </div>
  );
}

export default Login;
