import React from 'react';
import useTimer from '../../hooks/useTimer';

type timeProps = {
    time: number
}
export default function Timer({ time } : timeProps) {
  const [hours, minutes, seconds] = useTimer(time);

  return (
    <div>
      {hours < 10 ? `0${hours}` : hours}
      :
      {minutes < 10 ? `0${minutes}` : minutes}
      :
      {seconds < 10 ? `0${seconds}` : seconds}
    </div>
  );
}
