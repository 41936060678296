import React, { useEffect, useRef, useState } from 'react';
import './AddQuestions.css';
import { useParams } from 'react-router';
import { AxiosResponse } from 'axios';
import {
  SubmitHandler,
  useFieldArray,
  useForm,
  useWatch,
} from 'react-hook-form';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { FieldSkeleton, SpinCheckButton, UploadField } from '../index';
import { validateQuestions } from '../../hooks/examHook';
import {
  addQuestion,
  getPresignedURL, getQuestion,
  getQuestions, updateQuestionExam,
  uploadQuestionCandidateToS3, bulkupload, getQuestionTemplate,
} from '../../services/examService';
import { IPreSignedURL } from '../../models/IPreSignedURL';
import ApiContants from '../../constants/apiContants';
import questionConstants from '../../constants/questionConstants';
import { ICategory } from '../../models/ICategory';
import { setQuestionData } from '../../store/examStore';
import { useAppDispatch } from '../../store/storeHooks';
import IListQuestion from '../../models/IListQuestion';
import { IPoints } from '../../models/IPoints';
// import { setPointsData } from '../../store/settingsStore';
import languageConstants from '../../constants/languageConstants';
// import AddEvaluators from '../addEvaluators/AddEvaluators';
// import { IQuestionPoints } from '../../models/IQuestionPoints';

type IProps = {
  setCompVisible: any,
  toast: any,
  categories: ICategory[],
  questionPoint: any,
  handleQuestionError: any,
  addQuestionHandler: Function,
  updateQuestion: string | undefined,
}

type IQuestion = {
  QuestionType: string,
  Category: string,
  Language: string,
  RandomOption: string,
  Question: string,
  Point: string,
  Correct: string,
  OptionA: string,
  OptionB: string,
  OptionC: string,
  OptionD: string,
  OptionE: string,
  OptionF: string,
  OptionG: string,
  OptionH: string,
  OptionI: string,
  OptionJ: string,
}

type IPostQuestion = {
  question: IQuestion,
  examId: string,
}

export default function AddQuestions({
  setCompVisible, handleQuestionError, toast, categories, questionPoint, addQuestionHandler, updateQuestion,
}: IProps) {
  const dispatch = useAppDispatch();
  const apiConstants = new ApiContants();
  const { examId }: any = useParams();
  const [file, setFile] = useState({
    name: '',
    file: null,
    isPresent: false,
  });
  const [uploadButtonStatus, setUploadButtonStatus] = useState<'LOADING' | 'LOADED' | 'NOT_LOADING'>('NOT_LOADING');
  const [addButtonStatus, setAddButtonStatus] = useState<'LOADING' | 'LOADED' | 'NOT_LOADING'>('NOT_LOADING');
  // const questionPoints = [{ points: 1 }, { points: 5 }, { points: 10 }, { points: 20 }]

  const [uploadButtonDisabled, setUploadButtonDisabled] = useState<boolean>(true);
  const [optionsError, setOptionsError] = useState('');
  const optionHeaders = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J']
  const whiteSpaceRegex = /.*\S.*/
  const toastRef = useRef<any>(null);
  const [errorMsg, setErrorMsg] = useState<string[] | null>(null);
  const [errorDialog, setErrorDialog] = useState<boolean>(false);

  const defaultValues = {
    questionType: '',
    questionCategory: '',
    point: 0,
    language: '',
    question: '',
    options: [
      { value: '' },
      { value: '' },
    ],
    correctOption: '',
    languageConstants: '',
    correctOptions: null,
  };
  const questionTypeOptions = [
    { label: questionConstants.MCQ, value: questionConstants.MCQ },
    { label: questionConstants.MSQ, value: questionConstants.MSQ },
    { label: questionConstants.OEQC, value: questionConstants.OEQC },
    { label: questionConstants.OEQT, value: questionConstants.OEQT },
  ]

  // const questionCategoryOptions: ICategory[] = categories;

  const [questionCategoryOptions, setQuestionCatgoryOption] = useState<ICategory[] | null>(categories);
  const [questionPoints] = useState<IPoints[] | null>(questionPoint);

  const [updateLoader, setUpdateLoader] = useState<Boolean>(false)
  const [downloadQuestionButtonStatus, setDownloadQuestionButtonStatus] = useState<'LOADING' | 'LOADED' | 'NOT_LOADING'>('NOT_LOADING');

  const {
    register, handleSubmit, control, formState: { errors }, setValue,
  } = useForm({ defaultValues });

  const refreshQuestionsData = () => {
    dispatch(setQuestionData(null));
    getQuestions(examId)
      .then((response:AxiosResponse) => {
        dispatch(setQuestionData(response.data as IListQuestion[]));
      }).then(() => {
        addQuestionHandler(true);
      })
      .catch((e) => {
        toastRef.current.show({
          severity: 'error',
          summary: 'Something went wrong',
          detail: e.response.data.message,
        });
      });
  }

  const { fields, append } = useFieldArray({
    control,
    name: 'options',
  });

  const questionType = useWatch({
    control,
    name: 'questionType',
  });

  const questionCategory = useWatch({
    control,
    name: 'questionCategory',
  });

  const language = useWatch({
    control,
    name: 'language',
  });

  const options = useWatch({
    control,
    name: 'options',
  });

  const correctOption = useWatch({
    control,
    name: 'correctOption',
  });

  const correctOptions = useWatch({
    control,
    name: 'correctOptions',
  });

  const point = useWatch({
    control,
    name: 'point',
  });

  const question = useWatch({
    control,
    name: 'question',
  });

  const onQuestionTypeChange = async () => {
    setValue('correctOption', '');
    setQuestionCatgoryOption(categories);
  }

  useEffect(() => {
    if (updateQuestion) {
      setUpdateLoader(true)
      getQuestion(updateQuestion).then(({ data }) => {
        if (data.status) {
          setValue('questionType', data.question.type);
          // @ts-ignore
          setValue('questionCategory', questionCategoryOptions.find((c) => c.id === data.question.categoryId));
          // @ts-ignore
          setValue('point', questionPoints.find((q) => q.points === data.question.QuestionPoint.points));
          setValue('question', data.question.question);
          const answers: { value: string }[] = [];
          if (data.question.type === 'MCQ' || data.question.type === 'MSQ') {
            Object.keys(data.question).forEach((key) => {
              if (key.startsWith('option')) {
                if (data.question[key] !== null && data.question[key].length !== 0) {
                  answers.push({ value: data.question[key] });
                }
              }
            });
            setValue('options', answers);
            if (data.question.type === 'MCQ') {
              setValue('correctOption', data.question[`option${data.question.correct}`]);
            } else {
              // setValue('correctOptions', data.question.correct);
              const correctAnswers: string[] = [];
              data.question.correct.split(',').forEach((option: string) => {
                correctAnswers.push(data.question[`option${option}`]);
              });
              // @ts-ignore
              setValue('correctOptions', correctAnswers);
            }
          }
        } else {
          toast(
            'error',
            'Something went wrong',
            data.message,
          )
        }
        setUpdateLoader(false)
      });
    }
  }, [])

  const validateQuestionsHandler = (fileData: any) => {
    const data = {
      name: fileData.file.name,
      file: fileData.file,
      isPresent: true,
    };
    setFile(data);
    if (!fileData.valid) {
      setUploadButtonDisabled(true);
      setTimeout(() => {
        handleQuestionError(fileData.errors);
        setCompVisible(false);
      }, 1000);
    } else {
      setUploadButtonDisabled(false);
    }
  }

  const errorResponse = () => {
    setTimeout(() => {
      setCompVisible(false);
      toast(
        'error',
        'Something went wrong',
        'Please try again later',
      )
    });
  }

  const uploadQuestion = async () => {
    setUploadButtonStatus('LOADING');
    getPresignedURL(examId, apiConstants.QUESTION, file.name.split('.')[1])
      .then((response: AxiosResponse) => {
        const data = response.data as IPreSignedURL;
        uploadQuestionCandidateToS3(data.url, file.file)
          .then(() => {
            bulkupload(examId, apiConstants.QUESTION, file.name.split('.')[1])
              .then((res: AxiosResponse) => {
                if (res.status === 200) {
                  if (res.data.error) {
                    setUploadButtonStatus('NOT_LOADING');
                    setErrorMsg(res.data.errorMessages);
                    setErrorDialog(true);
                  } else {
                    setUploadButtonStatus('LOADED');
                    setErrorMsg(null);
                    addQuestionHandler(false);
                    setTimeout(() => {
                      setCompVisible(false);
                      toast(
                        'success',
                        'Success',
                        'Questions file has been uploaded successfully',
                      )
                    });
                    refreshQuestionsData();
                  }
                } else {
                  errorResponse();
                }
              })
              .catch((e) => {
                toastRef.current.show({
                  severity: 'error',
                  summary: 'Something went wrong',
                  detail: e.response.data.message,
                });
              });
          })
          .catch((e) => {
            toastRef.current.show({
              severity: 'error',
              summary: 'Something went wrong',
              detail: e.response.data.message,
            });
          });
      })
      .catch((e) => {
        toastRef.current.show({
          severity: 'error',
          summary: 'Something went wrong',
          detail: e.response.data.message,
        });
      });
  }
  const handleQuestionTemplateDownload = () => {
    setDownloadQuestionButtonStatus('LOADING');
    getQuestionTemplate()
      .then((response: AxiosResponse) => {
        if (response.status === 200) {
          window.open(response.data.url, '_blank', 'noopener,noreferrer');
          setDownloadQuestionButtonStatus('LOADED');
        }
      })
      .catch((e) => {
        toastRef.current.show({
          severity: 'error',
          summary: 'Something went wrong',
          detail: e.response.data.message,
        });
      })
  }

  const handleHidevalidationForm = () => {
    setErrorMsg(null)
    setErrorDialog(false);
  }

  const onSubmit: SubmitHandler<any> = (data: any) => {
    setAddButtonStatus('LOADING');
    const addQuestionData: IPostQuestion = {
      question: {
        QuestionType: data.questionType,
        Category: data.questionCategory.name,
        Language: data.language,
        RandomOption: 'Yes',
        Question: data.question,
        Point: data.point.points,
        Correct: '',
        OptionA: '',
        OptionB: '',
        OptionC: '',
        OptionD: '',
        OptionE: '',
        OptionF: '',
        OptionG: '',
        OptionH: '',
        OptionI: '',
        OptionJ: '',
      },
      examId,
    }
    let error = false;
    if ((data.questionType === questionConstants.MCQ || data.questionType === questionConstants.MSQ) && (data.options[0] === '' || data.options[1] === '')) {
      error = true;
      setOptionsError('Option A and Option B is required');
    }

    if (data.questionType === questionConstants.MCQ) {
      let result = ''
      for (let i = 0; i < data.options.length; i += 1) {
        if (data.correctOption === data.options[i].value) {
          result = optionHeaders[i];
        }
      }
      addQuestionData.question.Correct = result;
    }

    if (data.questionType === questionConstants.MSQ) {
      let result = '';
      for (let i = 0; i < data.correctOptions.length; i += 1) {
        for (let j = 0; j < data.options.length; j += 1) {
          if (data.correctOptions[i] === data.options[j].value) {
            if (i === 0) {
              result = optionHeaders[j];
            } else {
              result = `${result},${optionHeaders[j]}`;
            }
            break;
          }
        }
      }
      addQuestionData.question.Correct = result;
    }

    if (!error) {
      for (let i = 0; i < data.options.length; i += 1) {
        // @ts-ignore
        addQuestionData.question[`Option${optionHeaders[i]}`] = data.options[i].value
      }

      if (!updateQuestion) {
        console.log(addQuestionData.question.Language)
        addQuestion(addQuestionData)
          .then((res: AxiosResponse) => {
            if (res.status === 200) {
              setAddButtonStatus('LOADED');
              setTimeout(() => {
                setCompVisible(false);
                toast(
                  'success',
                  'Success',
                  'Question has been added successfully',
                )
              });
              refreshQuestionsData();
            } else {
              errorResponse();
            }
          })
          .catch((e) => {
            toastRef.current.show({
              severity: 'error',
              summary: 'Something went wrong',
              detail: e.response.data.message,
            });
          })
      } else {
        updateQuestionExam(updateQuestion, addQuestionData)
          .then((response: AxiosResponse) => {
            if (response.data.status) {
              setAddButtonStatus('LOADED');
              setTimeout(() => {
                setCompVisible(false);
                toast(
                  'success',
                  'Success',
                  response.data.message,
                )
              });
              refreshQuestionsData();
            } else {
              errorResponse();
            }
          }).catch((e) => {
            toastRef.current.show({
              severity: 'error',
              summary: 'Something went wrong',
              detail: e.response.data.message,
            });
          })
      }
    }
  }

  // @ts-ignore
  return (
    <div className="add-questions-container h-full w- full">
      <div className="add-questions-form">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="field flex justify-content-between">
            <div className="field split question-type no-margin-top">
              {(!updateLoader) && (
              <>
                <div className="label">
                  Question Type
                </div>
                <div>
                  <Dropdown
                    {...register('questionType', {
                      required: 'Question type is required.',
                      onChange: onQuestionTypeChange,
                    })}
                    value={questionType}
                    className="input"
                    optionLabel="label"
                    options={questionTypeOptions}
                    placeholder="Select type"
                    disabled={!!updateQuestion}
                    appendTo="self"
                  />
                  {errors.questionType?.type === 'required' ? (<small className="error-label p-error">{errors.questionType.message}</small>) : ''}
                </div>

              </>
              )}
              {(updateLoader) && (
              <FieldSkeleton label="Question Type" />
              )}

            </div>
            <div className="field split question-category no-margin-top">
              {(!updateLoader) && (
              <>
                <div className="label">
                  Question Category
                </div>
                <div>
                  <Dropdown
                    {...register('questionCategory', {
                      required: 'Question category is required',
                      onChange: onQuestionTypeChange,
                    })}
                    value={questionCategory}
                    className="input"
                    optionLabel="name"
                    options={questionCategoryOptions || []}
                    placeholder="Select category"
                    appendTo="self"
                  />
                  {errors.questionCategory?.type === 'required' ? (<small className="error-label p-error">{errors.questionCategory.message}</small>) : ''}
                </div>

              </>
              )}
              {(updateLoader) && (
              <FieldSkeleton label="Question Category" />
              )}
            </div>
          </div>
          {
            (questionType === questionConstants.OEQC)
            && (
              <div className="field">
                <div className="label">
                  Coding Language
                </div>
                <div>
                  <Dropdown
                    {...register('language', {
                      required: 'language is required.',
                    })}
                    className="input"
                    placeholder="Select language for OEQC"
                    options={languageConstants}
                    value={language}
                    appendTo="self"
                  />
                  {
                    errors.languageConstants?.type === 'required' ? (
                      <small className="error-label p-error">{errors.languageConstants.message}</small>) : ''
                  }
                </div>
              </div>
            )
          }

          <div className="field point">
            {(!updateLoader) && (
            <>
              <div className="label">
                Points
              </div>
              {/* <Dropdown {...register('point', { required: 'Point is Required' })} key={point} value={point} className="point-field" style={{ fontSize: '14px' }} optionLabel="points" options={questionPoints} placeholder="Enter the points for this question" appendTo="self" /> */}
              <Dropdown {...register('point', { required: 'Point is Required' })} key={point} value={point} className="point-field" optionLabel="points" options={questionPoints || []} placeholder="Enter the points for this question" appendTo="self" />
            </>
            )}
            {(updateLoader) && (
            <FieldSkeleton label="Points" />
            )}
            {
                errors.point?.type === 'required' ? (<small className="error-label p-error">{errors.point.message}</small>) : ''
            }

          </div>
          <div className="field question">
            {(!updateLoader) && (
            <>
              <div className="label">
                Question
              </div>
              <InputTextarea {...register('question', { required: 'Question is required', pattern: { value: whiteSpaceRegex, message: 'Invalid Question' } })} value={question} className="input" placeholder="Enter the question here" autoComplete="off" rows={5} cols={30} autoResize />

            </>
            )}
            {(updateLoader) && (
            <FieldSkeleton label="Question" />
            )}
            {
              errors.question?.type === 'required' ? (<small className="error-label p-error">{errors.question.message}</small>) : ''
            }
            {
              errors.question?.type === 'pattern' ? (<small className="error-label p-error">{errors.question.message}</small>) : ''
            }
          </div>
          {
            (questionType === questionConstants.MCQ || questionType === questionConstants.MSQ)
            && (
              <div className="field question">
                <div className="label">
                  Options
                </div>
                {
                  fields.map((field, index: number) => (
                    <>
                      <div className="field span" key={fields[index].value}>
                        <div className="sub-label">{`${index + 1}. `}</div>
                        <InputText {...register(`options.${index}.value`, { required: 'Options are required' })} className="input" type="text" placeholder="Enter the option" autoComplete="off" />
                      </div>
                      {
                        (index === fields.length - 1 && fields.length !== 10)
                        && (
                          <button type="button" className="add-more-button" onClick={() => append({ value: '' })}> + Add More</button>
                        )
                      }
                    </>
                  ))
                }
                {
                  (optionsError !== '') && (<small className="error-label p-error">{optionsError}</small>)
                }
              </div>
            )
          }
          {
            (questionType === questionConstants.MCQ)
            && (
              <div className="field">
                <div className="label">
                  Correct Option
                </div>
                <div>
                  <Dropdown
                    {...register('correctOption', { required: 'Correct option is required.' })}
                    value={correctOption}
                    className="input"
                    optionLabel="value"
                    options={options}
                    placeholder="Select the correct option"
                    appendTo="self"
                  />
                  {
                    errors.correctOption?.type === 'required' ? (
                      <small className="error-label p-error">{errors.correctOption.message}</small>) : ''
                  }
                </div>
              </div>
            )
          }
          {
            (questionType === questionConstants.MSQ)
            && (
              <div className="field">
                <div className="label">
                  Correct Options
                </div>
                <MultiSelect {...register('correctOptions', { required: 'Correct options are required.' })} value={correctOptions} options={options} optionLabel="value" placeholder="Select all the correct options" maxSelectedLabels={3} />
                {
                  errors.correctOptions?.type === 'required' ? (<small className="error-label p-error">{errors.correctOptions.message}</small>) : ''
                }
              </div>
            )
          }
          <div className="add-button-container w-full flex align-items-center justify-content-end">
            <div className="add-button">
              <SpinCheckButton styleClass="add-button-item" label={updateQuestion ? 'Update Question' : 'Add Question'} loadingStatus={addButtonStatus} onClick={handleSubmit(onSubmit)} />
            </div>
          </div>
        </form>
      </div>
      <div className="file-upload-container w-full h-auto">
        <UploadField title="questions" file={file} setFile={setFile} postValidation={validateQuestionsHandler} validator={validateQuestions} />
        <div className="upload-button-container w-full flex align-items-center justify-content-end">
          <div>
            <SpinCheckButton styleClass="upload-button-item" label="Download Template" loadingStatus={downloadQuestionButtonStatus} onClick={handleQuestionTemplateDownload} />
          </div>
          <div className="upload-button">
            <SpinCheckButton styleClass="upload-button-item" disabled={uploadButtonDisabled} label="Upload Questions" loadingStatus={uploadButtonStatus} onClick={uploadQuestion} />
          </div>
        </div>
      </div>
      <Toast ref={toastRef} />
      <Dialog className="validation-dialog" header="Validation error" visible={errorDialog} style={{ width: '550px', height: '330px', borderRadius: '8px' }} onHide={handleHidevalidationForm}>
        <div className="error-validation-dialog">
          {
            errorMsg?.map((error: string) => (
              <div className="error-validation-row">
                {error}
              </div>
            ))
          }
        </div>
      </Dialog>
    </div>
  )
}
