import React from 'react';
import './Admin.css';
import { Outlet } from 'react-router';
import { Navbar } from '../../components';

function Admin() {
  return (
    <div className="admin-view w-full">
      <div className="section-1">
        <Navbar />
      </div>
      <div className="section-2">
        <Outlet />
      </div>
    </div>
  );
}

export default Admin;
