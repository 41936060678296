import { Auth, Hub } from 'aws-amplify';
import { NavigateFunction } from 'react-router';
import AwsConstants from '../constants/awsConstants';
import {
  setRole, setAuthenticated, clearAuthStore, setWelcomeToast, setName, setEmail, setId,
} from '../store/authStore';
import { AppDispatch } from '../store/store';
import RoutesConstants from '../constants/routesConstants';
import ToastConstants from '../constants/toastConstants';
import { setLoader } from '../store/loaderStore';

const awsConstants = new AwsConstants();
const routesConstants = new RoutesConstants();
const toastConstants = new ToastConstants();

export function authLogin() {
  // @ts-ignore
  Auth.federatedSignIn({
    provider: awsConstants.PROVIDER,
  })
    .then(async () => {
      // Set loader to active here
    })
    .catch(() => {
      // Handle errors
    });
}

export function authLogout() {
  Auth.signOut()
    .then(() => {
      // Set loader to active here
    })
    .catch(() => {
      // Handle errors
    });
}

export const storeJWT = (jwt:string) => {
  localStorage.setItem('jwt', JSON.stringify(jwt));
}

export const getJWT = () => {
  const jwt = localStorage.getItem('jwt')
  if (jwt) {
    return JSON.parse(jwt)
  }
  return null
}

export function attachHub(navigate: NavigateFunction, dispatch:AppDispatch) {
  /*
   * Amplify Event Hub attached to login page
   * to listen to 'signIn' event and store the token
   * and role in auth store.
   */
  const listener = async (data: { payload: { event: any; }; }) => {
    if (data.payload.event === 'signIn') {
      await Auth.currentAuthenticatedUser().then((authUser) => {
        storeJWT(authUser.attributes['custom:jwt']);
        dispatch(setAuthenticated(true));
        dispatch(setId(authUser.attributes.id))
        dispatch(setRole(authUser.attributes['custom:role']));
        dispatch(setName(authUser.attributes.name));
        dispatch(setWelcomeToast(true));
        dispatch(setEmail(authUser.attributes.email))
        navigate(routesConstants.HOME, {
          state: {
            from: routesConstants.HOME,
            message: {
              title: toastConstants.SUCCESS_TITLE,
              detail: toastConstants.LOGIN_SUCCESS_DETAIL,
            },
          },
          replace: true,
        });
      })
    } else if (data.payload.event === 'oAuthSignOut') {
      dispatch(clearAuthStore());
      dispatch(setLoader(false));
      navigate(routesConstants.LOGIN);
    }
  };

  Hub.listen('auth', listener);
}
