import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { Sidebar } from 'primereact/sidebar';
import QuestionNavigator from '../questionNavigator/QuestionNavigator';
import { useAppSelector } from '../../store/storeHooks';

export type questionCategoryType = { name: string, id: number};
export type questionType = {
    id: string,
    examId: string,
    categoryId: number,
    type: string,
    language: string,
    point: number,
    randomOption: boolean,
    question: string,
    correct: string | null,
    optionA: string | null,
    optionB: string | null,
    optionC: string | null,
    optionD: string | null,
    optionE: string | null,
    optionF: string | null,
    optionG: string | null,
    optionH: string | null,
    optionI: string | null,
    optionJ: string | null,
    source: string,
    createdAt: string,
    updatedAt: string,
    deletedAt: string | null,
}

export type questionNavigatorType = {
    id: string,
    order: number,
    category: number | null,
    default: boolean,
    selected: boolean,
    marked: boolean,
    answered: boolean,
};

type NavigatorProps = {
    visiblePanel: boolean,
    setVisiblePanel: any,
    questionNavigator: questionNavigatorType[],
    categoryList?: any
    selectedQuestion: questionNavigatorType | null | undefined,
    setSelectedQuestion: any,
    dropdown: boolean,
    setOnReviewClick?: any,
}

export default function Navigator(
  {
    visiblePanel, setVisiblePanel,
    selectedQuestion, setSelectedQuestion,
    categoryList, questionNavigator,
    dropdown, setOnReviewClick,
  } : NavigatorProps,
) {
  // Category List
  const [questionCategoryList, setQuestionCategoryList] = useState<questionCategoryType[]>([]);
  const [questionCategory, setQuestionCategory] = useState<number | null>(null);
  const startIndex = useAppSelector((state) => state.counter.startIndex);

  const [answeredCount, setAnsweredCount] = useState<number>(0);
  const [unansweredCount, setUnansweredCount] = useState<number>(0);

  const [sortedQuestions, setSortedQuestions] = useState<questionNavigatorType[]>([]);

  useEffect(() => {
    setQuestionCategoryList(categoryList);
  }, []);

  const applyNavigatorStyles = (navigatorQuestions: questionNavigatorType[]) => {
    let select;
    if (selectedQuestion) {
      select = navigatorQuestions.map((q) => {
        if (q.id === selectedQuestion.id) {
          q.default = false;
          q.selected = true;
        } else {
          q.default = true;
          q.selected = false;
        }
        return q;
      });
    } else {
      return navigatorQuestions;
    }
    return select;
  }

  useEffect(() => {
    setSortedQuestions(
      () => applyNavigatorStyles([...questionNavigator]),
    )
  }, [questionNavigator]);

  useEffect(() => {
    if (!selectedQuestion) setSelectedQuestion(sortedQuestions[startIndex]);
  }, [sortedQuestions]);

  useEffect(() => {
    let answered: number = 0;
    let unanswered: number = 0;
    sortedQuestions.forEach((question) => {
      if (question.answered) answered += 1;
      else unanswered += 1;
    });
    setAnsweredCount(answered);
    setUnansweredCount(unanswered);
  }, [sortedQuestions]);

  useEffect(() => {
    setSortedQuestions(applyNavigatorStyles([...sortedQuestions]));
  }, [selectedQuestion]);

  useEffect(() => {
    if (!questionCategory) {
      setSortedQuestions(
        () => applyNavigatorStyles([...questionNavigator]),
      );
    } else {
      setSortedQuestions(
        () => applyNavigatorStyles(questionNavigator.filter((q) => (q.category === questionCategory))),
      )
    }
  }, [questionCategory]);

  return (
    <Sidebar
      showCloseIcon={false}
      className="p-sidebar-sm navigator-panel"
      style={{ width: '25rem', height: '65%' }}
      dismissable
      modal={false}
      visible={visiblePanel}
      position="right"
      onHide={() => setVisiblePanel(false)}
    >
      <div className="candidate-mark-sheet-panel">
        <div className="candidate-mark-sheet-close">
          <Button icon="pi pi-arrow-right" onClick={() => setVisiblePanel(false)} className="p-button-outlined p-button-info" />
        </div>
        <div className="candidate-mark-sheet-questions">
          <div className="question-flex-heading">
            <div>
              <h2>QUESTIONS</h2>
            </div>
            <div style={{ marginTop: '6%', marginRight: '6%' }}>
              { !dropdown && (
              <div>
                <Button style={{ height: '30px' }} label="Review" className="p-button-raised p-button-info p-button-sm markSheet-button" onClick={() => setOnReviewClick(true)} />
              </div>
              )}
            </div>
          </div>
          { !dropdown && (
          <div style={{
            display: 'flex', flexFlow: 'row no-wrap', justifyContent: 'space-between', marginLeft: '5%', marginRight: '7%',
          }}
          >
            <div>
              <h5 style={{ margin: '0' }}>
                Answered:
                {answeredCount}
              </h5>
            </div>
            <div>
              <h5 style={{ margin: '0' }}>
                Unanswered:
                {unansweredCount}
              </h5>
            </div>
          </div>
          )}
          <div className="question-flex-content">
            <QuestionNavigator
              dropdown={dropdown}
              questionCategoryList={questionCategoryList}
              questionCategory={questionCategory}
              setQuestionCategory={setQuestionCategory}
              sortedQuestions={sortedQuestions}
              setSortedQuestions={setSortedQuestions}
              selectedQuestion={selectedQuestion}
              setSelectedQuestion={setSelectedQuestion}
            />
          </div>
        </div>
      </div>
    </Sidebar>
  );
}
