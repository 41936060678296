import React, { useState } from 'react';
import './ListCandidates.css';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
// import { format } from 'date-fns';
import { SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { ProgressBar } from 'primereact/progressbar';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { AxiosResponse } from 'axios';
import IListCandidate from '../../models/IListCandidate';
// import FilterIcon from '../../assets/icons/FilterIcon.svg';
import {
  ActionButton, ListCandidatessRow,
  SpinCheckButton,
} from '../index';
import { IListExam } from '../../models/IListExam';
import {
  getCandidates, searchCandidateApi, sendInvite, sendInviteUsingEmailId,
} from '../../services/examService';
import { useAppDispatch, useAppSelector } from '../../store/storeHooks';
import {
  setCandidateCurrentIndex,
  setCandidateData, setCandidateIsSearch,
  setCandidatePageNo,
  setCandidateTotal,
} from '../../store/examStore';
import { setCollegeSearchValue } from '../../store/settingsStore';

type IListCandidatesProps = {
  candidates: IListCandidate[],
  examData: IListExam,
  handleAddCandidateClick: any,
  examId: string
  toast: any,
  handleSectionRefresh: Function,
  updateCandidateVisible: Function,
}

export default function ListCandidates({
  handleAddCandidateClick, examData, examId, toast, handleSectionRefresh, updateCandidateVisible,
}: IListCandidatesProps) {
  const [previousButtonStatus, setPreviousButtonStatus] = useState<'LOADING' | 'LOADED' | 'NOT_LOADING'>('NOT_LOADING');
  const [nextButtonStatus, setNextButtonStatus] = useState<'LOADING' | 'LOADED' | 'NOT_LOADING'>('NOT_LOADING');
  const [searchButtonStatus, setSearchButtonStatus] = useState<'LOADING' | 'LOADED' | 'NOT_LOADING'>('NOT_LOADING');
  // const [setListCandidates] = useState<any>(null);
  const candidatesData = useAppSelector((state) => state.exams.candidateData);
  const totalCandidates = useAppSelector((state) => state.exams.candidateTotal);
  const candidateCurrentIndex = useAppSelector((state) => state.exams.candidateCurrentIndex);
  const candidatePageNo = useAppSelector((state) => state.exams.candidatePageNo);
  const isSearch = useAppSelector((state) => state.exams.candidateIsSearch);
  // const searchValue = useAppSelector((state) => state.exams.candidateSearchValue);
  const [startingCandidate] = useState(0);
  const [dialogVisible, setDialogVisible] = useState<boolean>(false);
  const [displayProgress, setDisplayProgress] = useState<boolean>(false);
  // const [setCandidatesSearch] = useState<any>('');
  // const [ candidateFilter ] = useState<any>('');
  // const [setFilterResult] = useState<any>('');
  const [filterQuery, setFilterQuery] = useState<string>();
  const emailRegex = /^[a-zA-Z]+[a-zA-Z0-9+_.-]+@[a-zA-Z.]+$/
  const dispatch = useAppDispatch();
  // const [inviteParticular, setInviteParticular] = useState('');
  let candidateLen: number = 0;
  if (candidatesData != null) {
    candidateLen = candidatesData.length;
  }
  // const candidateFilterOptions = [
  //   { name: 'Percentage greater than..', code: 'PCT' },
  //   { name: 'Percentage lesser than..', code: 'PLT' },
  //   { name: 'Passed Candidates', code: 'PC' },
  // ];

  const invite = [
    { label: 'All Candidates', value: 'ALL' },
    { label: 'Particular Candidate', value: 'PC' },
  ];

  const defaultValues = {
    selectInvite: '',
    email: '',
    searchValue: '',
  };

  const refreshCandidatesData = () => {
    dispatch(setCandidateData(null));
    getCandidates(examId, 0)
      .then((response: AxiosResponse) => {
        dispatch(setCandidateData(response.data.candidates as IListCandidate[]));
        handleSectionRefresh(true);
      })
      .catch(() => {
      });
  }

  const {
    register, handleSubmit, formState: { errors }, control, clearErrors, resetField,
  } = useForm({ defaultValues });

  // @ts-ignore
  const selectInvite = useWatch({
    control,
    name: 'invite',
  })
  const errorResponse = () => {
    setTimeout(() => {
      toast(
        'error',
        'Something went wrong',
        'Please try again later',
      )
    });
  }
  const onSubmit: SubmitHandler<any> = (data: any) => {
    setDialogVisible(false);
    data.examId = examId;
    handleSectionRefresh(false);

    sendInviteUsingEmailId(data)
      .then((response: AxiosResponse) => {
        if (response.data !== 'NO CANDIDATE FOUND') {
          toast(
            'info',
            'Sending Email',
            'Email is being sent',
          )
          setTimeout(() => {
            refreshCandidatesData()
            toast(
              'success',
              'Success',
              'Invite has been sent successfully',
            )
          }, 3000);
        } else {
          handleSectionRefresh(true)
          errorResponse();
        }
      })
  }

  const allEmail = async (ExamId: string) => {
    setDisplayProgress(true);
    handleSectionRefresh(false);
    await sendInvite(ExamId).then((response: AxiosResponse) => {
      if (response.data !== 'NO CANDIDATE FOUND') {
        toast(
          'info',
          'Sending Email',
          'Email is being sent',
        )
        setTimeout(() => {
          refreshCandidatesData()
          toast(
            'success',
            'Success',
            'Invite has been sent successfully',
          )
        }, 3000);
      } else {
        handleSectionRefresh(true)
        errorResponse();
      }
    });
    setDisplayProgress(false);
  }

  const onSendInvite = (e: { value: any }) => {
    if (e.value === 'PC') {
      setDialogVisible(true);
    } else {
      allEmail(examId);
    }
  }

  const dialogClose = () => {
    setDialogVisible(false);
    resetField('email');
    clearErrors();
  }

  // const particularEmail = () => {
  //   setDialogVisible(false);
  // }

  const handleNextPageClick = () => {
    setNextButtonStatus('LOADING');
    if (isSearch === false) {
      getCandidates(examId, candidatePageNo + 1)
        .then((response: AxiosResponse) => {
          dispatch(setCandidatePageNo(candidatePageNo + 1));
          dispatch(setCandidateCurrentIndex(candidateCurrentIndex + 5));
          dispatch(setCandidateData(response.data.candidates as IListCandidate[]));
          dispatch(setCandidateTotal(response.data.len));
          setNextButtonStatus('NOT_LOADING');
        })
        .catch(() => {
        })
    } else {
      searchCandidateApi(examId, candidatePageNo + 1, filterQuery || '')
        .then((response: AxiosResponse) => {
          dispatch(setCandidateData(response.data.candidates as IListCandidate[]));
          dispatch(setCandidateTotal(response.data.len));
          dispatch(setCandidateCurrentIndex(candidateCurrentIndex + 5));
          dispatch(setCandidatePageNo(candidatePageNo + 1));
          setNextButtonStatus('NOT_LOADING');
        })
        .catch(() => {
        })
    }
  }

  const handlePreviousPageClick = () => {
    setPreviousButtonStatus('LOADING');
    if (isSearch === false) {
      getCandidates(examId, candidatePageNo - 1)
        .then((response: AxiosResponse) => {
          dispatch(setCandidatePageNo(candidatePageNo - 1));
          dispatch(setCandidateCurrentIndex(candidateCurrentIndex - 5));
          dispatch(setCandidateData(response.data.candidates as IListCandidate[]));
          dispatch(setCandidateTotal(response.data.len));
          setPreviousButtonStatus('NOT_LOADING');
        })
        .catch(() => {
        })
    } else {
      searchCandidateApi(examId, candidatePageNo - 1, filterQuery || '')
        .then((response: AxiosResponse) => {
          dispatch(setCandidateData(response.data.candidates as IListCandidate[]));
          dispatch(setCandidateTotal(response.data.len));
          dispatch(setCandidateCurrentIndex(candidateCurrentIndex - 5));
          dispatch(setCandidatePageNo(candidatePageNo - 1));
          setPreviousButtonStatus('NOT_LOADING');
        })
        .catch(() => {
        })
    }
  }

  const onFilterApply = () => {
    setSearchButtonStatus('LOADING');
    dispatch(setCandidateIsSearch(true));
    dispatch(setCandidatePageNo(0));
    dispatch(setCollegeSearchValue(filterQuery || ''));
    searchCandidateApi(examId, 0, filterQuery || '')
      .then((response: AxiosResponse) => {
        dispatch(setCandidateCurrentIndex(0));
        dispatch(setCandidateData(response.data.candidates as IListCandidate[]));
        dispatch(setCandidateTotal(response.data.len));
        dispatch(setCandidatePageNo(0));
        setSearchButtonStatus('NOT_LOADING');
      })
      .catch(() => {
      });
  };

  return (
    <div className="list-candidates-container w-full">
      <div className="header w-full flex align-items-center justify-content-between">
        <div className="title flex align-items-center h-full">
          Candidates
          {
                (examData && examData.candidateStatus === 'SUCCESS')
                && (
                <div className="upload-status success h-full flex align-items-center">
                  <i className="pi pi-info-circle info-icon" />
                </div>
                )
            }
          {
                (examData && examData.candidateStatus === 'ERROR')
                && (
                <div className="upload-status error h-full flex align-items-center">
                  <i className="pi pi-info-circle info-icon" />
                </div>
                )
            }
          {
                (examData && examData.candidateStatus === 'STARTED')
                && (
                <div className="upload-status started h-full flex align-items-center">
                  <i className="pi pi-info-circle info-icon" />
                </div>
                )
            }
        </div>
        <div className="filter-container flex">
          <div className="filter-fields">
            <div className="p-inputgroup">
              <span className="search p-input-icon-left">
                <i className="pi pi-search" />
                <InputText
                  onInput={(e: any) => setFilterQuery(e.target.value)}
                  type="string"
                  placeholder="Search candidate"
                />
              </span>
            </div>
          </div>
          <div className="search-drive mr-2">
            <SpinCheckButton
              styleClass="search-drive-button font-medium"
              loadingStatus={searchButtonStatus}
              onClick={() => onFilterApply()}
              label="Search"
            />
          </div>
          {
                (examData.statusId !== 3)
                && (
                <>
                  {/* <div> */}
                  {/*   <SpinCheckButton styleClass="upload-button-item" label="Download Template" loadingStatus={addButtonStatus} onClick={handleTemplateDownload} /> */}
                  {/* </div> */}
                  <div className="send-invite list-candidate-header-btn">
                    <span className="align-items-center">
                      <Dropdown
                        {...register('selectInvite')}
                        value={selectInvite}
                        optionLabel="label"
                        className="drowdown-outline"
                        options={invite}
                        placeholder="Invite"
                        onChange={onSendInvite}
                        appendTo="self"
                      />
                    </span>
                  </div>
                  <div className="add-candidates list-candidate-header-btn-add">
                    <ActionButton type="primary" label="Add Candidates" clickHandler={handleAddCandidateClick} />
                  </div>
                </>
                )
            }
        </div>
      </div>

      <Dialog
        header="Enter Candidate"
        className="dialog-box"
        visible={dialogVisible}
        style={{ width: '30%', height: '28%', borderRadius: '8px' }}
        onHide={dialogClose}
      >
        <form className="dialog-align candidate-invite-dialog" onSubmit={handleSubmit(onSubmit)}>
          <div className="field align-items-center justify-content-between">
            <div className="field split email">
              <div className="label email-label">
                Email
              </div>
              <div className="email-field-input">
                <InputText
                  {...register('email', {
                    required: 'Email is required.',
                    pattern: { value: emailRegex, message: 'Email is invalid' },
                  })}
                  style={{ width: '100%' }}
                  type="email"
                  className="input-text"
                  placeholder="Enter email here"
                />
                {
                    errors.email?.type === 'required' ? (
                      <small className="error-label p-error">{errors.email.message}</small>) : ''
                  }
                {
                    errors.email?.type === 'pattern' ? (
                      <small className="error-label p-error">{errors.email.message}</small>) : ''
                  }
              </div>
            </div>
            <div className="add-button-container invite-footer-button w-full flex align-items-center justify-content-end">
              <div className="sendParticular--button">
                <Button
                  label="Cancel"
                  className="p-button-outlined mr-3"
                  style={{ color: '#0369A1' }}
                  onClick={dialogClose}
                />
                <Button
                  label="Send Invite"
                  style={{ background: '#0369A1', height: '100%', width: 130 }}
                  onClick={handleSubmit(onSubmit)}
                />
              </div>
            </div>
          </div>
        </form>
      </Dialog>

      <div className="list-candidates-container w-full">
        <div className="list-candidates-table w-full">
          <div className="row-header row w-full">
            <div className="edit cell" />
            <div className="name cell">
              NAME
            </div>
            <div className="reg-no cell">
              REG. NUMBER
            </div>
            <div className="dob cell">
              DATE OF BIRTH
            </div>
            <div className="gender cell">
              GENDER
            </div>
            <div className="invite cell">
              INVITE
            </div>
          </div>
          {
            (displayProgress) && (
            <div className="progressBar">
              <ProgressBar
                mode="indeterminate"
                style={{
                  height: '6px', width: '50%', display: 'block', margin: '0px auto',
                }}
              />
            </div>
            )
          }

          {(candidatesData && !displayProgress
                && candidatesData?.map((candidate: IListCandidate, index: number) => {
                  if (index >= startingCandidate && index < startingCandidate + 5) {
                    return (
                      <ListCandidatessRow
                        updateCandidateVisible={updateCandidateVisible}
                        key={candidate.id}
                        candidate={candidate}
                      />
                    )
                  }
                  return <div key={candidate.id} />
                })
            )}

        </div>
        <div className="footer w-full flex align-items-center justify-content-between">
          <div className="result-details">
            {candidatesData != null
                    && `Showing ${(candidatesData!.length === 0) ? 0 : (candidateCurrentIndex + 1)} to ${(totalCandidates < candidateCurrentIndex + 5) ? (totalCandidates) : (candidateCurrentIndex + 5)} of ${totalCandidates} results`}
          </div>
          <div className="pagination-buttons flex">
            {(candidatePageNo !== 0)
                    && (
                    <div className="previous-button">
                      <SpinCheckButton
                        styleClass="mr-3"
                        label="Previous"
                        loadingStatus={previousButtonStatus}
                        onClick={handlePreviousPageClick}
                        // icons="pi pi-arrow-left"
                      />
                    </div>
                    )}
            {((totalCandidates - candidateLen) !== candidateCurrentIndex)
                    && (
                    <div className="next-button">
                      <SpinCheckButton
                        styleClass=""
                        label="Next"
                        loadingStatus={nextButtonStatus}
                        onClick={handleNextPageClick}
                        // icons="pi pi-arrow-right"
                      />
                    </div>
                    )}
          </div>
        </div>
      </div>
      <div className="list-candidate-line" />
    </div>
  );
}
