const env = {
  CLIENT_ID: '3ln7blh39jbt4f51qijqmks6kt',
  USER_POOL_ID: 'us-west-2_FYWTTAc6L',
  REGION: 'us-west-2',
  UI_DISTRIBUTION_DOMAIN: 'http://localhost:3000',
  // API_URL: <string>'http://localhost:8080/dev',
  // API_URL: <string>'https://79qpk5sw67.execute-api.us-east-1.amazonaws.com/dev',
  DOMAIN: <string>'presidio-hire-prod.auth.us-west-2.amazoncognito.com',
  API_URL: <string>'https://9olgw8wty5.execute-api.us-west-2.amazonaws.com/prod/api',
  EXAM_API_URL: <string>'https://9olgw8wty5.execute-api.us-west-2.amazonaws.com/prod/examapis',
  S3_URL: <string>'https://presidio-hire-exam-candidates-questions-bulk-prod.s3.us-west-2.amazonaws.com/',
  CANDIDATE_LOGIN_URL: <string>'/exams/candidate/verify',
};

export default env;
