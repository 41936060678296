import React from 'react';
import './ViewExamHeaderSkeleton.css';
import { Skeleton } from 'primereact/skeleton';

type ViewExamHeaderProp = {
  goBackClick: any,
  goBackEnter: any,
}

export default function ViewExamHeaderSkeleton({ goBackClick, goBackEnter }: ViewExamHeaderProp) {
  return (
    <div className="view-exam-header-skeleton header flex align-items-center justify-content-between">
      <div className="title-container flex flex-column align-items-start justify-content-center">
        <div role="button" tabIndex={0} onClick={goBackClick} onKeyDown={goBackEnter} className="back-navigation flex align-items-center justify-content-start cursor-pointer">
          <i className="pi pi-angle-left font-semibold" />
          <div className="label font-medium">
            Back
          </div>
        </div>

        <div className="title">
          <Skeleton className="h-full" />
        </div>
        <div className="sub-title">
          <Skeleton />
        </div>
      </div>
      <div className="edit-exam">
        <Skeleton className="h-full" />
      </div>
    </div>

  )
}
