import React from 'react';
import './ViewExamHeaderCard.css';
import { ViewExamHeaderCardLine } from '../index';

type TimeType = {
  startTime: string,
  endTime: string,
}

type ViewExamHeaderCardProp = {
  title: string;
  content: TimeType | string | number | null;
  subContent?: string | null;
}

export default function ViewExamHeaderCard({ title, content, subContent }: ViewExamHeaderCardProp) {
  return (
    <div className="view-exam-header-card shadow-1 flex flex-column align-items-start justify-content-between">
      <div className="title">
        {title.toUpperCase()}
      </div>
      <div className="content flex align-items-center justify-content-start">
        {
          content == null && <ViewExamHeaderCardLine />
        }
        {
          (content != null && title === 'TIME')
          && (
            <>
              {(content as TimeType).startTime}
              <ViewExamHeaderCardLine />
              {(content as TimeType).endTime}
            </>
          )
        }
        {
          (content != null && title === 'DATE') && content
        }
        {
          (content != null && (title === 'NUMBER OF CANDIDATES' || title === 'NUMBER OF QUESTIONS'))
          && (
            <span>
              {content}
              <span className="sub-content">
                {subContent}
              </span>
            </span>
          )
        }
        {
          (content != null && (title === 'AVERAGE PERCENTAGE'))
          && (
            <span>
              {content as number}
              %
            </span>
          )
        }
      </div>
    </div>
  )
}
