import React, { useEffect, useRef, useState } from 'react';
import './EditExams.css';
import { useNavigate, useParams } from 'react-router';
import { AxiosResponse } from 'axios';
import {
  Controller, SubmitHandler, useForm, useWatch,
} from 'react-hook-form';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { MultiSelect } from 'primereact/multiselect';
import { getMonth, getYear } from 'date-fns';
import { Toast } from 'primereact/toast';
import { IListCampusDrives } from '../../../../models/IListCampusDrives';
import { getAllCampusDrive } from '../../../../services/campusDriveService';
import { TimeSelect, FieldSkeleton, SpinCheckButton } from '../../../../components';
import { getUserByRole } from '../../../../services/userService';
import { IUser } from '../../../../models/IUser';
import { editExam, getEvaluators, getExam } from '../../../../services/examService';
import RoutesConstants from '../../../../constants/routesConstants';
import { IListExam } from '../../../../models/IListExam';
import { extractExamDate } from '../../../../hooks/examHook';

export default function EditExams() {
  const navigate = useNavigate();
  const { examId }: any = useParams();
  const routesConstants = new RoutesConstants();
  const [examData, setExamData] = useState<IListExam | null>(null);
  const [campusDrives, setCampusDrives] = useState<IListCampusDrives[] | null>(null);
  const [evaluators, setEvaluators] = useState<IUser[] | null>(null);
  const [superEvaluators, setSuperEvaluators] = useState<IUser[] | null>(null);
  const [startTimeError, setStartTimeError] = useState<boolean>(false);
  const [startTimeErrorMessage, setStartTimeErrorMessage] = useState<string>('');
  const [endTimeError, setEndTimeError] = useState<boolean>(false);
  const [endTimeErrorMessage, setEndTimeErrorMessage] = useState<string>('');
  const [loadingStatus, setLoadingStatus] = useState<'LOADED' | 'NOT_LOADING' | 'LOADING'>('NOT_LOADING');
  const [timeRemainders] = useState<any[]>(['30', '25', '20', '15', '10', '5']);
  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);
  const [selectedCampusDriveId, setSelectedCampusDriveId] = useState<string | null>(null);
  const toastRef = useRef<any>(null);
  let selectedEvaluatorsId: string | any[] = [];
  let selectedSuperEvaluatorsId: string | any[] = [];
  const defaultValues = {
    examName: '',
    campusDrive: '',
    examDate: '',
    startTime: '',
    endTime: '',
    evaluator: [],
    buffer: '',
    timeAlerts: [],
    superEvaluator: [],
  };

  const {
    register, handleSubmit, control, setValue, formState: { errors },
  } = useForm({ defaultValues });

  const examName = useWatch({
    control,
    name: 'examName',
  });
  const campusDrive = useWatch({
    control,
    name: 'campusDrive',
  });
  const evaluator = useWatch({
    control,
    name: 'evaluator',
  });
  const superEvaluator = useWatch({
    control,
    name: 'superEvaluator',
  });
  const timeAlerts = useWatch({
    control,
    name: 'timeAlerts',
  });
  const examDate = useWatch({
    control,
    name: 'examDate',
  });
  const getTime = (startTime: string, endTime: string) => {
    const startTimeArr = startTime.split(':');
    const endTimeArr = endTime.split(':');
    const start = startTimeArr[0].split('T');
    const end = endTimeArr[0].split('T');
    const startHour = parseInt(start[1], 10);
    const endHour = parseInt(end[1], 10);
    let shour = startHour.toString();
    let ehour = endHour.toString();
    shour = startHour.toString().padStart(2, '0');
    ehour = endHour.toString().padStart(2, '0');
    const startMin = startTimeArr[1];
    const endMin = endTimeArr[1];
    return {
      startTime: `${shour}:${startMin}`,
      endTime: `${ehour}:${endMin}`,
    };
  }

  useEffect(() => {
    // setTimeRemainder(['30', '25', '20', '15', '10', '5']);
    /*
     * Getting exam data from database
     */
    getExam(examId)
      .then((response: AxiosResponse) => {
        // Setting exam data values for each field
        const endTime = getTime(response.data.exams.startTime, response.data.exams.endTime)
        response.data.exams.startTime = endTime.startTime;
        response.data.exams.endTime = endTime.endTime;
        setExamData(response.data.exams);
        setValue('examName', response.data.exams.name);
        setValue('startTime', endTime.startTime);
        setValue('endTime', endTime.endTime);
        setValue('campusDrive', response.data.exams.CampusDrive.name);
        const dobFormatted = (response.data.exams.examDate).substr(0, 10)
        setValue('examDate', dobFormatted);
        setSelectedCampusDriveId(response.data.exams.CampusDrive.id)
        const timeAlertsList = response.data.exams.timeAlerts.split(',');
        console.log(timeRemainders);
        console.log(timeAlertsList);
        console.log(timeRemainders.filter((a:any) => timeAlertsList?.includes(a)));
        // @ts-ignore
        setValue('timeAlerts', timeRemainders.filter((a:any) => timeAlertsList?.includes(a)));
      })
      .catch((e) => {
        toastRef.current.show({
          severity: 'error',
          summary: 'Something went wrong',
          detail: e.response.data.message,
        });
      });
    /*
     * Getting exam evaluators and superEvaluators from database and storing id in an
     */
    getEvaluators(examId)
      .then((response: AxiosResponse) => {
        selectedEvaluatorsId = (response.data.EVL).map((a:any) => a.id);
        selectedSuperEvaluatorsId = (response.data.SEVL).map((a:any) => a.id);
      })
      .catch((e) => {
        toastRef.current.show({
          severity: 'error',
          summary: 'Something went wrong',
          detail: e.response.data.message,
        });
      });
    /*
     * Getting all the campus drives from the database
     */
    getAllCampusDrive()
      .then((response: AxiosResponse) => {
        setCampusDrives(response.data.campusDrives);
      })
      .catch((e) => {
        toastRef.current.show({
          severity: 'error',
          summary: 'Something went wrong',
          detail: e.response.data.message,
        });
      });

    /*
     * Getting all the evaluators from the database
     */
    getUserByRole('EVL').then((response: AxiosResponse) => {
      setEvaluators(response.data.user);
      // Setting already existing evaluators of an exam
      setValue('evaluator', response.data.user.filter((a:any) => selectedEvaluatorsId.includes(a.id)));
    })
      .catch((e) => {
        toastRef.current.show({
          severity: 'error',
          summary: 'Something went wrong',
          detail: e.response.data.message,
        });
      });

    /*
     * Getting all the super evaluators from the database
     */
    getUserByRole('SEVL').then((response: AxiosResponse) => {
      setSuperEvaluators(response.data.user);
      // Setting already existing super evaluators of an exam
      setValue('superEvaluator', response.data.user.filter((a:any) => selectedSuperEvaluatorsId.includes(a.id)))
      console.log(response.data.user.filter((a:any) => selectedSuperEvaluatorsId.includes(a.id)));
    })
      .catch((e) => {
        toastRef.current.show({
          severity: 'error',
          summary: 'Something went wrong',
          detail: e.response.data.message,
        });
      });
  }, []);

  /*
   *  Setting campus drive of the exam from exam data
  */
  useEffect(() => {
    if (campusDrives != null) {
      // @ts-ignore
      setValue('campusDrive', campusDrives.find((c) => c.campusDriveId === selectedCampusDriveId))
    }
  }, [campusDrives]);

  const goBackClick = () => {
    navigate(-1);
  };

  const goBackEnter = () => {
    // @ts-ignore
    if (event.key === 'Enter') {
      goBackClick();
    }
  };

  const onCampusDriveChange = (e: any) => {
    const { value } = e;
    // register('campusDrive').onChange(e)
    setStartDate(new Date(value.startDate));
    setEndDate(new Date(value.endDate));
  }

  const onStartTimeChange = (time: string, error: boolean, message: string = '') => {
    if (!error) {
      setValue('startTime', time);
    } else {
      setStartTimeError(error);
      setStartTimeErrorMessage(message);
    }
  }

  const onEndTimeChange = (time: string, error: boolean, message: string = '') => {
    if (!error) {
      setValue('endTime', time);
    } else {
      setEndTimeError(error);
      setEndTimeErrorMessage(message);
    }
  }

  // function formatDateString(dateStr: string): string | undefined {
  //   const dArr = dateStr.split('-');
  //   return `${dArr[2]}-${dArr[1]}-${dArr[0]}`;
  // }
  const errorToast = () => {
    toastRef.current.show({
      severity: 'error',
      summary: 'Something went wrong',
      detail: 'Please try again later!',
    });
  }

  const sucessToast = () => {
    toastRef.current.show({
      severity: 'success',
      summary: 'Successfull',
      detail: 'Exam updated successfully',
    });
  }

  const onSubmit: SubmitHandler<any> = (data: any) => {
    setLoadingStatus('LOADING');
    const regEx = /(\d\d:\d\d)/;
    let error:boolean = false;
    data.timeAlerts = data.timeAlerts.join(',');
    if (!regEx.test(data.startTime)) {
      error = true;
      setStartTimeError(true);
      setStartTimeErrorMessage('Start Time is required.');
    }

    if (!regEx.test(data.endTime)) {
      error = true;
      setEndTimeError(true);
      setEndTimeErrorMessage('End Time is required.');
    }

    if (!error) {
      if (data.examDate !== examDate) {
        data.examDate = extractExamDate(data.examDate);
      }
      editExam(data, examId)
        .then(() => {
          setLoadingStatus('LOADED');
          setTimeout(() => {
            navigate(routesConstants.EXAMS);
          }, 500);
          sucessToast();
        })
        .catch(() => {
          errorToast();
        });
    }
  };

  return (
    <div className="edit-exam-view h-full flex flex-column align-items-center justify-content-start">
      <div className="header w-full flex flex-column align-items-start justify-content-center">
        <div role="button" tabIndex={0} onClick={goBackClick} onKeyDown={goBackEnter} className="back-navigation flex align-items-center justify-content-start cursor-pointer">
          <i className="pi pi-angle-left font-semibold" />
          <div className="label font-medium">
            Back
          </div>
        </div>

        <div className="title">
          Edit exam
        </div>
      </div>

      <div className="edit-exam-form w-full">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="field exam-name">
            <div className="label">
              Name
            </div>

            <InputText {...register('examName', { required: 'Exam name is required.' })} className="input" type="text" placeholder="Enter a exam name" value={examName} autoComplete="off" />
            {
                  errors.examName?.type === 'required' ? (<small className="error-label p-error">{errors.examName.message}</small>) : ''
                }
          </div>
          {
                  (campusDrives == null)
                  && <FieldSkeleton label="Campus Drive" />
              }
          {
                  (campusDrives != null)
                  && (
                  <div className="field campus-drive">
                    <div className="label">
                      Campus Drive
                    </div>
                    <Dropdown {...register('campusDrive', { required: 'Campus Drive is required.', onChange: onCampusDriveChange })} value={campusDrive} className="input" optionLabel="campusDriveName" options={campusDrives} placeholder="Select Campus Drive" />
                    {
                          errors.campusDrive?.type === 'required' ? (<small className="error-label p-error">{errors.campusDrive.message}</small>) : ''
                        }
                  </div>
                  )
              }

          <div className="field exam-date">
            <div className="label">
              Date
            </div>

            <Controller
              control={control}
              name="examDate"
              rules={{
                required: 'Exam date is required.',
              }}
              render={({ field: { onChange, ref } }) => (
                <Calendar
                  ref={ref}
                  onChange={(ev) => onChange(ev)}
                  className="input"
                  minDate={(startDate != null) ? startDate : new Date()}
                  maxDate={(endDate != null) ? endDate : null}
                  viewDate={(startDate != null) ? (new Date(getYear(startDate), getMonth(startDate))) : undefined}
                  dateFormat="dd-mm-yy"
                  onMonthChange={() => {}}
                  placeholder="Choose a exam date"
                  showButtonBar
                  readOnlyInput
                  value={new Date(examDate)}
                />
              )}
            />
            {
                  errors.examDate ? (<small className="error-label p-error">{errors.examDate?.message}</small>) : ''
                }
          </div>

          <div className="field time flex align-items-center justify-content-between">
            <TimeSelect callback={onStartTimeChange} label="Start Time" timeValue={examData != null ? examData.startTime : ''} />
            <TimeSelect callback={onEndTimeChange} label="End Time" timeValue={examData != null ? examData.endTime : ''} />
          </div>
          {
                startTimeError ? (
                  <small className="error-label p-error">
                    {startTimeErrorMessage}
                    <br />
                  </small>
                ) : ''
              }
          {
                endTimeError ? (<small className="error-label p-error">{endTimeErrorMessage}</small>) : ''
              }
          <div className="field campus-drive">
            <div className="label">
              Last minute remainder to candidate
            </div>
            <MultiSelect {...register('timeAlerts', { required: 'Time to remain candidates is required.' })} value={timeAlerts} className="input" optionLabel="" options={timeRemainders} placeholder="Select Time to remind candidates" />
            {
              // @ts-ignore
              errors.time ? (<small className="error-label p-error">{errors.time.message}</small>) : ''
            }
          </div>

          {
                  (!superEvaluators)
                  && <FieldSkeleton label="Super Evaluators" />
              }
          {
                  (superEvaluators)
                  && (
                  <div className="field campus-drive">
                    <div className="label">
                      Super Evaluators
                    </div>
                    <MultiSelect {...register('superEvaluator', { required: 'Super Evaluators are required.' })} value={superEvaluator} className="input" optionLabel="name" options={superEvaluators} placeholder="Select super evaluators" />
                    {
                          // @ts-ignore
                          errors.superEvaluator ? (<small className="error-label p-error">{errors.superEvaluator.message}</small>) : ''
                        }
                  </div>
                  )
              }

          {
                  (!evaluators)
                  && <FieldSkeleton label="Evaluators" />
              }
          {
                  (evaluators)
                  && (
                  <div className="field campus-drive">
                    <div className="label">
                      Evaluators
                    </div>
                    <MultiSelect {...register('evaluator', { required: 'Evaluators are required.' })} value={evaluator} className="input" optionLabel="name" options={evaluators} placeholder="Select evaluators" />
                    {
                          // @ts-ignore
                          errors.evaluator?.type === 'required' ? (<small className="error-label p-error">{errors.evaluator.message}</small>) : ''
                        }
                  </div>
                  )
              }

          <div className="submit-button-container w-full flex align-items-center justify-content-end">
            <SpinCheckButton styleClass={`submit-button ${loadingStatus === 'LOADING' || loadingStatus === 'LOADED' ? 'icon-present' : ''}`} label="Schedule Exam" loadingStatus={loadingStatus} onClick={null} />
          </div>
        </form>
      </div>
      <Toast ref={toastRef} />
    </div>
  );
}
