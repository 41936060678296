import env from '../environment/env';

export default class AwsConstants {
  public REGION:string = <string>env.REGION;

  public CLIENT_ID:string = <string>env.CLIENT_ID;

  public USER_POOL_ID:string = <string>env.USER_POOL_ID;

  public DOMAIN:string = <string>env.DOMAIN;

  public SUB_DOMAIN:string = 'oauth2/authorize';

  public IDENTITY_PROVIDER:string = 'Azure';

  public REDIRECT_URI: string = <string>env.UI_DISTRIBUTION_DOMAIN;

  public RESPONSE_TYPE:string = 'CODE';

  public SCOPE:string = 'aws.cognito.signin.user.admin+email+openid+profile';

  public PROVIDER: string = 'Azure';
}
