import axios from 'axios';
import ApiContants from '../constants/apiContants';
import { ICreateCampusDrive } from '../models/ICreateCampusDrive';
import { ICampusDrive } from '../models/ICampusDrive';
import { ICreateCollege } from '../models/ICreateCollege';

const apiConstants = new ApiContants();

export function padZero(data: string, paddingSize: number) {
  return data.padStart(paddingSize, '0');
}

export function getAllUniversity() {
  return axios.get(apiConstants.COLLEGE);
}
export function getPaginationUniversity(pageno: number) {
  return axios.get(apiConstants.COLLEGE, {
    params: {
      sortBy: 'updatedAt,DESC',
      pageNo: pageno,
    },
  });
}

export function getUniversity(collegeId: string) {
  return axios.get(`${apiConstants.COLLEGE}/${collegeId}`);
}

export function deleteUniversity(collegeId: string) {
  return axios.delete(`${apiConstants.COLLEGE}/${collegeId}`);
}

export function addUniversity(addCollegeData: ICreateCollege) {
  return axios.post(apiConstants.COLLEGE, addCollegeData);
}

export function updateUniversity(updateCollegeData: ICreateCollege, collegeId: string) {
  return axios.put(`${apiConstants.COLLEGE}/${collegeId}`, updateCollegeData);
}
export function searchCollegeApi(data: any) {
  return axios.post(`${apiConstants.COLLEGE}${apiConstants.SEARCH}`, data)
}
export function createCampusDrive(campusDriveData: ICampusDrive) {
  const { driveName, university, driveDuration } = campusDriveData;
  let campusDrive: ICreateCampusDrive | null = null;
  if (driveDuration) {
    campusDrive = {
      name: driveName,
      collegeId: university.universityId,
      startDate: `${driveDuration[0].getFullYear()}-${padZero((driveDuration[0].getMonth() + 1).toString(), 2)}-${padZero((driveDuration[0].getDate()).toString(), 2)}`,
      endDate: `${driveDuration[1].getFullYear()}-${padZero((driveDuration[1].getMonth() + 1).toString(), 2)}-${padZero((driveDuration[1].getDate()).toString(), 2)}`,
    };
  }
  return axios.post(apiConstants.CAMPUS_DRIVE, campusDrive);
}

export function updateCampusDrive(campusDriveData: ICampusDrive) {
  const { driveName, university, driveDuration } = campusDriveData;
  let campusDrive: ICreateCampusDrive | null = null;
  if (driveDuration) {
    campusDrive = {
      name: driveName,
      collegeId: university.universityId,
      startDate: `${driveDuration[0].getFullYear()}-${padZero((driveDuration[0].getMonth() + 1).toString(), 2)}-${padZero((driveDuration[0].getDate()).toString(), 2)}`,
      endDate: `${driveDuration[1].getFullYear()}-${padZero((driveDuration[1].getMonth() + 1).toString(), 2)}-${padZero((driveDuration[1].getDate()).toString(), 2)}`,
    };
  }
  return axios.put(`${apiConstants.CAMPUS_DRIVE}/${campusDriveData.id}`, campusDrive);
}

export function getAllCampusDrive() {
  return axios.get(apiConstants.CAMPUS_DRIVE, {
    params: {
      sortBy: 'updatedAt,DESC',
    },
  });
}

export function getPagenationCampusDrive(data:any) {
  return axios.get(apiConstants.CAMPUS_DRIVE, {
    params: {
      ...data,
    },
  });
}

export function getCampusDriveDistinctYears() {
  return axios.get(apiConstants.CAMPUS_DRIVE, {
    params: {
      sortBy: 'updatedAt,DESC',
      distinctYears: true,
    },
  });
}
export function getCampusDrive(campusDriveId: string) {
  return axios.get(`${apiConstants.CAMPUS_DRIVE}/${campusDriveId}`);
}

export function getCampusDriveCount(campusDriveId: string) {
  return axios.get(`${apiConstants.CAMPUS_DRIVE}${apiConstants.CAMPUS_DRIVE_COUNT}/${campusDriveId}`);
}
