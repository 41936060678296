import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import IListQuestion from '../models/IListQuestion';
import IListCandidate from '../models/IListCandidate';
import { IUser } from '../models/IUser';

export interface ExamState {
  questionsData: IListQuestion[] | null;
  candidateQuestionData:string[] | null;
  candidateData: IListCandidate[] | null;
  evaluatorData: IUser[] | null;
  codingLanguage: string;
  sessionData : any;
  sentStatus :any;
  examStatus: string;
  malpracticeCount:number;
  examStartingNo: number,
  currentDataIndex: number,
  totalCount: number,
  isSearch: boolean,
  searchValue: string,
  searchPageNo: number,
  examStatusId: number,
  candidateTotal: number,
  candidatePageNo: number,
  candidateCurrentIndex: number,
  candidateIsSearch: boolean,
  candidateSearchValue: string,
  selectedQuestion: any[] | null,
  searchType: number,
}

const initialState: ExamState = {
  questionsData: null,
  candidateData: null,
  candidateQuestionData: null,
  codingLanguage: '',
  evaluatorData: null,
  sentStatus: null,
  examStatus: 'upcoming',
  malpracticeCount: 0,
  examStartingNo: 0,
  currentDataIndex: 0,
  totalCount: 0,
  isSearch: false,
  searchValue: '',
  searchPageNo: 0,
  examStatusId: 0,
  candidateTotal: 0,
  candidatePageNo: 0,
  candidateCurrentIndex: 0,
  candidateIsSearch: false,
  candidateSearchValue: '',
  sessionData: {},
  selectedQuestion: [],
  searchType: 0,
};

export const examStore = createSlice({
  name: 'exams',
  initialState,
  reducers: {
    setQuestionData: (state, action: PayloadAction<IListQuestion[] | null>) => {
      state.questionsData = action.payload
    },
    setCandidateData: (state, action: PayloadAction<IListCandidate[] | null>) => {
      state.candidateData = action.payload
    },
    setCandidateQuestionData: (state, action: PayloadAction<string[] | null>) => {
      state.candidateQuestionData = action.payload
    },
    setEvaluatorData: (state, action: PayloadAction<IUser[] | null>) => {
      state.evaluatorData = action.payload
    },
    setSessionData: (state, action: PayloadAction<{}>) => {
      state.sessionData = action.payload
    },
    setSentStatus: (state, action: PayloadAction<any>) => {
      state.evaluatorData = action.payload
    },
    setExamStatus: (state, action:PayloadAction<string>) => {
      state.examStatus = action.payload
    },
    setMalpracticeCount: (state, action:PayloadAction<number>) => {
      state.malpracticeCount = action.payload
    },
    setCurrentDataIndex: (state, action: PayloadAction<number>) => {
      state.currentDataIndex = action.payload
    },
    setTotalCountData: (state, action: PayloadAction<number>) => {
      state.totalCount = action.payload
    },
    setIsSearchData: (state, action: PayloadAction<boolean>) => {
      state.isSearch = action.payload
    },
    setSearchValueData: (state, action: PayloadAction<string>) => {
      state.searchValue = action.payload
    },
    setCodingLanguage: (state, action: PayloadAction<string>) => {
      state.codingLanguage = action.payload
    },
    setSearchPageNoData: (state, action: PayloadAction<number>) => {
      state.searchPageNo = action.payload
    },
    setExamStartingNo: (state, action:PayloadAction<number>) => {
      state.examStartingNo = action.payload
    },
    setExamStatusId: (state, action:PayloadAction<number>) => {
      state.examStatusId = action.payload
    },
    setCandidateTotal: (state, action:PayloadAction<number>) => {
      state.candidateTotal = action.payload
    },
    setCandidatePageNo: (state, action:PayloadAction<number>) => {
      state.candidatePageNo = action.payload
    },
    setCandidateCurrentIndex: (state, action:PayloadAction<number>) => {
      state.candidateCurrentIndex = action.payload
    },
    setCandidateIsSearch: (state, action:PayloadAction<boolean>) => {
      state.candidateIsSearch = action.payload
    },
    setCandidateSearchValue: (state, action:PayloadAction<string>) => {
      state.candidateSearchValue = action.payload
    },
    setSelectedQuestion: (state, action:PayloadAction<any[] | null>) => {
      state.selectedQuestion = action.payload
    },
    setSearchType: (state, action: PayloadAction<number>) => {
      state.searchType = action.payload
    },
  },
});

export const {
  setExamStartingNo,
  setCandidateData,
  setQuestionData,
  setEvaluatorData,
  setSentStatus,
  setExamStatus,
  setMalpracticeCount,
  setCurrentDataIndex,
  setTotalCountData,
  setIsSearchData,
  setSearchValueData,
  setSearchPageNoData,
  setCandidateTotal,
  setCandidatePageNo,
  setCandidateCurrentIndex,
  setCandidateIsSearch,
  setCandidateSearchValue,
  setSessionData,
  setExamStatusId,
  setCodingLanguage,
  setSelectedQuestion,
  setSearchType,
} = examStore.actions;

export default examStore.reducer;
