import React, { useEffect } from 'react';
import { Auth } from 'aws-amplify';
/*
* Comment for local development - Start
*/
import { useNavigate } from 'react-router';
import RoutesConstants from '../constants/routesConstants';
/*
* Comment for local development - End
*/

const privateRoute = (Component: any) => function PrivateRoute(props: any) {
  /*
  * Comment for local development - Start
  */
  const navigate = useNavigate();
  const routesConstants = new RoutesConstants();
  /*
  * Comment for local development - End
  */
  async function checkAuth() {
    try {
      await Auth.currentAuthenticatedUser();
    } catch (err) {
      /*
      * Comment for local development - Start
      */
      navigate(routesConstants.LOGIN);
      /*
      * Comment for local development - End
      */
    }
  }
  useEffect(() => {
    checkAuth();
  });
  return <Component {...props} />;
};

export default privateRoute;
