import React, { useEffect, useRef, useState } from 'react';
import './Settings.css';
import { AxiosResponse } from 'axios';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { InputText } from 'primereact/inputtext';
import { Chips } from 'primereact/chips';
import jwtDecode from 'jwt-decode';
import { getJWT } from '../../../services/authService';
import {
  ActionButton, AddCollege, ListColleges, ExamCandidatesSkeleton, ListCategories, AddCategory, SpinCheckButton,
} from '../../../components';
import { getPaginationUniversity } from '../../../services/campusDriveService';
import { ICollege } from '../../../models/ICollege';
import { getAllCategory } from '../../../services/examService';
import { useAppDispatch, useAppSelector } from '../../../store/storeHooks';
import {
  setCategoryData, setCategoryDialogTitle,
  setCategoryDialogVisible, setCollegeData,
  setCollegeDialogTitle,
  setCollegeDialogVisible, setCollegeTotal, setUpdateCategoryData,
  setUpdateCollegeData,
} from '../../../store/settingsStore';
import { ICategory } from '../../../models/ICategory';
import { getAdditionalSetting, updateAdditionalSetting } from '../../../services/settingService';

export default function Settings() {
  const toastRef = useRef<any>(null);
  const collegeDialogTitle = useAppSelector((state) => state.settings.collegeDialogTitle);
  const categoryDialogTitle = useAppSelector((state) => state.settings.categoryDialogTitle);
  const dispatch = useAppDispatch();
  const collegeData = useAppSelector((state) => state.settings.collegeData);
  const categoriesData = useAppSelector((state) => state.settings.categoryData);

  const collegeDialogVisible = useAppSelector((state) => state.settings.collegeDialogVisible);
  const categoryDialogVisible = useAppSelector((state) => state.settings.categoryDialogVisible);
  const collegeStartingNo = useAppSelector((state) => state.settings.collegeStartingPageNo);
  const [updateStatus, setUpdateStatus] = useState<'LOADING' | 'LOADED' | 'NOT_LOADING'>('NOT_LOADING');
  const [additionalStatus, setAdditionalStatus] = useState<'LOADING' | 'LOADED' | 'NOT_LOADING'>('NOT_LOADING');
  const [viewAdditionalSettings, setViewAdditionalSettings] = useState<boolean>(false);
  const [malpracticeCount, setMalpracticeCount] = useState<number>(0);
  const [resumeCount, setResumeCount] = useState<number>(0);
  const [questionPoint, setQuestionPoint] = useState<any>([]);
  const [questionPointLength, setQuestionPointLength] = useState<number>(0);
  const [malpracticeUser, setMalpracticeUser] = useState<string>('');
  const [resumeUser, setResumeUser] = useState<string>('');
  const [updateData, setUpdateData] = useState<any>([
    {
      resumeCount: null,
      malpracticeCount: null,
      userId: '',
      questionpoint: [],
      deletedPoint: [],
    },
  ]);

  useEffect(() => {
    dispatch(setCollegeData(null))
    dispatch(setCategoryData(null));

    getPaginationUniversity(collegeStartingNo)
      .then((response: AxiosResponse) => {
        dispatch(setCollegeData(response.data.colleges as ICollege[]));
        dispatch(setCollegeTotal(response.data.len));
      })
      .catch((e) => {
        toastRef.current.show({
          severity: 'error',
          summary: 'Something went wrong',
          detail: e.response.data.message,
        });
      });

    getAllCategory()
      .then((response: AxiosResponse) => {
        dispatch(setCategoryData(response.data as ICategory[]));
      })
      .catch((e) => {
        toastRef.current.show({
          severity: 'error',
          summary: 'Something went wrong',
          detail: e.response.data.message,
        });
      });
  }, []);

  const toast = (severity: string, summary: string, detail: string) => {
    toastRef.current.show({
      severity,
      summary,
      detail,
      life: 3000,
    })
  }

  const handleHideAddCollege = () => {
    dispatch(setCollegeDialogVisible(false));
  };

  const handleHideAddCategory = () => {
    dispatch(setCategoryDialogVisible(false));
  };

  const handleAddCollegeClick = () => {
    dispatch(setUpdateCollegeData(null));
    dispatch(setCollegeDialogTitle('Add'));
    dispatch(setCollegeDialogVisible(true));
  }

  const handleAddCategoryClick = () => {
    dispatch(setUpdateCategoryData(null));
    dispatch(setCategoryDialogTitle('Add'));
    dispatch(setCategoryDialogVisible(true));
  }

  const showViewAdditionalSettings = () => {
    setAdditionalStatus('LOADING');
    getAdditionalSetting()
      .then((response: AxiosResponse) => {
        setMalpracticeCount(response.data.counts.malpracticeCount);
        setResumeCount(response.data.counts.resumeCount);
        setResumeUser(response.data.resumeUserName);
        setMalpracticeUser(response.data.malpracticeUserName);
        setQuestionPoint(Object.keys(response.data.questionPoints));
        setQuestionPointLength(Object.values(response.data.questionPoints).length);
        setViewAdditionalSettings(true);
        setAdditionalStatus('NOT_LOADING');
      })
      .catch((e) => {
        toastRef.current.show({
          severity: 'error',
          summary: 'Something went wrong',
          detail: e.response.data.message,
        });
      });
  };

  const updateAdditionalSettings = () => {
    setUpdateStatus('LOADING');
    if (updateData[0].resumeCount || updateData[0].malpracticeCount || updateData[0].questionpoint || updateData[0].deletedPoint) {
      const decode: {userId: string,
            email: string,
            name: string,
            sub: string,
            roleId: number,
            role: string,
            sessionId: string
          } = jwtDecode(getJWT());
      updateData[0].userId = decode.userId;
      updateAdditionalSetting(updateData)
        .then((res: AxiosResponse) => {
          setUpdateStatus('NOT_LOADING');
          setUpdateData([{
            resumeCount: null,
            malpracticeCount: null,
            userId: '',
            questionpoint: [],
            deletedPoint: [],
          }])
          if (res.data.status === false) {
            setTimeout(() => {
              toast(
                'Error',
                'Something Went Wrong',
                'Try Again!',
              )
            });
          }
          setViewAdditionalSettings(false);
        })
        .catch((e) => {
          toastRef.current.show({
            severity: 'error',
            summary: 'Something went wrong',
            detail: e.response.data.message,
          });
        });
    }
  }

  const hideViewAdditionalSettings = () => {
    setViewAdditionalSettings(false);
  }

  const malpracticeChange = (e: any) => {
    setMalpracticeCount(e.target.value);
    updateData[0].malpracticeCount = e.target.value;
  }

  const resumeChange = (e: any) => {
    setResumeCount(e.target.value);
    updateData[0].resumeCount = e.target.value;
  }

  const updateQuestionPoints = (e: any) => {
    if (questionPointLength > e.target.value.length) {
      const point = questionPoint.filter((val: any) => !e.target.value.includes(val));
      updateData[0].deletedPoint.push(point[0]);
    } else {
      updateData[0].questionpoint.push(e.target.value[e.target.value.length - 1]);
    }
    setQuestionPoint(e.target.value);
  }

  // eslint-disable-next-line no-unused-vars
  const customChip = (item: string | number | boolean | {} | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactNodeArray | React.ReactPortal | null | undefined) => (
    <div>
      f
      <span>
        {item}
        {' '}
        - (active)
        {' '}
      </span>
      <i className="pi pi-user-plus" style={{ fontSize: '14px' }} />
    </div>
  )

  return (
    <div className="settings-view w-full flex flex-auto align-items-center justify-content-center">
      <div className="settings-layout h-full flex flex-column">
        <div className="header w-full flex align-items-center justify-content-between">
          <div className="title font-semibold">
            Settings
          </div>
          <div className="additional-setting-button">
            <SpinCheckButton label="Additional Settings" styleClass="p-button-primary" loadingStatus={additionalStatus} onClick={showViewAdditionalSettings} />
          </div>
        </div>

        <ListColleges colleges={collegeData} handleAddCollegeClick={handleAddCollegeClick} />

        {
          ((categoriesData != null && categoriesData.length === 0) || categoriesData == null)
          && (
            <div className="categories-list-container w-full">
              <div className="header w-full flex align-items-center justify-content-between">
                <div className="title">
                  Question Categories
                </div>
                <div className="filter-container flex">
                  <div className="add-category">
                    <ActionButton type="primary" label="Add Category" clickHandler={handleAddCategoryClick} />
                  </div>
                </div>
              </div>
              {
                (categoriesData == null) && <ExamCandidatesSkeleton />
              }

              {
                (categoriesData != null && categoriesData!.length === 0)
                && (
                  <div className="empty-categories flex align-items-center justify-content-center">
                    No categories added yet.
                  </div>
                )
              }
            </div>
          )
        }

        {
          (categoriesData != null && categoriesData.length > 0)
          && <ListCategories categories={categoriesData} handleAddCategoryClick={handleAddCategoryClick} />
        }

        <Dialog header={`${collegeDialogTitle} College`} visible={collegeDialogVisible} style={{ width: '590px', height: '540px', borderRadius: '8px' }} onHide={handleHideAddCollege}>
          <AddCollege toast={toast} />
        </Dialog>

        <Dialog header={`${categoryDialogTitle} Category`} visible={categoryDialogVisible} style={{ width: '590px', height: '250px', borderRadius: '8px' }} onHide={handleHideAddCategory}>
          <AddCategory toast={toast} />
        </Dialog>

        <Toast ref={toastRef} />
      </div>
      <Dialog header="Additional Settings" visible={viewAdditionalSettings} style={{ width: '550px', height: '480px', borderRadius: '8px' }} onHide={hideViewAdditionalSettings}>
        <div className="additional-Setting">
          <div className="field">
            <div className="label">
              Malpractice Count
            </div>
            <InputText
              className="input"
              type="text"
              placeholder="Malpractice Count"
              autoComplete="off"
              value={malpracticeCount}
              onChange={malpracticeChange}
            />
            {
              (malpracticeUser) && (
                <div className="input-edited">
                  Last edited by
                  {' '}
                  {malpracticeUser}
                </div>
              )
            }
          </div>
          <div className="field">
            <div className="label">
              Resume Count
            </div>
            <InputText
              className="input"
              type="text"
              placeholder="Resume Count"
              autoComplete="off"
              value={resumeCount}
              onChange={resumeChange}
            />
            {
              (resumeUser) && (
                <div className="input-edited">
                  Last edited by
                  {' '}
                  {resumeUser}
                </div>
              )
            }
          </div>
          <div className="field">
            <div className="label">
              Question Points
            </div>
            <Chips value={questionPoint} className="input" onChange={updateQuestionPoints} />
          </div>
          <div className="update-button">
            <SpinCheckButton styleClass="p-button-info" label="Update" loadingStatus={updateStatus} onClick={updateAdditionalSettings} />
          </div>
        </div>
      </Dialog>
    </div>
  )
}
