import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import languageConstants from '../../constants/languageConstants';

function LanguagesDropdown({ onSelectChange, selectedLanguage }:any) {
  const [n, setN] = useState<number>(languageConstants.length)
  useEffect(() => {
    console.log('the dropdown language is', selectedLanguage)
    console.log('the value of n is', n)
    for (let i = 0; i < languageConstants.length; i += 1) {
      if (languageConstants[i].value === selectedLanguage) { setN(i); console.log('inside if of dropdown', n); break; }
    }
  })
  console.log('inside dropdown comp the lang outside useeff is', selectedLanguage, n == null ? languageConstants[n] : 'none', n)

  return (
    <Select
      options={languageConstants}
      value={languageConstants[n]}
      onChange={(selectedOption) => onSelectChange(selectedOption)}
    />
  );
}

export default LanguagesDropdown;
