import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import IListCampusDrives from '../models/IListCampusDrives';

export interface ExamState {
  campusDriveStartingNo: number,
  listCampusDrive: IListCampusDrives[] | null,
  currentDataIndex: number,
  totalCount: number,
  isSearch: boolean,
  searchValue: string | null,
  searchPageNo: number,
  searchType: number,
}
const initialState: ExamState = {
  campusDriveStartingNo: 0,
  listCampusDrive: null,
  currentDataIndex: 0,
  totalCount: 0,
  isSearch: false,
  searchValue: null,
  searchPageNo: 0,
  searchType: 0,
};
export const campusDriveStore = createSlice({
  name: 'campusdrive',
  initialState,
  reducers: {
    setCampusDriveStartingNo: (state, action:PayloadAction<number>) => {
      state.campusDriveStartingNo = action.payload
    },
    setListCampusDriveData: (state, action: PayloadAction<IListCampusDrives[] | null>) => {
      state.listCampusDrive = action.payload
    },
    setCurrentDataIndex: (state, action: PayloadAction<number>) => {
      state.currentDataIndex = action.payload
    },
    setTotalCountData: (state, action: PayloadAction<number>) => {
      state.totalCount = action.payload
    },
    setIsSearchData: (state, action: PayloadAction<boolean>) => {
      state.isSearch = action.payload
    },
    setSearchValueData: (state, action: PayloadAction<string>) => {
      state.searchValue = action.payload
    },
    setSearchPageNoData: (state, action: PayloadAction<number>) => {
      state.searchPageNo = action.payload
    },
    setSearchType: (state, action: PayloadAction<number>) => {
      state.searchType = action.payload
    },
  },
});

export const {
  setCampusDriveStartingNo,
  setListCampusDriveData,
  setCurrentDataIndex,
  setTotalCountData,
  setIsSearchData,
  setSearchValueData,
  setSearchPageNoData,
  setSearchType,
} = campusDriveStore.actions;

export default campusDriveStore.reducer;
