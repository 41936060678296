import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';

export interface AuthState {
  authenticated: boolean;
  role: string;
  name: string;
  welcomeToast: boolean;
  email:string;
  id: string;
  examErrorToast:boolean;
}

const initialState: AuthState = {
  authenticated: false as boolean,
  role: '' as string,
  name: '' as string,
  welcomeToast: true as boolean,
  examErrorToast: true as boolean,
  email: '' as string,
  id: '' as string,
};

export const authStore = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthenticated: (state, action: PayloadAction<boolean>) => {
      state.authenticated = action.payload;
    },
    setRole: (state, action: PayloadAction<string>) => {
      state.role = action.payload;
    },
    setName: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
    },
    setId: (state, action: PayloadAction<string>) => {
      state.id = action.payload;
    },
    setWelcomeToast: (state, action: PayloadAction<boolean>) => {
      state.welcomeToast = action.payload;
    },
    setEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    setExamFailureToast: (state, action: PayloadAction<boolean>) => {
      state.examErrorToast = action.payload;
    },
    clearAuthStore: (state) => {
      state.welcomeToast = true;
      state.authenticated = false;
      state.email = '';
      state.role = '';
      state.name = '';
      state.id = '';
    },
  },
});

export const {
  setAuthenticated, setRole, setName, setWelcomeToast, clearAuthStore, setEmail, setId, setExamFailureToast,
} = authStore.actions;

export const useAuthentication = (state: RootState) => state.auth.authenticated;

export const useRole = (state: RootState) => state.auth.role;

export default authStore.reducer;
