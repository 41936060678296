import React, { useEffect, useState } from 'react';
import './AddEvaluatorFilter.css';
import { SubmitHandler, useForm } from 'react-hook-form';
import { AxiosResponse } from 'axios';
import { Slider } from 'primereact/slider';
import { ProgressSpinner } from 'primereact/progressspinner';
import { SpinCheckButton } from '../index';
import { IListExam } from '../../models/IListExam';
import { addExamEvaluationRange, candidateFilter, getCandidateCount } from '../../services/examService';
// import {useAppDispatch} from "../../store/storeHooks";

type IProps = {
 // handleEvaluationFilterError: any,
  addEvaluationFilterHandler: any,
  // resetExamData: any
  setCompVisible: any,
  toast: any,
  examData: IListExam,
  candidateLength: number
}

export default function AddEvaluatorFilter({
  addEvaluationFilterHandler,
  setCompVisible, toast, examData, candidateLength,
}: IProps) {
  const [maxNoStds] = useState<number>(candidateLength);
  const [addRangeUploadByCountButton, setAddRangeUploadByCountButton] = useState<'LOADING' | 'LOADED' | 'NOT_LOADING'>('NOT_LOADING');
  const [addRangeBySliderOption, setAddRangeBySliderOption] = useState<'LOADING' | 'LOADED' | 'NOT_LOADING'>('NOT_LOADING');
  const [noChangeError] = useState('');
  const [rangeSlider, setRangeSlider] = useState<any>(examData.startRange !== null ? [examData.startRange, examData.endRange] : [0, 100]);
  const [topScore, setTopScore] = useState<number>(0);
  const [bottomScore, setBottomScore] = useState<number>(0);
  const [studentCount, setStudentCount] = useState<number>(0);
  const [rangeSpinner, setRangeSpinner] = useState<boolean>(false)
  const [countSpinner, setCountSpinner] = useState<boolean>(false)
  const [checkValue, setCheckValue] = useState<boolean>(false)
  // const dispatch = useAppDispatch();

  const onChangeRangeSlider = async () => {
    setRangeSpinner(true)
    await getCandidateCount(examData.id, rangeSlider[0], rangeSlider[1])
      .then((response:AxiosResponse) => {
        setStudentCount(response.data.count);
      })
      .catch(() => {})
    setRangeSpinner(false);
  }

  // on student count change
  const onCountChange = async (count:number) => {
    await candidateFilter(examData.id, count)
      .then((response:AxiosResponse) => {
        setTopScore(response.data[0].percentage);
        const len = response.data.length
        if (response.data.length > 1) setBottomScore(response.data[len - 1].percentage);
        else setBottomScore(response.data[0].percentage);
        setCountSpinner(false)
      })
  }

  useEffect(() => {
    onChangeRangeSlider();
    if (studentCount > 0) onCountChange(studentCount);
  }, []);

  const defaultValues = {
    studentCount: '',
  }

  // if (examData != null && examData.startRange != null) {
  //   setRangeSlider([examData.startRange, examData.endRange]);
  // }

  const {
    register, handleSubmit, formState: { errors }, setError,
  } = useForm({ defaultValues });

  // const defaultValues2 = {
  //   rangeSlide: [examData.startRange !== null ? examData.startRange : 0,examData.endRange !== null ? examData.endRange : 100],
  // }
  const {
    handleSubmit: handleSubmit2,
  } = useForm();

  const maxLengthCheck = async (object: any) => {
    if (object.target.value === '0') {
      // object.target.value = object.target.value.slice(0, object.target.maxLength)
      setError('studentCount', { type: 'pattern', message: 'Enter COUNT greater than 0' })
      setCheckValue(true);
      return;
    }
    if (object.target.value !== null && object.target.value !== '') {
      setCountSpinner(true);
      if (object.target.value > 0 && (object.target.value > object.target.maxLength || object.target.value > maxNoStds)) {
        object.target.value = object.target.value.slice(0, object.target.maxLength)
        setError('studentCount', { type: 'pattern', message: `Enter COUNT less than ${maxNoStds} which is this the total student count` })
        setCountSpinner(false);
        setCheckValue(true);
      } else if (object.target.value > 0) {
        setCheckValue(false);
        await onCountChange(object.target.value);
      }
    }
  }

  const errorResponse = () => {
    setTimeout(() => {
      setCompVisible(false);
      toast(
        'error',
        'Something went wrong',
        'Please try again later',
      )
    });
  }

  const onSubmitCount: SubmitHandler<any> = () => {
    setAddRangeUploadByCountButton('LOADING');
    addExamEvaluationRange(examData.id, bottomScore, topScore)
      .then((response: AxiosResponse) => {
        if (response.status === 200) {
          addEvaluationFilterHandler(true, bottomScore, topScore);
          // resetExamData(response.data);
          setAddRangeUploadByCountButton('LOADED');
          setTimeout(() => {
            setCompVisible(false);
            toast(
              'success',
              'Success',
              'Evaluation filter has been added successfully',
            )
          });
          // dispatch(setExam)
        }
      })
      .catch(() => {
        errorResponse();
      })
  }

  const onSubmitRange = () => {
    setAddRangeBySliderOption('LOADING');
    addExamEvaluationRange(examData.id, rangeSlider[0], rangeSlider[1])
      .then((response: AxiosResponse) => {
        if (response.status === 200) {
          addEvaluationFilterHandler(true, rangeSlider[0], rangeSlider[1]);
          // resetExamData(response.data);
          setAddRangeBySliderOption('LOADED');
          setTimeout(() => {
            setCompVisible(false);
            toast(
              'success',
              'Success',
              'Evaluation filter has been added successfully',
            )
          });
        }
      })
      .catch(() => {
        errorResponse();
      })
  };

  return (
    <div className="add-evaluation-filter-container h-full w-full">
      <div className="add-evaluation-filter-form h-full w-full">
        <form key={1} onSubmit={handleSubmit(onSubmitCount)} className="h-auto w-full flex flex-column align-items-center justify-content-between">
          <div className="content w-full">
            <div className="field name field-margin-top">
              <div className="label">
                Enter the number to filter top n out of
                {' '}
                {maxNoStds}
                {' '}
                students

              </div>
              <input {...register('studentCount', { required: 'Students count is required' })} className="input-number" type="number" maxLength={5} min={1} max={maxNoStds} onInput={maxLengthCheck} />
              {
                errors.studentCount?.type === 'required' ? (<small className="error-label p-error">{errors.studentCount.message}</small>) : ''
              }
              {
                errors.studentCount?.type === 'pattern' ? (
                  <small className="error-label p-error">
                    *
                    {errors.studentCount.message}
                  </small>
                ) : ''
              }
            </div>
            <div className="field name result-student-count flex align-items-center justify-content-evenly">
              <div className="flex-column align-items-center justify-content-center">
                <div className="score flex align-items-center justify-content-center w-full">
                  {/* {topScore} */}
                  {/* % */}
                  {
                      (countSpinner)
                      && <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="var(--surface-ground)" animationDuration=".5s" />
                   }
                  {
                    (!countSpinner)
                      && (
                      <div>
                        {topScore}
                        %
                      </div>
                      )
                   }

                </div>
                <div className="description">Highest percentage</div>
              </div>
              <div className="flex-column align-items-center justify-content-center">
                <div className="score flex align-items-center justify-content-center w-full">
                  {/* {bottomScore} */}
                  {/* % */}
                  {
                      (countSpinner)
                      && <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="var(--surface-ground)" animationDuration=".5s" />
                   }
                  {
                      (!countSpinner)
                      && (
                      <div>
                        {bottomScore}
                        %
                      </div>
                      )
                   }
                </div>
                <div className="description">Lowest percentage</div>
              </div>
            </div>
            {
              noChangeError !== '' ? (<small className="error-label p-error">{noChangeError}</small>) : ''
            }
            <div className="add-button-container w-full flex align-items-center justify-content-center">
              <div className="add-button">
                <SpinCheckButton styleClass="add-button-item" disabled={checkValue} label="Add Evaluation filter" loadingStatus={addRangeUploadByCountButton} onClick={handleSubmit(onSubmitCount)} />
              </div>
            </div>
          </div>
        </form>
        <div className="separator flex align-items-center justify-content-between w-full">
          <div className="separator-line flex align-items-center justify-content-start w-5">
            <div className="line" />
          </div>
          <div className="alternative">or</div>
          <div className="separator-line flex align-items-center justify-content-end w-5">
            <div className="line" />
          </div>
        </div>
        <form key={2} onSubmit={handleSubmit2(onSubmitRange)} className="h-auto w-full flex-column align-items-center justify-content-between">
          <div className="content w-full">
            <div className="field name">
              <div className="label field-margin-top flex align-items-center justify-content-between">
                Select the score range to filter
                <div className=" flex align-items-center justify-content-center">
                  <div className="label-range field-margin-top">
                    {rangeSlider[0]}
                    {' '}
                    %
                  </div>
                  <div className="label-range field-margin-top">
                    -
                  </div>
                  <div className="label-range field-margin-top">
                    {rangeSlider[1]}
                    {' '}
                    %
                  </div>
                </div>
              </div>
              <div className="range-slider">
                <Slider value={rangeSlider} onChange={(e) => setRangeSlider(e.value)} onSlideEnd={() => onChangeRangeSlider()} range />
              </div>
            </div>

            <div className="result-student-count flex-column align-items-center justify-content-center">
              <div className="score flex align-items-center justify-content-center w-full">
                {/* {studentCount} */}
                {
                  (rangeSpinner)
                    && <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="var(--surface-ground)" animationDuration=".5s" />
                 }
                {
                  (!rangeSpinner)
                    && <div>{studentCount}</div>
                 }
              </div>
              <div className="description flex align-items-center justify-content-center w-full">Number of students within given range</div>
            </div>

            {
              noChangeError !== '' ? (<small className="error-label p-error">{noChangeError}</small>) : ''
            }
            <div className="add-button-container w-full flex align-items-center justify-content-center">
              <div className="add-button">
                <SpinCheckButton styleClass="add-button-item" label="Add Evaluation filter" loadingStatus={addRangeBySliderOption} onClick={handleSubmit2(onSubmitRange)} />
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}
