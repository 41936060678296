import React from 'react';
import IUser from '../../models/IUser';

type IListEvaluatorsRowProps = {
  evaluator: IUser
}

export default function ListEvaluatorsRow({ evaluator }: IListEvaluatorsRowProps) {
  const getUserRole = () => ((evaluator.roleId === 3) ? 'Admin' : (evaluator.roleId === 2) ? 'Super Evaluator' : 'Evaluator')

  return (
    <div className="row w-full">
      <div className="name cell">
        <div className="text-ellipsis">
          {evaluator.name}
        </div>
      </div>
      <div className="email cell">
        {evaluator.email}
      </div>
      <div className="role cell">
        {getUserRole()}
      </div>
      <div className="evaluated-count cell">
        0
      </div>
    </div>
  )
}
