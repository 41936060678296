import React, { useEffect, useState } from 'react';
import './CandidateMarkSheet.css';
import { Button } from 'primereact/button';
import { useNavigate, useParams } from 'react-router';
import { Skeleton } from 'primereact/skeleton';
import { getMarkSheet } from '../../../services/candidateService';
import Navigator, { questionNavigatorType, questionType } from '../../../components/navigator/Navigator';
import MarkSheetQuestion, { evaluationType, responseType } from '../../../components/markSheetQuestion/MarkSheetQuestion';
import RoutesConstants from '../../../constants/routesConstants';

export type markSheetDisplayType = {
  order: number,
  question: questionType,
  response: responseType,
  evaluation?: evaluationType
}

export default function CandidateMarkSheet() {
  const [visibleRight, setVisibleRight] = useState(true);

  const [candidate, setCandidate] = useState<any>();

  const { examId, candidateId } = useParams();
  const [selectedQuestion, setSelectedQuestion] = useState<questionNavigatorType | null>();
  const [questionsList, setQuestionsList] = useState<questionType[]>();
  const [categoryList, setCategoryList] = useState<any>();
  const [markSheetDisplayList, setMarkSheetDisplayList] = useState<markSheetDisplayType[]>();
  const [markSheetDisplay, setMarkSheetDisplay] = useState<markSheetDisplayType>();
  const navigate = useNavigate();
  const routesConstant = new RoutesConstants();
  // Question List
  const [questions, setQuestions] = useState<questionType[]>([]);

  // Question List
  const [questionNavigator, setQuestionNavigator] = useState<questionNavigatorType[]>([]);

  useEffect(() => {
    setQuestionNavigator(questions.map((q, index) => ({
      id: q.id,
      order: index + 1,
      category: q.categoryId,
      default: true,
      selected: false,
      marked: false,
      answered: false,
    })));
  }, [questions]);

  useEffect(() => {
    if (examId && candidateId) {
      getMarkSheet(examId, candidateId).then(({ data }: any) => {
        const candidatePrep = {
          candidateDetails: data.candidateDetails,
          status: data.CandidateStatus.status,
          totalQuestions: 0,
          evaluated: 0,
          marks: 0,
          totalMarks: 0,
        };
        setCategoryList(data.categoryList.map((c: any) => ({ label: c.name, value: c.id })));
        const questionListTemp: questionType[] = [];
        const display: markSheetDisplayType[] = [];
        setQuestions(data.candidateData.map((q: any) => q.question));
        data.candidateData.forEach((c: any, index: number) => {
          questionListTemp.push(c.question);
          display.push({
            order: index + 1, question: c.question, evaluation: c.evaluation, response: c.response,
          });
          candidatePrep.totalMarks += c.question.point;
          candidatePrep.marks += c.evaluation.points;
          candidatePrep.evaluated += c.evaluation.id ? 1 : 0;
          candidatePrep.totalQuestions += 1;
        });
        setCandidate(candidatePrep);
        setQuestionsList(questionListTemp);
        setMarkSheetDisplayList(display);
      }).catch(() => (navigate(`${routesConstant.EXAMS}/${examId}`)));
    }
  }, []);

  useEffect(() => {
    if (markSheetDisplayList && questionsList && selectedQuestion) {
      setMarkSheetDisplay(markSheetDisplayList[questionsList.findIndex((q) => q.id === selectedQuestion.id)]);
    }
  }, [selectedQuestion]);

  return (
    <div>
      { !candidate && (<div className="sheet-candidate-display-canvas-skeleton"><Skeleton width="100%" height="100%" /></div>)}
      { candidate && (
      <div className="sheet-candidate-display-canvas">
        <div>
          <p className="sheet-candidate-display-canvas-label">Candidate</p>
          {' '}
          {candidate.candidateDetails.name}
          {' '}
          -
          {candidate.candidateDetails.registerNo}
        </div>
        <div>
          <p className="sheet-candidate-display-canvas-label">Marks</p>
          {' '}
          {candidate.marks}
          /
          {candidate.totalMarks}
        </div>
        <div>
          <p className="sheet-candidate-display-canvas-label">Evaluated</p>
          {' '}
          {candidate.evaluated}
          /
          {candidate.totalQuestions}
        </div>
        <div>
          <p className="sheet-candidate-display-canvas-label">Candidate status</p>
          {' '}
          { candidate.status }
        </div>
      </div>
      ) }
      { (questionsList && categoryList)
          && (
          <Navigator
            visiblePanel={visibleRight}
            setVisiblePanel={setVisibleRight}
            questionNavigator={questionNavigator}
            categoryList={categoryList}
            selectedQuestion={selectedQuestion}
            setSelectedQuestion={setSelectedQuestion}
            dropdown
          />
          )}
      <div className="markSheet-navigator">
        <Button icon="pi pi-arrow-left" onClick={() => setVisibleRight(true)} className="p-button-rounded p-button-info" />
      </div>
      <div>
        { !(markSheetDisplay && markSheetDisplayList) && (
        <div className="mark-sheet-question-display-canvas-skeleton">
          <Skeleton width="100%" height="100%" />
        </div>
        ) }
        { (markSheetDisplay && markSheetDisplayList) && (
        <MarkSheetQuestion
          navigator={questionNavigator}
          setSelectedQuestion={setSelectedQuestion}
          markSheetDisplay={markSheetDisplay}
        />
        ) }
      </div>
    </div>
  );
}
