import React, { useEffect } from 'react';
import './Candidate.css';
import { Outlet, useNavigate } from 'react-router';
import { AxiosResponse } from 'axios';
import { useAppDispatch, useAppSelector } from '../../store/storeHooks';
import useInterval from '../../hooks/useInterval';
import { finish, heartbeat } from '../../services/eventService';
import { setIsRunning, setTimeRemaining, setTimeTracker } from '../../store/examSessionStore';
import { setLoader, setMessage } from '../../store/loaderStore';
import RoutesConstants from '../../constants/routesConstants';

function Candidate() {
  const isRunning = useAppSelector((state) => state.examSession.isRunning);
  const timeTracker = useAppSelector((state) => state.examSession.timeTracker);
  const examSession = useAppSelector((state) => state.counter.examSession);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const routesConstant = new RoutesConstants();

  const finishExam = () => {
    dispatch(setIsRunning(false));
    dispatch(setLoader(true));
    dispatch(setMessage('Submitting Exam'));
    finish(examSession.id).then(() => {
      dispatch(setLoader(false));
      dispatch(setMessage(''));
      navigate(`${routesConstant.CANDIDATE}${routesConstant.CANDIDATE_END}`);
    });
  }

  useInterval(() => {
    if (isRunning) {
      heartbeat(examSession.id, timeTracker).then((response: AxiosResponse) => {
        if (response.data.re_sync) {
          dispatch(setTimeRemaining(response.data.time));
          dispatch(setTimeTracker(response.data.time));
        }
      });
    }
  }, 15000);

  useEffect(() => {
    if (timeTracker === 0 && isRunning) finishExam();
  }, [timeTracker]);

  return (
    <div className="candidate-view w-full h-full">
      <Outlet />
    </div>
  );
}

export default Candidate;
