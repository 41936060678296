import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { useAppDispatch, useAppSelector } from '../../store/storeHooks';
import EvaluationNavigation from './evaluationNavigation/evaluationNavigation';
import MarkSheetQuestion from '../markSheetQuestion/MarkSheetQuestion';
import { getResponseData } from '../../services/examService';
import { markSheetDisplayType } from '../../views/adminView/candidateMarkSheet/CandidateMarkSheet';
import { setNavigatorLoader } from '../../store/loaderStore';

export type evaluationNavigatorType = {
  order: number,
  responseId: string,
  questionId: string,
  skipQuestion: boolean,
  skipResponse: boolean,
  evaluated: boolean,
  selected: boolean,
  default: boolean,
}

export default function Evaluation() {
  const responses = useAppSelector((state) => state.evaluation.validRespondeId);
  const [visibleRight, setVisibleRight] = useState(true);
  const [responseNavigator, setResponseNavigator] = useState<evaluationNavigatorType[] | null>(null);
  const [selectedResponse, setSelectedResponse] = useState<evaluationNavigatorType | null>();
  const [responseDisplay, setResponseDisplay] = useState<markSheetDisplayType>();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (responses) {
      setResponseNavigator(responses.map((response, index) => (
        {
          order: index + 1,
          responseId: response.id,
          questionId: response.questionId,
          skipQuestion: false,
          skipResponse: false,
          evaluated: false,
          selected: false,
          default: true,
        }
      )));
    }
  }, [responses]);

  useEffect(() => {
    dispatch(setNavigatorLoader(true));
    if (selectedResponse) {
      getResponseData(selectedResponse.responseId).then(({ data }) => {
        const response = { ...data.res };
        delete response.Question;
        response.questionId = selectedResponse.questionId;
        setResponseDisplay({
          order: selectedResponse.order,
          question: data.res.Question,
          response,
          evaluation: data.evaluation,
        });
        dispatch(setNavigatorLoader(false));
      }).catch(() => {
        // TODO: HANDLE QUESTION FETCH ERROR
        dispatch(setNavigatorLoader(false));
      })
    }
  }, [selectedResponse])

  return (
    <div>
      <div className="w-full">
        {
          responseNavigator
          && (
            <EvaluationNavigation
              visiblePanel={visibleRight}
              setVisiblePanel={setVisibleRight}
              responseNavigator={responseNavigator}
              setResponseNavigator={setResponseNavigator}
              selectedResponse={selectedResponse}
              setSelectedResponse={setSelectedResponse}
            />
          )
        }
      </div>
      <div className="mt-5" />
      <div className="markSheet-navigator">
        <Button icon="pi pi-arrow-left" onClick={() => setVisibleRight(true)} className="p-button-rounded p-button-info" />
      </div>
      { responseDisplay && (
      <MarkSheetQuestion
        responseNavigator={responseNavigator}
        setResponseNavigator={setResponseNavigator}
        setSelectedResponse={setSelectedResponse}
        selectedResponse={selectedResponse}
        markSheetDisplay={responseDisplay}
        isCandidate={false}
      />
      ) }
    </div>
  )
}
