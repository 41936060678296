/* eslint-disable function-paren-newline */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useRef, useState } from 'react';
import './ListCampusDrive.css';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router';
import { AxiosResponse } from 'axios';
import { SubmitHandler, useForm } from 'react-hook-form';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';
import RoutesConstants from '../../../../constants/routesConstants';
import { IListCampusDrives } from '../../../../models/IListCampusDrives';
import { getPagenationCampusDrive, getCampusDriveDistinctYears } from '../../../../services/campusDriveService';
import { CampusDriveListSkeleton, ListCampusDriveRow, SpinCheckButton } from '../../../../components';
import { useAppDispatch, useAppSelector } from '../../../../store/storeHooks';
import {
  setCampusDriveStartingNo,
  setCurrentDataIndex, setIsSearchData,
  setListCampusDriveData, setSearchValueData, setTotalCountData, setSearchPageNoData,
  setSearchType,
} from '../../../../store/campusDriveStore';
import FilterIcon from '../../../../assets/icons/FilterIcon.svg';

export default function ListCampusDrive() {
  const navigate = useNavigate();
  const routesConstants = new RoutesConstants();
  const startingNo = useAppSelector((state) => state.campusdrive.campusDriveStartingNo);
  const searchPageNo = useAppSelector((state) => state.campusdrive.searchPageNo);
  const currentIndex = useAppSelector((state) => state.campusdrive.currentDataIndex);
  const campusDriveList = useAppSelector((state) => state.campusdrive.listCampusDrive);
  const totalCount = useAppSelector((state) => state.campusdrive.totalCount);
  const isSearch = useAppSelector((state) => state.campusdrive.isSearch);
  const searchVal = useAppSelector((state) => state.campusdrive.searchValue);
  const [previousButtonStatus, setPreviousButtonStatus] = useState<'LOADING' | 'LOADED' | 'NOT_LOADING'>('NOT_LOADING');
  const [nextButtonStatus, setNextButtonStatus] = useState<'LOADING' | 'LOADED' | 'NOT_LOADING'>('NOT_LOADING');
  const [searchButtonStatus, setSearchButtonStatus] = useState<'LOADING' | 'LOADED' | 'NOT_LOADING'>('NOT_LOADING');
  const toastRef = useRef<any>(null);
  const searchType = useAppSelector((state) => state.campusdrive.searchType);
  const [userFilterOptions, setUserFilterOptions] = useState([]);
  const [userFilter, setFilterValue] = useState<any>('Filter');

  const [showCloseButton, setButtonValue] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  const dispatch = useAppDispatch();
  let driveLen: number = 0;
  if (campusDriveList != null) {
    driveLen = campusDriveList.length;
  }

  const removeValueInDropDown = () => {
    const data: any = {};
    if (searchVal !== '') {
      data.query = {
        sortBy: 'updatedAt,DESC',
        pageNo: searchPageNo,
        campusDriveName: searchVal,
      };
    } else {
      data.query = {
        sortBy: 'updatedAt,DESC',
        pageNo: searchPageNo,
      };
    }
    setFilterValue('');
    dispatch(setSearchType(0));
    setShowLoader(true);
    getPagenationCampusDrive(data.query)
      .then((response: AxiosResponse) => {
        dispatch(setCurrentDataIndex(0));
        dispatch(setListCampusDriveData(response.data.campusDrives));
        dispatch(setTotalCountData(response.data.len));
        dispatch(setSearchPageNoData(0));
        dispatch(setCurrentDataIndex(0));
        setSearchButtonStatus('NOT_LOADING');
        setShowLoader(false);
        console.log('the starting number is', startingNo)
        console.log('the searchpageno is', searchPageNo)
      })

      .catch(() => {});
    setFilterValue('Filter');
    setButtonValue(false);
    console.log('entered into removeValueInDropDown');
  };

  const onFilterQueryChange = (e: { value: any }) => {
    const filter = e.value.name;
    setShowLoader(true);
    setFilterValue(filter);
    dispatch(setSearchType(filter));

    setButtonValue(true);
    const data: any = {};

    if (searchVal !== '') {
      data.query = {
        sortBy: 'updatedAt,DESC',
        pageNo: searchPageNo,
        campusDriveName: searchVal,
        yearSelected: filter,
      };
    } else {
      data.query = {
        sortBy: 'updatedAt,DESC',
        pageNo: searchPageNo,
        yearSelected: filter,
      };
    }
    getPagenationCampusDrive(data.query)
      .then((response: AxiosResponse) => {
        dispatch(setCurrentDataIndex(0));
        dispatch(setListCampusDriveData(response.data.campusDrives));
        dispatch(setTotalCountData(response.data.len));
        dispatch(setSearchPageNoData(0));
        dispatch(setCurrentDataIndex(0));
        setSearchButtonStatus('NOT_LOADING');
        setShowLoader(false);
        console.log('the starting number is', startingNo)
        console.log('the searchpageno is', searchPageNo)
      })

      .catch(() => {});
  };

  const defaultValues = {
    searchValue: null,
  }
  const {
    handleSubmit, register,
  } = useForm({ defaultValues });
  useEffect(() => {
    /*
     * Getting the campus drive list from api
     * and setting it to campusDriveList state
     */
    const data: any = {};
    data.query = {
      sortBy: 'updatedAt,DESC',
      pageNo: searchPageNo,
      campusDriveName: searchVal,
      yearSelected: (new Date().getFullYear()).toString(),
    };
    console.log('data.query', data.query);
    getPagenationCampusDrive(data.query)
      .then((response: AxiosResponse) => {
        dispatch(setListCampusDriveData(response.data.campusDrives));
        dispatch(setTotalCountData(response.data.len));
      })
      .catch((e) => {
        toastRef.current.show({
          severity: 'error',
          summary: 'Something went wrong',
          detail: e.response.data.message,
        });
      });

    getCampusDriveDistinctYears()
      .then((response: AxiosResponse) => {
        console.log(response.data.campusDrives[0].startDate);
        const FilterOptions = (response.data.campusDrives).map((campusDrive: IListCampusDrives) =>
          ({ name: campusDrive.startDate }),
        );
        setUserFilterOptions(FilterOptions);
      })
      .catch((e) => {
        toastRef.current.show({
          severity: 'error',
          summary: 'Something went wrong',
          detail: e.response.data.message,
        });
      });
  }, []);
  const handleChange = (e: any) => {
    dispatch(setSearchValueData(e.target.value));
  };

  const handleNewDriveClick = () => {
    dispatch(setSearchType(0));
    dispatch(setCampusDriveStartingNo(0));
    navigate(routesConstants.CAMPUS_DRIVE + routesConstants.CAMPUS_DRIVE_CREATE);
  };
  const handleNextPageClick = () => {
    setNextButtonStatus('LOADING');
    const data: any = {};

    if (searchType !== 0) {
      data.query = {
        sortBy: 'updatedAt,DESC',
        pageNo: startingNo + 1,
        campusDriveName: searchVal,
        yearSelected: searchType,
      };
    } else if (searchType === 0 && searchVal === null) {
      data.query = {
        sortBy: 'updatedAt,DESC',
        pageNo: startingNo + 1,
        campusDriveName: searchVal,
        yearSelected: (new Date().getFullYear()).toString(),
      };
    } else {
      data.query = {
        sortBy: 'updatedAt,DESC',
        pageNo: startingNo + 1,
        campusDriveName: searchVal,
      };
    }
    if (isSearch === false) {
      getPagenationCampusDrive(data.query)
        .then((response: AxiosResponse) => {
          dispatch(setCampusDriveStartingNo(startingNo + 1));
          dispatch(setCurrentDataIndex(currentIndex + 8));
          dispatch(setListCampusDriveData(response.data.campusDrives));
          dispatch(setTotalCountData(response.data.len));
          setNextButtonStatus('NOT_LOADING');
        })
        .catch((e) => {
          toastRef.current.show({
            severity: 'error',
            summary: 'Something went wrong',
            detail: e.response.data.message,
          });
        });
    } else {
      getPagenationCampusDrive(data.query)
        .then((response: AxiosResponse) => {
          dispatch(setSearchPageNoData(searchPageNo + 1));
          dispatch(setListCampusDriveData(response.data.campusDrives));
          dispatch(setTotalCountData(response.data.len));
          dispatch(setCurrentDataIndex(currentIndex + 8));
          setNextButtonStatus('NOT_LOADING');
        })
        .catch((e) => {
          toastRef.current.show({
            severity: 'error',
            summary: 'Something went wrong',
            detail: e.response.data.message,
          });
        });
    }
  }

  const handlePreviousPageClick = () => {
    setPreviousButtonStatus('LOADING');
    const data: any = {};

    if (searchType !== 0) {
      data.query = {
        sortBy: 'updatedAt,DESC',
        pageNo: startingNo - 1,
        campusDriveName: searchVal,
        yearSelected: searchType,

      };
    } else if (searchType === 0 && searchVal === null) {
      data.query = {
        sortBy: 'updatedAt,DESC',
        pageNo: startingNo - 1,
        campusDriveName: searchVal,
        yearSelected: (new Date().getFullYear()).toString(),
      };
    } else {
      data.query = {
        sortBy: 'updatedAt,DESC',
        pageNo: startingNo - 1,
        campusDriveName: searchVal,
      };
    }

    if (isSearch === false) {
      getPagenationCampusDrive(data.query)
        .then((response: AxiosResponse) => {
          dispatch(setCampusDriveStartingNo(startingNo - 1));
          dispatch(setCurrentDataIndex(currentIndex - 8));
          dispatch(setListCampusDriveData(response.data.campusDrives));
          dispatch(setTotalCountData(response.data.len));
          setPreviousButtonStatus('NOT_LOADING');
        })
        .catch((e) => {
          toastRef.current.show({
            severity: 'error',
            summary: 'Something went wrong',
            detail: e.response.data.message,
          });
        });
    } else {
      getPagenationCampusDrive(data.query)
        .then((response: AxiosResponse) => {
          dispatch(setSearchPageNoData(searchPageNo - 1));
          dispatch(setListCampusDriveData(response.data.campusDrives));
          dispatch(setTotalCountData(response.data.len));
          dispatch(setCurrentDataIndex(currentIndex - 8));
          setPreviousButtonStatus('NOT_LOADING');
        })
        .catch((e) => {
          toastRef.current.show({
            severity: 'error',
            summary: 'Something went wrong',
            detail: e.response.data.message,
          });
        });
    }
  }
  const searchCampusDrive: SubmitHandler<any> = (data: any) => {
    setSearchButtonStatus('LOADING');
    dispatch(setSearchValueData(data.searchValue));
    dispatch(setIsSearchData(true));
    dispatch(setSearchPageNoData(0));
    dispatch(setCampusDriveStartingNo(0));
    if (searchType !== 0) {
      data.query = {
        sortBy: 'updatedAt,DESC',
        pageNo: searchPageNo,
        campusDriveName: data.searchValue,
        yearSelected: searchType,
      };
    } else if (searchType === 0 && searchVal === null) {
      data.query = {
        sortBy: 'updatedAt,DESC',
        pageNo: searchPageNo,
        campusDriveName: data.searchValue,
        yearSelected: (new Date().getFullYear()).toString(),
      };
    } else {
      data.query = {
        sortBy: 'updatedAt,DESC',
        pageNo: searchPageNo,
        campusDriveName: data.searchValue,
      };
    }
    getPagenationCampusDrive(data.query)
      .then((response: AxiosResponse) => {
        dispatch(setCurrentDataIndex(0));
        dispatch(setListCampusDriveData(response.data.campusDrives));
        dispatch(setTotalCountData(response.data.len));
        dispatch(setSearchPageNoData(0));
        dispatch(setCurrentDataIndex(0));
        setSearchButtonStatus('NOT_LOADING');
        console.log('the starting number is', startingNo)
        console.log('the searchpageno is', searchPageNo)
      })

      .catch((e) => {
        toastRef.current.show({
          severity: 'error',
          summary: 'Something went wrong',
          detail: e.response.data.message,
        });
      });
  }
  return (
    <div className="campus-drive-list-view h-full w-full">
      <div className="header flex align-items-center justify-content-between">
        <div className="title font-semibold">
          Campus Drives
        </div>
        <div className="filter-container block flex">
          <div className="flex flex-row">
            <div className="filter-fields">
              <div className="p-inputgroup">
                <span className="search p-input-icon-left">
                  <i className="pi pi-search" />
                  <InputText {...register('searchValue')} type="string" placeholder="Search Drive" onChange={handleChange} />
                </span>
              </div>
            </div>
            <div className="search-drive mr-2">
              <SpinCheckButton styleClass="search-drive-button font-medium" loadingStatus={searchButtonStatus} onClick={handleSubmit(searchCampusDrive)} label="Search" icons="pi pi-search" />
            </div>
            <div className="filter-fields">
              <div className="p-inputgroup">
                <span className="p-input-icon-left flex align-items-center">
                  {showLoader && (
                  <span>
                    <i className="pi pi-spin pi-spinner" style={{ color: '#71717A', marginLeft: '15px' }} />
                  </span>
                  )}
                  {!showLoader && (
                  <img src={FilterIcon} className="filter-icon" alt="Filter" />)}
                  <Dropdown
                    value={userFilter}
                    options={userFilterOptions}
                    onChange={onFilterQueryChange}
                    optionLabel="name"
                    placeholder={userFilter}
                  />
                  {showCloseButton && (
                  <span onClick={removeValueInDropDown}>
                    {' '}
                    <i
                      className="pi pi-times-circle"
                      style={{
                        color: '#8B0000', marginRight: '15px',
                      }}
                    />
                    {' '}
                  </span>
                  )}
                </span>
              </div>
            </div>
          </div>
          <div className="new-drive">
            <Button className="new-drive-button font-medium" onClick={handleNewDriveClick} label="Create new campus drive" />
          </div>
        </div>
      </div>
      {
        (campusDriveList == null)
        && <CampusDriveListSkeleton />
      }
      {
        (campusDriveList != null && campusDriveList!.length > 0)
        && (
          <>
            <div className="drive-list-container w-full h-auto flex flex-column align-items-center justify-content-start">
              <div className="list-header flex w-full">
                <div className="cell campus-drive-col">
                  CAMPUS DRIVE
                </div>
                <div className="cell university-col">
                  UNIVERSITY
                </div>
                <div className="cell exams-col">
                  NUMBER OF EXAMS
                </div>
                <div className="cell students-col">
                  STUDENTS
                </div>
                <div className="view-campus-drive h-full" />
              </div>

              {campusDriveList!.map((campusDrive: IListCampusDrives, index) => (
                <ListCampusDriveRow
                  key={campusDrive.campusDriveId}
                  shade={index}
                  lastItem={(index + 1) === campusDriveList!.length}
                  campusDriveData={campusDrive}
                />
              ))}
            </div>
            <div className="footer w-full flex align-items-center justify-content-between">
              <div className="campus-drive-result-details">
                {
                  campusDriveList != null && `Showing ${(campusDriveList!.length === 0) ? 0 : (currentIndex + 1)} to ${(totalCount < currentIndex + 8) ? (totalCount) : (currentIndex + 8)} of ${totalCount} results`
                }
              </div>
              <div className="pagination-buttons flex align-items-center justify-content-between">
                {
                  (startingNo !== 0 || searchPageNo !== 0)
                  && (
                  <div className="mr-3">
                    <SpinCheckButton styleClass="" label="Previous" loadingStatus={previousButtonStatus} onClick={handlePreviousPageClick} icons="pi pi-arrow-left" />
                  </div>
                  )
                }
                {
                  ((totalCount - driveLen) !== currentIndex)
                  && (
                  <div className="">
                    <SpinCheckButton styleClass="" label="Next" loadingStatus={nextButtonStatus} onClick={handleNextPageClick} icons="pi pi-arrow-right" />
                  </div>
                  )
                }

              </div>
            </div>
          </>
        )
      }
      {
        (campusDriveList != null && campusDriveList!.length === 0)
        && (
          <div className="no-data w-full flex align-items-center justify-content-center">
            <div className="no-campusDrive-content">No campus drive data available. Create a campus drive to list here.</div>
          </div>
        )
      }
      <Toast ref={toastRef} />
    </div>
  );
}
