import axios from 'axios';
import { IExam, IExams } from '../models/IExam';
import { ICreateExams } from '../models/ICreateExam';
import { extractEvaluatos, extractExamDate, mergeEvaluators } from '../hooks/examHook';
import ApiContants from '../constants/apiContants';
import { ICreateCategory } from '../models/ICreateCategory';
import IUser from '../models/IUser';

const apiConstants = new ApiContants();

export function getAllExams() {
  return axios.get(apiConstants.EXAM, {
    params: {
      sortBy: 'updatedAt,DESC',
      pageNo: '0',
    },
  });
}

export function getPagenationExam(data:any) {
  return axios.get(apiConstants.EXAM, {
    params: {
      ...data,
    },
  });
}

export function getExamDistinctYears() {
  return axios.get(apiConstants.EXAM, {
    params: {
      sortBy: 'updatedAt,DESC',
      distinctYears: true,
    },
  });
}

export function searchExamApi(data: any) {
  return axios.post(`${apiConstants.EXAM}${apiConstants.SEARCH}`, data)
}

export async function getDashbordData() {
  return axios.get(`${apiConstants.EXAM}${apiConstants.DASHBOARD}`);
}

export function getExam(examId: string) {
  return axios.get(`${apiConstants.EXAM}/${examId}`);
}
export function editExam(examData: IExam, examId: string) {
  let exam = {};
  if (examData) {
    exam = {
      id: examId,
      name: examData.examName,
      examDate: examData.examDate,
      campusDriveId: examData.campusDrive.id,
      startTime: examData.startTime,
      endTime: examData.endTime,
      timeAlerts: examData.timeAlerts,
      evaluators: mergeEvaluators(extractEvaluatos(examData.evaluator), extractEvaluatos(examData.superEvaluator)),
    };
  }
  return axios.put(`${apiConstants.EXAM}/${examId}`, exam);
}
export function createExam(examData: IExams) {
  const {
    examName,
    campusDrive,
    examDate,
    startTime,
    endTime,
    buffer,
    timeAlerts,
    evaluator,
    superEvaluator,
  } = examData;
  let exam: ICreateExams | null = null;

  if (examData) {
    // @ts-ignore
    exam = {
      name: examName,
      examDate: extractExamDate(examDate),
      campusDriveId: campusDrive.campusDriveId,
      buffer,
      timeAlerts,
      startTime,
      endTime,
      evaluators: mergeEvaluators(extractEvaluatos(evaluator), extractEvaluatos(superEvaluator)),
    };
  }
  return axios.post(apiConstants.EXAM, exam);
}

export function startExam(examId : string) {
  return axios.put(`${apiConstants.EXAM}/${examId}${apiConstants.STARTEXAM}`);
}

export function endExam(examId : string) {
  return axios.put(`${apiConstants.EXAM}/${examId}${apiConstants.EXAM_END}`);
}
export function getExamForCampusDrive(campusDriveId: string) {
  return axios.get(
    apiConstants.EXAM,
    {
      params: {
        campusDriveId,
      },
    },
  )
}

export function getPresignedURL(examId: string, option: string, type?:string) {
  if (!type) {
    type = 'csv';
  }
  console.log(type)
  return axios.get(`${apiConstants.EXAM}/${examId}${option}?type=${type}`);
}

export function bulkupload(examId: string, option: string, type?:string) {
  if (!type) {
    type = 'csv'
  }
  return axios.get(`${apiConstants.EXAM}${apiConstants.BULKUPLOAD}/${examId}${option}?type=${type}`);
}

export function uploadQuestionCandidateToS3(url: string, form: any) {
  return axios.put(url, form, {
    headers: {
      'Content-Type': 'application/octet-stream',
    },
  });
}

export function getQuestions(examId: string) {
  return axios.get(
    `${apiConstants.EXAM}${apiConstants.QUESTION}`,
    {
      params: {
        examId,
      },
    },
  );
}

export function getCandidates(examId: string, pageNo: number) {
  return axios.get(
    `${apiConstants.EXAM}${apiConstants.CANDIDATE}`,
    {
      params: {
        examId,
        pageNo,
      },
    },
  );
}
export function getExamSessionCandidates(examId: string, pageNo: number, scorepagelimit: string, passed: number) {
  return axios.get(
    `${apiConstants.EXAM}${apiConstants.CANDIDATE}`,
    {
      params: {
        examId,
        pageNo,
        scorepagelimit,
        passed,
      },
    },
  );
}
export function getCandidatesGreaterFilter(examId: string, pageNo: number, scorepagelimit: string, passed: number, greater: number) {
  return axios.get(
    `${apiConstants.EXAM}${apiConstants.CANDIDATE}`,
    {
      params: {
        examId,
        pageNo,
        scorepagelimit,
        passed,
        greater,
      },
    },
  );
}
export function getCandidatesLesserFilter(examId: string, pageNo: number, scorepagelimit: string, passed: number, lesser: number) {
  return axios.get(
    `${apiConstants.EXAM}${apiConstants.CANDIDATE}`,
    {
      params: {
        examId,
        pageNo,
        scorepagelimit,
        passed,
        lesser,
      },
    },
  );
}

export function getEvaluatedCandidateFilter(examId: string, pageNo: number, scorepageLimit: string, evaluvated: string) {
  return axios.get(
    `${apiConstants.EXAM}${apiConstants.CANDIDATE}`,
    {
      params: {
        examId,
        pageNo,
        scorepageLimit,
        evaluvated,
      },
    },
  );
}

export function downloadFilteredCandidates(data:any) {
  return axios.post(`${apiConstants.EXAM}${apiConstants.CANDIDATE}${apiConstants.DOWNLOADFILTEREDCANDIDATES}`, data);
}
export function searchCandidateApi(examId: string, pageNo: number, candidateName: string) {
  return axios.get(
    `${apiConstants.EXAM}${apiConstants.CANDIDATE}`,
    {
      params: {
        examId,
        pageNo,
        candidateName,
      },
    },
  );
}

export function candidateSessionReset(candidateSession:any) {
  return axios.post(`${apiConstants.EXAM}${apiConstants.CANDIDATE}${apiConstants.RESET}`, { candidateSession })
}

export function getEvaluators(examId: string) {
  return axios.get(`${apiConstants.EXAM}/${examId}${apiConstants.EVALUATOR}`);
}

export function getEvaluatorsByRole(examId: string) {
  return axios.get(`${apiConstants.EXAM}/${examId}${apiConstants.EVALUATOR}${apiConstants.RESTEVALUATOR}`);
}

export function addEvaluator(examId: string, evaluators: IUser[]) {
  const data: string[] = extractEvaluatos(evaluators);
  return axios.post(`${apiConstants.EXAM}/${examId}${apiConstants.EVALUATOR}${apiConstants.ADDEVALUATOR}`, { evaluators: data });
}

export function sendInvite(examId: string) {
  return axios.get(
    `${apiConstants.EXAM}${apiConstants.CANDIDATE}${apiConstants.CANDIDATEINVITE}?examId=${examId}`,
  );
}
export function sendInviteUsingEmailId(data: any) {
  return axios.post(`${apiConstants.EXAM}${apiConstants.CANDIDATE}${apiConstants.CANDIDATEINVITE}`, data);
}

export function addQuestion(data: any) {
  return axios.post(`${apiConstants.EXAM}${apiConstants.QUESTION}`, data);
}

export function addCandidate(data: any) {
  return axios.post(`${apiConstants.EXAM}${apiConstants.CANDIDATE}`, data);
}

export function getExamCount(examId: string) {
  return axios.get(`${apiConstants.EXAM}${apiConstants.EXAM_COUNT}/${examId}`);
}
// get exam by User ID
export function getExamByUser(id:string) {
  return axios.get(`${apiConstants.EXAM}/${id}`);
}
export function getAllCategory() {
  return axios.get(`${apiConstants.EXAM}${apiConstants.QUESTION}${apiConstants.CATEGORY}`);
}

export function getQuestionPoints() {
  return axios.get(`${apiConstants.EXAM}${apiConstants.QUESTION_POINTS}`);
}

export function addCategory(categoryData: ICreateCategory) {
  return axios.post(`${apiConstants.EXAM}${apiConstants.QUESTION}${apiConstants.CATEGORY}`, categoryData);
}
export function editQuestionCategory(categoryId: string, category: any) {
  return axios.put(`${apiConstants.EXAM}${apiConstants.QUESTION}${apiConstants.CATEGORY}/${categoryId}`, category);
}

export function deleteCategory(categoryId: string) {
  console.log(categoryId);
  return axios.delete(`${apiConstants.EXAM}${apiConstants.QUESTION}${apiConstants.CATEGORY}/${categoryId}`);
}

export function getNextQuestion(data: any) {
  return axios.post(`${apiConstants.EXAM}${apiConstants.QUESTION}${apiConstants.NEXT_QUESTION}`, data);
}

export function createResponse(data: any) {
  return axios.post(`${apiConstants.EXAM}${apiConstants.QUESTION}${apiConstants.CANDIDATE_RESPONSE}`, data);
}

export function getQuestionTemplate() {
  return axios.get(`${apiConstants.EXAM}${apiConstants.QUESTION}${apiConstants.QUESTIONTEMPLATE}`);
}

export function malpracticeEvent(data : any) {
  return axios.post(`${apiConstants.EXAM}${apiConstants.MALPRACTICE}`, data);
}

export function bookmarkQuestion(data:any) {
  return axios.post(`${apiConstants.EVENT}${apiConstants.BOOKMARK}`, data);
}

export function getResponse(data: any) {
  return axios.post(`${apiConstants.EVALUATE}${apiConstants.QUESTION}`, data);
}

export function getResponseData(responseId: string) {
  return axios.get(`${apiConstants.EVALUATE}${apiConstants.GETQUESTION}/${responseId}`);
}

export function addPoints(data: any) {
  return axios.post(`${apiConstants.EVALUATE}`, data);
}

export function skipQuestionApi(data: any) {
  return axios.post(`${apiConstants.EVALUATE}${apiConstants.SKIP}`, data);
}

export function endEvaluationApi(data: any) {
  return axios.post(`${apiConstants.EVALUATE}${apiConstants.END}`, data);
}

export function dashboardData() {
  return axios.get(`${apiConstants.EXAM}${apiConstants.DASHBOARD}`);
}

export function candidateFilter(examId: string, studentCount: number) {
  return axios.get(`${apiConstants.EXAM}/${examId}${apiConstants.FILTER}?limit=${studentCount}`)
}

export function getCandidateCount(examId: string, startRange: number, endRange: number) {
  return axios.get(`${apiConstants.EXAM}/${examId}${apiConstants.RANGE}?startRange=${startRange}&endRange=${endRange}`)
}

export function addExamEvaluationRange(examId: string, startRange: number, endRange: number) {
  return axios.post(`${apiConstants.EXAM}/${examId}${apiConstants.RANGE}?startRange=${startRange}&endRange=${endRange}`)
}

export function downloadFilteredQuestion(data:any) {
  return axios.post(`${apiConstants.EXAM}${apiConstants.QUESTION}${apiConstants.DOWNLOADFILTEREDQUESTIONS}`, data);
}

export function getQuestion(questionId: string) {
  return axios.get(`${apiConstants.EXAM}${apiConstants.QUESTION}/${questionId}`);
}

export function updateQuestionExam(questionId: string, data: any) {
  return axios.put(`${apiConstants.EXAM}${apiConstants.QUESTION}/${questionId}`, data);
}

export function reevaluateQuestion(data: any) {
  return axios.put(
    `${apiConstants.EXAM}${apiConstants.QUESTION}${apiConstants.REEVALUATE}`,
    {
      examId: data.examId,
      questions: data.selectedQuestion,
    },
  );
}

export function getQuestionforReevaluation(examId: string) {
  return axios.get(`${apiConstants.EXAM}${apiConstants.QUESTION}${apiConstants.REEVALUATE}/${examId}`);
}
