import React from 'react';
import './CampusDrive.css';
import { Outlet } from 'react-router';

export default function CampusDrive() {
  return (
    <div className="campus-drive-view w-full flex flex-auto align-items-center justify-content-center">
      <div className="campus-drive-layout h-full flex align-items-center justify-content-center">
        <Outlet />
      </div>
    </div>
  );
}
