import React from 'react';
import './SpinCheckButton.css';
import { Button } from 'primereact/button';

type ISpinCheckButtonProp = {
  styleClass: string,
  label: string,
  disabled?: boolean,
  loadingStatus: 'LOADING' | 'LOADED' | 'NOT_LOADING',
  onClick?: any,
  icons?: any,
}

export default function SpinCheckButton({
  styleClass, label, loadingStatus, onClick, disabled, icons,
}: ISpinCheckButtonProp) {
  const loading = loadingStatus === 'LOADING';
  const loaded = loadingStatus === 'LOADED';

  if (loading || loaded) {
    disabled = false;
  }

  if (onClick) return <Button label={label} disabled={disabled} className={`spin-check-button ${styleClass}`} icon={loaded ? 'pi pi-check' : (loading ? 'pi pi-spin pi-sun' : (icons ? `${icons}` : ''))} loading={loading} onClick={onClick} />;
  return <Button label={label} disabled={disabled} className={`spin-check-button ${styleClass}`} icon={loaded ? 'pi pi-check' : (loading ? 'pi pi-spin pi-sun' : (icons ? `${icons}` : ''))} loading={loading} />;
}
