/* eslint-disable react/no-array-index-key */
import React from 'react';
import './ListReviewRow.css';
import { ListReviewRowSkeleton } from '../skeleton';
import IListReview from '../../models/IListReview';
import EvaluateConstants from '../../constants/evaluateConstants';

type IListReviewowProp = {
    shade: number;
    lastItem: boolean;
    reviewData:IListReview;
    column: string[];
}

export default function ListReviewRow(props: IListReviewowProp) {
  const {
    reviewData, shade, lastItem, column,
  } = props;
  const evaluateConstants = new EvaluateConstants();

  return (
    <>
      {
        (reviewData == null)
        && <ListReviewRowSkeleton shade={shade} lastItem={lastItem} column={['Question Category', 'Pending Question']} />
      }
      {
        (reviewData != null)
        && (
        <div className={`list-review-row flex w-full ${shade % 2 === 0 ? 'shade' : ''} ${lastItem ? 'last-item' : ''}`}>
          {[...column].map((el, index) => (
            <div key={`Review Row ${index}`} className="cell" style={{ width: `${100 / column.length}%` }}>
              {el === evaluateConstants.ASSIGNED ? reviewData.ASSIGNED : ''}
              {el === evaluateConstants.INPROGESS ? reviewData.INPROGRESS : ''}
              {el === evaluateConstants.NOTASSIGNED ? reviewData.NOTASSIGNED : ''}
              {el === evaluateConstants.TOTAL ? reviewData.TOTAL : ''}
              {el === evaluateConstants.CATEGORYNAME ? reviewData.categoryName : ''}
            </div>
          ))}
        </div>
        )
    }
    </>

  );
}
