import React from 'react';
import './ExamQuestionsSkeleton.css';
import { Skeleton } from 'primereact/skeleton';

export default function ExamQuestionsSkeleton() {
  return (
    <div className="exam-questions-skeleton">
      <Skeleton className="h-full w-full" />
    </div>
  )
}
