import React from 'react';
import './QuestionNavigatorDisplay.css';
import { ScrollPanel } from 'primereact/scrollpanel';
import { ProgressSpinner } from 'primereact/progressspinner';
import { questionNavigatorType } from '../navigator/Navigator';
import { useAppSelector } from '../../store/storeHooks';

type questionNavigatorDisplayProps = {
  sortedQuestions: questionNavigatorType[], setSortedQuestions: any,
  selectedQuestion: questionNavigatorType | null | undefined, setSelectedQuestion: any
}

export default function QuestionNavigatorDisplay(
  {
    sortedQuestions,
    setSelectedQuestion,
    selectedQuestion,
  } : questionNavigatorDisplayProps,
) {
  const questionClickHandler = (selected: questionNavigatorType) => {
    setSelectedQuestion(selected);
  }

  const navigatorLoader: boolean = useAppSelector((state) => state.loader.navigatorLoader);

  const applyStyles = (question: questionNavigatorType) => {
    if (selectedQuestion && question.id === selectedQuestion.id) return 'question-navigator-question-number-selected';
    if (question.marked) return 'question-navigator-question-number-marked';
    if (question.answered) return 'question-navigator-question-number-answered';
    return 'question-navigator-question-number-default';
  }

  return (
    <ScrollPanel style={{
      width: '100%', maxHeight: '23rem', overflow: 'auto', marginTop: '30px',
    }}
    >
      <div className="question-navigator-display">
        {
        sortedQuestions.map((question, index) => (
          !(selectedQuestion && selectedQuestion.order === question.order && navigatorLoader)
            ? (
              <div key={question.id} className="question-navigator-display-item">
                <div
                  onClick={() => questionClickHandler(question)}
                  tabIndex={index}
                  role="button"
                  className={`question-navigator-question-number ${applyStyles(question)}`}
                  onKeyPress={() => questionClickHandler(question)}
                >
                  <span className="number">{question.order}</span>
                </div>
              </div>
            )
            : (
              <div key={question.id} className="question-navigator-display-item">
                <ProgressSpinner style={{ width: '50px', height: '50px', stroke: '#0369A1' }} />
              </div>
            )
        ))
      }
      </div>
    </ScrollPanel>
  );
}
