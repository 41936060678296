import React, { useEffect, useState } from 'react';
import './ListQuestions.css';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import { AxiosResponse } from 'axios';
// import { CSVLink } from 'react-csv';
import IListQuestion from '../../models/IListQuestion';
import {
  ActionButton, ListQuestionsRow,
  SpinCheckButton,
  ViewQuestionModal,
} from '../index';
import FilterIcon from '../../assets/icons/FilterIcon.svg';
import questionConstants from '../../constants/questionConstants';
import { IListExam } from '../../models/IListExam';
import { ICategory } from '../../models/ICategory';
import {
  downloadFilteredQuestion,
} from '../../services/examService';

type IListQuestionsProps = {
  questions: IListQuestion[],
  examData: IListExam,
  handleAddQuestionClick: any,
  categories: ICategory[],
  updateQuestionVisible: Function
}

export default function ListQuestions({
  questions, handleAddQuestionClick, examData, categories, updateQuestionVisible,
}: IListQuestionsProps) {
  const [listQuestions, setListQuestions] = useState<any>(null);
  const [startingQuestion, setStatingQuestion] = useState(0);
  const [questionSearch, setQuestionSearch] = useState<any>('');
  const [questionFilter, setQuestionFilter] = useState<any>('');
  const [filterResult, setFilterResult] = useState<any>('');
  const [searchResult, setSearchResult] = useState<any>('');

  const [questionDialogVisible, setQuestionDialogVisible] = useState(false);
  const [dialogQuestion, setDialogQuestion] = useState<IListQuestion>();

  const [downloadSpinnerQuestion, setDownloadSpinnerQuestion] = useState<'LOADING' | 'LOADED' | 'NOT_LOADING'>('NOT_LOADING');

  // const [downloadFilterQuestion, setDownloadFilterQuestion] = useState<IListQuestion[]>([])

  const questionFiltersOption = [
    {
      label: 'Question Type',
      code: 'QT',
      items: [
        { id: questionConstants.MCQ, name: questionConstants.MCQ },
        { id: questionConstants.MSQ, name: questionConstants.MSQ },
        { id: questionConstants.OEQC, name: questionConstants.OEQC },
        { id: questionConstants.OEQT, name: questionConstants.OEQT },
      ],
    },
    {
      label: 'Categories',
      code: 'CT',
      items: categories,
    },
  ];

  useEffect(() => {
    setListQuestions(questions);
  }, []);

  const handleNextPageClick = () => {
    if (startingQuestion + 5 < listQuestions!.length) setStatingQuestion(startingQuestion + 5)
  }

  const handlePreviousPageClick = () => {
    if (startingQuestion - 5 >= 0) setStatingQuestion(startingQuestion - 5)
  }

  const handleHideQuestionModal = () => {
    setQuestionDialogVisible(false);
  }

  const handleMoreInfoClick = (question: IListQuestion) => {
    setDialogQuestion(question);
    setQuestionDialogVisible(true);
  }

  const onSearchQueryChange = (value: any) => {
    setQuestionSearch(value);
    let result = questions;

    if (value !== '' && questionFilter === '') {
      result = questions.filter((question: IListQuestion) => question.question.toLowerCase().includes(value.toLowerCase()));
    } else if (value !== '' && questionFilter !== '') {
      result = filterResult.filter((question: IListQuestion) => question.question.toLowerCase().includes(value.toLowerCase()));
    } else if ((value === '' || value === undefined) && questionFilter === '') {
      result = questions
      setQuestionSearch('');
    }
    setListQuestions(result);
    setSearchResult(result);
  }

  const onFilterQueryChange = (e: { value: any}) => {
    const filter = e.value
    setQuestionFilter(filter);
    let result = questions;

    if (filter === undefined && questionSearch === '') {
      result = questions
      setQuestionFilter('');
    } else if (filter === undefined && questionSearch !== '') {
      result = searchResult;
      setQuestionFilter('');
    } else if (filter !== '' && questionSearch === '') {
      result = questions?.filter((question: IListQuestion) => question.categoryId === filter.id || question.type === filter.id);
    } else if (filter !== '' && questionSearch !== '') {
      result = searchResult?.filter((question: IListQuestion) => question.type === filter.id);
    }
    setListQuestions(result);
    setFilterResult(result);
  }

  const groupedItemTemplate = (option: any) => (
    <div className="flex align-items-center justify-content-start filter-item">
      {option.label}
    </div>
  )

  const downloadQuestionFilter = () => {
    setDownloadSpinnerQuestion('LOADING');
    const filterdata:any = {};
    filterdata.examId = examData.id;
    filterdata.questiontype = questionFilter.name
    filterdata.search = questionSearch
    downloadFilteredQuestion(filterdata)
      .then((response:AxiosResponse) => {
        if (response.status === 200) {
          window.location.assign(response.data.url);
          setDownloadSpinnerQuestion('LOADED');
        }
      })
  }

  return (
    <>
      <div className="list-questions-container w-full">
        <div className="header w-full flex align-items-center justify-content-between">
          <div className="title flex align-items-center h-full">
            Questions
            {
                  (examData.questionStatus === 'SUCCESS')
                  && (
                  <div className="upload-status success h-full flex align-items-center">
                    <i className="pi pi-info-circle info-icon" />
                  </div>
                  )
              }
            {
                  (examData.questionStatus === 'ERROR')
                  && (
                  <div className="upload-status error h-full flex align-items-center">
                    <i className="pi pi-info-circle info-icon" />
                  </div>
                  )
              }
            {
                  (examData.questionStatus === 'STARTED')
                  && (
                  <div className="upload-status started h-full flex align-items-center">
                    <i className="pi pi-info-circle info-icon" />
                  </div>
                  )
              }
          </div>
          <div className="filter-container flex align-items-center justify-content-between">
            <div className="filter-fields">
              {
                (listQuestions) && (
                <div className="p-inputgroup">
                  <span className="search p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={questionSearch} onChange={(e) => onSearchQueryChange(e.target.value)} placeholder="Search by question" />
                  </span>
                  <span className="p-input-icon-left flex align-items-center">
                    <img src={FilterIcon} className="filter-icon" alt="Filter" />
                    <Dropdown
                      value={questionFilter}
                      options={questionFiltersOption}
                      onChange={onFilterQueryChange}
                      optionLabel="name"
                      optionGroupLabel="label"
                      optionGroupChildren="items"
                      optionGroupTemplate={groupedItemTemplate}
                      showClear={questionFilter !== '' && questionFilter != null}
                      placeholder="Filter"
                    />
                  </span>
                </div>
                )
              }

            </div>
            {
                  (examData.statusId !== 3)
                  && (
                  <div className="flex justify-content-between align-items-center">
                    <div className="add-questions list-question-header-button">
                      <ActionButton type="primary" label="Add Questions" clickHandler={handleAddQuestionClick} />
                    </div>
                  </div>
                  )
              }
            <div>
              <SpinCheckButton styleClass="upload-button-item" label="Export Questions" loadingStatus={downloadSpinnerQuestion} onClick={downloadQuestionFilter} />
            </div>
          </div>
        </div>
        <div className="list-questions-container w-full">
          <div className="list-questions-table w-full">
            <div className="row-header row w-full">
              <div className="question cell">
                QUESTION
              </div>
              <div className="exam cell">
                QUESTION TYPE
              </div>
              <div className="category cell">
                CATEGORY
              </div>
              <div className="more-info cell" />
            </div>

            {
          listQuestions?.map((question: IListQuestion, index: number) => {
            if (index >= startingQuestion && index < startingQuestion + 5) {
              return <ListQuestionsRow updateQuestionVisible={updateQuestionVisible} key={question.id} question={question} moreInfoHandler={handleMoreInfoClick} categories={categories} />
            }
            return <div key={question.id} />
          })
         }
          </div>

          <div className="footer w-full flex align-items-center justify-content-between">
            <div className="result-details">
              {
                    listQuestions != null
                    && `Showing ${(listQuestions!.length === 0) ? 0 : (startingQuestion + 1)} to ${(startingQuestion + 5 > listQuestions!.length) ? listQuestions!.length : (startingQuestion + 5)} of ${listQuestions!.length} results`
                }
            </div>
            <div className="pagination-buttons flex">
              <div className="qns-previous-button">
                {(startingQuestion >= 5) && (
                <ActionButton type="primary" label="Previous" clickHandler={handlePreviousPageClick} />
                )}
              </div>
              <div className="qns-next-button">
                {(listQuestions != null) && (startingQuestion + 5 < listQuestions!.length) && (
                <ActionButton type="primary" label="Next" clickHandler={handleNextPageClick} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {
            (dialogQuestion !== undefined)
            && (
            <Dialog className="question-dialog" header={dialogQuestion!.question} visible={questionDialogVisible} style={{ width: '590px', height: 'auto', borderRadius: '8px' }} onHide={handleHideQuestionModal}>
              {
                      (dialogQuestion.type === questionConstants.MSQ || dialogQuestion.type === questionConstants.MCQ)
                      && <ViewQuestionModal question={dialogQuestion as IListQuestion} />
                  }
            </Dialog>
            )
        }
    </>
  )
}
