import React from 'react';
import './DashboardExamsCard.css'
// eslint-disable-next-line no-unused-vars
// import { languages } from 'prismjs';
import DashboardExamItemView from '../dashboardExamsViewSkeleton/DashboardExamItemView';

export default function DashboardExamsCard() {
  return (
    <div className="dashboard-exam-view-container">
      <DashboardExamItemView />
      <DashboardExamItemView />
      <DashboardExamItemView />
      <DashboardExamItemView />
    </div>
  )
}
