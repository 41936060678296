import axios from 'axios';
import ApiContants from '../constants/apiContants';

const apiConstants = new ApiContants();

export function heartbeat(sessionId: string, time: number) {
  const event = {
    sessionId, time,
  };
  return axios.put(`${apiConstants.HEARTBEAT}`, event);
}

export function finish(sessionId: string) {
  return axios.put(`${apiConstants.FINISH}`, { sessionId });
}
