import React, { useEffect, useState } from 'react';
import { RadioButton } from 'primereact/radiobutton';
import { candidateQuestion } from '../MarkSheetQuestion';
import './MarkSheetMCQ.css';

export default function MarkSheetMCQ({ markSheetDisplay, isCandidate, setResponseString }: candidateQuestion) {
  const [options, setOptions] = useState<{name: string, key: string, checked: boolean}[]>();
  const [preFetchResponseString, setPreFetchResponseString] = useState<string>('');

  const selectOption = (e: any) => {
    if (options) {
      let selectedOption: string = '';
      setOptions(options.map((option) => {
        if (option.name === e.value.name) {
          option.checked = !option.checked;
          if (option.checked) selectedOption = option.name;
        } else option.checked = false;
        return option;
      }));
      if (selectedOption === preFetchResponseString) setResponseString(undefined);
      else if (selectedOption.length !== 0) setResponseString(selectedOption);
      else setResponseString(null);
    }
  }

  useEffect(() => {
    const temp: {name: string, key: string, checked: boolean}[] = [];
    const { response } = markSheetDisplay;
    const answer = response ? response.answer : null;
    setPreFetchResponseString(answer || '');
    if (response && answer) {
      Object.keys(markSheetDisplay.question).forEach((key) => {
        if (key.startsWith('option')) {
          // @ts-ignore
          const option: string = markSheetDisplay.question[key];
          if (option !== null && option.length !== 0) temp.push({ key, name: option, checked: option === answer });
        }
      });
    } else {
      Object.keys(markSheetDisplay.question).forEach((key) => {
        if (key.startsWith('option')) {
          // @ts-ignore
          const option: string = markSheetDisplay.question[key] ? (markSheetDisplay.question[key].length === 0 ? null : markSheetDisplay.question[key]) : null;
          if (option) temp.push({ key, name: option, checked: false });
        }
      })
    }
    setOptions(temp);
  }, [markSheetDisplay]);

  return (
    <div className="markSheet-mcq-panel">
      {
          options
            && options.map((option) => (
              <div key={option.key} className="field-radiobutton">
                <RadioButton
                  inputId={option.key}
                  name="mcq"
                  value={option}
                  disabled={!isCandidate}
                  onChange={selectOption}
                  checked={option.checked}
                />
                <label htmlFor={option.key}>{option.name}</label>
              </div>
            ))
        }
    </div>
  );
}
