import { useEffect, useState } from 'react';
import { useAppDispatch } from '../store/storeHooks';
import { setTimeTracker } from '../store/examSessionStore';

const getReturnValues = (remaining: number) => {
  const hours = Math.floor(remaining / 3600);
  const minutes = Math.floor((remaining % 3600) / 60);
  const seconds = Math.floor(remaining % 3600 % 60);
  return [hours, minutes, seconds];
}

export default function useTimer(secondsLeft: number) {
  let timer = secondsLeft;

  const [countDown, setCountDown] = useState(timer);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(timer - 1);
      timer -= 1;
      dispatch(setTimeTracker(timer));
    }, 1000);

    return () => clearInterval(interval);
  }, [timer]);

  return getReturnValues(countDown);
}
