import { Skeleton } from 'primereact/skeleton'
import './DashboardExamItemView.css';
import React from 'react';

export default function DashboardExamItemView() {
  return (
    <div className="exam-card-skeleton">
      <div className="dashboard-exam-list-skeleton w-full">
        <div className="line-1">
          <div className="university-name-col title">
            <Skeleton width="80%" />
          </div>
        </div>
        <div className="line-2 flex">
          <div className="cell exam-name-col sub-content">
            <Skeleton width="80%" className="mb-2" />
          </div>
          <div className="cell participants-col sub-content">
            <Skeleton width="80%" className="mb-2" />
          </div>
          <div className="cell percentage-passed-col sub-content">
            <Skeleton width="80%" className="mb-2" />
          </div>
          <div className="cell date-col sub-content">
            <Skeleton width="80%" className="mb-2" />
          </div>
        </div>
      </div>
    </div>
  )
}
