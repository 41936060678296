import React, { useEffect, useRef, useState } from 'react';
import './Dashboard.css';
import { Link, useNavigate } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { Toast } from 'primereact/toast';
import { OngoingExamCard, DashboardUpcomingExamItem } from '../../../components/index';
import DashboardExamsCard from '../../../components/skeleton/dashboardExamsCardSkeleton/DashboardExamsCard';
import { dashboardData } from '../../../services/examService';
import DashboardExamItem from '../../../components/dashboardExamItem/DashboardExamItem';
import { ICompletedExams } from '../../../models/ICompletedExams';
import { IUpcomingExams } from '../../../models/IUpcomingExams';
import { useAppDispatch } from '../../../store/storeHooks';
import { setCurrentDataIndex, setExamStartingNo, setExamStatusId } from '../../../store/examStore';
import RoutesConstants from '../../../constants/routesConstants';

export default function Dashboard() {
  const [listOngoingExams, setListOngoingExams] = useState<any>(null);
  const [listCompletedExams, setListCompletedExams] = useState<any>(null);
  const [listUpcomingExams, setListUpcomingExams] = useState<any>(null);
  const [listactiveParticipants, setActiveParticipants] = useState<any>(null);
  const [listexpectedParticipants, setExpectedParticipants] = useState<any>(null);
  const [listcompletedParticipants, setCompletedParticipants] = useState<any>(null);
  const [listofAvg, setListofAvg] = useState<any>(null);
  const toastRef = useRef<any>(null);
  const navigate = useNavigate();
  const routesConstants = new RoutesConstants();
  const dispatch = useAppDispatch();
  //  const [listOngoingExams, setListOngoingExams] = useState<any>(null);
  useEffect(() => {
    dashboardData()
      .then((response: AxiosResponse) => {
        if (response.status === 200) {
          setListofAvg(response.data.average)
          setListCompletedExams(response.data.topCompletedExams);
          setListUpcomingExams(response.data.topUpcomingExams);
          setActiveParticipants(response.data.activeParticipants)
          setExpectedParticipants(response.data.expectedParticipants)
          setListOngoingExams(response.data.topOngoingExams)
          setCompletedParticipants(response.data.completedParticipants)
        }
      })
      .catch((e) => {
        toastRef.current.show({
          severity: 'error',
          summary: 'Something went wrong',
          detail: e.response.data.message,
        });
      });
  }, []);
  const handleOngoingExam = () => {
    dispatch(setExamStatusId(2));
    dispatch(setExamStartingNo(0));
    dispatch(setCurrentDataIndex(0));
  }
  const handleCompletedExam = () => {
    dispatch(setExamStatusId(3));
    dispatch(setExamStartingNo(0));
    dispatch(setCurrentDataIndex(0));
  }
  const handleUpcomingExam = () => {
    dispatch(setExamStatusId(1));
    dispatch(setExamStartingNo(0));
    dispatch(setCurrentDataIndex(0));
  }
  const handleCardClick = (examData: any) => {
    navigate(`${routesConstants.EXAMS}/${examData.examId}`);
  }
  return (
    <div className="dashboard-view w-full flex flex-auto align-items-center justify-content-center">
      <div className="dashboard-layout h-full flex align-items-center justify-content-centert flex-column">
        <div className="dashboard-container">
          <div className="ongoing-exams shadow-1">
            <div className="title">
              <div>
                Ongoing Exams
              </div>
              <div>
                <Link to="/exams" onClick={handleOngoingExam}>View all</Link>
              </div>
            </div>
            {
                (listOngoingExams != null && listOngoingExams!.length === 0)
                    && (
                    <div className="content">
                      There are currently no Ongoing exams
                    </div>
                    )
                }

            {
                listOngoingExams !== null && (
                <div className="flex flex-row">
                  {
                      listOngoingExams !== null
                      && (
                        (listOngoingExams).map((ongoingExamItem: IUpcomingExams, index:number) => (
                          /* eslint-disable-next-line react/no-array-index-key */
                          <OngoingExamCard
                            key={ongoingExamItem.Exam.id}
                            onGoingExams={ongoingExamItem}
                            listactiveParticipants={listactiveParticipants[index]}
                            onCardClick={handleCardClick}
                          />
                        ))
                      )
                  }

                </div>
                )
              }

          </div>

          <div className="splitter flex align-items-center justify-content-between">
            <div className="exam-status shadow-1">
              <div className="title">
                <div>
                  Completed Exam
                </div>
                <div>
                  <Link to="/exams" onClick={handleCompletedExam}>View all</Link>
                </div>
              </div>
              {
                    (listCompletedExams === null) && <DashboardExamsCard />
                }
              {
                  (listCompletedExams != null && listCompletedExams!.length === 0)
                    && (
                    <div className="content">
                      There are currently no Completed exams
                    </div>
                    )
                }
              {
                    (listCompletedExams !== null && listCompletedExams.length > 0)
                    && (
                        listCompletedExams!.map((completedExamItem: ICompletedExams, index:any) => (
                          <DashboardExamItem
                            key={completedExamItem.Exam.id}
                            completedExams={completedExamItem}
                            listactiveParticipants={listcompletedParticipants[index]}
                            listofAvg={listofAvg ? listofAvg[index][0].avg : 0}
                            onCardClick={handleCardClick}
                          />
                        ))
                    )
                }
            </div>

            <div className="upcoming-exam shadow-1">
              <div className="title">
                <div>
                  Upcoming Exam
                </div>
                <div>
                  <Link to="/exams" onClick={handleUpcomingExam}>View all</Link>
                </div>
              </div>
              {
                    (listUpcomingExams === null) && <DashboardExamsCard />
                }
              {
                    (listUpcomingExams != null && listUpcomingExams!.length === 0)
                    && (
                    <div className="content">
                      There are currently no Upcoming exams
                    </div>
                    )
                }
              {
                    (listUpcomingExams !== null && listUpcomingExams.length > 0)
                    && (
                        listUpcomingExams!.map((UpComingExamItem: IUpcomingExams, index:any) => (
                          <DashboardUpcomingExamItem
                            key={UpComingExamItem.Exam.id}
                            upcomingExams={UpComingExamItem}
                            listexpectedParticipants={listexpectedParticipants[index]}
                            onCardClick={handleCardClick}
                          />
                        ))
                    )
                }
            </div>
          </div>
        </div>
      </div>
      <Toast ref={toastRef} />
    </div>
  );
}
