import { configureStore } from '@reduxjs/toolkit';
import {
  persistReducer,
} from 'redux-persist';
import thunk from 'redux-thunk';
import localStorage from 'redux-persist/lib/storage';
import authReducer from './authStore';
import loaderReducer from './loaderStore';
import settingsReducer from './settingsStore';
import usersReducer from './userStore';
import examsReducer from './examStore';
import evaluationStore from './evaluationStore';
import counterReducer from './questionCounter';
import examSessionReducer from './examSessionStore';
import campusDriveStore from './campusDriveStore';
import scoreboardStore from './scoreboardStore';

const persistConfig = {
  key: 'root',
  storage: localStorage,
};

const authPersistReducer = persistReducer(persistConfig, authReducer);

const store = configureStore({
  reducer: {
    auth: authPersistReducer,
    loader: loaderReducer,
    settings: settingsReducer,
    users: usersReducer,
    exams: examsReducer,
    counter: counterReducer,
    examSession: examSessionReducer,
    evaluation: evaluationStore,
    campusdrive: campusDriveStore,
    scoreboard: scoreboardStore,
  },
  middleware: [thunk],
});

export default store;

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
