/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useRef, useState } from 'react';
import './ListUsers.css';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { AxiosResponse } from 'axios';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import FilterIcon from '../../assets/icons/FilterIcon.svg';
import { ListUserRow, SpinCheckButton } from '../index';
import { IUser } from '../../models/IUser';
import { useAppDispatch, useAppSelector } from '../../store/storeHooks';
import { getPaginationUsers, inviteUser } from '../../services/userService';
import {
  setCurrentIndex,
  setIsSearch,
  setSearchType,
  setSearchCode,
  setSearchValue,
  setTotalCount,
  setUserData,
  setUserStartingPageNo,
} from '../../store/userStore';

type IListUsersProps = {
  users: IUser[];
};
// eslint-disable-next-line no-unused-vars
export default function ListUsers({ users }: IListUsersProps) {
  const dispatch = useAppDispatch();
  const [startingUser] = useState(0);
  const listUsers = useAppSelector((state) => state.users.userData);
  const startingNo = useAppSelector((state) => state.users.userStartingPageNo);
  const currentIndex = useAppSelector((state) => state.users.currentIndex);
  const isSearch = useAppSelector((state) => state.users.isSearch);
  const searchVal = useAppSelector((state) => state.users.searchValue);
  const searchType = useAppSelector((state) => state.users.searchType);
  const searchCode = useAppSelector((state) => state.users.searchCode);
  const totalCount = useAppSelector((state) => state.users.totalCount);
  const [userFilter, setFilterValue] = useState<any>('Filter');
  const [showCloseButton, setButtonValue] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [searchButtonStatus, setSearchButtonStatus] = useState<
    'LOADING' | 'LOADED' | 'NOT_LOADING'
  >('NOT_LOADING');
  const [previousButtonStatus, setPreviousButtonStatus] = useState<
    'LOADING' | 'LOADED' | 'NOT_LOADING'
  >('NOT_LOADING');
  const [nextButtonStatus, setNextButtonStatus] = useState<
    'LOADING' | 'LOADED' | 'NOT_LOADING'
  >('NOT_LOADING');
  const [overlayIndex, setOverlayIndex] = useState<number | null>(null);
  const [inviteButtonStatus, setInviteButtonStatus] = useState<
    'LOADING' | 'LOADED' | 'NOT_LOADING'
  >('NOT_LOADING');
  const userFilterOptions = [
    { name: 'Admin', code: 'ADM' },
    { name: 'Super Evaluator', code: 'SEVL' },
    { name: 'Evaluator', code: 'EVL' },
  ];
  let userLen: number = 0;
  if (listUsers != null) {
    userLen = listUsers.length;
  }
  const defaultValues = {
    searchValue: '',
    email: '',
    role: '',
  };
  const [displayResponsive, setDisplayResponsive] = useState<boolean>(false);
  const emailRegex = /^[a-zA-Z]+[a-zA-Z0-9+_.-]+@presidio\.com$/;
  const [email] = useState<string>();
  const rolesList = [
    { label: 'Super Evaluator', value: 'SEVL' },
    { label: 'Evaluator', value: 'EVL' },
  ];
  const {
    register,
    handleSubmit,
    control,
    setError,
    setValue,
    formState: { errors },
  } = useForm({ defaultValues });
  const role = useWatch({
    control,
    name: 'role',
  });
  const toastRef = useRef<any>(null);
  const removeValueInDropDown = () => {
    const data: any = {};
    data.query = {};
    setFilterValue('');
    dispatch(setSearchType(''));
    setShowLoader(true);
    if (searchVal === '') {
      getPaginationUsers(0, data.query)
        .then((response: AxiosResponse) => {
          dispatch(setUserStartingPageNo(0));
          dispatch(setCurrentIndex(0));
          dispatch(setUserData(response.data.user));
          dispatch(setTotalCount(response.data.len));
          setNextButtonStatus('NOT_LOADING');
          setShowLoader(false);
        })
        .catch(() => {});
    } else {
      data.query = {
        sortBy: 'updatedAt',
        pageNo: 0,
        searchedValue: searchVal,
      };
      getPaginationUsers(0, data.query)
        .then((response: AxiosResponse) => {
          dispatch(setUserStartingPageNo(0));
          dispatch(setCurrentIndex(0));
          dispatch(setUserData(response.data.user));
          dispatch(setTotalCount(response.data.len));
          setNextButtonStatus('NOT_LOADING');
          setShowLoader(false);
        })
        .catch(() => {});
    }
    setFilterValue('Filter');
    setButtonValue(false);
  };
  const onFilterQueryChange = (e: { value: any }) => {
    const data: any = {};
    data.query = {};
    const filter = e.value.name;
    const filterCode = e?.value?.code;
    setShowLoader(true);
    setFilterValue(filter);
    dispatch(setSearchType(filter));
    dispatch(setSearchCode(filterCode));
    setButtonValue(true);
    if (searchVal !== '') {
      data.query = {
        sortBy: 'updatedAt',
        pageNo: startingNo,
        role: filterCode,
        type: 'Roles',
        searchedValue: searchVal,
      };
    } else {
      data.query = {
        sortBy: 'updatedAt',
        pageNo: startingNo,
        role: filterCode,
        type: 'Roles',
      };
    }
    getPaginationUsers(0, data.query)
      .then((response: AxiosResponse) => {
        dispatch(setCurrentIndex(0));
        dispatch(setUserData(response.data.user));
        dispatch(setTotalCount(response.data.len));
        dispatch(setUserStartingPageNo(0));
        setSearchButtonStatus('NOT_LOADING');
        setShowLoader(false);
      })
      .catch(() => {});
  };
  const handleChange = (e: any) => {
    dispatch(setSearchValue(e.target.value));
  };
  const updateIndex = (index: number) => {
    if (overlayIndex === index) {
      setOverlayIndex(null);
    } else {
      setOverlayIndex(index);
    }
  };
  const handleNextPageClick = () => {
    setNextButtonStatus('LOADING');
    const data: any = {};
    data.query = {};
    if (isSearch === false) {
      getPaginationUsers(startingNo + 1, data.query)
        .then((response: AxiosResponse) => {
          dispatch(setUserStartingPageNo(startingNo + 1));
          dispatch(setCurrentIndex(currentIndex + 8));
          dispatch(setUserData(response.data.user));
          dispatch(setTotalCount(response.data.len));
          setNextButtonStatus('NOT_LOADING');
        })
        .catch(() => {});
    } else {
      if (searchType !== '') {
        data.query = {
          sortBy: 'updatedAt',
          pageNo: startingNo + 1,
          role: searchCode,
          type: 'Roles',
          searchedValue: searchVal,
        };
      } else {
        data.query = {
          sortBy: 'updatedAt',
          pageNo: startingNo + 1,
          searchedValue: searchVal,
        };
      }
      getPaginationUsers(startingNo - 1, data.query)
        .then((response: AxiosResponse) => {
          dispatch(setCurrentIndex(0));
          dispatch(setUserData(response.data.user));
          dispatch(setTotalCount(response.data.len));
          dispatch(setUserStartingPageNo(0));
          dispatch(setCurrentIndex(0));
          setSearchButtonStatus('NOT_LOADING');
        })
        .catch(() => {});
    }
  };
  const handlePreviousPageClick = () => {
    setPreviousButtonStatus('LOADING');
    const data: any = {};
    data.query = {};
    if (isSearch === false) {
      getPaginationUsers(startingNo - 1, data.query)
        .then((response: AxiosResponse) => {
          dispatch(setUserStartingPageNo(startingNo - 1));
          dispatch(setCurrentIndex(currentIndex - 8));
          dispatch(setUserData(response.data.user));
          dispatch(setTotalCount(response.data.len));
          setPreviousButtonStatus('NOT_LOADING');
        })
        .catch(() => {});
    } else {
      if (searchType !== '') {
        data.query = {
          sortBy: 'updatedAt',
          pageNo: startingNo - 1,
          role: searchCode,
          type: 'Roles',
          searchedValue: searchVal,
        };
      } else {
        data.query = {
          sortBy: 'updatedAt',
          pageNo: startingNo - 1,
          searchedValue: searchVal,
        };
      }
      getPaginationUsers(startingNo - 1, data.query)
        .then((response: AxiosResponse) => {
          dispatch(setCurrentIndex(0));
          dispatch(setUserData(response.data.user));
          dispatch(setTotalCount(response.data.len));
          dispatch(setUserStartingPageNo(0));
          dispatch(setCurrentIndex(0));
          setSearchButtonStatus('NOT_LOADING');
        })
        .catch(() => {});
    }
  };
  const searchUser: SubmitHandler<any> = (data) => {
    setSearchButtonStatus('LOADING');
    dispatch(setIsSearch(true));
    dispatch(setUserStartingPageNo(0));
    if (searchType !== '') {
      data.query = {
        sortBy: 'updatedAt',
        pageNo: 0,
        role: searchCode,
        type: 'Roles',
        searchedValue: searchVal,
      };
    } else {
      data.query = {
        sortBy: 'updatedAt',
        pageNo: 0,
        searchedValue: searchVal,
      };
    }
    getPaginationUsers(startingNo - 1, data.query)
      .then((response: AxiosResponse) => {
        dispatch(setCurrentIndex(0));
        dispatch(setUserData(response.data.user));
        dispatch(setTotalCount(response.data.len));
        dispatch(setUserStartingPageNo(0));
        dispatch(setCurrentIndex(0));
        setSearchButtonStatus('NOT_LOADING');
      })
      .catch(() => {});
  };
  const handleinviteNewUserClick = () => setDisplayResponsive(true);
  const handleCloseinviteNewUserClick = () => setDisplayResponsive(false);
  const refreshInviteData = () => {
    setValue('role', '');
    setValue('email', '');
  };
  const onSubmit: SubmitHandler<any> = async (data: any) => {
    setInviteButtonStatus('LOADING');
    // const regEx = /\S+@\S+\.\S+/;
    if (!emailRegex.test(data.email) || !data.email.endsWith('presidio.com')) {
      setError('email', { message: 'Enter a vaild email' });
    } else {
      await inviteUser(data).then((response: any) => {
        if (!response) {
          toastRef.current.show({
            severity: 'error',
            summary: 'User not invited',
            detail: 'There was an error while adding the user',
          });
        }
        if (response.data.message) {
          toastRef.current.show({
            severity: response.data.severity,
            summary: response.data.summary,
            detail: response.data.message,
          });
        }
      });
      refreshInviteData();
      setInviteButtonStatus('LOADED');
      handleCloseinviteNewUserClick();
    }
    setInviteButtonStatus('NOT_LOADING');
  };
  return (
    <div className="list-role-container w-full">
      <div className="header flex align-items-center justify-content-between">
        <div className="title font-semibold">Roles</div>
        <div className="filter-container flex">
          <form onSubmit={handleSubmit(searchUser)} className="flex flex-row">
            <div className="filter-fields">
              <div className="p-inputgroup">
                <span className="search p-input-icon-left">
                  <i className="pi pi-search" />
                  <InputText
                    {...register('searchValue')}
                    type="string"
                    placeholder="Search by Name or Email"
                    onChange={handleChange}
                  />
                </span>
              </div>
            </div>
            <div className="search-drive mr-2">
              <SpinCheckButton
                styleClass="search-drive-button font-medium"
                loadingStatus={searchButtonStatus}
                onClick={searchUser}
                label="Search"
                icons="pi pi-search"
              />
            </div>
            <div className="filter-fields">
              <div className="p-inputgroup">
                <span className="p-input-icon-left flex align-items-center">
                  {showLoader && (
                  <span>
                    <i className="pi pi-spin pi-spinner" style={{ color: '#71717A', marginLeft: '15px' }} />
                  </span>
                  )}
                  {!showLoader && (
                  <img src={FilterIcon} className="filter-icon" alt="Filter" />)}
                  <Dropdown
                    value={userFilter}
                    options={userFilterOptions}
                    onChange={onFilterQueryChange}
                    optionLabel="name"
                    placeholder={userFilter}
                  />
                  {showCloseButton && (
                  <span onClick={removeValueInDropDown}>
                    <i
                      className="pi pi-times-circle"
                      style={{
                        color: '#8B0000', marginRight: '15px',
                      }}
                    />
                  </span>
                  )}
                </span>
              </div>
            </div>
          </form>
          <div className="new-user">
            <Button
              className="new-user-button font-medium justify-content-center"
              onClick={handleinviteNewUserClick}
              label="Invite new user"
            />
          </div>
        </div>
      </div>
      {
        (users != null && users!.length > 0)
        && (
        <>
          <div className="list-candidates-container w-full">
            <div className="list-candidates-table w-full">
              <div className="row-header row w-full">
                <div className="name cell">NAME</div>
                <div className="role cell">ROLE</div>
                <div className="email cell">EMAIL</div>
                <div className="elipsis cell" />
              </div>
              {listUsers?.map((user: IUser, index: number) => {
                if (index >= startingUser && index < startingUser + 7) {
                  return (
                    <ListUserRow
                      key={user.id}
                      index={index + 1}
                      user={user}
                      overlayClick={updateIndex}
                      overlayIndex={overlayIndex}
                    />
                  );
                }
                return <div key={user.id} />;
              })}
            </div>
            <div className="footer w-full flex align-items-center justify-content-between">
              <div className="result-details">
                {listUsers != null
                  && `Showing ${listUsers!.length === 0 ? 0 : currentIndex + 1} to ${totalCount < currentIndex + 8 ? totalCount : currentIndex + 8} of ${totalCount} results`}
              </div>
              <div className="pagination-buttons flex">
                {startingNo !== 0 && (
                <div className="mr-3">
                  <SpinCheckButton
                    styleClass=""
                    label="Previous"
                    loadingStatus={previousButtonStatus}
                    onClick={handlePreviousPageClick}
                    icons="pi pi-arrow-left"
                  />
                </div>
                )}
                {totalCount - userLen !== currentIndex && (
                <div className="">
                  <SpinCheckButton
                    styleClass=""
                    label="Next"
                    loadingStatus={nextButtonStatus}
                    onClick={handleNextPageClick}
                    icons="pi pi-arrow-right"
                  />
                </div>
                )}
              </div>
            </div>
          </div>
          <Dialog
            header="&nbsp;Invite New User"
            className="dialog-box list-user-dialog"
            visible={displayResponsive}
            style={{
              width: '30%',
              height: '320px',
              borderRadius: '9px',
              paddingBottom: '3px',
            }}
            onHide={handleCloseinviteNewUserClick}
          >
            <form
              className="dialog-align invite-user"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="field align-items-center justify-content-between">
                <div className="field split email">
                  <div className="label email-label">Email</div>
                  <div className="email-field-input">
                    <InputText
                      {...register('email', {
                        required: 'Email is required.',
                        pattern: {
                          value: emailRegex,
                          message: 'Enter a vaild presidio email',
                        },
                      })}
                      type="email"
                      value={email}
                      className="input-text"
                      placeholder="Enter email here"
                      style={{ width: '100%' }}
                    />
                    {errors.email?.type === 'required' ? (
                      <small className="error-label p-error">
                        {errors.email.message}
                      </small>
                    ) : (
                      ''
                    )}
                    {errors.email?.type === 'pattern' ? (
                      <small className="error-label p-error">
                        {errors.email.message}
                      </small>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
                <div className="field split email">
                  <div className="label email-label">Role</div>
                  <div className="email-field-input">
                    <Dropdown
                      {...register('role', { required: 'Role is required.' })}
                      style={{ width: '100%', fontSize: '14px' }}
                      value={role}
                      options={rolesList}
                      placeholder="Select a Role"
                    />
                    {errors.email?.type === 'required' ? (
                      <small className="error-label p-error">
                        {errors.email.message}
                      </small>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
                <div className="add-button-container w-full flex align-items-center justify-content-end">
                  <div className="new-user">
                    <SpinCheckButton
                      styleClass="new-user-button font-medium"
                      loadingStatus={inviteButtonStatus}
                      label="Invite User"
                      onClick={handleSubmit(onSubmit)}
                    />
                  </div>
                </div>
              </div>
            </form>
          </Dialog>
        </>
        )
      }
      {
              (users != null && users!.length === 0)
              && (
                <div className="empty-roles flex align-items-center justify-content-center">
                  No users added yet.
                </div>
              )
      }

      <Toast ref={toastRef} />
    </div>
  );
}
