import React, { useEffect, useState } from 'react';
import './CandidateNavbar.css';
// import { AxiosResponse } from 'axios';
import jwtDecode from 'jwt-decode';
import PresidioHireLogo from '../../assets/icons/PresidioHireLogo.svg';
import { useAppSelector } from '../../store/storeHooks';
import Timer from '../timer/Timer';
// import { getAuthData } from '../../services/candidateService';
import { getJWT } from '../../services/authService';

type candiadateProps ={
  time :any,
}
function CandidateNavbar({ time }:candiadateProps) {
  const remaining = useAppSelector((state) => state.examSession.timeRemaining);
  const isRunning = useAppSelector((state) => state.examSession.isRunning);
  const [name, setName] = useState<string>('');

  useEffect(() => {
    // getAuthData()
    //   .then((response:AxiosResponse) => {
    //     setName(response.data.authorizer.claims.name);
    //   })
    const decode: {userId: string,
          email: string,
          name: string,
          sub: string,
          roleId: number,
          role: string,
          sessionId: string
      } = jwtDecode(getJWT());
    setName(decode.name);
  }, [])

  return (
    <div className="candidate-nav-comp w-full shadow-1 flex align-items-center justify-content-between">
      <div className="presidio-hire-logo-container">
        <img src={PresidioHireLogo} className="presidio-hire-logo" alt="Presidio Hire Logo" />
      </div>
      <div className="nav-row-container">
        {
        time === 'true' && (
          <div className="timer-container">

            { isRunning && (
            <div className="timedisplay">
              <Timer time={remaining} />
            </div>
            )}
          </div>
        )

      }
        <div className="candidate-profile-container">
          <div className="candidate-profile-name font-medium">
            {name}
          </div>
          <div className="candidate-profile-role">Candidate</div>
        </div>
      </div>
    </div>
  );
}

export default CandidateNavbar;
