/* eslint-disable function-paren-newline */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useRef, useState } from 'react';
import { AxiosResponse } from 'axios';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router';
import { Toast } from 'primereact/toast';
import { useParams } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import RoutesConstants from '../../../../constants/routesConstants';
import './ListExams.css';
import { ExamListSkeleton, ListExamRow, SpinCheckButton } from '../../../../components';
import { useAppDispatch, useAppSelector } from '../../../../store/storeHooks';
import RolesConstants from '../../../../constants/rolesContants';
import {
  getPagenationExam,
  getExamDistinctYears,
} from '../../../../services/examService';
import { IListExam } from '../../../../models/IListExam';
import {
  setSearchType,
  setExamStatusId, setExamStartingNo, setCurrentDataIndex, setIsSearchData, setTotalCountData,
} from '../../../../store/examStore';
import FilterIcon from '../../../../assets/icons/FilterIcon.svg';
import { IExam } from '../../../../models/IExam';

export default function ListExams() {
  const toast = useRef<any>(null);
  const rolesConstants = new RolesConstants();
  const routesConstants = new RoutesConstants();
  const navigate = useNavigate();
  const authRole = useAppSelector((state) => state.auth.role);
  const [examList, setExamList] = useState<IListExam[] | null>(null);
  const [userRole, setUserRole] = useState('');
  const { campusDriveId } = useParams();
  const startingNo = useAppSelector((state) => state.exams.examStartingNo);
  const currentIndex = useAppSelector((state) => state.exams.currentDataIndex);
  const examStatusId = useAppSelector((state) => state.exams.examStatusId);
  const totalCount = useAppSelector((state) => state.exams.totalCount);
  const isSearch = useAppSelector((state) => state.exams.isSearch);
  const [previousButtonStatus, setPreviousButtonStatus] = useState<'LOADING' | 'LOADED' | 'NOT_LOADING'>('NOT_LOADING');
  const [nextButtonStatus, setNextButtonStatus] = useState<'LOADING' | 'LOADED' | 'NOT_LOADING'>('NOT_LOADING');
  const [searchButtonStatus, setSearchButtonStatus] = useState<'LOADING' | 'LOADED' | 'NOT_LOADING'>('NOT_LOADING');
  const toastRef = useRef<any>(null);
  const [searchValue, setSearchValue] = useState<string>('');
  const [showLoader, setShowLoader] = useState(false);
  const [userFilterOptions, setUserFilterOptions] = useState([]);
  const [userFilter, setFilterValue] = useState<any>('Filter');
  const [showCloseButton, setButtonValue] = useState(false);
  const searchType = useAppSelector((state) => state.exams.searchType);
  const dispatch = useAppDispatch();
  let driveLen: number = 0;
  if (examList != null) {
    driveLen = examList.length;
  }
  if (campusDriveId != null) {
    dispatch(setExamStatusId(0))
    dispatch(setExamStartingNo(0))
  }
  useEffect(() => {
    const data: any = {};
    data.query = {
      sortBy: 'updatedAt,DESC',
      pageNo: startingNo,
      statusId: examStatusId,
      examName: searchValue,
      campusDriveId,
      yearSelected: (new Date().getFullYear()).toString(),
    };

    getPagenationExam(data.query)
      .then((response: AxiosResponse) => {
        let { exams } = response.data;
        const count = response.data.len;
        if (campusDriveId != null) {
          dispatch(setExamStatusId(0))
          exams = exams.filter((exam: IListExam) => exam.campusDriveId === campusDriveId);
        }
        dispatch(setTotalCountData(count));
        setExamList(exams);
      })
      .catch(() => {
        setExamList([]);
        toast.current.show({
          severity: 'error',
          summary: 'Something went wrong!',
          detail: 'Please try again later.',
          life: 3000,
        })
      });

    getExamDistinctYears()
      .then((response: AxiosResponse) => {
        console.log(response);
        const FilterOptions = (response.data.distinctYears).map((distinctYears: IExam) =>
          ({ name: distinctYears.examDate }),
        );
        setUserFilterOptions(FilterOptions);
      })
      .catch(() => {
        toastRef.current.show({
          severity: 'error',
          summary: 'Something went wrong',
          detail: 'Please try again later.',
        });
      });

    setUserRole((authRole === rolesConstants.ADMIN) ? 'Admin' : (authRole === rolesConstants.SUPER_EVALUATOR) ? 'Super Evaluator' : (authRole === rolesConstants.EVALUATOR) ? 'Evaluator' : '');
  }, []);

  const handleNewExamClick = () => {
    dispatch(setSearchType(0));
    dispatch(setExamStartingNo(0));
    navigate(routesConstants.EXAMS + routesConstants.EXAM_CREATE);
  };

  const onFilterQueryChange = (e: { value: any }) => {
    const filter = e.value.name;

    setShowLoader(true);
    setFilterValue(filter);
    dispatch(setSearchType(filter));

    setButtonValue(true);
    const data: any = {};

    if (searchValue !== '') {
      data.query = {
        sortBy: 'updatedAt,DESC',
        pageNo: startingNo,
        statusId: examStatusId,
        examName: searchValue,
        campusDriveId,
        yearSelected: filter,
      };
    } else {
      data.query = {
        sortBy: 'updatedAt,DESC',
        pageNo: startingNo,
        statusId: examStatusId,
        campusDriveId,
        yearSelected: filter,
      };
    }

    getPagenationExam(data.query)
      .then((response: AxiosResponse) => {
        dispatch(setExamStartingNo(0));
        dispatch(setCurrentDataIndex(0));
        const { exams } = response.data;
        setExamList(exams);
        dispatch(setTotalCountData(response.data.len));
        setNextButtonStatus('NOT_LOADING');
        setShowLoader(false);
      })
      .catch(() => {});
  };

  const removeValueInDropDown = () => {
    const data: any = {};
    if (searchValue !== '') {
      data.query = {
        sortBy: 'updatedAt,DESC',
        pageNo: startingNo,
        statusId: examStatusId,
        examName: searchValue,
        campusDriveId,
      };
    } else {
      data.query = {
        sortBy: 'updatedAt,DESC',
        pageNo: startingNo,
        statusId: examStatusId,
        campusDriveId,
      };
    }
    getPagenationExam(data.query)
      .then((response: AxiosResponse) => {
        dispatch(setExamStartingNo(0));
        dispatch(setCurrentDataIndex(0));
        const { exams } = response.data;
        setExamList(exams);
        dispatch(setTotalCountData(response.data.len));
        setNextButtonStatus('NOT_LOADING');
        setShowLoader(false);
      })
      .catch(() => {});

    setFilterValue('');
    dispatch(setSearchType(0));
    setShowLoader(true);
    setFilterValue('Filter');
    setButtonValue(false);
    console.log('entered into removeValueInDropDown');
  };

  const handleNextPageClick = () => {
    setNextButtonStatus('LOADING');
    const data: any = {};

    if (searchType !== 0) {
      data.query = {
        sortBy: 'updatedAt,DESC',
        pageNo: startingNo + 1,
        statusId: examStatusId,
        examName: searchValue,
        campusDriveId,
        yearSelected: searchType,
      };
    } else if (searchType === 0 && searchValue === null) {
      data.query = {
        sortBy: 'updatedAt,DESC',
        pageNo: startingNo + 1,
        statusId: examStatusId,
        examName: searchValue,
        campusDriveId,
        yearSelected: (new Date().getFullYear()).toString(),
      };
    } else {
      data.query = {
        sortBy: 'updatedAt,DESC',
        pageNo: startingNo + 1,
        statusId: examStatusId,
        examName: searchValue,
        campusDriveId,
      };
    }

    if (isSearch === false) {
      getPagenationExam(data.query)
        .then((response: AxiosResponse) => {
          dispatch(setExamStartingNo(startingNo + 1));
          dispatch(setCurrentDataIndex(currentIndex + 8));
          const { exams } = response.data;
          setExamList(exams);
          dispatch(setTotalCountData(response.data.len));
          setNextButtonStatus('NOT_LOADING');
        })
        .catch((e) => {
          toastRef.current.show({
            severity: 'error',
            summary: 'Something went wrong',
            detail: e.response.data.message,
          });
        });
    } else {
      getPagenationExam(data.query).then((response) => {
        dispatch(setExamStartingNo(startingNo + 1));
        dispatch(setCurrentDataIndex(currentIndex + 8));
        const { exams } = response.data;
        setExamList(exams);
        dispatch(setTotalCountData(response.data.len));
        setNextButtonStatus('NOT_LOADING');
      })
        .catch((e) => {
          toastRef.current.show({
            severity: 'error',
            summary: 'Something went wrong',
            detail: e.response.data.message,
          });
        })
    }
  }

  const handlePreviousPageClick = () => {
    setPreviousButtonStatus('LOADING');

    const data: any = {};

    if (searchType !== 0) {
      data.query = {
        sortBy: 'updatedAt,DESC',
        pageNo: startingNo - 1,
        statusId: examStatusId,
        examName: searchValue,
        campusDriveId,
        yearSelected: searchType,

      };
    } else if (searchType === 0 && searchValue === null) {
      data.query = {
        sortBy: 'updatedAt,DESC',
        pageNo: startingNo - 1,
        statusId: examStatusId,
        examName: searchValue,
        campusDriveId,
        yearSelected: (new Date().getFullYear()).toString(),
      };
    } else {
      data.query = {
        sortBy: 'updatedAt,DESC',
        pageNo: startingNo - 1,
        statusId: examStatusId,
        examName: searchValue,
        campusDriveId,
      };
    }

    if (isSearch === false) {
      getPagenationExam(data.query)
        .then((response: AxiosResponse) => {
          dispatch(setExamStartingNo(startingNo - 1));
          dispatch(setCurrentDataIndex(currentIndex - 8));
          const { exams } = response.data;
          setExamList(exams);
          dispatch(setTotalCountData(response.data.len));
          setPreviousButtonStatus('NOT_LOADING');
        })
        .catch((e) => {
          toastRef.current.show({
            severity: 'error',
            summary: 'Something went wrong',
            detail: e.response.data.message,
          });
        });
    } else {
      getPagenationExam(data.query).then((response) => {
        dispatch(setExamStartingNo(startingNo - 1));
        dispatch(setCurrentDataIndex(currentIndex - 8));
        const { exams } = response.data;
        setExamList(exams);
        dispatch(setTotalCountData(response.data.len));
        setNextButtonStatus('NOT_LOADING');
      })
        .catch((e) => {
          toastRef.current.show({
            severity: 'error',
            summary: 'Something went wrong',
            detail: e.response.data.message,
          });
        })
    }
  }
  const searchExam = () => {
    setSearchButtonStatus('LOADING');
    dispatch(setCurrentDataIndex(0));
    dispatch(setIsSearchData(true));
    dispatch(setExamStartingNo(0));
    setSearchValue(searchValue);

    const data: any = {};

    if (searchType !== 0) {
      data.query = {
        sortBy: 'updatedAt,DESC',
        pageNo: startingNo,
        statusId: examStatusId,
        examName: searchValue,
        campusDriveId,
        yearSelected: searchType,
      };
    } else if (searchType === 0 && searchValue === null) {
      data.query = {
        sortBy: 'updatedAt,DESC',
        pageNo: startingNo,
        statusId: examStatusId,
        examName: searchValue,
        campusDriveId,
        yearSelected: (new Date().getFullYear()).toString(),
      };
    } else {
      data.query = {
        sortBy: 'updatedAt,DESC',
        pageNo: startingNo,
        statusId: examStatusId,
        examName: searchValue,
        campusDriveId,
      };
    }

    getPagenationExam(data.query).then((response) => {
      if (response.data.exams.length !== 0) {
        const { exams } = response.data;
        setExamList(exams);
        dispatch(setTotalCountData(response.data.len));
        dispatch(setExamStartingNo(0));
        dispatch(setCurrentDataIndex(0));
      } else {
        setSearchValue('');
        toastRef.current.show({
          severity: 'info',
          summary: 'Not Found',
          detail: 'There is no data found',
        })
      }
      setSearchButtonStatus('NOT_LOADING');
    })
      .catch((e) => {
        toastRef.current.show({
          severity: 'error',
          summary: 'Something went wrong',
          detail: e.response.data.message,
        });
      })
  }
  const handleChange = (e: any) => {
    console.log(e.target.value);
    setSearchValue(e.target.value);
  };
  return (
    <div className="exams-list-view h-full w-full">
      <Toast ref={toast} />
      <div className="header flex align-items-center justify-content-between">
        { (examStatusId === 0) && (
          <div className="title font-semibold">
            All Exams
          </div>
        )}
        { (examStatusId === 1) && (
          <div className="title font-semibold">
            Upcoming Exams
          </div>
        )}
        { (examStatusId === 2) && (
          <div className="title font-semibold">
            Ongoing Exams
          </div>
        )}
        { (examStatusId === 3) && (
          <div className="title font-semibold">
            Completed Exams
          </div>
        )}
        <div className="filter-container flex">
          <div className="flex flex-row">
            <div className="filter-fields">
              <div className="p-inputgroup">
                <span className="search p-input-icon-left">
                  <i className="pi pi-search" />
                  <InputText
                    onInput={(e: any) => setSearchValue(e.target.value)}
                    type="string"
                    placeholder="Search by Name"
                    value={searchValue}
                    onChange={handleChange}
                  />
                </span>
              </div>
            </div>
            <div className="search-drive mr-2">
              <SpinCheckButton styleClass="search-drive-button font-medium" loadingStatus={searchButtonStatus} onClick={searchExam} label="Search" icons="pi pi-search" />
            </div>
            <div className="filter-fields">
              <div className="p-inputgroup">
                <span className="p-input-icon-left flex align-items-center">
                  {showLoader && (
                  <span>
                    <i className="pi pi-spin pi-spinner" style={{ color: '#71717A', marginLeft: '15px' }} />
                  </span>
                  )}
                  {!showLoader && (
                  <img src={FilterIcon} className="filter-icon" alt="Filter" style={{ marginLeft: '15px' }} />)}
                  <Dropdown
                    value={userFilter}
                    options={userFilterOptions}
                    onChange={onFilterQueryChange}
                    optionLabel="name"
                    placeholder={userFilter}
                  />
                  {showCloseButton && (
                  <span onClick={removeValueInDropDown}>
                    {' '}
                    <i
                      className="pi pi-times-circle"
                      style={{
                        color: '#8B0000', marginRight: '15px',
                      }}
                    />
                    {' '}
                  </span>
                  )}
                </span>
              </div>
            </div>
          </div>
          {
                (userRole === 'Admin') && (
                <div className="new-exam">
                  <Button className="new-exam-button font-medium" onClick={handleNewExamClick} label="Create new exam" />
                </div>
                )
            }
        </div>
      </div>
      {
            (examList == null)
            && <ExamListSkeleton />
        }
      {
            (examList != null && examList!.length > 0)
            && (
            <>
              <div className="exam-list-container w-full h-auto flex flex-column align-items-center justify-content-start">
                <div className="list-header flex w-full">
                  <div className="cell campus-drive-col">
                    CAMPUS DRIVE
                  </div>
                  <div className="cell col">
                    EXAM NAME
                  </div>
                  <div className="cell col">
                    TIME
                  </div>
                  {userRole === 'Admin' && (
                  <div className="cell  student-col">
                    STUDENTS
                  </div>
                  )}
                  {/* eslint-disable-next-line no-mixed-operators */}
                  {(userRole === 'Evaluator' || userRole === 'Super Evaluator') && (
                  <div className="cell col">
                    PENDING VS TOTAL QUESTIONS
                  </div>
                  )}
                  <div className="cell col">
                    {(userRole === 'Admin' || userRole === 'Super Evaluator') && (
                    <div className="view-exam h-full" />
                    )}
                    <div className="view-exam h-full" />
                    {(userRole === 'Admin' || userRole === 'Super Evaluator') && (
                    <div className="view-exam h-full" />
                    )}
                  </div>
                </div>

                {examList!.map((exam: IListExam, index) => (
                  <ListExamRow
                    key={exam.id}
                    shade={index}
                    lastItem={(index + 1) === examList!.length}
                    examData={exam}
                    userRole={userRole}
                  />
                ))}
              </div>
              <div className="footer w-full flex align-items-center justify-content-between">
                <div className="exam-drive-result-details">
                  {examList != null && `Showing ${(examList!.length === 0) ? 0 : (currentIndex + 1)} to ${(totalCount < currentIndex + 8) ? (totalCount) : (currentIndex + 8)} of ${totalCount} results`}
                </div>
                <div className="pagination-buttons flex align-items-center justify-content-between">
                  {(startingNo !== 0)
                          && (
                          <div className="mr-3">
                            <SpinCheckButton styleClass="previous-button" label="Previous" loadingStatus={previousButtonStatus} onClick={handlePreviousPageClick} />
                          </div>
                          )}
                  {((totalCount - driveLen) !== currentIndex)
                          && (
                          <div className="">
                            <SpinCheckButton styleClass="" label="Next" loadingStatus={nextButtonStatus} onClick={handleNextPageClick} />
                          </div>
                          )}
                </div>
              </div>
            </>
            )
        }
      {
            (examList != null && examList!.length === 0)
            && (
            <div className="no-data w-full flex align-items-center justify-content-center">
              <div className="no-exam-content">No exams assigned to you yet.</div>
            </div>
            )
        }
      <Toast ref={toastRef} />
    </div>
  );
}
