import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Button } from 'primereact/button';
import { useAppDispatch, useAppSelector } from '../../store/storeHooks';
import Navigator, { questionNavigatorType } from '../navigator/Navigator';
import CandidateQuestionDisplay from './candidateQuestionDisplay/CandidateQuestionDisplay';
import './CandidateTakingExam.css'
import RoutesConstants from '../../constants/routesConstants';
import { setQuesId } from '../../store/questionCounter';

export default function CandidateTakingExam() {
  const questionsId: { id: string, bookmark: boolean, answered: boolean }[] = useAppSelector((state) => state.counter.quesId);
  const examSessionId = useAppSelector((state) => state.counter.examSession)
  const [questionNavigator, setQuestionNavigator] = useState<questionNavigatorType[] | null>(null);
  const [visibleRight, setVisibleRight] = useState(true);
  const [selectedQuestion, setSelectedQuestion] = useState<questionNavigatorType | null>();
  const [onReviewClick, setOnReviewClick] = useState<boolean>(false);
  const navigate = useNavigate();
  const routesConstant = new RoutesConstants();
  const dispatch = useAppDispatch();

  const reviewHandler = () => {
    if (questionNavigator) {
      dispatch(setQuesId(questionNavigator.map((question) => ({ id: question.id, bookmark: question.marked, answered: question.answered }))));
      navigate(`${routesConstant.CANDIDATE}${routesConstant.CANDIDATE_REVIEW}`);
    }
  }

  useEffect(() => {
    if (!(questionsId && examSessionId)) navigate(`${routesConstant.CANDIDATE}${routesConstant.CANDIDATE_START}`);
  }, [])

  useEffect(() => {
    if (!questionNavigator) {
      setQuestionNavigator(questionsId.map((q, index) => ({
        id: q.id,
        order: index + 1,
        category: null,
        default: true,
        selected: false,
        marked: q.bookmark,
        answered: q.answered,
      })));
    }
  }, [questionsId]);

  return (
    <div className="w-full">
      { questionNavigator
            && (
            <Navigator
              visiblePanel={visibleRight}
              setVisiblePanel={setVisibleRight}
              questionNavigator={questionNavigator}
              selectedQuestion={selectedQuestion}
              setSelectedQuestion={setSelectedQuestion}
              dropdown={false}
              setOnReviewClick={setOnReviewClick}
            />
            )}
      <div className="candidate-taking-exam-question-display w-full">
        { (selectedQuestion && questionNavigator) && (
        <CandidateQuestionDisplay
          session={examSessionId}
          setSelectedQuestion={setSelectedQuestion}
          selectedQuestion={selectedQuestion}
          questionNavigator={questionNavigator}
          setQuestionNavigator={setQuestionNavigator}
          reviewHandler={reviewHandler}
          setOnReviewClick={setOnReviewClick}
          onReviewClick={onReviewClick}
        />
        ) }
      </div>
      <div className="markSheet-navigator">
        <Button icon="pi pi-arrow-left" onClick={() => setVisibleRight(true)} className="p-button-rounded p-button-info" />
      </div>
    </div>
  );
}
