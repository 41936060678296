import React, { } from 'react';
import './ListCampusDriveRow.css';
import { Link } from 'react-router-dom';
import { IListCampusDrives } from '../../models/IListCampusDrives';
import { ListExamRowSkeletion } from '../skeleton';
import RoutesConstants from '../../constants/routesConstants';

type IListCampusDriveRowProp = {
  shade: number;
  lastItem: boolean;
  campusDriveData: IListCampusDrives;
}

function ListCampusDriveRow({ shade, lastItem, campusDriveData }: IListCampusDriveRowProp) {
  const routesConstant = new RoutesConstants();

  return (
    <>
      {
        (campusDriveData.examCount == null || campusDriveData.studentCount == null) && (<ListExamRowSkeletion shade={shade} lastItem={lastItem} />)
      }
      {
        (campusDriveData.examCount != null && campusDriveData.studentCount != null)
        && (
          <div className={`list-row flex w-full ${shade % 2 === 0 ? 'shade' : ''} ${lastItem ? 'last-item' : ''}`}>
            <div className="cell campus-drive-col align-content-center">{campusDriveData.campusDriveName}</div>
            <div className="cell university-col">{campusDriveData.collegeName}</div>
            <div className="cell exams-col">{campusDriveData.examCount}</div>
            <div className="cell col-2">{`${campusDriveData.studentCount} Students`}</div>
            <div className="view-campus-drive h-full flex align-items-center">
              <Link to={`${routesConstant.CAMPUS_DRIVE}/${campusDriveData.campusDriveId}${routesConstant.EXAM_EDIT}`} className="no-underline">Edit</Link>
            </div>
            <div className="view-campus-drive h-full flex align-items-center">
              <Link to={`${routesConstant.EXAMS + routesConstant.CAMPUS_DRIVE}/${campusDriveData.campusDriveId}`} className="no-underline">View</Link>
            </div>
          </div>
        )
      }
    </>
  );
}

export default ListCampusDriveRow;
