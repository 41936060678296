import React, { useEffect } from 'react';
import './CandidateEnd.css';

// eslint-disable-next-line no-unused-vars
import { useNavigate } from 'react-router';
import icondone from '../../../assets/icons/icondone.svg';
import CandidateNavbar from '../../../components/candidateNavbar/CandidateNavbar';

export default function CandidateEnd() {
  const navigate = useNavigate();
  useEffect(() => {
    // localStorage.clear();
    addEventListener(('popstate'), () => {
      localStorage.clear();
      navigate('/candidate/candidate-login')
    })
  }, [])
  return (
    <div className="candidate-end-page-container">
      <CandidateNavbar time="true" />
      <div className="candidate-end-page-content">
        <div className="iconposition">
          <div className="succeslogo">
            <img src={icondone} className="ticklogo" alt="Presidio Hire Logo" />
          </div>
        </div>
        <div className="candidate-end">
          Thank you for taking the test with Presidio!
        </div>
        <div className="base-candidate-end ">
          We will contact you with your results soon. You can safely&nbsp;
          <b>close this screen.</b>
        </div>
      </div>
    </div>
  );
}
