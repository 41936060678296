import React, { useEffect } from 'react';
import { Button } from 'primereact/button';
import { Sidebar } from 'primereact/sidebar';
import { evaluationNavigatorType } from '../evaluation';
import ResponseNavigator from './responseNavigator/responseNavigator';

type EvaluationNavigationProps = {
  visiblePanel: boolean,
  setVisiblePanel: Function,
  responseNavigator: evaluationNavigatorType[],
  setResponseNavigator: Function,
  selectedResponse: evaluationNavigatorType | null | undefined,
  setSelectedResponse: Function,
}
export default function EvaluationNavigation({
  visiblePanel,
  setVisiblePanel,
  responseNavigator, selectedResponse, setSelectedResponse, setResponseNavigator,
}: EvaluationNavigationProps) {
  const applyNavigatorStyles = (navigatorResponses: evaluationNavigatorType[]) => {
    let select;
    if (selectedResponse) {
      select = navigatorResponses.map((q) => {
        if (q.responseId === selectedResponse.responseId) {
          q.default = false;
          q.selected = true;
        } else {
          q.default = true;
          q.selected = false;
        }
        return q;
      });
    } else {
      return navigatorResponses;
    }
    return select;
  }

  useEffect(() => {
    if (!selectedResponse) setSelectedResponse(responseNavigator[0]);
  }, [responseNavigator]);

  useEffect(() => {
    setResponseNavigator(applyNavigatorStyles([...responseNavigator]));
  }, [selectedResponse]);

  return (
    <Sidebar
      showCloseIcon={false}
      className="p-sidebar-sm navigator-panel"
      style={{ width: '25rem', height: '65%' }}
      dismissable
      modal={false}
      visible={visiblePanel}
      position="right"
      onHide={() => setVisiblePanel(false)}
    >
      <div className="candidate-mark-sheet-panel">
        <div className="candidate-mark-sheet-close">
          <Button icon="pi pi-arrow-right" onClick={() => setVisiblePanel(false)} className="p-button-outlined p-button-info" />
        </div>
        <div className="candidate-mark-sheet-questions">
          <div className="question-flex-heading">
            <div>
              <h2>RESPONSES</h2>
            </div>
            {/* <div style={{ marginTop: '6%', marginRight: '6%' }}> */}
            {/*   { !dropdown && ( */}
            {/*     <div> */}
            {/*       <Button style={{ height: '30px' }} label="Review" className="p-button-raised p-button-info p-button-sm markSheet-button" onClick={() => setOnReviewClick(true)} /> */}
            {/*     </div> */}
            {/*   )} */}
            {/* </div> */}
          </div>
          {/* { !dropdown && ( */}
          {/*   <div style={{ */}
          {/*     display: 'flex', flexFlow: 'row no-wrap', justifyContent: 'space-between', marginLeft: '5%', marginRight: '7%', */}
          {/*   }} */}
          {/*   > */}
          {/*     <div> */}
          {/*       <h5 style={{ margin: '0' }}> */}
          {/*         Answered: */}
          {/*         {answeredCount} */}
          {/*       </h5> */}
          {/*     </div> */}
          {/*     <div> */}
          {/*       <h5 style={{ margin: '0' }}> */}
          {/*         Unanswered: */}
          {/*         {unansweredCount} */}
          {/*       </h5> */}
          {/*     </div> */}
          {/*   </div> */}
          {/* )} */}
          <div className="question-flex-content">
            <ResponseNavigator
              sortedResponse={responseNavigator}
              setSortedResponse={setResponseNavigator}
              selectedResponse={selectedResponse}
              setSelectedResponse={setSelectedResponse}
            />
          </div>
        </div>
      </div>
    </Sidebar>
  );
}
