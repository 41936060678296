import React, { useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import { questionNavigatorType } from '../../navigator/Navigator';
import { getNextQuestion } from '../../../services/examService';
import MarkSheetQuestion from '../../markSheetQuestion/MarkSheetQuestion';
import { markSheetDisplayType } from '../../../views/adminView/candidateMarkSheet/CandidateMarkSheet';
import './CandidateQuestionDisplay.css'
import { useAppDispatch } from '../../../store/storeHooks';
import { setNavigatorLoader } from '../../../store/loaderStore';

type candidateQuestionDisplayProps = {
    selectedQuestion: questionNavigatorType,
    setSelectedQuestion: any
    session: any,
    questionNavigator: questionNavigatorType[],
    setQuestionNavigator: any,
    reviewHandler: any,
    setOnReviewClick: any,
    onReviewClick: boolean
};
export default function CandidateQuestionDisplay({
  selectedQuestion, session, setSelectedQuestion, questionNavigator, setQuestionNavigator, reviewHandler, setOnReviewClick, onReviewClick,
}: candidateQuestionDisplayProps) {
  const [questionDisplay, setQuestionDisplay] = useState<markSheetDisplayType | null>(null);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(setNavigatorLoader(true));
    getNextQuestion({ examsessionId: session.id, questionId: selectedQuestion.id }).then((response: AxiosResponse) => {
      setQuestionDisplay({
        order: selectedQuestion.order, question: response.data.question, response: response.data.res,
      })
      dispatch(setNavigatorLoader(false));
    }).catch(() => {
      // TODO: HANDLE QUESTION FETCH ERROR
      dispatch(setNavigatorLoader(false));
    });
  }, [selectedQuestion]);

  return (
    <div className="question-display-container">
      { questionDisplay && (
      <MarkSheetQuestion
        markSheetDisplay={questionDisplay}
        setMarkSheetDisplay={setQuestionDisplay}
        isCandidate
        navigator={questionNavigator}
        setNavigator={setQuestionNavigator}
        setSelectedQuestion={setSelectedQuestion}
        selectedQuestion={selectedQuestion}
        reviewHandler={reviewHandler}
        setOnReviewClick={setOnReviewClick}
        onReviewClick={onReviewClick}
      />
      )}
    </div>
  );
}
