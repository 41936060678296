/* eslint-disable react/no-array-index-key */
import React, { useEffect, useRef, useState } from 'react';
import './EvaluateExam.css';
import { Dropdown } from 'primereact/dropdown';
import { SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { AxiosResponse } from 'axios';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { toNumber } from 'lodash';
import { Toast } from 'primereact/toast';
import { getResponse } from '../../../../services/examService';
import { useAppSelector, useAppDispatch } from '../../../../store/storeHooks';
import IListReview from '../../../../models/IListReview';
import ListReviewRow from '../../../../components/listReviewRow/ListReviewRow';
import ReviewPageSkeleton from '../../../../components/skeleton/ReviewPageSkeleton/ReviewPageSkeleton';
import { getReviewDetails } from '../../../../services/evaluationService';
import SpinCheckButton from '../../../../components/spinCheckButton/SpinCheckButton';
import RoutesConstants from '../../../../constants/routesConstants';
import {
  setCategoryIdData, setCategoryValueData,
  setValidResponseIdsData,
} from '../../../../store/evaluationStore';
// import IResponse from '../../../../models/IResponse';

// import IResponse from '../../../../models/IResponse';

export default function EvaluateExam() {
  const [uploadButtonStatus, setUploadButtonStatus] = useState<'LOADING' | 'LOADED' | 'NOT_LOADING'>('NOT_LOADING');
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const toastRef = useRef<any>(null);
  const routesConstants = new RoutesConstants();
  const authName = useAppSelector((state) => state.auth.email);
  const examId = String(location.pathname.split('/').pop());
  const [examName, setExamName] = useState<string>('');
  const defaultValues = {
    categoryId: '',
    numOfQuestions: 0,
  }
  const column = ['CATEGORYNAME', 'NOTASSIGNED'];
  const {
    register, control, handleSubmit, formState: { errors }, setError, setValue,
  } = useForm({ defaultValues });

  const [ReviewList, setReviewList] = useState<IListReview[] | null>(null);
  useEffect(() => {
    if (ReviewList === null) {
      getReviewDetails(examId)
        .then((response: AxiosResponse) => {
          if (response) {
            setReviewList(response.data.data);
            setExamName(response.data.exam.name);
          } else {
            toastRef.current.show({
              severity: 'error',
              summary: 'Network Error',
              detail: 'Try again after some time.',
            });
          }
        })
    }
  }, [])

  const [categoryOptions, setCategoryOptions] = useState([]);
  const [maxNoQns, setMaxNoQns] = useState<number>(0);
  useEffect(() => {
  }, [location.pathname]);
  useEffect(() => {
    if (ReviewList != null) {
      const options: any = [];
      ReviewList.forEach((category) => {
        const option = {
          label: category.categoryName as string,
          value: category.categoryId as string,
        };
        options.push(option);
      });
      setCategoryOptions(options);
    }
  }, [ReviewList]);
  const maxLengthCheck = (object: any) => {
    if (object.target.value.length > object.target.maxLength) {
      object.target.value = object.target.value.slice(0, object.target.maxLength)
    }
  }
  const category = useWatch({
    control,
    name: 'categoryId',
  });

  const onSubmit: SubmitHandler<any> = (data: any) => {
    setUploadButtonStatus('LOADING');
    data.numOfQuestions = toNumber(data.numOfQuestions);
    data.emailId = authName;
    data.examId = examId;
    let error = false
    if (ReviewList) {
      ReviewList.forEach((element:any) => {
        if (element.categoryId === data.categoryId) {
          if (data.numOfQuestions > element.NOTASSIGNED) {
            error = true;
            setError('numOfQuestions', { type: 'pattern', message: 'Enter COUNT less than or equal to NOTASSIGNED value' })
            setUploadButtonStatus('NOT_LOADING');
          }
        }
      });
    }
    if (!error) {
      getResponse(data)
        .then((response: AxiosResponse) => {
          if (response) {
            const value = response.data.responses;
            if (value.length === 0) {
              setUploadButtonStatus('NOT_LOADING');
              toastRef.current.show({
                severity: 'error',
                summary: 'No Questions',
                detail: 'No Questions available on this category',
              });
            } else {
              dispatch(setValidResponseIdsData(response.data.responses));
              navigate(`${routesConstants.EXAMS}/${examId}${routesConstants.QUESTION}`);
            }
          } else {
            setUploadButtonStatus('NOT_LOADING');
            toastRef.current.show({
              severity: 'error',
              summary: 'Network Error',
              detail: 'Try again after some time.',
            });
          }
        })
        .catch(() => {});
      dispatch(setCategoryIdData(null));
      dispatch(setCategoryIdData(data.categoryId as string));
      categoryOptions.forEach((cat:any) => {
        if (cat.id === data.categoryId) {
          const value = cat.name;
          dispatch(setCategoryValueData(value.toLowerCase()));
        }
      })
    }
  }
  const categoryChange = (c:any) => {
    setValue('categoryId', c.value)
    if (ReviewList) {
      ReviewList.forEach((element:any) => {
        if (element.categoryId === c.value) {
          setMaxNoQns(element.NOTASSIGNED)
          if (element.NOTASSIGNED >= 1) {
            setValue('numOfQuestions', 1);
          } else {
            setValue('numOfQuestions', 0);
          }
        }
      });
    }
  }
  return (
    <div className="column-container">
      <div className="evaluate-exam-container h-full w- full">
        <div className="evaluate-exam-form">
          <form onSubmit={handleSubmit(onSubmit)}>
            <h1>{examName}</h1>
            <h5>Please select the categories and amount of questions you would like to evaluate. These questions would be assigned to you until the session is ended.</h5>
            <div className="field no-margin-top flex align-items-center justify-content-between">
              <div className="field category no-margin-top">
                <div className="entire-field">
                  <div className="label">
                    Select Categories
                  </div>
                  <Dropdown {...register('categoryId', { required: 'Category is required.' })} value={category} className="input" optionLabel="label" options={categoryOptions} onChange={categoryChange} placeholder="Select the categories you would like to evaluate" />
                </div>
              </div>
            </div>
            <div className="field no-margin-top flex align-items-center justify-content-between">
              <div className="field category no-margin-top">
                <div className="entire-field">
                  <div className="label">
                    No. of Questions
                  </div>
                  <input {...register('numOfQuestions', { required: 'No. of questions is required' })} className="point-field" type="number" maxLength={2} min="1" max={maxNoQns} placeholder="Enter the number of questions" onInput={maxLengthCheck} />
                  {
              errors.numOfQuestions?.type === 'pattern' ? (
                <small className="error-label p-error">
                  *
                  {errors.numOfQuestions.message}
                </small>
              ) : ''
             }
                </div>
              </div>
            </div>
            <div className="upload-button-container w-full flex align-items-center justify-content-end">
              <div className="upload-button">
                <SpinCheckButton styleClass="upload-button-item" loadingStatus={uploadButtonStatus} label="Start Evaluation" onClick={handleSubmit(onSubmit)} />
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="evaluate-Exam-Space p-5" />
      <div className="review-container">
        {(ReviewList == null)
          && (
          <ReviewPageSkeleton column={column} />
          )}
        {(ReviewList != null && ReviewList.length > 0)
          && (
            <div className="exam-list-container w-full h-auto flex flex-column align-items-center justify-content-start">
              <div className="list-header flex w-full ">
                {[...column].map((el, index) => (
                  <div key={`Header ${column[index]}`} className="cell" style={{ width: `${100 / column.length}%` }}>
                    {el.toUpperCase()}
                  </div>
                ))}
              </div>
              {ReviewList!.map((_review, index) => (
                <ListReviewRow
                  key={`Review Row ${ReviewList[index].categoryId}`}
                  shade={index}
                  lastItem={(index + 1) === ReviewList!.length}
                  reviewData={_review}
                  column={column}
                />
              ))}
            </div>
          )}
        {
        (ReviewList != null && ReviewList!.length === 0)
          && (
            <div className="no-data w-full flex align-items-center justify-content-center">
              <div className="no-exam-content">No Category data is avaliable.</div>
            </div>
          )
        }
      </div>
      <Toast ref={toastRef} />
    </div>
  );
}
