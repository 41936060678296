import React, { useRef, useState } from 'react';
import './AddCollege.css';
import { SubmitHandler, useForm } from 'react-hook-form';
import { InputText } from 'primereact/inputtext';
import { AxiosResponse } from 'axios';
import _ from 'lodash';
import { Toast } from 'primereact/toast';
import { ICollege } from '../../models/ICollege';
import { SpinCheckButton } from '../index';
import { ICreateCollege } from '../../models/ICreateCollege';
import {
  addUniversity, getAllUniversity,
  getPaginationUniversity,
  updateUniversity,
} from '../../services/campusDriveService';
import { useAppDispatch, useAppSelector } from '../../store/storeHooks';
import {
  setAllCollegeData,
  setCollegeData,
  setCollegeDialogVisible,
  setCollegeTotal,
  setUpdateCollegeData,
} from '../../store/settingsStore';
import { extractUniversities } from '../../hooks/collegeHook';

type IProps = {
  toast: any,
}

export default function AddCollege({ toast }: IProps) {
  const [addButtonStatus, setAddButtonStatus] = useState<'LOADING' | 'LOADED' | 'NOT_LOADING'>('NOT_LOADING');
  const emailRegex = /^[a-zA-Z]+[a-zA-Z0-9+_.-]+@[a-zA-Z.]+$/
  const whiteSpaceRegex = /.*\S.*/
  const updateCollegeData: ICollege | null = useAppSelector((state) => state.settings.updateCollegeData);
  const collegeStartingNo = useAppSelector((state) => state.settings.collegeStartingPageNo);
  const collegeDialogTitle = useAppSelector((state) => state.settings.collegeDialogTitle);
  const [noChangeError, setNoChangeError] = useState('');
  const dispatch = useAppDispatch();
  const toastRef = useRef<any>(null);

  const defaultValues = {
    name: '',
    contact: '',
    location: '',
    poc: '',
  }

  if (updateCollegeData != null) {
    defaultValues.name = updateCollegeData.name;
    defaultValues.contact = updateCollegeData.contact;
    defaultValues.location = updateCollegeData.location;
    defaultValues.poc = updateCollegeData.poc;
  }

  const defaultValueCopy = defaultValues;

  const {
    register, handleSubmit, formState: { errors },
  } = useForm({ defaultValues });

  const refreshCollegeData = () => {
    dispatch(setCollegeData(null));
    getPaginationUniversity(collegeStartingNo)
      .then((response: AxiosResponse) => {
        dispatch(setCollegeData(response.data.colleges as ICollege[]));
        dispatch(setCollegeTotal(response.data.len));
      })
      .catch((e) => {
        toastRef.current.show({
          severity: 'error',
          summary: 'Something went wrong',
          detail: e.response.data.message,
        });
      })
    getAllUniversity()
      .then((response: AxiosResponse) => {
        dispatch(setAllCollegeData(extractUniversities(response.data.colleges as ICollege[])));
      })
      .catch((e) => {
        toastRef.current.show({
          severity: 'error',
          summary: 'Something went wrong',
          detail: e.response.data.message,
        });
      });
  }

  const addCollege = (collegeData: ICreateCollege) => {
    addUniversity(collegeData)
      .then((response: AxiosResponse) => {
        if (response.status === 200) {
          setAddButtonStatus('LOADED');
          setTimeout(() => {
            dispatch(setCollegeDialogVisible(false));
            toast(
              response.data.status,
              response.data.summary,
              response.data.message,
            )
          });
          refreshCollegeData();
        } else {
          setTimeout(() => {
            dispatch(setCollegeDialogVisible(false));
            toast(
              'error',
              'Something went wrong',
              response.data.message,
            )
          });
        }
      })
      .catch((e) => {
        toastRef.current.show({
          severity: 'error',
          summary: 'Something went wrong',
          detail: e.response.data.message,
        });
      });
  }
  const updateCollege = (collegeData: ICreateCollege) => {
    updateUniversity(collegeData, updateCollegeData!.id)
      .then((response: AxiosResponse) => {
        if (response.status === 200) {
          setAddButtonStatus('LOADED');
          setTimeout(() => {
            dispatch(setCollegeDialogVisible(false));
            toast(
              'success',
              'Success',
              'College has been updated successfully',
            )
          });
          dispatch(setUpdateCollegeData(null));
          refreshCollegeData();
        } else {
          setTimeout(() => {
            dispatch(setCollegeDialogVisible(false));
            toast(
              'error',
              'Something went wrong',
              'Please try again later',
            )
          });
        }
      })
      .catch((e) => {
        toastRef.current.show({
          severity: 'error',
          summary: 'Something went wrong',
          detail: e.response.data.message,
        });
      });
  }

  const onSubmit: SubmitHandler<any> = (data: any) => {
    setAddButtonStatus('LOADING');
    const collegeData: ICreateCollege = {
      name: data.name,
      contact: data.contact,
      location: data.location,
      poc: data.poc,
    };
    if (updateCollegeData == null) {
      addCollege(collegeData);
    } else if (_.isEqual(collegeData, defaultValueCopy)) {
      setNoChangeError('No changes were made!');
      setAddButtonStatus('NOT_LOADING');
    } else {
      setNoChangeError('');
      updateCollege(collegeData);
    }
  };

  return (
    <div className="add-college-container h-full w-full">
      <div className="add-college-form h-full w-full">
        <form onSubmit={handleSubmit(onSubmit)} className="h-full w-full flex flex-column align-items-center justify-content-between">
          <div className="w-full">
            <div className="field name no-margin-top">
              <div className="label">
                Name
              </div>
              <InputText {...register('name', { required: 'College Name is required.', pattern: { value: whiteSpaceRegex, message: 'Invalid College Name' } })} className="input" type="text" placeholder="Enter college name" autoComplete="off" />
              {
                errors.name?.type === 'required' ? (<small className="error-label p-error">{errors.name.message}</small>) : ''
              }
              {
                errors.name?.type === 'pattern' ? (<small className="error-label p-error">{errors.name.message}</small>) : ''
              }
            </div>

            <div className="field name">
              <div className="label">
                Contact Email
              </div>
              <InputText {...register('contact', { required: 'Contact email is required.', pattern: { value: emailRegex, message: 'Contact Email is invalid' } })} className="input" type="text" placeholder="Enter contact email" autoComplete="off" />
              {
                errors.contact?.type === 'required' ? (<small className="error-label p-error">{errors.contact.message}</small>) : ''
              }
              {
                errors.contact?.type === 'pattern' ? (<small className="error-label p-error">{errors.contact.message}</small>) : ''
              }
            </div>

            <div className="field name">
              <div className="label">
                Location
              </div>
              <InputText {...register('location', { required: 'Location is required.', pattern: { value: whiteSpaceRegex, message: 'Invalid Location' } })} className="input" type="text" placeholder="Enter college location" autoComplete="off" />
              {
                errors.location?.type === 'required' ? (<small className="error-label p-error">{errors.location.message}</small>) : ''
              }
              {
                errors.location?.type === 'pattern' ? (<small className="error-label p-error">{errors.location.message}</small>) : ''
              }
            </div>

            <div className="field name">
              <div className="label">
                Point of Contact
              </div>
              <InputText {...register('poc', { required: 'Point of contact is required.', pattern: { value: whiteSpaceRegex, message: 'Invalid Point of Contact' } })} className="input" type="text" placeholder="Enter point of contact" autoComplete="off" />
              {
                errors.poc?.type === 'required' ? (<small className="error-label p-error">{errors.poc.message}</small>) : ''
              }
              {
                errors.poc?.type === 'pattern' ? (<small className="error-label p-error">{errors.poc.message}</small>) : ''
              }
            </div>
          </div>
          {
            noChangeError !== '' ? (<small className="error-label p-error">{noChangeError}</small>) : ''
          }
          <div className="add-button-container w-full flex align-items-center justify-content-end">
            <div className="add-button">
              <SpinCheckButton styleClass="add-button-item" label={`${collegeDialogTitle} College`} loadingStatus={addButtonStatus} onClick={handleSubmit(onSubmit)} />
            </div>
          </div>
        </form>
      </div>
      <Toast ref={toastRef} />
    </div>
  )
}
