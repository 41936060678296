import React, { useEffect, useRef, useState } from 'react';
import { AxiosResponse } from 'axios';
import { Toast } from 'primereact/toast';
import { IUser } from '../../models/IUser';
import { changeUserRole, getPaginationUsers } from '../../services/userService';
import { setUserData } from '../../store/userStore';
import { useAppDispatch } from '../../store/storeHooks';

type IListCandidatesRowProps = {
  index: number;
  user: IUser;
  overlayClick: any;
  overlayIndex: number | null;
}

export default function ListUserRow({
  user, index, overlayClick, overlayIndex,
}: IListCandidatesRowProps) {
  const dispatch = useAppDispatch();
  const toastRef = useRef<any>(null);
  // const [overlayHidden, setOverlayHidden] = useState(true);
  const getUserName = () => user.name.split(',').join('')
  const getUserRole = () => ((user.roleId === 3) ? 'Admin' : (user.roleId === 2) ? 'Super Evaluator' : 'Evaluator')

  const [adminLoading, setAdminLoading] = useState<'LOADING' | 'NOT_LOADING'>('NOT_LOADING');
  const [evaluatorLoading, setEvaluatorLoading] = useState<'LOADING' | 'NOT_LOADING'>('NOT_LOADING');
  const [superEvaluatorLoading, setSuperEvaluatorLoading] = useState<'LOADING' | 'NOT_LOADING'>('NOT_LOADING');

  const refreshUserData = () => {
    dispatch(setUserData(null));
    const data: any = {};
    getPaginationUsers(0, data)
      .then((response: AxiosResponse) => {
        if (response.status === 200) {
          dispatch(setUserData(response.data.user as IUser[]));
        }
      })
  }

  const handleClickOutside = (e:any) => {
    const targetClass = e.target.id;
    if (targetClass !== 'role-dropdown-button' && targetClass !== 'role-dropdown-content-1' && targetClass !== 'role-dropdown-content-2') {
      overlayClick(null)
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const toggleOverlay = () => {
    overlayClick(index)
  }

  const checkOverlay = (i: number) => {
    if (overlayIndex) {
      return !(i === overlayIndex)
    }
    return true
  }

  const errorToast = () => {
    toastRef.current.show({
      severity: 'error',
      summary: 'Something went wrong',
      detail: 'Please try again later!',
    });
  }

  const makeAdmin = () => {
    setAdminLoading('LOADING')
    changeUserRole(user.id, 'ADM')
      .then((response: AxiosResponse) => {
        if (response.status === 200) {
          overlayClick(index);
          toastRef.current.show({
            severity: 'success',
            summary: 'User role changed',
            detail: 'User role has been changed to Admin',
          });
          refreshUserData();
        } else {
          overlayClick(index);
          errorToast();
        }
      })
      .catch(() => {
        overlayClick(index);
        errorToast();
      })
  }

  const makeSuperEvaluator = () => {
    setSuperEvaluatorLoading('LOADING')
    changeUserRole(user.id, 'SEVL')
      .then((response: AxiosResponse) => {
        if (response.status === 200) {
          overlayClick(index);
          toastRef.current.show({
            severity: 'success',
            summary: 'User role changed',
            detail: 'User role has been changed to Super Evaluator',
          });
          refreshUserData();
        } else {
          overlayClick(index);
          errorToast();
        }
      })
      .catch(() => {
        overlayClick(index);
        errorToast();
      })
  }

  const makeEvaluator = () => {
    setEvaluatorLoading('LOADING')
    changeUserRole(user.id, 'EVL')
      .then((response: AxiosResponse) => {
        if (response.status === 200) {
          overlayClick(index);
          toastRef.current.show({
            severity: 'success',
            summary: 'User role changed',
            detail: 'User role has been changed to Evaluator',
          });
          refreshUserData();
        } else {
          overlayClick(index);
          errorToast();
        }
      })
      .catch(() => {
        overlayClick(index);
        errorToast();
      })
  }
  return (
    <>
      <div className={`row w-full ${(index + 1) % 5 === 0 ? 'last-row' : ''}`}>
        <div className="name cell">
          {getUserName()}
        </div>
        <div className="role cell">
          {getUserRole()}
        </div>
        <div className="email cell">
          {user.email}
        </div>
        <div className="elipsis cell">
          <div className={`overlay shadow-1 ${checkOverlay(index) ? 'hidden' : ''}`}>
            {
            (user.roleId === 2)
            && (
              <>
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                <div className="overlay-item" onClick={makeAdmin} id="role-dropdown-content-1">
                  {
                    adminLoading === 'NOT_LOADING' && <i className="pi pi-arrow-circle-up" id="role-dropdown-content-1" />
                  }
                  {
                    adminLoading === 'LOADING' && <i className="pi pi-spin pi-spinner" id="role-dropdown-content-1" />
                  }
                  <div className="text" id="role-dropdown-content-1">
                    Upgrade to Admin
                  </div>
                </div>
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                <div className="overlay-item" onClick={makeEvaluator} id="role-dropdown-content-2">
                  {
                    evaluatorLoading === 'NOT_LOADING' && <i className="pi pi-arrow-circle-down" id="role-dropdown-content-2" />
                  }
                  {
                    evaluatorLoading === 'LOADING' && <i className="pi pi-spin pi-spinner" id="role-dropdown-content-2" />
                  }
                  <div className="text" id="role-dropdown-content-2">
                    Downgrade to Evaluator
                  </div>
                </div>
              </>
            )
          }

            {
            (user.roleId === 3)
            && (
              <>
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                <div className="overlay-item" onClick={makeSuperEvaluator} id="role-dropdown-content-1">
                  {
                    superEvaluatorLoading === 'NOT_LOADING' && <i className="pi pi-arrow-circle-down" id="role-dropdown-content-1" />
                  }
                  {
                    superEvaluatorLoading === 'LOADING' && <i className="pi pi-spin pi-spinner" id="role-dropdown-content-1" />
                  }
                  <div className="text" id="role-dropdown-content-1">
                    Downgrade to Super Evaluator
                  </div>
                </div>
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                <div className="overlay-item" onClick={makeEvaluator} id="role-dropdown-content-2">
                  {
                    evaluatorLoading === 'NOT_LOADING' && <i className="pi pi-arrow-circle-down" id="role-dropdown-content-2" />
                  }
                  {
                    evaluatorLoading === 'LOADING' && <i className="pi pi-spin pi-spinner" id="role-dropdown-content-2" />
                  }
                  <div className="text" id="role-dropdown-content-2">
                    Downgrade to Evaluator
                  </div>
                </div>
              </>
            )
          }

            {
            (user.roleId === 1)
            && (
              <>
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                <div className="overlay-item" onClick={makeAdmin} id="role-dropdown-content-1">
                  {
                    adminLoading === 'NOT_LOADING' && <i className="pi pi-arrow-circle-up" id="role-dropdown-content-1" />
                  }
                  {
                    adminLoading === 'LOADING' && <i className="pi pi-spin pi-spinner" id="role-dropdown-content-1" />
                  }
                  <div className="text" id="role-dropdown-content-1">
                    Upgrade to Admin
                  </div>
                </div>
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                <div className="overlay-item" onClick={makeSuperEvaluator} id="role-dropdown-content-2">
                  {
                    superEvaluatorLoading === 'NOT_LOADING' && <i className="pi pi-arrow-circle-up" id="role-dropdown-content-2" />
                  }
                  {
                    superEvaluatorLoading === 'LOADING' && <i className="pi pi-spin pi-spinner" id="role-dropdown-content-2" />
                  }
                  <div className="text" id="role-dropdown-content-2">
                    Upgrade to Super Evaluator
                  </div>
                </div>
              </>
            )
          }
          </div>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
          <i className="pi pi-ellipsis-h cursor-pointer" id="role-dropdown-button" onClick={toggleOverlay} />
        </div>
      </div>
      <Toast ref={toastRef} />
    </>
  )
}
