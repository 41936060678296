import React from 'react';
import './ExamErrorModalRow.css';

type ExamErrorModalProp = {
  value: string,
  index: number,
}

export default function ExamErrorModalRow({ index, value }: ExamErrorModalProp) {
  return (
    <div className="exam-error-modal-row flex align-items-center justify-content-start">
      <div className="index">
        {`${index + 1}.`}
      </div>
      <div className="value">
        {value}
      </div>
    </div>
  )
}
