import React, { useEffect, useState } from 'react';
import './candidateReviewPage.css';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Dialog } from 'primereact/dialog';
import { useNavigate } from 'react-router';
import { SpinCheckButton } from '../index';
import exclamationtriangle from '../../assets/icons/exclamationtriangle.svg';
import { useAppDispatch, useAppSelector } from '../../store/storeHooks';
import { setIsRunning } from '../../store/examSessionStore';
import { setLoader, setMessage } from '../../store/loaderStore';
import { finish } from '../../services/eventService';
import RoutesConstants from '../../constants/routesConstants';
import {
  setStartIndex,
} from '../../store/questionCounter';

export default function CandidateReviewPage() {
  const [dialogVisible, setDialogVisible] = useState<boolean>(false);
  const [uploadButtonStatus] = useState<'LOADING' | 'LOADED' | 'NOT_LOADING'>('NOT_LOADING');
  const [totalCount, setTotalCount] = useState<number>(0);
  const [responded, setResponded] = useState<number>(0);
  const [markedForReview, setMarkedForReview] = useState<number>(0);
  const review: any = useAppSelector((state) => state.counter.quesId);
  const examSession = useAppSelector((state) => state.counter.examSession);
  const routesConstant = new RoutesConstants();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const finishExam = () => {
    dispatch(setIsRunning(false));
    dispatch(setLoader(true));
    dispatch(setMessage('Submitting Exam'));
    finish(examSession.id).then(() => {
      dispatch(setLoader(false));
      dispatch(setMessage(''));
      navigate(`${routesConstant.CANDIDATE}${routesConstant.CANDIDATE_END}`);
    });
  }

  const {
    handleSubmit,
  } = useForm({ });

  const showDialogue = () => {
    setDialogVisible(true);
  }

  const dialogClose = () => {
    setDialogVisible(false);
  }

  const onSubmit: SubmitHandler<any> = () => {
    setDialogVisible(false);
    finishExam();
  }

  const handleQuestionNavigate = (event: any, index: number) => {
    dispatch(setStartIndex(index));
    navigate(`${routesConstant.CANDIDATE}${routesConstant.CANDIDATE_QUESTION}`);
  }

  useEffect(
    () => {
      setTotalCount(review.length);
      review.forEach((data: any) => {
        if (data.bookmark) setMarkedForReview(markedForReview + 1);
        if (data.answered) setResponded(responded + 1);
      })
    },
    [],
  );

  const applyStyles = (data: any) => {
    if (data.bookmark) return 'dot-circle-yellow';
    if (data.answered) return 'dot-circle-green';
    return 'dot-circle-gray';
  }

  return (
    <div>
      <div className="candidateReviewPage-container h-full w- full">
        <div className="candidateReviewPage-container-form">
          <h3 className="review-header">
            Want to recheck one more time? Review marked questions? Or if you are
            feeling confident you can choose to submit and end candidate.
          </h3>
          <p className="review-content">
            You have answered
            {' '}
            {responded}
            {' '}
            out of
            {' '}
            {totalCount}
            {' '}
            questions and marked
            {' '}
            {markedForReview}
            {' '}
            questions for review.
          </p>
          <div className="grid-container ">
            {
              review.map((data: any, index: number) => (
                <div className="grid-item" key={data.id} onClick={() => handleQuestionNavigate(data, index)} role="button" tabIndex={index} onKeyPress={() => handleQuestionNavigate(data, index)}>
                  <div className={`dot-circle ${applyStyles(data)} ml-1 mb-2`} />
                  <div className="question-number justify-content-center">{index + 1}</div>
                </div>
              ))
            }
          </div>
          <div className="dot-explanation">
            <div className="dot-circle-green-display" />
            <div className="answer pl-3">Questions Answered</div>
          </div>
          <div className="dot-explanation">
            <div className="dot-circle-yellow-display" />
            <div className="answer pl-3">Marked for Review</div>
          </div>
          <div className="dot-explanation">
            <div className="dot-circle-gray-display" />
            <div className="answer pl-3">Not Answered</div>
          </div>
          <div className="footer-co">
            <div className="submit-evaluation-button">
              <SpinCheckButton styleClass="upload-button-item submit-evaluation-button" onClick={showDialogue} loadingStatus={uploadButtonStatus} label="Submit and End Exam" />
            </div>
          </div>
        </div>
      </div>
      <Dialog
        className="dialog-pop"
        visible={dialogVisible}
        style={{ borderRadius: '8px', height: '200px', width: '600px' }}
        onHide={dialogClose}
        header={(
          <div className="dialog-content dialog-column-container flex align-items-center justify-content-center">
            <div className="headcontent end-dialog-row-container">
              <div className="warninglogo">
                <img src={exclamationtriangle} className="warlogo" alt="Presidio Hire Logo" />
              </div>
              <div className="textHeader">
                Are you sure you want to submit?
              </div>
            </div>
            <form className="end-exam-dialog-align dialog-column-container" onSubmit={handleSubmit(onSubmit)}>
              <div className="end-dialog-content-message">
                This will end your exam and you won’t be able to change your answers once you submit. Doesn’t hurt to check everything once if you have time.
              </div>
              <div className="add-button-container dialog-row-container flex align-items-center justify-content-end">
                { /* <SpinCheckButton label="Cancel" styleClass="dialog-cancel-button" onClick={dialogClose} loadingStatus={uploadButtonStatus} /> */}
                <SpinCheckButton styleClass="dialog-submitQuestions-button" label="Yes,Submit and End Exam" onClick={handleSubmit(onSubmit)} loadingStatus={uploadButtonStatus} />
              </div>
            </form>
          </div>
)}
      />
    </div>
  );
}
