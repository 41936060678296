import React, { useEffect, useRef, useState } from 'react';
import { Toast } from 'primereact/toast';
import { AxiosResponse } from 'axios';
import { ICategory } from '../../models/ICategory';
import DeleteIcon from '../../assets/icons/DeleteIcon.svg';
import { deleteCategory, getAllCategory } from '../../services/examService';
import {
  setCategoryData, setCategoryDialogTitle, setCategoryDialogVisible,
  setUpdateCategoryData,
} from '../../store/settingsStore';
import { ICollege } from '../../models/ICollege';
import { useAppDispatch } from '../../store/storeHooks';
import EditIcon from '../../assets/icons/EditIcon.svg';

type IListCategoriesRowProps = {
  index: number;
  category: ICategory;
  overlayClick: any;
  overlayIndex: number | null;
}

export default function ListCategoriesRow({
  category, index, overlayClick, overlayIndex,
}: IListCategoriesRowProps) {
  const dispatch = useAppDispatch();
  const toastRef = useRef<any>(null);
  const [deleteLoading, setDeleteLoading] = useState<'LOADING' | 'NOT_LOADING'>('NOT_LOADING');

  const handleClickOutside = (e:any|null) => {
    const targetClass = e.target.id;
    if (targetClass !== 'category-dropdown-button' && targetClass !== 'category-dropdown-content-edit' && targetClass !== 'category-dropdown-content-delete') {
      overlayClick(null)
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const checkOverlay = (i: number) => {
    if (overlayIndex) {
      return !(i === overlayIndex)
    }
    return true
  }

  const toggleOverlay = () => {
    overlayClick(index)
  }

  const refreshCategoryData = () => {
    dispatch(setCategoryData(null));
    getAllCategory()
      .then((response: AxiosResponse) => {
        dispatch(setCategoryData(response.data as ICollege[]));
      })
      .catch(() => {});
  }

  const deleteCategoryClick = () => {
    setDeleteLoading('LOADING');
    deleteCategory(category.id)
      .then((response: AxiosResponse) => {
        if (response.status === 200) {
          overlayClick(index)
          toastRef.current.show({
            severity: 'success',
            summary: 'Deleted',
            detail: 'Category has been deleted successfully!',
            life: 1000000,
          });
          refreshCategoryData();
        } else {
          overlayClick(index)
          toastRef.current.show({
            severity: 'error',
            summary: 'Something went wrong',
            detail: 'Please try again later!',
          });
        }
      })
      .catch(() => {
        overlayClick(index)
        toastRef.current.show({
          severity: 'error',
          summary: 'Something went wrong',
          detail: 'Please try again later!',
        });
      });
  }
  const editCategory = () => {
    overlayClick(index)
    dispatch(setUpdateCategoryData(category));
    dispatch(setCategoryDialogTitle('Update'));
    dispatch(setCategoryDialogVisible(true));
  }
  return (
    <>
      <div className={`row w-full ${(index + 1) % 5 === 0 ? 'last-row' : ''}`}>
        <div className="name cell">
          {category.name}
        </div>
        <div className="elipsis cell">
          <div className={`overlay shadow-1 pad ${checkOverlay(index) ? 'hidden' : ''}`}>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <div className="overlay-item mar" onClick={editCategory} id="category-dropdown-content-edit">
              <img src={EditIcon} alt="Edit Category" id="category-dropdown-content-edit" />
              <div className="text" id="category-dropdown-content-edit">
                Edit
              </div>
            </div>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <div className="overlay-item" onClick={deleteCategoryClick} id="category-dropdown-content-delete">
              {
                deleteLoading === 'NOT_LOADING' && <img src={DeleteIcon} alt="Delete Category" id="category-dropdown-content-delete" />
              }
              {
                deleteLoading === 'LOADING' && <i className="pi pi-spin pi-spinner" id="category-dropdown-content-delete" />
              }
              <div className="text" id="category-dropdown-content-delete">
                Delete
              </div>
            </div>
          </div>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
          <i className="pi pi-ellipsis-h cursor-pointer category-dropdown-button" onClick={toggleOverlay} id="category-dropdown-button" />
        </div>
      </div>
      <Toast ref={toastRef} />
    </>
  )
}
