import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface questionCounter{
    totalCount: number;
    actualCount: number;
    question:any;
    questionCategory:string;
    oeqcCodinglanguage:string;
    examSession: any;
     options:[] | null;
  quesId: {id: string, bookmark: boolean, answered: boolean}[];
  answers:any | null;
  candidateData:[]|null;
  responseStartTime : number;
  preAnswer:string;
  ExamId:any | null;
  ExamRemainTimings:number[];
  duration: number;
  reviewResponse:any|null;
  responded: number;
  notResponded: number;
  markedForReview: number;
  bookmarkStatus: boolean;
  startIndex: number;
}

const initialState: questionCounter = {
  totalCount: 0,
  actualCount: 0,
  question: null,
  oeqcCodinglanguage: '',
  questionCategory: ' ' as string,
  examSession: null,
  options: null,
  quesId: [],
  answers: null,
  candidateData: null,
  ExamRemainTimings: [],
  responseStartTime: 0,
  preAnswer: ' ' as string,
  ExamId: null,
  duration: 0,
  reviewResponse: null,
  responded: 0,
  notResponded: 0,
  markedForReview: 0,
  bookmarkStatus: false,
  startIndex: 0,
};

// @ts-ignore
export const questionCounterStore = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    setTotalCount: (state, action: PayloadAction<number>) => {
      state.totalCount = action.payload
    },
    setActualCount: (state, action: PayloadAction<number>) => {
      state.actualCount = action.payload
    },
    setQuestion: (state, action: PayloadAction<any>) => {
      state.question = action.payload
    },
    setOeqcCodingLanguage: (state, action: PayloadAction<string>) => {
      state.oeqcCodinglanguage = action.payload
    },
    setQuestionCategory: (state, action: PayloadAction<string>) => {
      state.questionCategory = action.payload
    },
    setExamSession: (state, action: PayloadAction<string>) => {
      state.examSession = action.payload
    },
    setOptions: (state, action: PayloadAction<any>) => {
      state.options = action.payload
    },
    setQuesId: (state, action: PayloadAction<any>) => {
      state.quesId = action.payload
    },
    setAnswers: (state, action: PayloadAction<any>) => {
      state.answers = action.payload
    },
    setPreAnswer: (state, action: PayloadAction<string>) => {
      state.preAnswer = action.payload
    },
    setCandidateData: (state, action: PayloadAction<any>) => {
      state.candidateData = action.payload
    },
    setExamIdData: (state, action: PayloadAction<any>) => {
      state.ExamId = action.payload
    },
    setExamRemainTimings: (state, action: PayloadAction<any>) => {
      state.ExamRemainTimings = action.payload
    },
    setDuration: (state, action: PayloadAction<any>) => {
      state.duration = action.payload
    },
    setReviewResponse: (state, action:PayloadAction<any>) => {
      state.reviewResponse = action.payload
    },
    setResponded: (state, action:PayloadAction<any>) => {
      state.responded = action.payload
    },
    setNotResponded: (state, action:PayloadAction<any>) => {
      state.notResponded = action.payload
    },
    setMarkedForReview: (state, action:PayloadAction<any>) => {
      state.markedForReview = action.payload
    },
    setResponseStartTime: (state, action: PayloadAction<any>) => {
      state.responseStartTime = action.payload
    },
    setBookmark: (state, action:PayloadAction<any>) => {
      state.bookmarkStatus = action.payload
    },
    setStartIndex: (state, action:PayloadAction<number>) => {
      state.startIndex = action.payload
    },
  },
});

export const {
  setTotalCount, setActualCount, setQuestion, setOeqcCodingLanguage, setQuestionCategory, setExamSession, setExamRemainTimings, setOptions, setQuesId, setAnswers, setCandidateData, setResponseStartTime, setPreAnswer, setDuration, setReviewResponse, setResponded, setNotResponded, setMarkedForReview, setExamIdData, setBookmark, setStartIndex,
} = questionCounterStore.actions;

export default questionCounterStore.reducer;
