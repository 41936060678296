import React from 'react';
import { Skeleton } from 'primereact/skeleton';
import './FieldSkeleton.css';

type IProps = {
  label: string;
}

export default function FieldSkeleton(props: IProps) {
  const { label } = props;
  return (
    <div className="university-skeleton">
      <div className="label">
        {label}
      </div>
      <Skeleton className="input" />
    </div>
  )
}
