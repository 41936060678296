import axios from 'axios';
import ApiContants from '../constants/apiContants';

const apiConstants = new ApiContants();

export function getCandidateTemplate() {
  return axios.get(`${apiConstants.EXAM}${apiConstants.CANDIDATE}${apiConstants.TEMPLATE}`);
}

export function getInitData() {
  return axios.get(`${apiConstants.START_PAGE}`);
}

// Candidate start exam
export function startExam(data: any) {
  return axios.post(`${apiConstants.EXAM}${apiConstants.CANDIDATE_TAKE_EXAM}`, data);
}

export function candidateVerify(examId:string, data: any) {
  return axios.post(`${apiConstants.EXAM}${apiConstants.CANDIDATE}/verify?examId=${examId}`, data);
}

export function updateStartExam() {
  return axios.put(`${apiConstants.EXAM}${apiConstants.UPDATE_CANDIDATE_SESSION}`);
}

export function reviewData() {
  return axios.post(`${apiConstants.EXAM}${apiConstants.CANDIDATE_REVIEW}`);
}

export function getMarkSheet(examId:string, candidateId: string) {
  return axios.get(`${apiConstants.EVALUATE}${apiConstants.MARKSHEET}/${examId}/${candidateId}`);
}

function userUniqueConst(userRegNo:string) {
  let suma = 0;
  for (let i = 0; i < userRegNo.length; i += 1) {
    for (let j = 0; j < userRegNo[i].length; j += 1) {
      const charCode = userRegNo[i].charCodeAt(j);
      suma += charCode;
    }
  }
  return suma;
}

export function shuffleQuestion(questionArray:[], userRegNo:string) {
  const n = questionArray.length
  const k = userUniqueConst(userRegNo)
  for (let i = n - 1; i > 0; i -= 1) {
    const j = Math.floor(k * i) % n
    const temp = questionArray[i]
    questionArray[i] = questionArray[j]
    questionArray[j] = temp
  }
  return questionArray;
}

export function getCandidate(candidate: string) {
  return axios.get(`${apiConstants.EXAM}${apiConstants.CANDIDATE}/${candidate}`);
}

export function updateCandidateData(candidateId: string, update: any) {
  return axios.put(`${apiConstants.EXAM}${apiConstants.CANDIDATE}/${candidateId}`, update);
}
