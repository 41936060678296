import axios from 'axios';
import ApiContants from '../constants/apiContants';

const apiConstants = new ApiContants();

export function getUserByRoleForExam(role: string, examId: number) {
  // return axios.get(`${apiConstants.USER}/${role}`)
  return axios.get(apiConstants.EXAMUSERS, {
    params: {
      role,
      examId,
    },
  });
}

export function getUserByRole(role: string) {
  // return axios.get(`${apiConstants.USER}/${role}`)
  return axios.get(apiConstants.USER, {
    params: {
      role,
    },
  });
}
export function getPaginationUsers(pageno: number, data:any) {
  if (JSON.stringify(data) !== '{}') {
    return axios.get(apiConstants.USER, {
      params: {
        ...data,
      },
    });
  }
  return axios.get(apiConstants.USER, {
    params: {
      sortBy: 'updatedAt,DESC',
      pageNo: pageno,
    },
  });
}
// export function searchUserApi(data: any) {
//   return axios.post(`${apiConstants.USER}${apiConstants.SEARCH}`, data)
// }
export function getAllUsers() {
  return axios.get(apiConstants.USER);
}

export function changeUserRole(user: string, role: string) {
  return axios.put(`${apiConstants.USER}/${user}`, {
    role,
  })
}

export function sendInvite(email:string) {
  return axios.post(`${apiConstants.USER}${apiConstants.SENDINVITE}`, {
    email,
  })
}

export function inviteUser(data: any) {
  return axios.post(`${apiConstants.USER}${apiConstants.INVITEUSER}`, data);
}
