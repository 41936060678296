import React from 'react';
import { ScrollPanel } from 'primereact/scrollpanel';
import { ProgressSpinner } from 'primereact/progressspinner';
import { evaluationNavigatorType } from '../../../evaluation';
import { useAppSelector } from '../../../../../store/storeHooks';

type ResponseNavigatorDisplayProps = {
  sortedResponse: evaluationNavigatorType[],
  setSortedResponse: Function
  selectedResponse: evaluationNavigatorType | null | undefined,
  setSelectedResponse: Function,
}

export default function ResponseNavigatorDisplay({
  sortedResponse, selectedResponse, setSelectedResponse,
}: ResponseNavigatorDisplayProps) {
  const responseClickHandler = (selected: evaluationNavigatorType) => {
    setSelectedResponse(selected);
  }

  const navigatorLoader: boolean = useAppSelector((state) => state.loader.navigatorLoader);

  const applyStyles = (response: evaluationNavigatorType) => {
    if (selectedResponse && response.responseId === selectedResponse.responseId) return 'question-navigator-question-number-selected';
    if (response.evaluated) return 'question-navigator-question-number-answered';
    if (response.skipResponse) return 'question-navigator-question-number-marked';
    if (response.skipQuestion) return 'question-navigator-question-number-skip';
    return 'question-navigator-question-number-default';
  }

  return (
    <ScrollPanel style={{
      width: '100%', maxHeight: '23rem', overflow: 'auto', marginTop: '30px',
    }}
    >
      <div className="question-navigator-display">
        {
          sortedResponse.map((response, index) => (
            !(selectedResponse && selectedResponse.order === response.order && navigatorLoader)
              ? (
                <div key={response.responseId} className="question-navigator-display-item">
                  <div
                    onClick={() => responseClickHandler(response)}
                    tabIndex={index}
                    role="button"
                    className={`question-navigator-question-number ${applyStyles(response)}`}
                    onKeyPress={() => responseClickHandler(response)}
                  >
                    <span className="number">{response.order}</span>
                  </div>
                </div>
              )
              : (
                <div key={response.responseId} className="question-navigator-display-item">
                  <ProgressSpinner style={{ width: '50px', height: '50px', stroke: '#0369A1' }} />
                </div>
              )
          ))
        }
      </div>
    </ScrollPanel>
  )
}
