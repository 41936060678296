import * as XLSX from 'xlsx';
import array from 'lodash';
import { IUser } from '../models/IUser';
import { IQuestion } from '../models/IQuestion';
import questionTypes from '../constants/questionConstants';
import questionErrors from '../constants/questionErrorsConstants';
import ICandidate from '../models/ICandidate';
import candidateErrors from '../constants/candidateErrorsConstants';

type IFileData = {
  name: string,
  file: any,
  isPresent: boolean,
}

const padZero = (input: string) => input.padStart(2, '0');

export function extractExamDate(date: Date): string {
  return `${date.getFullYear()}-${padZero((date.getMonth() + 1).toString())}-${padZero(date.getDate().toString())}`
}

function getDate(serial: number) {
  const utcDays = Math.floor(serial - 25569);
  const utcValue = utcDays * 86400;
  const dateInfo = new Date(utcValue * 1000);

  return `${dateInfo.getDate()}/${dateInfo.getMonth() + 1}/${dateInfo.getFullYear()}`;
}

export function extractEvaluatos(evaluators: IUser[]): string[] {
  return evaluators.map((evaluator: IUser) => evaluator.id)
}

export function mergeEvaluators(evaluators: string[], superEvaluators: string[]): string[] {
  return evaluators.concat(superEvaluators);
}

export function findTimeDuration(startTime: string, endTime: string): string {
  let [startHour, startMin]: string [] | number[] = startTime.split(':');
  let [endHour, endMin]: string [] | number[] = endTime.split(':');

  startHour = parseInt(startHour, 10);
  startMin = parseInt(startMin, 10);
  endHour = parseInt(endHour, 10);
  endMin = parseInt(endMin, 10);

  let hourDuration: number = (startHour > endHour) ? (startHour - endHour) - 1 : (endHour - startHour) - 1;
  let minDuration: number = (60 - startMin) + endMin;

  if (minDuration > 60) {
    hourDuration += 1;
    minDuration %= 60;
  }

  return (hourDuration <= 0) ? `${minDuration} mins` : `${hourDuration} hr ${minDuration} mins`;
}

function validateQuestion(ques:IQuestion, no: number) {
  no += 1;
  const mcqOptionReg = /[ABCDEFGHIJ]{1}/;
  const msqOptionReg = /[ABCDEFGHIJ]{1}(, [ABCDEFGHIJ])*/
  const pointReg = /^-?\d+$/
  const result = {
    valid: true,
    errors: [] as any,
  }

  if (ques.QuestionType === '' || ques.QuestionType === undefined) {
    result.valid = false;
    result.errors.push(`${questionErrors.QUESTION_TYPE_MISSING} ${no}`)
  }
  if (ques.Category === '' || ques.Category === undefined) {
    result.valid = false;
    result.errors.push(`${questionErrors.CATEGORY_MISSING} ${no}`)
  }
  if (ques.Question === '' || ques.Question === undefined) {
    result.valid = false;
    result.errors.push(`${questionErrors.QUESTION_MISSING} ${no}`)
  }
  if (ques.Point === '' || ques.Point === undefined) {
    result.valid = false;
    result.errors.push(`${questionErrors.POINTS_MISSING} ${no}`)
  }
  if (!pointReg.test(ques.Point)) {
    result.valid = false;
    result.errors.push(`${questionErrors.POINTS_INVALID} ${no}`)
  }
  if (ques.QuestionType === questionTypes.MCQ) {
    if ((ques.RandomOption !== 'Yes' && ques.RandomOption !== 'No')) {
      result.valid = false;
      result.errors.push(`${questionErrors.MCQ_RANDOM_INVALID} ${no}`)
    }
    if (ques.OptionA === '' || ques.OptionB === '' || ques.OptionA === undefined || ques.OptionB === undefined) {
      result.valid = false;
      result.errors.push(`${questionErrors.MCQ_MISSING_OPTIONS} ${no}`)
    }
    if (ques.Correct === '' || ques.Correct === undefined) {
      result.valid = false;
      result.errors.push(`${questionErrors.CORRECT_MISSING} ${no}`)
    } else {
      if (ques.Correct.length !== 1) {
        result.valid = false;
        result.errors.push(`${questionErrors.CORRECT_MISSING} ${no}`)
      }
      if (!mcqOptionReg.test(ques.Correct)) {
        result.valid = false;
        result.errors.push(`${questionErrors.CORRECT_INVALID} ${no}`)
      }
    }
  } else if (ques.QuestionType === questionTypes.MSQ) {
    if (ques.RandomOption !== 'Yes' && ques.RandomOption !== 'No') {
      result.valid = false;
      result.errors.push(`${questionErrors.MSQ_RANDOM_INVALID} ${no}`)
    }
    if (ques.OptionA === '' || ques.OptionB === '' || ques.OptionA === undefined || ques.OptionB === undefined) {
      result.valid = false;
      result.errors.push(`${questionErrors.MSQ_MISSING_OPTIONS} ${no}`)
    }
    if (ques.Correct === '' || ques.Correct === undefined) {
      result.valid = false;
      result.errors.push(`${questionErrors.CORRECT_MISSING} ${no}`)
    } else {
      const correctSplit = ques.Correct.split(',')
      if (!msqOptionReg.test(ques.Correct)) {
        result.valid = false;
        result.errors.push(`${questionErrors.CORRECT_INVALID} ${no}`)
      }
      if (array.uniq(correctSplit).length !== correctSplit.length) {
        result.valid = false;
        result.errors.push(`${questionErrors.MSQ_CORRECT_REDUNDANT} ${no}`)
      }
    }
  }

  return result;
}

function validateCandidate(cand: ICandidate, no: number) {
  no += 1;
  const dateRegex = /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/
  const phoneRegex = /^[6-9]\d{9}$/
  const emailRegex = /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/
  const result = {
    valid: true,
    errors: [] as any,
  }
  let dob;
  if (cand.DOB) {
    dob = getDate(cand.DOB);
  }
  if (cand.Name === '' || cand.Name === undefined) {
    result.valid = false;
    result.errors.push(`${candidateErrors.NAME_MISSING} ${no}`)
  }
  if (cand.Gender === '' || cand.Name === undefined) {
    result.valid = false;
    result.errors.push(`${candidateErrors.GENDER_MISSING} ${no}`)
  }
  if (cand.Gender !== 'Male' && cand.Gender !== 'Female' && cand.Gender !== 'Other') {
    result.valid = false;
    result.errors.push(`${candidateErrors.GENDER_INVALID} ${no}`)
  }
  if (dob === '' || dob === undefined) {
    result.valid = false;
    result.errors.push(`${candidateErrors.DOB_MISSING} ${no}`)
  } else if (!dateRegex.test(dob)) {
    result.valid = false;
    result.errors.push(`${candidateErrors.DOB_INVALID} ${no}`)
  }
  if (cand.RegisterNumber === '' || cand.RegisterNumber === undefined) {
    result.valid = false;
    result.errors.push(`${candidateErrors.REGISTRATION_NUMBER_MISSING} ${no}`)
  }
  if (cand.Batch === '' || cand.Batch === undefined) {
    result.valid = false;
    result.errors.push(`${candidateErrors.BATCH_MISSING} ${no}`)
  }
  if (cand.Email === '' || cand.Email === undefined) {
    result.valid = false;
    result.errors.push(`${candidateErrors.EMAIL_MISSING} ${no}`)
  }
  if (!emailRegex.test(cand.Email)) {
    result.valid = false;
    result.errors.push(`${candidateErrors.EMAIL_INVALID} ${no}`)
  }
  if (cand.Phone === '' || cand.Phone === undefined) {
    result.valid = false;
    result.errors.push(`${candidateErrors.EMAIL_MISSING} ${no}`)
  }
  if (!phoneRegex.test(cand.Phone)) {
    result.valid = false;
    result.errors.push(`${candidateErrors.PHONE_INVALID} ${no}`)
  }

  return result;
}

export async function validateQuestions(fileData: IFileData) {
  const validationResult: any = {
    valid: true,
    file: fileData.file,
    errors: [],
  }
  const data = await fileData.file.arrayBuffer();
  const workBook = XLSX.read(data, { raw: false });
  const rows: IQuestion[] = XLSX.utils.sheet_to_json(workBook.Sheets[workBook.SheetNames[0]]);
  rows.map((row, index) => {
    const result = validateQuestion(row, index);
    if (!result.valid) {
      validationResult.valid = false;
      validationResult.errors = validationResult.errors.concat(result.errors);
    }
    return null;
  });

  return validationResult;
}

export async function validateCandidates(fileData: IFileData) {
  const validationResult: any = {
    valid: true,
    file: fileData.file,
    errors: [],
  }
  const data = await fileData.file.arrayBuffer();
  const workBook = XLSX.read(data, { raw: false });
  const rows: ICandidate[] = XLSX.utils.sheet_to_json(workBook.Sheets[workBook.SheetNames[0]]);
  rows.map((row, index) => {
    const result = validateCandidate(row, index);
    if (!result.valid) {
      validationResult.valid = false;
      validationResult.errors = validationResult.errors.concat(result.errors);
    }
    return null;
  });

  return validationResult;
}
