import React, { useEffect, useRef, useState } from 'react';
import './CreateExams.css';
import { useNavigate } from 'react-router';
import { AxiosResponse } from 'axios';
import {
  Controller, SubmitHandler, useForm, useWatch,
} from 'react-hook-form';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { MultiSelect } from 'primereact/multiselect';
import { getMonth, getYear } from 'date-fns';
import { Toast } from 'primereact/toast';
import _ from 'lodash';
import { IListCampusDrives } from '../../../../models/IListCampusDrives';
import { getAllCampusDrive } from '../../../../services/campusDriveService';
import { TimeSelect, FieldSkeleton, SpinCheckButton } from '../../../../components';
import { getUserByRole } from '../../../../services/userService';
import { IUser } from '../../../../models/IUser';
import { createExam } from '../../../../services/examService';
import RoutesConstants from '../../../../constants/routesConstants';

export default function CreateExams() {
  const navigate = useNavigate();
  const routesConstants = new RoutesConstants();
  const [campusDrives, setCampusDrives] = useState<IListCampusDrives[] | undefined>(undefined);
  const [evaluators, setEvaluators] = useState<IUser[] | undefined>(undefined);
  const [superEvaluators, setSuperEvaluators] = useState<IUser[] | undefined>(undefined);
  const [timeRemainders, setTimeRemainder] = useState<string[] | undefined>(undefined);
  const [startTimeError, setStartTimeError] = useState<boolean>(false);
  const [startTimeErrorMessage, setStartTimeErrorMessage] = useState<string>('');
  const [endTimeError, setEndTimeError] = useState<boolean>(false);
  const [endTimeErrorMessage, setEndTimeErrorMessage] = useState<string>('');
  const [loadingStatus, setLoadingStatus] = useState<'LOADED' | 'NOT_LOADING' | 'LOADING'>('NOT_LOADING');
  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);
  const whiteSpaceRegex = /.*\S.*/
  const toastRef = useRef<any>(null);
  // const timeRemainders = useState<number[]|undefined>([30, 25, 20, 15, 10, 5]);
  const defaultValues = {
    examName: '',
    campusDrive: '',
    examDate: '',
    startTime: '',
    endTime: '',
    buffer: '',
    timeAlerts: '',
    evaluator: undefined,
    superEvaluator: undefined,
  };
  const {
    register, handleSubmit, control, setValue, formState: { errors },
  } = useForm({ defaultValues });

  const campusDrive = useWatch({
    control,
    name: 'campusDrive',
  });

  const evaluator = useWatch({
    control,
    name: 'evaluator',
  });

  const superEvaluator = useWatch({
    control,
    name: 'superEvaluator',
  });

  const timeRemain = useWatch({
    control,
    name: 'timeAlerts',
  });

  useEffect(() => {
    /*
     * Getting all the campus drives from the database
     */
    getAllCampusDrive()
      .then((response: AxiosResponse) => {
        setCampusDrives(response.data.campusDrives);
      })
      .catch((e) => {
        toastRef.current.show({
          severity: 'error',
          summary: 'Something went wrong',
          detail: e.response.data.message,
        });
      });
    setTimeRemainder(['30', '25', '20', '15', '10', '5']);

    /*
     * Getting all the evaluators from the database
     */
    getUserByRole('EVL').then((response: AxiosResponse) => {
      setEvaluators(response.data.user);
    })
      .catch((e) => {
        toastRef.current.show({
          severity: 'error',
          summary: 'Something went wrong',
          detail: e.response.data.message,
        });
      });

    /*
     * Getting all the super evaluators from the database
     */
    getUserByRole('SEVL').then((response: AxiosResponse) => {
      setSuperEvaluators(response.data.user);
    })
      .catch((e) => {
        toastRef.current.show({
          severity: 'error',
          summary: 'Something went wrong',
          detail: e.response.data.message,
        });
      });
  }, []);

  const goBackClick = () => {
    navigate(-1);
  };

  const goBackEnter = () => {
    // @ts-ignore
    if (event.key === 'Enter') {
      goBackClick();
    }
  };

  const onCampusDriveChange = (e: any) => {
    const { value } = e;
    setStartDate(new Date(value.startDate));
    setEndDate(new Date(value.endDate));
  }

  const onStartTimeChange = (time: string, error: boolean, message: string = '') => {
    // eslint-disable-next-line no-console
    console.log('startTime', time);
    if (!error) {
      setValue('startTime', time);
    }
    setStartTimeError(error);
    setStartTimeErrorMessage(message);
  }

  const onEndTimeChange = (time: string, error: boolean, message: string = '') => {
    // eslint-disable-next-line no-console
    console.log('endTime', time);
    if (!error) {
      setValue('endTime', time);
    }
    setEndTimeError(error);
    setEndTimeErrorMessage(message);
  }

  const onSubmit: SubmitHandler<any> = (data: any) => {
    setLoadingStatus('LOADING');
    data.timeAlerts = data.timeAlerts.join(',');
    console.log(data);
    const regEx = /(\d\d:\d\d)/;
    let error = false;
    const today = new Date();
    const setTime: string[] = data.startTime.split(':');
    if (_.parseInt(setTime[0]) < today.getHours() && today.toDateString() === data.examDate.toDateString()) {
      error = true;
      setStartTimeError(true)
      setStartTimeErrorMessage('Time less than currrent time.');
    }

    if (!regEx.test(data.startTime)) {
      error = true;
      setStartTimeError(true);
      setStartTimeErrorMessage('Start Time is required.');
    }

    if (!regEx.test(data.endTime)) {
      error = true;
      setEndTimeError(true);
      setEndTimeErrorMessage('End Time is required.');
    }

    if (!error) {
      createExam(data)
        .then((response) => {
          if (response) {
            setLoadingStatus('LOADED');
            navigate(routesConstants.EXAMS);
          } else {
            setLoadingStatus('NOT_LOADING');
            const toast = (severity: string, summary: string, detail: string) => {
              toastRef.current.show({
                severity,
                summary,
                detail,
                life: 3000,
              })
            }
            setTimeout(() => {
              setLoadingStatus('NOT_LOADING');
              toast(
                'error',
                'Something went wrong',
                'Please enter proper start and end',
              )
            })
          }
        })

        .catch((e) => {
          toastRef.current.show({
            severity: 'error',
            summary: 'Something went wrong',
            detail: e.response.data.message,
          });
        });
    }
  };

  return (
    <div className="create-exam-view h-full flex flex-column align-items-center justify-content-start">
      <div className="header w-full flex flex-column align-items-start justify-content-center">
        <div role="button" tabIndex={0} onClick={goBackClick} onKeyDown={goBackEnter} className="back-navigation flex align-items-center justify-content-start cursor-pointer">
          <i className="pi pi-angle-left font-semibold" />
          <div className="label font-medium">
            Back
          </div>
        </div>

        <div className="title">
          Create new exam
        </div>
      </div>

      <div className="create-exam-form w-full">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="field exam-name">
            <div className="label">
              Name
            </div>
            <InputText {...register('examName', { required: 'Exam name is required.', pattern: { value: whiteSpaceRegex, message: 'Invalid Exam Name' } })} className="input" type="text" placeholder="Enter a exam name" autoComplete="off" />
            {
                errors.examName?.type === 'required' ? (<small className="error-label p-error">{errors.examName.message}</small>) : ''
              }
            {
                errors.examName?.type === 'pattern' ? (<small className="error-label p-error">{errors.examName.message}</small>) : ''
              }
          </div>
          {
                (campusDrives == null)
                && <FieldSkeleton label="Campus Drive" />
            }
          {
                (campusDrives != null)
                && (
                <div className="field campus-drive">
                  <div className="label">
                    Campus Drive
                  </div>
                  <Dropdown {...register('campusDrive', { required: 'Campus Drive is required.', onChange: onCampusDriveChange })} value={campusDrive} className="input" optionLabel="campusDriveName" options={campusDrives} placeholder="Choose one" />
                  {
                        errors.campusDrive?.type === 'required' ? (<small className="error-label p-error">{errors.campusDrive.message}</small>) : ''
                      }
                </div>
                )
            }

          <div className="field exam-date">
            <div className="label">
              Date
            </div>

            <Controller
              control={control}
              name="examDate"
              rules={{
                required: 'Exam date is required.',
              }}
              render={({ field: { onChange, ref } }) => (
                <Calendar
                  ref={ref}
                  onChange={(ev) => onChange(ev)}
                  className="input"
                  minDate={(startDate != null) ? startDate : new Date()}
                  maxDate={(endDate != null) ? endDate : null}
                  viewDate={(startDate != null) ? (new Date(getYear(startDate), getMonth(startDate))) : undefined}
                  dateFormat="dd-mm-yy"
                  onMonthChange={() => {}}
                  placeholder="Choose a exam date"
                  showButtonBar
                  readOnlyInput
                />
              )}
            />
            {
                errors.examDate ? (<small className="error-label p-error">{errors.examDate?.message}</small>) : ''
              }
          </div>

          <div className="field time flex align-items-center justify-content-between">
            <TimeSelect callback={onStartTimeChange} label="Start Time" timeValue="" />
            <TimeSelect callback={onEndTimeChange} label="End Time" timeValue="" />
          </div>
          <div className="flex  flex-row ">

            <div className="flex" id="flxbox">
              {
                  startTimeError ? (
                    <small className="error-label p-error">
                      {startTimeErrorMessage}
                    </small>
                  ) : ''
                }
            </div>

            <div className="flex ">
              {
                  endTimeError ? (<small className="error-label p-error">{endTimeErrorMessage}</small>) : ''
                }
            </div>

          </div>
          <div className="field exam-name">
            <div className="label">
              Buffer Time
            </div>
            <InputText {...register('buffer', { required: 'Buffer Time is required.' })} className="input" type="number" placeholder="Enter the Buffer Time" autoComplete="off" min={0} />
            {
                errors.buffer?.type === 'required' ? (<small className="error-label p-error">{errors.buffer.message}</small>) : ''
              }
          </div>
          <div className="field campus-drive">
            <div className="label">
              Last minute remainder to candidate
            </div>
            <MultiSelect {...register('timeAlerts', { required: 'Time to remain candidates is required.' })} value={timeRemain} className="input" optionLabel="" options={timeRemainders} placeholder="Select Time to remain candidates" />
            {
              // @ts-ignore
              errors.time ? (<small className="error-label p-error">{errors.time.message}</small>) : ''
            }
          </div>

          {
                (!superEvaluators)
                && <FieldSkeleton label="Super Evaluators" />
            }
          {
                (superEvaluators)
                && (
                <div className="field campus-drive">
                  <div className="label">
                    Super Evaluators
                  </div>
                  <MultiSelect {...register('superEvaluator', { required: 'Super Evaluators are required.' })} value={superEvaluator} className="input" optionLabel="name" options={superEvaluators} placeholder="Select super evaluators" />
                  {
                        // @ts-ignore
                        errors.superEvaluator ? (<small className="error-label p-error">{errors.superEvaluator.message}</small>) : ''
                      }
                </div>
                )
            }

          {
                (!evaluators)
                && <FieldSkeleton label="Evaluators" />
            }
          {
                (evaluators)
                && (
                <div className="field campus-drive">
                  <div className="label">
                    Evaluators
                  </div>
                  <MultiSelect {...register('evaluator', { required: 'Evaluators are required.' })} value={evaluator} className="input" optionLabel="name" options={evaluators} placeholder="Select evaluators" />
                  {
                        // @ts-ignore
                        errors.evaluator?.type === 'required' ? (<small className="error-label p-error">{errors.evaluator.message}</small>) : ''
                      }
                </div>
                )
            }

          <div className="submit-button-container w-full flex align-items-center justify-content-end">
            <SpinCheckButton styleClass={`submit-button ${loadingStatus === 'LOADING' || loadingStatus === 'LOADED' ? 'icon-present' : ''}`} label="Schedule Exam" loadingStatus={loadingStatus} onClick={null} />
          </div>
        </form>
      </div>
      <Toast ref={toastRef} />

    </div>
  );
}
