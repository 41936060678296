const questionErrors = {
  QUESTION_TYPE_MISSING: 'Question Type is missing in question',
  CATEGORY_MISSING: 'Category is missing in questiong',
  QUESTION_MISSING: 'Question is missing in question',
  CORRECT_MISSING: 'Correct answer is missing in question',
  CORRECT_INVALID: 'Correct answer is invalid in question',
  POINTS_MISSING: 'Points is missing in question',
  POINTS_INVALID: 'Points value is invalid in question',
  MCQ_MISSING_OPTIONS: 'MCQ is missing options in question',
  MCQ_RANDOM_INVALID: 'Invalid random answers value in MCQ question',
  MSQ_MISSING_OPTIONS: 'MSQ is missing options in question',
  MSQ_RANDOM_INVALID: 'Invalid random answers value in MSQ question',
  MSQ_CORRECT_REDUNDANT: 'Redundant correct options for MSQ question',
}

export default questionErrors;
