import axios, { AxiosError, AxiosResponse } from 'axios';
import env from '../environment/env';
import { getJWT } from './authService';
// import store from '../store/store';

function axiosInit() {
  // const state = store.getState();

  /*
  * Adding base url to all the url
  */
  axios.defaults.baseURL = `${env.API_URL}`;

  const requestHandler = (request: any) => {
    /*
     * Interception request to add Authorization token
     * to header
     */

    const { url } = request;
    const substr = url.substring(1, 6);
    if (substr !== 'exams') {
      axios.defaults.baseURL = env.API_URL
      // axios.defaults.baseURL = 'https://5yfezlh0n1.execute-api.us-east-1.amazonaws.com/qa/api'
      request.baseURL = axios.defaults.baseURL
    } else {
      axios.defaults.baseURL = env.EXAM_API_URL
      // axios.defaults.baseURL = 'https://5yfezlh0n1.execute-api.us-east-1.amazonaws.com/qa/examapis'
      request.baseURL = axios.defaults.baseURL
    }
    if (!request.url.startsWith(env.S3_URL)) {
      if (!request.url.startsWith(env.CANDIDATE_LOGIN_URL)) {
        request.headers.Authorization = `Bearer ${getJWT()}`;
      }
    }

    return request;
  };

  const responseHandler = (response: AxiosResponse) => response;

  const requestErrorHandler = (error: AxiosError) => {
    // handle axios request errors here
    Promise.reject(error);
  }
  const responseErrorHandler = (error: AxiosError) =>
  // handle axios response errors here
    Promise.reject(error)

  axios.interceptors.request.use(
    (request: any) => requestHandler(request),
    (error: AxiosError) => requestErrorHandler(error),
  );

  axios.interceptors.response.use(
    (response: AxiosResponse) => responseHandler(response),
    (error: AxiosError) => responseErrorHandler(error),
  );
}

export default axiosInit;
