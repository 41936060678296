/* eslint-disable no-alert */
import React, { useEffect, useRef, useState } from 'react';
import './CandidateExamView.css';
import { Outlet, useNavigate } from 'react-router';
import { AxiosResponse } from 'axios';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
// import { Splitter, SplitterPanel } from 'primereact/splitter';
import CandidateNavbar from '../../../components/candidateNavbar/CandidateNavbar';
import {
  useAppDispatch, useAppSelector,
  // useAppSelector
} from '../../../store/storeHooks';
import ToastConstants from '../../../constants/toastConstants';
import { malpracticeEvent } from '../../../services/examService';
import MalpracticeConstants from '../../../constants/malpracticeConstants';
import RoutesConstants from '../../../constants/routesConstants';
import { setWelcomeToast } from '../../../store/authStore';
import exclamationtriangle from '../../../assets/icons/exclamationtriangle.svg';

function Admin() {
  const dispatch = useAppDispatch();
  const [dialogVisible, setDialogVisible] = useState<boolean>(false);
  // const switchstatus = useAppSelector((state) => state.exams.switchTabStatus);
  const [details, setDetails] = useState<string>('');
  const routesConstant = new RoutesConstants();
  const navigate = useNavigate();
  const toastConstants = new ToastConstants();
  const toastRef = useRef<any>(null);
  // const remaining = useAppSelector((state) => state.examSession.timeRemaining);
  const isRunning = useAppSelector((state) => state.examSession.isRunning);
  const timeTracker = useAppSelector((state) => state.examSession.timeTracker);
  const timeRemainingAlerts = useAppSelector((state) => state.counter.ExamRemainTimings);
  const [remainder, setRemainder] = useState<boolean>(false);
  const [timeRemaining, setTimeRemaining] = useState<string>('');
  // const examstate = useAppSelector((state) => state.exams);
  const malpractice = new MalpracticeConstants();
  dispatch(setWelcomeToast(true));
  const dialogClose = () => {
    setDialogVisible(false);
  }

  useEffect(() => {
    // if (timeTracker === 900 && isRunning) {
    //   setTimeRemaining('15');
    //   setRemainder(true);
    // }
    // if (timeTracker === 600 && isRunning) {
    //   setTimeRemaining('10');
    //   setRemainder(true);
    // }
    // if (timeTracker === 590 && isRunning) {
    //   setRemainder(false);
    // }
    // if (timeTracker === 890 && isRunning) {
    //   setRemainder(false);
    // }
    console.log(timeTracker);
    console.log(timeRemainingAlerts);
    if ((timeRemainingAlerts.includes(timeTracker)) && isRunning) {
      console.log(timeTracker);
      setTimeRemaining((timeTracker / 60).toString());
      setRemainder(true);
    }
    if ((timeRemainingAlerts.includes(timeTracker + 10)) && isRunning) {
      setRemainder(false);
    }
  }, [timeTracker]);

  const hideWarning = () => {
    setRemainder(false);
  }

  const malpracticeCall = (type:string) => {
    malpracticeEvent({
      action: type,
    }).then((res: AxiosResponse) => {
      if (!res.data.status) {
        setTimeout(() => {
          navigate(`${routesConstant.CANDIDATE}${routesConstant.CANDIDATE_TERMINATION}`);
        }, 0);
      }
    })
      .catch((e) => {
        toastRef.current.show({
          severity: 'error',
          summary: 'Something went wrong',
          detail: e.response.data.message,
        });
      })
  }

  window.onblur = () => {
    const currentUrl = location.href;
    if (!(currentUrl.endsWith(routesConstant.CANDIDATE_TERMINATION) || currentUrl.endsWith(routesConstant.CANDIDATE_END))) {
      setDialogVisible(true);
      setDetails(toastConstants.TAB_MALPRACTICE);
      malpracticeCall(`${malpractice.TAB_SWITCH}`)
    }
  };

  return (
    <div className="candidate-exam-view h-full w-full">
      <div className="section-1">
        <CandidateNavbar time="true" />
      </div>
      {
            (remainder)
            && (
              <div className="card time-alert">
                <div className="grid">
                  <div className="col-12 md:col-1 icon-clock">
                    <div className="pi pi-clock" />
                  </div>
                  <div className="col-12 md:col-10">
                    <div className="info flex justify-content-between">
                      {timeRemaining}
                      {' '}
                      mins Remaining!
                    </div>
                    <div className="sub-info font-family:Courgette">
                      You have
                      {' '}
                      {timeRemaining}
                      {' '}
                      mins to complete the exam
                    </div>
                  </div>
                  {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                  <div className="col-12 md:col-1 icon-close" onClick={hideWarning}>
                    <div className="pi pi-times" />
                  </div>
                </div>
              </div>
            // <div className="time-alert w-full"> style={{ height: '300px' }}
            //   <div className="time-alert-container flex-column justify-content-between align-items-center">
            //     <div className="pi pi-clock" />
            //     <span>
            //       <h4 className="flex justify-content-between">10 mins Remaining!</h4>
            //     </span>
            //     <h6 className="font-family:Courgette">You have 15 mins to complete the exam</h6>
            //   </div>
            // </div>
            )
        }

      <div
        className="section-2 w-full"
        onPaste={(e:any) => {
          setDialogVisible(true);
          e.preventDefault()
          setDetails(toastConstants.PASTE_MALPRACTICE);
          malpracticeCall(`${malpractice.PASTE}`);
          return false;
        }}
        onCopy={(e) => {
          setDialogVisible(true);
          e.preventDefault()
          setDetails(toastConstants.COPY_MALPRACTICE);
          malpracticeCall(`${malpractice.COPY}`);
          return false;
        }}
        onCut={(e:any) => {
          setDialogVisible(true);
          e.preventDefault()
          setDetails(toastConstants.CUT_MALPRACTICE);
          malpracticeCall(`${malpractice.CUT}`);
          return false;
        }}
      >
        <Outlet />
      </div>

      <Dialog
        className="malpractice-width"
        visible={dialogVisible}
        style={{ borderRadius: '8px', width: '450px', height: '230px' }}
        onHide={dialogClose}
        header={(
          <>
            <div className="warninglogo">
              <img src={exclamationtriangle} className="warlogo" alt="Presidio Hire Logo" />
            </div>
            <div className="malpractice-Header">
              Malpractice
            </div>
          </>
        )}
      >
        <div className="malpractice-main">
          <div className="malpractice-main-content flex align-items-center justify-content-center">
            <p className="dialog-message">{details}</p>
          </div>
          <div className="malpractice-button">
            <Button label="OK" className="p-button-danger" onClick={dialogClose} />
          </div>
        </div>
      </Dialog>
      <Toast ref={toastRef} />
    </div>
  );
}

export default Admin;
