import React, { useEffect, useState } from 'react';
import { candidateQuestion } from '../MarkSheetQuestion';
import './MarkSheetOEQC.css';

export default function MarkSheetOEQC({ markSheetDisplay, isCandidate, setResponseString }: candidateQuestion) {
  const [preFetchResponseString, setPreFetchResponseString] = useState<string>('');
  const [response, setResponse] = useState<string>('');
  useEffect(() => {
    if (markSheetDisplay.response) {
      setPreFetchResponseString(markSheetDisplay.response.answer);
      setResponse(markSheetDisplay.response.answer === null ? '' : markSheetDisplay.response.answer);
    } else setResponse('');
  }, [markSheetDisplay]);

  const onInputHandler = (e: any) => {
    setResponse(e.target.value);
  }

  useEffect(() => {
    if (response === preFetchResponseString) setResponseString(undefined);
    else if (response.trim().length !== 0) setResponseString(response);
    else if (response.trim().length === 0) setResponseString(null);
  }, [response])

  return (
    <div>
      <div className="markSheet-oeqt-container">
        <textarea className="markSheet-oeqt-text" onInput={onInputHandler} value={response} disabled={!isCandidate} />
      </div>
    </div>
  )
}
