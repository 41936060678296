import React from 'react';
import { format, getDate, getYear } from 'date-fns';
import IListCandidate from '../../models/IListCandidate';
import EditIcon from '../../assets/icons/EditIcon.svg';

type IListCandidatesRowProps = {
  candidate: IListCandidate,
    updateCandidateVisible: Function,
}

export default function ListCandidatesRow({
  candidate, updateCandidateVisible,
}: IListCandidatesRowProps) {
  const getDOB = (dob: string) => {
    const date = new Date(dob);
    return `${getDate(date)} ${format(date, 'MMM')} ${getYear(date)}`;
  }

  return (
    <div className="row w-full">
      <div
        style={{ cursor: 'pointer' }}
        role="button"
        tabIndex={0}
        onKeyPress={() => updateCandidateVisible(candidate.id)}
        onClick={() => updateCandidateVisible(candidate.id)}
        className="edit cell"
      >
        <img src={EditIcon} alt="Edit Candidate" id="college-dropdown-content-edit" />
      </div>
      <div className="name cell">
        <div className="text-ellipsis">
          {candidate.name}
        </div>
      </div>
      <div className="reg-no cell">
        {candidate.regNo}
      </div>
      <div className="dob cell">
        {getDOB(candidate.dob)}
      </div>
      <div className="gender cell">
        {candidate.gender}
      </div>
      <div className="invite cell">
        {candidate.emailStatus}
      </div>
    </div>
  )
}
