import React, { useEffect, useRef, useState } from 'react';
import './ReEvaluateExamPage.css';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { AxiosResponse } from 'axios';
import { useLocation } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import { useNavigate } from 'react-router';
import IListQuestion from '../../models/IListQuestion';
import {
  ActionButton,
} from '../index';
// import { useAppDispatch } from '../../store/storeHooks';
import { getAllCategory, getQuestionforReevaluation, reevaluateQuestion } from '../../services/examService';
import { ICategory } from '../../models/ICategory';

export default function ReEvaluateExamPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const toastRef = useRef<any>(null);
  // const dispatch = useAppDispatch();
  const [startingQuestion, setStatingQuestion] = useState(0);

  const [questionSearch, setQuestionSearch] = useState<any>('');
  const [questionFilter] = useState<any>('');
  const [selectedQuestion, setSelectedQuestion] = useState<any>([]);
  const examId = String(location.pathname.split('/').pop());
  const [questions, setQuestions] = useState<IListQuestion[] | null>(null);
  const [copyOfQuestions, setCopyOfQuestions] = useState<IListQuestion[] | null>(null);
  const [categories, setCategories] = useState<ICategory[] | null>(null);
  const [allquestions, setAllQuestions] = useState<boolean>(false);
  const toast = (severity: string, summary: string, detail: string) => {
    toastRef.current.show({
      severity,
      summary,
      detail,
      life: 3000,
    })
  }
  let result1 = 'N/A'
  const getCategoryName = (categoryId: string) => {
    if (categories) {
      categories.forEach((category) => {
        if (category.id === categoryId) {
          result1 = category.name;
        }
      })
    }
    return (result1);
    // for (let i = 0; i < categories.length; i += 1) {
    //   if (categories[i].id === categoryId) {
    //     result = categories[i].name
    //   }
    // }
    // return result;
  }

  // const questionFiltersOption = [
  //   {
  //     label: 'Question Type',
  //     code: 'QT',
  //     items: [
  //       { id: questionConstants.OEQC, name: questionConstants.OEQC },
  //       { id: questionConstants.OEQT, name: questionConstants.OEQT },
  //     ],
  //   },
  //   {
  //     label: 'Categories',
  //     code: 'CT',
  //     items: categories,
  //   },
  // ];

  useEffect(() => {
    getQuestionforReevaluation(examId)
      .then((response: AxiosResponse) => {
        // dispatch(setQuestionData(response.data));
        const val = response.data.data;
        if (val.length > 0) {
          setQuestions(response.data.data);
        }
        setCopyOfQuestions(response.data.data);
      })
    getAllCategory()
      .then((response: AxiosResponse) => {
        setCategories(response.data);
      })
      .catch((e) => {
        toast(
          'error',
          'Error',
          e.response.data.message,
        )
      })
  }, []);

  useEffect(() => {
    const selectedCities = [...selectedQuestion];
    if (questions) {
      questions.forEach((value) => {
        if (value.isReEvaluate === true) {
          if (!selectedCities.includes(value.id)) {
            selectedCities.push(value.id);
          }
        }
      })
      setSelectedQuestion(selectedCities);
    }
  }, [questions]);

  const handleNextPageClick = () => {
    if (startingQuestion + 8 < questions!.length) setStatingQuestion(startingQuestion + 8)
  }

  const handlePreviousPageClick = () => {
    if (startingQuestion - 8 >= 0) setStatingQuestion(startingQuestion - 8)
  }

  const handleReEvaluateClick = () => {
    const data = {
      examId,
      selectedQuestion,
    }
    reevaluateQuestion(data)
      .then((response: AxiosResponse) => {
        if (response.data.status === true) {
          toast(
            'success',
            'Success',
            response.data.message,
          )
        }
      })
  }
  const onSearchQueryChange = (value: any) => {
    setQuestionSearch(value);
    let result = copyOfQuestions;

    if (value !== '' && questionFilter === '') {
      // @ts-ignore
      result = copyOfQuestions.filter((question: IListQuestion) => question.question.toLowerCase().includes(value.toLowerCase()));
    } else if (value !== '' && questionFilter !== '') {
      // @ts-ignore
      result = copyOfQuestions.filter((question: IListQuestion) => question.question.toLowerCase().includes(value.toLowerCase()));
    } else if ((value === '' || value === undefined) && questionFilter === '') {
      result = copyOfQuestions;
      setQuestionSearch('');
    }
    setQuestions(result);
  }
  const onSelectedQuestionChange = (e: { value: any, checked: boolean }) => {
    const selectedCities = [...selectedQuestion];
    if (e.checked) { selectedCities.push(e.value); } else selectedCities.splice(selectedCities.indexOf(e.value), 1);
    setSelectedQuestion(selectedCities);
  }

  const handleSelectAllQuestions = () => {
    if (allquestions === false) {
      setAllQuestions(true);
      const selectQuestion:any = [];
      questions?.forEach((question) => {
        selectQuestion.push(question.id);
      })
      setSelectedQuestion(selectQuestion);
    } else {
      setAllQuestions(false);
      const selectQuestion:any = [];
      setSelectedQuestion(selectQuestion);
    }
  }
  const goBackClick = () => {
    navigate(-1);
  };

  const goBackEnter = () => {
    // @ts-ignore
    if (event.key === 'Enter') {
      goBackClick();
    }
  };

  return (
    <div className="list-questions-container w-full">
      <div className="list-questions-reevaluate-container-header w-full flex align-items-center justify-content-between">
        <div className="title-container flex flex-column align-items-start justify-content-center h-full">
          <div role="button" tabIndex={0} onClick={goBackClick} onKeyDown={goBackEnter} className="back-navigation flex align-items-center justify-content-start cursor-pointer">
            <i className="pi pi-angle-left font-semibold" />
            <div className="label font-medium">
              Back
            </div>
          </div>
          <div className="title font-semibold">
            Questions
          </div>
        </div>
        <div className="filter-container flex align-items-center justify-content-between">
          <div className="filter-fields">
            <div className="p-inputgroup">
              <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText value={questionSearch} className="reevaluate-search" onChange={(e) => onSearchQueryChange(e.target.value)} placeholder="Search by question" />
              </span>
              {/* <span className="ml-2"> */}
              {/*   <ActionButton type="primary" label="Search" clickHandler={handlePreviousPageClick} /> */}
              {/* </span> */}
              {/* <span className="p-input-icon-left flex align-items-center"> */}
              {/*   <img src={FilterIcon} className="filter-icon" alt="Filter" /> */}
              {/*   <Dropdown */}
              {/*     value={questionFilter} */}
              {/*     options={questionFiltersOption} */}
              {/*     onChange={onFilterQueryChange} */}
              {/*     optionLabel="name" */}
              {/*     optionGroupLabel="label" */}
              {/*     optionGroupChildren="items" */}
              {/*     showClear={questionFilter !== '' && questionFilter != null} */}
              {/*     placeholder="Filter" */}
              {/*   /> */}
              {/* </span> */}
            </div>

          </div>
        </div>
      </div>
      <div className="list-questions-reevaluate-container w-full">
        <div className="list-questions-reevaluate-table w-full">
          <div className="row-header row w-full">
            <div className="question-reevaluate cell pl-0">
              <div className="field-checkbox pr-2 mt-3 pl-0">
                <Checkbox inputId="city1" name="city" value="" checked={(allquestions === true)} onChange={handleSelectAllQuestions} />
              </div>
              QUESTION
            </div>
            <div className="exam cell">
              QUESTION TYPE
            </div>
            <div className="category cell">
              CATEGORY
            </div>
          </div>
          {(questions === null || questions === [])
            && (
              <div className="empty-questions-reevalaute flex align-items-center justify-content-center">
                No questions to display.
              </div>
            )}
          { (questions?.length !== 0)
            && questions?.map((question: IListQuestion, index: number) => {
              if (index >= startingQuestion && index < startingQuestion + 8) {
                return (
                  <div key={question.id} className="row w-full">
                    <div className="field-checkbox ml-3 mt-3">
                      <Checkbox inputId="city1" name="city" value={question.id} checked={selectedQuestion.indexOf(question.id) !== -1 || (question.isReEvaluate === true && selectedQuestion.includes(question.id))} onChange={onSelectedQuestionChange} />
                    </div>
                    <div className="question cell">
                      <div className="text-ellipsis">
                        {question.question}
                      </div>
                    </div>
                    <div className="exam cell">
                      {question.type}
                    </div>
                    <div className="category cell">
                      {getCategoryName(question.categoryId)}
                    </div>
                  </div>
                )
              }
              return <div key={question.id} />
            })}

        </div>

        <div className="footer w-full flex align-items-center justify-content-between">
          <div className="result-details">
            {
                questions !== null
                && `Showing ${(questions!.length === 0) ? 0 : (startingQuestion + 1)} to ${(startingQuestion + 8 > questions!.length) ? questions!.length : (startingQuestion + 8)} of ${questions!.length} results`
              }
          </div>
          <div className="pagination-buttons flex">

            {
              (startingQuestion >= 5) && (
              <div className="qns-previous-button">
                <ActionButton type="primary" label="Previous" clickHandler={handlePreviousPageClick} />
              </div>
              )
            }
            {
                (questions !== null) && (startingQuestion + 5 < questions!.length) && (
                <div className="qns-next-button">
                  <ActionButton type="primary" label="Next" clickHandler={handleNextPageClick} />
                </div>
                )
            }
            <div className="re-evaluate-button ml-2">
              <ActionButton type="primary" label="Re-Evaluate" clickHandler={handleReEvaluateClick} />
            </div>
          </div>
        </div>
      </div>
      <Toast ref={toastRef} />
    </div>
  )
}
