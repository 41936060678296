export class RolesConstants {
  EVALUATOR: string = 'EVL';

  SUPER_EVALUATOR: string = 'SEVL';

  ADMIN: string = 'ADM';

  CANDIDATE: string = 'STD';
}

export default RolesConstants;
